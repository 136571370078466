import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'fillColor' && prop !== 'baseColor' })(
  ({ theme, fillColor, baseColor }) => ({
    clipPath: "path('M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z')",
    background: `linear-gradient(to bottom, ${fillColor || theme.colors.sky[600]} 50%, ${baseColor || theme.colors.sky[300]} 50%)`,
    backgroundSize: '100% 200%',
    backgroundPosition: 'bottom left',
    transition: 'all .5s ease-out',
    width: 20,
    height: 20,
    animation: 'slideDown 1.5s linear infinite',
    '@keyframes slideDown': {
      '0%': {
        backgroundPosition: 'bottom left',
      },
      '100%': {
        backgroundPosition: 'top left',
      },
    },
  }),
)

const AnimatedDownloadIcon = ({ baseColor, fillColor }) => {
  return (
    <StyledBox baseColor={baseColor} fillColor={fillColor} />
  )
}

AnimatedDownloadIcon.propTypes = {
  baseColor: PropTypes.string,
  fillColor: PropTypes.string,
}

export default AnimatedDownloadIcon

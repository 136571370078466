import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { capitalize } from '@mui/material'
import { getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom'

import AdjustNavigation, { ADJUST_MODES } from './components/AdjustNavigation'
import RotateNavigation from './components/RotateNavigation'
import Slider from './components/Slider'
import { IMAGE_EDITOR_VARIANT } from './NewImageEditor.constants'
import {
  AdjustNavigationWrapper,
  AdjustSliderTitle,
  ImageEditorCropperContainer,
  StyledCropperFade,
  StyledCropperWrapper,
} from './NewImageEditor.style'

const CropperWrapper = ({
  cropper,
  children,
  className,
  navigationProps = {},
  variant,
  adjustments,
  setAdjustments,
  onDownload,
}) => {
  const navigationRef = useRef(null)
  const [mode, setMode] = useState(ADJUST_MODES.SATURATION)

  const state = cropper.getState()

  const transitions = cropper.getTransitions()

  const transforms = cropper.getTransforms()
  const { rotate } = transforms


  useEffect(() => {
    navigationRef.current?.refresh()
  }, [state?.boundary.width, state?.boundary.height])

  const onChangeValue = (value) => {
    if (mode in adjustments) {
      setAdjustments((previousValue) => ({
        ...previousValue,
        [mode]: value,
      }))
    }
  }

  const handleRotate = (angle, options) => {
    cropper.rotateImage(angle, options)
    // console.log(angle, options, cropper)
    // console.log(cropper.getState())
    if (!options) {
      cropper.setCoordinates(({ imageSize }) => {
        return {
          width: Math.max(imageSize.width, imageSize.height),
          height: Math.max(imageSize.width, imageSize.height),
        }
      })
    }
  }

  const handleRotateEnd = (...args) => {
    const zoom = getZoomFactor(cropper.getState(), cropper.getSettings(), 0)
    cropper.zoomImage(zoom, {
      transitions: true,
    })
    cropper.setCoordinates(({ visibleArea }) => ({
      width: visibleArea.width,
      height: visibleArea.height,
    }))
    cropper.transformImageEnd(...args)
  }

  return (
    <StyledCropperWrapper className={className} variant={variant}>
      <StyledCropperFade visible={true}>
        <ImageEditorCropperContainer>
          {children}
        </ImageEditorCropperContainer>

        {variant === IMAGE_EDITOR_VARIANT.CROP && (
          <RotateNavigation
            ref={navigationRef}
            value={rotate}
            onRotate={handleRotate}
            onRotateEnd={handleRotateEnd}
            onFlip={cropper.flipImage}
            className={cl(navigationProps.className)}
            buttonClassName={navigationProps.buttonClassName}
            barClassName={navigationProps.barClassName}
            valueBarClassName={navigationProps.valueBarClassName}
            zeroBarClassName={navigationProps.zeroBarClassName}
            highlightedBarClassName={navigationProps.highlightedBarClassName}
            disabled={transitions.active}
          />
        )}

        {variant === IMAGE_EDITOR_VARIANT.ADJUST && (
          <AdjustNavigationWrapper>
            <AdjustSliderTitle component={'div'}>{capitalize(mode)}</AdjustSliderTitle>
            <Slider value={adjustments[mode]} onChange={onChangeValue} />
            <AdjustNavigation mode={mode} onChange={setMode} onDownload={onDownload} />
          </AdjustNavigationWrapper>
        )}
      </StyledCropperFade>
    </StyledCropperWrapper>
  )
}

// NOTE: prop types are take from corresponding d.ts
CropperWrapper.propTypes = {
  cropper: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  navigationProps: PropTypes.object,
  variant: PropTypes.oneOf([IMAGE_EDITOR_VARIANT.CROP, IMAGE_EDITOR_VARIANT.ADJUST]),
  adjustments: PropTypes.object,
  setAdjustments: PropTypes.func,
  onDownload: PropTypes.func,
}

export default CropperWrapper

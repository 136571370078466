import { styled } from '@mui/material/styles'

export default styled('div')`
  height: 100%;
  width: 100%;

  .select-all {
    margin-left: 0;
  }

  .select-all-checkbox {
    padding: 6px;
  }
`

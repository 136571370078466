import React, { memo } from 'react'
import { bool, object, string } from 'prop-types'

import { useStoreActions } from 'easy-peasy'

import InlineEdit from 'components/FormFields/InlineEdit'

import Style, { Caption, Label } from './PictureMeta.style'
import PictureTagsList from './PictureTagsList'

const PictureMeta = ({
  color,
  editable,
  hideName,
  hideGrade,
  hideTitle,
  hideTags,
  picture,
  alignment,
  placement,
  size = 'large',
}) => {
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)

  const handleBlur = async (value) => updatePicture({ picture: { id: picture.id, title: value } })

  const label = [
    !hideName && picture.child && picture.child.name,
    !hideGrade && picture.label && picture.label.name,
  ].filter(Boolean).join(', ')

  return (
    <Style alignment={alignment}>
      {editable && !hideTitle ? (
        <InlineEdit
          alignment={alignment}
          placement={placement}
          onChange={handleBlur}
          defaultValue={picture.title}
        />
      ) : (
        <Caption className={`label uppercase truncated ${color || 'darkgray'}`} size={size}>
          {!hideTitle && picture.title}
        </Caption>
      )}

      <Label className={'__label'} size={size}>{label}</Label>

      {picture.tags && !hideTags && (
        <PictureTagsList
          tags={picture.tags}
          alignment={picture.alignment}
        />
      )}
    </Style>
  )
}

PictureMeta.propTypes = {
  color: string,
  editable: bool,
  hideGrade: bool,
  hideTitle: bool,
  hideName: bool,
  hideTags: bool,
  picture: object.isRequired,
  size: string,
  alignment: string,
  placement: string,
}

const MemoPictureMeta = memo(PictureMeta)
export default MemoPictureMeta

import React from 'react'
import PropTypes from 'prop-types'

import ImageSkeleton from 'components/Image/Image.skeleton'
import { ListPageWrap } from 'components/Picture/List/List.style'
import PictureMeta from 'components/PictureMeta'
import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import withCaptions from 'hoc/withCaptions'

import PictureLabel, { ContentWrap, Placeholder, RetryIcon, Wrap } from './UploadPictureWrap.style'

const PictureWrap = ({ className, children, onClick }) => {
  return (
    <Wrap className={className} onClick={onClick}>
      <ContentWrap>
        <Placeholder>
          {children}
        </Placeholder>
      </ContentWrap>
    </Wrap>
  )
}
PictureWrap.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

const PictureWrapWithLabel = withCaptions(PictureWrap)

const {
  FAILED,
  QUEUED,
  IN_PROGRESS,
  RETRY,
} = UPLOADING_IMAGE_STATUSES

const UploadPictureWrap = ({
  onClick,
  picture,
  showUploads,
  hideTags,
  size = 'large',
  view,
}) => {
  if (!showUploads && picture.status === RETRY) return null

  const isGridView = view === 'grid'
  const PageWrap = isGridView ? PictureWrapWithLabel : ListPageWrap

  switch (picture.status) {
    case QUEUED:
    case IN_PROGRESS:
      return (
        <PageWrap
          className={'bg-lightgray flex flex-column'}
          onClick={onClick}
          labelBtm={
            isGridView ? (
              <PictureMeta color={'darkblue'} size={size} picture={picture} hideTags={hideTags} />
            ) : null
          }
        >
          <ImageSkeleton />
        </PageWrap>
      )
    case RETRY:
      return (
        <PageWrap
          className={'bg-lightgray pointer flex flex-column align-center justify-center'}
          onClick={onClick}
          labelBtm={
            isGridView ? (
              <PictureMeta
                color={'red'}
                size={size}
                picture={{ title: 'Upload Failed', child: { name: 'click to retry' } }}
                hideTags={hideTags}
              />
            ) : null
          }
        >
          <RetryIcon fontSize={isGridView ? 'large' : 'small'} />
          {isGridView && <PictureLabel className={'label'}>{picture?.name}</PictureLabel>}
        </PageWrap>
      )
    case FAILED:
      return (
        <PageWrap
          className={'bg-lightgray pointer flex flex-column align-center justify-center'}
          onClick={onClick}
          labelBtm={
            isGridView ? (
              <PictureMeta
                color={'red'}
                size={size}
                picture={{ title: 'Upload Failed', child: { name: picture?.message } }}
                hideTags={hideTags}
              />
            ) : null
          }
        >
          {isGridView && <PictureLabel className={'label'}>Not Allowed</PictureLabel>}
        </PageWrap>
      )
  }
}

UploadPictureWrap.propTypes = {
  picture: PropTypes.object,
  onClick: PropTypes.func,
  showUploads: PropTypes.bool,
  hideTags: PropTypes.bool,
  size: PropTypes.string,
  view: PropTypes.string,
}

export default UploadPictureWrap

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Collapse, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'

import UploaderQueueFolder from './UploaderQueueFolder'
import UploadQueueHeader from './UploadQueueHeader'

const useStyles = makeStyles(() => ({
  root: {
    width: 380,
    maxHeight: 400,
    borderRadius: 0,
    overflowY: 'auto',
  },
}))

const UploadQueueList = ({ id, title, subtitle, tree, disabledClose, onClose }) => {
  const [active, setActive] = useState(false)
  const classes = useStyles()

  const handleToggle = () => {
    setActive((value) => !value)
  }

  const handleClose = () => {
    onClose(id)
  }

  return (
    <div>
      <UploadQueueHeader
        title={title}
        subtitle={subtitle}
        active={active}
        onToggle={handleToggle}
        onClose={handleClose}
        disabledClose={disabledClose}
      />
      <Collapse in={active} timeout={'auto'} unmountOnExit>
        <Paper elevation={0} className={classes.root}>
          {!!tree && tree.map((item) => (
            <UploaderQueueFolder
              key={item.key}
              subtitle={item.subtitle}
              name={item.name}
              date={item.date}
              files={item.files}
              type={item.type}
            />
          ))}
        </Paper>
      </Collapse>
    </div>
  )
}

UploadQueueList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tree: PropTypes.array,
  disabledClose: PropTypes.bool,
  onClose: PropTypes.func,
}

export default UploadQueueList

import { styled } from '@mui/material/styles'

export default styled('div')`
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      margin: 0.5rem 1rem;
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'

import { BodyText } from 'components/NewModal/NewModal.style'

const ConfirmDeleteTextLabelsContent = ({ labelName }) => {
  return (
    <BodyText sx={{ mt: 3 }}>
      Are you sure that you want to delete{' '}
      <Typography variant={'strong'}>“{labelName}”</Typography>?
      It will be deleted from all images to which it’s applied.
    </BodyText>
  )
}

ConfirmDeleteTextLabelsContent.propTypes = {
  labelName: PropTypes.string,
}

export default ConfirmDeleteTextLabelsContent

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .referral-modal {
    max-width: 1000px;

    .__content {
      @media (min-width: ${theme.breakpoints.md}) {
        display: grid;
        height: 90%;
        grid-template-columns: 3fr 4fr;
      }

      @media (max-width: ${theme.breakpoints.md}) {
        min-width: 400px;
      }
    }

    .__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 2rem;
    }

    .__share-button {
      align-self: center;
      margin: 1rem 0;
    }
  }
`

import React from 'react'
import { bool, func, node, string } from 'prop-types'

import FormControlLabel from '@mui/material/FormControlLabel'
import { makeStyles } from '@mui/styles'

import Checkbox from 'components/FormFields/Checkbox/Checkbox.style'

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '0.875rem',
    fontFamily: theme.typography.secondary.fontFamily,
    fontWeight: theme.typography.fontWeightLight,
  },
}))

const SelectVisibleControl = ({ checked, onChange, label, name }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      className={'input-wrap select-all'}
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          color={'primary'}
          className={'select-all-checkbox'}
        />
      }
      label={label}
      classes={classes}
    />
  )
}

SelectVisibleControl.propTypes = {
  checked: bool,
  onChange: func,
  label: node,
  name: string,
}

export default SelectVisibleControl

const theme = {
  breakpoints: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1600px',
  },
  nav: {
    height: '64px',
    mobileHeight: '54px',
  },
  colors: {
    secondary: {
      light: '#E3F2FD',
      med: '#86D1FF',
      main: '#50bdff',
      dark: '#009FFF',
    },
    primary: {
      light: '#1D40CF',
      med: '#1936AF',
      main: '#142C90',
      dark: '#102371',
    },
    blue: '#009FFF',
    darkblue: '#102371',
    darkgray: '#707070',
    grey: '#333333',
    lightblue: '#0FA5FF',
    lightgray: '#D6D6D6',
    medgray: '#B1B1B1',
    red: '#E80000',
    snowgray: '#FAFAFA',

    // New Palette
    white: '#FFFFFF',
    black: '#1E202B',
    gray: {
      50: '#FAFAFA',
      100: '#ECECEE',
      300: '#D8D9DD',
      400: '#BBBDC4',
      500: '#9EA0AB',
      700: '#646778',
      900: '#3D4156',
    },
    green: {
      100: '#E1F6E8',
      500: '#B1D7BE',
      700: '#208E46',
    },
    yellow: {
      100: '#FEEEC7',
      500: '#FDE4A5',
      600: '#FBCE5F',
      700: '#E39F00',
    },
    orange: {
      500: '#F58100',
    },
    newRed: {
      100: '#F7DCD7',
      700: '#D84E37',
      900: '#FF2400',
    },
    pink: {
      100: '#F8E2ED',
      700: '#C95F98',
    },
    sky: {
      100: '#F5F9FA',
      200: '#EFF6FB',
      300: '#E6F1FC',
      400: '#DAEDFC',
      500: '#C5DFF7',
      600: '#66C5FF',
      700: '#009FFF',
      900: '#0A65C7',
    },
    newBlue: {
      700: '#142C90',
      900: '#102371',
    },
    newPaperShadow: '#0B279E',
    violet: {
      100: '#EDEBF4',
      200: '#E8E6F1',
      300: '#E2DEED',
      500: '#CAC3DF',
      700: '#7A68AE',
    },
    purple: {
      100: '#EDE5F9',
      700: '#9361DE',
    },
  },
  typography: {
    type: {
      primary: '"Montserrat", sans-serif',
      secondary: '"Montserrat", sans-serif',
      foco: '"Foco", sans-serif',
      montserrat: '"Montserrat", sans-serif',
    },
    size: {
      xxs: '0.375rem', // 6px
      xs2: '0.625rem', // 10px
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      md: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      xl2: '1.5rem', // 24px
      xl3: '1.875rem', // 30px
      xl4: '2.25rem', // 36px
      xl5: '3rem', // 48px
      xl6: '4rem', // 64px
    },
  },
}

export default theme

const axios = require('axios')
const AuthError = require('./auth.error')
const { proxySessionCookie, clearSession } = require('./auth.utils')
const { getSessionCookie, SESSION_COOKIE_NAME } = require('../../../lib/cookies')

exports.usersLogin = async (req) => {
  const fetchRes = await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/login`, {
    user: { ...req.body },
  })

  if (!fetchRes) return
  return await fetchRes.headers['set-cookie'][0]
}

exports.usersLogout = async (req, res) => {
  await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/logout`)
  clearSession(res, req)
  return
}

exports.usersSignUp = async (req) => {
  await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/sign_up`, {
    user: { ...req.body },
  })

  return await this.usersLogin(req)
}

exports.cookieAuth = async ({ req, res }) => {
  // check user has cookie
  const cookie = req.headers.cookie || ''
  const sessionCookie = getSessionCookie(cookie)
  const { activation_token: activationToken, redirect } = req.query
  if (!sessionCookie && !activationToken) {
    throw new AuthError(
      'Request failed with status code 401',
      { status: 401, redirectPath: 'signin', query: { redirect: redirect || req.url } },
    )
  }
  // submit request to ensure session still valid
  try {
    const userRes = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/auth`, {
      headers: {
        Cookie: `${SESSION_COOKIE_NAME}=${sessionCookie}`,
        'Key-Inflection': 'camel',
      },
    })
    // Pass user data to app response context
    res.userData = userRes.data.user || {}
    res.adminData = userRes.data.admin || {}
    res.csrfToken = userRes.headers['x-csrf-token']

    proxySessionCookie(res, req, userRes.headers['set-cookie'][0])
    return res
  } catch (error) {
    if (activationToken) {
      throw new AuthError(
        'Request failed with status code 401',
        { status: 401, redirectPath: 'activate-account', query: { redirect: req.url, token: activationToken } },
      )
    }
    const response = error.response
    throw new AuthError(
      error.message,
      { status: response && response.status, redirectPath: 'signin', query: { redirect: req.query.redirect || req.url } },
    )
  }
}

exports.validateResetToken = async ({ req }) => {
  const { token } = req.query

  try {
    return await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/validate_reset_password_token?token=${token}`)
  } catch (error) {
    throw new AuthError(error.message, { status: error.response.status, redirectPath: 'signin' })
  }
}

exports.validateActivationToken = async ({ req }) => {
  const { token } = req.query

  try {
    return await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/validate_activation_token?token=${token}`)
  } catch (error) {
    const query = {}
    if (req.query.redirect) {
      query.redirect = req.query.redirect
    }

    throw new AuthError(error.message, { status: error.response.status, redirectPath: 'signin', query })
  }
}

exports.resetPassword = async (req) => {
  try {
    const resetRes = await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/reset_password`, {
      user: { ...req.body },
    })

    return resetRes.headers['set-cookie'][0]
  } catch (error) {
    const response = error.response
    throw new AuthError(error.message, { status: response && response.status, redirectPath: 'signin' })
  }
}

exports.activateAccount = async (req) => {
  try {
    const activateRes = await axios.put(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/users/activate_account`, {
      user: { ...req.body },
    })

    return activateRes.headers['set-cookie'][0]
  } catch (error) {
    const response = error.response
    throw new AuthError(error.message, { status: response && response.status, redirectPath: 'signin' })
  }
}

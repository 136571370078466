import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CircularProgress, InputLabel } from '@mui/material'
import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'

import { MIXED_CONTENT_KEYWORD } from 'components/EditPageTextForm/EditPageTextForm.utils'
import { NOOP } from 'utils/constants'

import { caseInsensitiveCompare, getOptionLabel, normalizeValue } from './utils'

const filter = createFilterOptions()

const useStyles = makeStyles(() => ({
  tag: {
    borderRadius: 3,
    backgroundColor: '#D6D6D6',
    color: '#707070',
  },
  hasPopupIcon: {},
  inputRoot: {
    '$hasPopupIcon &.Mui-disabled': {
      paddingRight: 10,
    },
  },
}))

const useInputStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
}))


const Autocomplete = forwardRef(({
  options,
  label,
  creatable,
  isValidNewOption,
  value,
  maxLength,
  helperHint,
  error,
  loading,
  disabled,
  variant = '',
  renderTags,
  paperComponent,
  isMixedTagsEdit,
  isBulkEdit,
  disableAutofill = false,
  onAutocompleteChange,
  onChange,
  onInputBlur,
  onInputChange,
  onSelect,
  ...props
}, ref) => {
  const classes = useStyles()
  const inputClasses = useInputStyles()

  const [isLoading, setIsLoading] = useState(false)

  const autoCompleteOptions = useMemo(() => {
    if (!value) {
      return options
    }

    const additionalValue = (
      Array.isArray(value) ? value : [value]
    ).filter((v) => !options.some((o) => o.value === v.value))

    return [...options, ...additionalValue]
  }, [options, value])

  const filterOptions = useCallback((filteredOptions, params) => {
    const filtered = filter(filteredOptions, params)
    // BE has case insensitive comparison, so "tagName" and "tagname" is similar on BE side
    // so FE need to compare tags insensitive too. Also tags trimmed at BE side.

    const equalExistsValue =
      autoCompleteOptions.some(({ label }) => caseInsensitiveCompare(label, params?.inputValue))
    // Suggest the creation of a new value
    if (params.inputValue !== '' && !equalExistsValue) {
      filtered.push({
        value: params.inputValue,
        label: `Add "${params.inputValue}"`,
        __isNew__: true,
      })
    }

    return filtered
  }, [autoCompleteOptions])

  const isOptionEqualToValue = useCallback((option, test) => option.value === test?.value, [])

  const restProps = creatable ? {
    filterOptions,
    filterSelectedOptions: true,
    selectOnFocus: true,
    clearOnBlur: true,
  } : {
    disableClearable: true,
  }

  const inputProps = {
    error: !!error,
    helperText: error?.message || error || helperHint,
    onSelect,
  }

  const handleChange = (_e, nextValue) => {
    if (typeof onChange !== 'function') {
      return null
    }

    const changePromise = onChange(
      Array.isArray(nextValue)
        ? nextValue.reduce((acc, o) => {
          const normalized = normalizeValue(o, autoCompleteOptions, value)
          if (normalized) {
            acc.push(normalized)
          }
          return acc
        }, [])
        : nextValue && normalizeValue(nextValue, autoCompleteOptions, value))


    if (changePromise && typeof changePromise.then === 'function' && changePromise[Symbol.toStringTag] === 'Promise') {
      // is compliant native promise implementation
      setIsLoading(true)
      changePromise.then(() => {
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      })
    }

    onAutocompleteChange?.()
    return changePromise
  }

  return (
    <>
      {label && (
        <>
          {typeof label === 'string' ? (
            <InputLabel htmlFor={'component-outlined'}>{label}</InputLabel>
          ) : label}
        </>
      )}
      <MuiAutocomplete
        options={autoCompleteOptions}
        getOptionLabel={getOptionLabel}
        freeSolo
        PaperComponent={paperComponent}
        renderTags={isMixedTagsEdit ? NOOP : renderTags}
        renderInput={(params) => {
          const inputValue = isMixedTagsEdit ?
            (params.inputProps.value || value) :
            // if tags have "Mixed" value - show it as input, so it can be erased
            params.inputProps.value

          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: disableAutofill ? 'off' : params.id,
                maxLength,
                value: inputValue,
                style: {
                  fontStyle: isBulkEdit && inputValue === MIXED_CONTENT_KEYWORD ? 'italic' : 'normal',
                },
                onChange: (e) => {
                  onInputChange?.(e)
                  if (!e.target.value && isMixedTagsEdit) {
                    handleChange([]) // if user remove Mixed - it means, that he want to remove all tags
                  } else {
                    params.inputProps.onChange(e)
                  }
                },
                onBlur: (e) => {
                  onInputBlur?.(e)
                  params.inputProps.onBlur(e)
                },
              }}
              InputProps={{
                ...params.InputProps,
                variant: variant,
                endAdornment: loading || isLoading ?
                  <CircularProgress color={'inherit'} size={20} /> : params.InputProps.endAdornment,
              }}
              inputRef={ref}
              classes={inputClasses}
              margin={'dense'}
              variant={'outlined'}
              autoComplete={params.id}
              autoCorrect={'off'}
              {...inputProps}
            />
          )
        }}
        {...props}
        {...restProps}
        disabled={disabled || loading || isLoading}
        // If isMixedTagsEdit is true, set value to an empty array;
        // this is necessary for the autocomplete to correctly handle the "Mixed" string value display.
        value={isMixedTagsEdit ? [] : value}
        classes={classes}
        ChipProps={!renderTags ? {
          size: 'small',
          deleteIcon: <ClearIcon />,
        } : null}
        popupIcon={<ExpandMoreIcon />}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={(optionProps, option) => (
          <li {...optionProps} key={optionProps.key}>
            {option.__isNew__ ? (
              <AddIcon sx={{ mr: 1, fontSize: '1.25rem', color: '#9EA0AB', mt: '-2px', lineHeight: 1 }} />
            ) : null}
            <span>{getOptionLabel(option)}</span>
          </li>
        )}
      />
    </>
  )
})

Autocomplete.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  noOptionsText: PropTypes.string,
  creatable: PropTypes.bool,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  maxLength: PropTypes.number,
  helperHint: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.string,
  renderTags: PropTypes.func,
  paperComponent: PropTypes.func,
  isBulkEdit: PropTypes.bool,
  isMixedTagsEdit: PropTypes.bool,
  disableAutofill: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputBlur: PropTypes.func,
  onAutocompleteChange: PropTypes.func,
  onSelect: PropTypes.func,
}

export default Autocomplete

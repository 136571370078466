import Chip from '@mui/material/Chip'
import { withStyles } from '@mui/styles'

const TagLabelChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderRadius: 3,
  },
}), {})(Chip)

export default TagLabelChip

import React from 'react'
import { array } from 'prop-types'

import classnames from 'classnames'
import { withStyles } from '@mui/styles'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Tag from 'components/Tag'

import Style from './PictureTagsFilter.style'

const FilterTag = withStyles(() => ({
  root: {
    border: '1px solid #D6D6D6',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    '&:hover, &.active': {
      backgroundColor: '#009FFF14',
      border: '1px solid #009FFF',
      color: '#009FFF',
    },
  },
}))(Tag)
/**
 * Tags Filter Dropdown for picture list
 */
const PictureTagsFilter = ({ tags }) => {
  const tagFilters = useStoreState((state) => state.pictures.filters.tags)

  const setTagFilters = useStoreActions((actions) => actions.pictures.setTagsFilter)
  const setPicturesLoaded = useStoreActions((actions) => actions.pictures.setPicturesLoaded)

  const toggleTagFilter = (tagId) => {
    setPicturesLoaded(false)

    const checked = tagFilters.includes(tagId)

    if (checked) {
      setTagFilters(tagFilters.filter((id) => id !== tagId))
    } else {
      setTagFilters(tagFilters.concat(tagId))
    }
  }

  return (
    <Style>
      <div className={'tags-wrap'}>
        {tags && tags.map((tag) => (
          <FilterTag
            key={tag.id}
            className={classnames({ active: tagFilters.includes(tag.id) })}
            onClick={() => toggleTagFilter(tag.id)}
            label={tag.name}
          />
        ))}
        <FilterTag
          className={classnames({ active: tagFilters.includes('') })}
          onClick={() => toggleTagFilter('')}
          label={'No Tag'}
        />
      </div>
    </Style>

  )
}

PictureTagsFilter.propTypes = {
  /** all tags */
  tags: array.isRequired,
}

export default PictureTagsFilter

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .no-child {
    padding: 0 16px;
    min-height: 48px;
    color: ${({ theme }) => theme.colors.darkgray};
  }

  .children-wrap {
    overflow: hidden;
    @media (min-width: ${theme.breakpoints.md}) {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`

export const PictureChildFilterDropdownStories = styled('div')`
  border: 1px solid black;
  margin: 2rem;
  padding: 2rem;
  width: 500px;
`

import React from 'react'
import { node, string } from 'prop-types'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControlLabel from '@mui/material/FormControlLabel'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    color: '#707070',
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
}))

const useSummaryStyles = makeStyles((theme) => ({
  content: {
    '&$expanded': {
      margin: `${theme.spacing(0.5)} 0`,
    },
  },
  expanded: {},
  expandIcon: {
    '&$expanded': {
      color: theme.palette.primary.main,
    },
  },
}))

const useDetailsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '8px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

const PictureChildFiltersGroup = ({ children, label, control }) => {
  const classes = useStyles()
  const summaryClasses = useSummaryStyles()
  const detailsClasses = useDetailsStyles()

  return (
    <Accordion elevation={0} classes={classes}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label={'Expand'}
        aria-controls={'additional-actions1-content'}
        id={'additional-actions1-header'}
        classes={summaryClasses}
      >
        <FormControlLabel
          aria-label={'Acknowledge'}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={control}
          label={label}
        />
      </AccordionSummary>
      <AccordionDetails classes={detailsClasses}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

PictureChildFiltersGroup.propTypes = {
  children: node,
  label: string,
  control: node,
}


export default PictureChildFiltersGroup

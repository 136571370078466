import { styled } from '@mui/material/styles'

import ModalStyle from 'components/Modal/Modal.style'
import theme from 'styles/theme'

export default styled('div')`
  .edit-address-modal {
    width: auto;

    @media (min-width: ${theme.breakpoints.md}) {
      max-width: 840px;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      max-width: 1146px;
    }

    .subtitle {
      color: ${theme.colors.darkgray};
      font-size: ${theme.typography.size.md};
      margin: 0 0 1.5rem 0;

      @media (min-width: ${theme.breakpoints.sm}) {
        margin: 0 2.5rem;
      }
    }

    .content {
      padding-top: 1rem;

      @media (min-width: ${theme.breakpoints.sm}) {
        padding: 2.5rem;
      }
    }

    .save-button {
      width: 220px;

      @media (min-width: ${theme.breakpoints.sm}) {
        margin: 2.5rem 1rem 0.5rem 1rem;
      }
    }
  }

  & ${ModalStyle} {
    overflow: unset;
  }
`


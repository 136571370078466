import React, { useEffect, useState } from 'react'

import Checkbox from '@mui/material/Checkbox'
import { useStoreState } from 'easy-peasy'

import { useNewModalContext } from 'components/NewModal/useNewModalContext'
import { getTextPageSection } from 'containers/Book/Book.utils'
import useMetaThemeColor from 'hooks/useMetaThemeColor'

import { Label, Style, Subtitle, Wrap } from './ConfirmPreviewContent.style'
// This color is not in the theme palette. It's a combination of several colors with different opacities.
const META_THEME_COLOR = '#3f4e8d'

const ConfirmPreviewContent = () => {
  const [, setModalState] = useNewModalContext()
  useMetaThemeColor(META_THEME_COLOR, true)
  const activeBook = useStoreState((state) => state.book_v3.activeBook_v3)
  const textSection = getTextPageSection(activeBook.cover.sections)
  const activeBookTitle =  textSection.title
  const bookTitle = activeBookTitle ? `“${activeBookTitle}”` : 'your untitled art book'

  const [happy, setHappy] = useState(false)
  const [reviewed, setReviewed] = useState(false)
  const [reprint, setReprint] = useState(false)

  useEffect(() => {
    setModalState((state) => ({ ...state, disabledSubmitButton: !(happy && reviewed && reprint) }))
  }, [happy, reviewed, reprint])

  return (
    <Style>
      <Subtitle>
        You are approving {bookTitle}. Before you check out, please acknowledge the following:
      </Subtitle>
      <Wrap>
        <Label
          label={'I have thoroughly reviewed my book'}
          control={
            <Checkbox
              color={'primary'}
              checked={reviewed}
              onChange={(e) => setReviewed(e.target.checked)}
            />
          }
        />
        <Label
          label={'I am happy with the current state of my book'}
          control={
            <Checkbox
              color={'primary'}
              checked={happy}
              onChange={(e) => setHappy(e.target.checked)}
            />
          }
        />
        <Label
          label={'I understand that I will have to pay for a new book if I want to reprint'}
          control={
            <Checkbox
              color={'primary'}
              checked={reprint}
              onChange={(e) => setReprint(e.target.checked)}
            />
          }
        />
      </Wrap>
    </Style>
  )
}

export default ConfirmPreviewContent

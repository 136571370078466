import MuiBackdrop from '@mui/material/Backdrop'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import { opacify } from 'polished'

import theme from 'styles/theme'

export const Backdrop = styled(MuiBackdrop, { shouldForwardProp: (prop) => prop !== 'variant' })`
  background-color: ${({ variant, theme }) => variant === 'dark'
  ? opacify(-0.07, theme.colors.black)
  : opacify(-0.1, theme.colors.darkblue)
};
`

export const StyledDialog = styled(Dialog)``

export default styled('div')`
  padding: 1rem;
  border-radius: 4px;
  flex-direction: column;

  @media (min-width: ${theme.breakpoints.md}) {
    height: auto;
    width: 768px;
    max-width: 768px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;

    button {
      margin: 1rem;
    }

    /* desktop */
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }
  }

  .cta-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.has-back {
      justify-content: space-between;
    }

    .back-wrap {
      display: flex;
      align-items: center;
      margin: 0;

      label {
        cursor: pointer;
      }
    }

    .close, .back {
      fill: ${theme.colors.medgray};
      cursor: pointer;
      padding: ${({ theme }) => theme.spacing(1)};
      margin: ${({ theme }) => theme.spacing(-1)};
    }
  }

  .modal__header {
    margin-bottom: 1rem;
  }
`

import React, { useEffect, useRef, useState } from 'react'

import ContentLoader from 'react-content-loader'

import theme from 'styles/theme'

import { ImageSkeletonStyle } from './Image.style'

const ImageSkeleton = () => {
  const skeletonContainer = useRef()

  const [dimensions, setDimensions] = useState()

  useEffect(() => {
    const { width, height } = skeletonContainer.current.getBoundingClientRect()

    setDimensions({ width, height })
  }, [skeletonContainer.current])

  return (
    <ImageSkeletonStyle ref={skeletonContainer}>
      {dimensions && (
        <ContentLoader
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          backgroundColor={theme.colors.medgray}
          foregroundColor={theme.colors.lightgray}
        >
          <rect x={'0'} y={'0'} rx={'0'} ry={'0'} width={'100%'} height={'95%'} />
        </ContentLoader>
      )}
    </ImageSkeletonStyle>
  )
}

export default ImageSkeleton

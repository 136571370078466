import queue from 'async/queue'
import EventEmitter from 'eventemitter3'
import { createInstance } from 'localforage'
import pick from 'lodash/pick'
import { v4 as uuidv4 } from 'uuid'

import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import { BOOK_SECTION_TYPES } from 'utils/constants'
import TypedError from 'utils/errors/TypedError'
import s3upload from 'utils/s3upload'

import { createPictureRecord, getPictureRecord, handleFailedUpload, updatePictureRecord } from './uploadService.helpers'

const {
  FAILED,
  QUEUED,
  IN_PROGRESS,
  RETRY,
  SUCCESS,
} = UPLOADING_IMAGE_STATUSES


export const isPictureUploaded = (picture) => {
  return [UPLOADING_IMAGE_STATUSES.SUCCESS].includes(picture.status)
}

export const isPictureInQueue = (picture) => {
  return [
    UPLOADING_IMAGE_STATUSES.IN_PROGRESS,
    UPLOADING_IMAGE_STATUSES.QUEUED,
  ].includes(picture.status)
}

const ACCOUNT_APP_UPLOADED_PICTURES_CACHE_KEY = 'ACCOUNT_APP_UPLOADED_PICTURES_CACHE_KEY'
let cacheStore

if (typeof window !== 'undefined') {
  cacheStore = createInstance({
    name: ACCOUNT_APP_UPLOADED_PICTURES_CACHE_KEY,
  })
}

class UploadService {
  pictures = new Map()
  booksCache = new Map()
  queues = new Map()
  isUploading = false
  emitter = new EventEmitter()

  constructor() {

    if (!cacheStore)
      return

    Promise.all([
      cacheStore.getItem('books'),
      cacheStore.getItem('pictures'),
    ]).then(([books, pictures]) => {
      this.booksCache = new Map((books || []).map((book) => [book.id, book]))
      // restore only last 500 pictures
      this.pictures = new Map((pictures || []).map((picture) => {
        const _key = uuidv4()
        return [_key, { ...picture, _key, file: null }]
      }).sort(function(a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }).slice(0, 500))

      this.emitter.emit('update', this.getPictures())
    })
  }

  getPictures() {
    return Array.from(this.pictures.values())
  }

  getPicturesForCache() {
    const pictures = this.getPictures().filter(isPictureUploaded)

    return pictures.map((picture) => pick(picture, ['_key', 'status', 'fileName', 'name', 'id', 'createdAt']))
  }

  getBooksForCache() {
    return Array.from(this.booksCache.values())
  }

  async uploadPicture(picture, callback) {
    try {
      this.setPicture(picture._key, { status: IN_PROGRESS })

      const createData = await createPictureRecord(picture)

      this.setPicture(picture._key, createData)

      await this.handleUploadImage(picture._key)

      const updateData = await updatePictureRecord({ id: createData.id, uploaded: true })

      this.uploadSuccess({ ...picture, ...updateData, new: true, status: null })
    } catch (err) {
      const status = err?.type === FAILED ? FAILED : RETRY

      const pic = this.setPicture(picture._key, { message: err.message, status })

      await handleFailedUpload(pic)
    }

    callback && callback()
  }

  async handleUploadImage(key, counter = 0) {
    const picture = this.pictures.get(key)

    if (!picture.file) {
      throw new TypedError(`Unable to retry file upload`, FAILED)
    }

    try {
      await s3upload(picture)
    } catch (err) {
      if (err.type !== FAILED && err?.response?.status === 403 && counter < 2) {
        const pictureData = await getPictureRecord(picture)

        this.setPicture(key, pictureData)

        return await this.handleUploadImage(key, counter + 1)
      }

      throw err
    }
  }


  // NOTE: entry point fn that handles upload
  async dispatchUpload() {
    if (this.isUploading) return

    this.isUploading = true

    const picture = Array.from(this.getPictures()).find(({ status }) => status === QUEUED)

    // if no pictures are left queue is empty run complete and exit
    if (!picture) {
      this.isUploading = false

      this.emitUpdate()
    } else {
      await this.uploadPicture(picture)

      this.isUploading = false

      this.dispatchUpload()
    }
  }

  addPicturesBatch({ pictures, meta, book }) {
    const queueIndex = book?.id || 'default'

    if (!this.queues.has(queueIndex)) {
      const uploadQueue = queue(this.uploadPicture.bind(this), 1)

      uploadQueue.drain(() => {
        if (!book?.id) return

        const book_id = book.id
        const uploadedPictures = this.getPictures()
          .filter((pic) => pic.book_id === book_id)
          .map((pic) => pic.id).filter(Boolean)
        console.log('all items have been processed', book?.id, uploadedPictures)

        this.storeActions.onBatchAddPictures({
          bookId: book_id,
          selectedPictures: uploadedPictures,
        })
      })

      this.queues.set(queueIndex, uploadQueue)
    }

    const batchQueue = this.queues.get(queueIndex)

    for (let picture of pictures) {
      if (book) {
        this.booksCache.set(book.id, {
          id: book.id,
          title:
            book.cover.sections[
              book.cover.sections.findIndex(
                ({ sectionType }) => sectionType === BOOK_SECTION_TYPES.TEXT,
              )
            ].title,
        })
      }

      const _key = uuidv4()
      const queueItem = {
        ...meta,
        _key,
        file: picture,
        name: picture.name,
        prevStatus: null,
        status: QUEUED,
        book_id: book?.id,
      }

      this.pictures.set(_key, queueItem)

      batchQueue.push(queueItem)
    }

    this.emitUpdate()
  }


  addPicture({ meta = {}, picture }) {
    this.addPicturesBatch({
      pictures: [picture],
      meta,
    })
  }

  emitUpdate() {
    this.emitter.emit('update', this.getPictures())

    cacheStore.setItem('books', this.getBooksForCache())

    cacheStore.setItem('pictures', this.getPicturesForCache())
  }

  clearPicturesByBookId(book_id) {
    if (book_id !== 'default') {
      this.booksCache.delete(book_id)
    }

    const pictures = this.getPictures().filter((pic) => book_id === 'default' ? !pic.book_id : pic.book_id === book_id)

    for (const picture of pictures) {
      this.pictures.delete(picture._key)
    }

    this.emitUpdate()
  }

  clearCache() {
    this.booksCache.clear()
    this.pictures.clear()

    cacheStore.setItem('books', [])
    cacheStore.setItem('pictures', [])
  }

  pictureUploaded(key) {
    this.setPicture(key, { status: SUCCESS })

    this.emitUpdate()
  }

  requeueFailed() {
    const failedPictures = this.getPictures().filter((pic) => pic.status === RETRY)

    for (const picture of failedPictures) {
      const queueIndex = picture.book_id || 'default'
      const batchQueue = this.queues.get(queueIndex)
      this.setPicture(picture._key, { status: QUEUED })
      batchQueue.push(picture)
    }

    this.emitUpdate()
  }

  // NOTE: inject store actions to update dux state
  setStoreActions(handlers) {
    this.storeActions = handlers
  }

  /*
  NOTE: only dispatches when not already uploading so it
  is safe to use this at any point in the upload lifecycle
   */
  setPicture(key, update = {}) {
    const current = this.pictures.get(key)

    this.pictures.set(key, { ...current, ...update })

    const updated = this.pictures.get(key)

    this.emitUpdate()

    return updated
  }

  uploadSuccess(picture) {
    this.pictureUploaded(picture._key)
    if (!this.storeActions)
      return

    this.storeActions.onUploadSuccess(picture)
  }
}

const uploadService = new UploadService()

export default uploadService

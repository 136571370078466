import React, { useContext } from 'react'
import { bool, func, node } from 'prop-types'

import withLoader from 'hoc/withLoader'
import { UploadDropzoneContext } from 'hooks/useUploadingDropzone'

import Style from './PictureDropzone.style'

const PictureDropzone = ({
  renderEmptyScreen,
  children,
  isEmpty,
}) => {
  const { getRootProps, getInputProps, openDropzone } = useContext(UploadDropzoneContext)

  return (
    <Style isEmpty={isEmpty}>
      <input {...getInputProps()} />

      {children}

      {isEmpty && (
        <div className={'dropzone-empty'}>
          {renderEmptyScreen({ getRootProps, openDropzone })}
        </div>
      )}
    </Style>
  )
}

PictureDropzone.propTypes = {
  renderEmptyScreen: func,
  children: node,
  isEmpty: bool,
}

const PictureDropzoneWithLoader = withLoader(PictureDropzone)

export default PictureDropzoneWithLoader

import { BOOK_SIZES } from 'pdf-printer/src/components/Page'

import { ALIGN_TITLE } from 'components/AlignTitle'
import { BOOK_SECTION_TYPES } from 'utils/constants'



export const LEGACY_BOOK_MAPPING = {
  1: BOOK_SIZES.SMALL.id,
  2: BOOK_SIZES.LARGE.id,
  3: BOOK_SIZES.SMALL.id,
  4: BOOK_SIZES.SMALL.id,
  5: BOOK_SIZES.LARGE.id,
  6: BOOK_SIZES.LARGE.id,
}

export const DEFAULT_TEXT_SETTINGS = {
  hideDate: false,
  hideGrade: false,
  hideName: false,
  hideTitle: false,
}

export const DEFAULT_BOOK = {
  cover: {
    title: '',
    titleAlign: ALIGN_TITLE.CENTER,
  },
  productId: '',
  pictures: [],
}

export const DEFAULT_BOOK_v3 = {
  cover: {
    sections: [
      {
        sectionType: BOOK_SECTION_TYPES.TEXT,
        title: '',
      },
      {
        sectionType: BOOK_SECTION_TYPES.IMAGE,
      },
    ],
  },
  pages: [],
  productId: 0,
}

export const MAX_BOOK_CAPACITY = 350
export const MIN_BOOK_CAPACITY = 20

export const CROP_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  NONE: 'none',
}

export const MAX_CUSTOM_BOOK_TITLE_LENGTH = 37
export const MAX_LETTER_BOOK_TITLE_LENGTH = 50

export const DEFAULT_POSITION = { xPositionPercentage: 0, yPositionPercentage: 0 }

export const PAGE_SETTINGS = {
  TEXT: 'area:text',
  IMAGE: 'area:image',
}

export const NO_TITLE_PLACEHOLDER = 'Untitled Book'

export const EMPTY_BOOK_LENGTH = 4

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Buttons/Button'
import ShippingAddress, { useShippingAddressForm } from 'components/Forms/ShippingAddress'
import Modal from 'components/Modal'
import { APP_MODALS } from 'components/ModalManager'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'
import addressValidationService from 'utils/addressValidationService'

import EditAddressStyle from './EditAddress.style'

const EditAddress = ({ address, onSuccess, title, subtitle, showSetDefault }) => {
  const { unsetModal, setModal } = useModal()
  // TODO probably could be removed in the future
  const [, setShippingAddresses] = useState([])

  const {
    control,
    errors,
    handleSubmit,
    watchCountry,
  } = useShippingAddressForm(address)

  const submit = async (data) => {
    const { firstName, lastName, country, state, ...addressFields } = data
    const shippingName = `${firstName} ${lastName}`
    const shippingStateProvince = state.value
    const shippingCountry = country.value

    const address = {
      shippingName,
      shippingStateProvince,
      shippingCountry,
      firstName,
      lastName,
      ...addressFields,
    }

    const validAddress = await addressValidationService.checkAddress(address)

    if (validAddress && shippingCountry === 'US') {
      setModal({
        active: true,
        name: APP_MODALS.ConfirmAddress,
        validAddress,
        userAddress: address,
        onSuccess: handleSuccess,
        setAddress: setShippingAddresses,
      })
    } else {
      handleSuccess(address)
    }
  }

  const handleSuccess = (address) => {
    onSuccess(address)
    unsetModal()
  }

  return (
    <EditAddressStyle>
      <Modal handleClose={unsetModal} className={'edit-address-modal'}>
        <H2 className={'darkblue center'}>{title}</H2>
        {subtitle && <P className={'center subtitle'}>{subtitle}</P>}
        <section className={'content'}>
          <form onSubmit={handleSubmit(submit)}>
            <ShippingAddress
              control={control}
              shippingCountry={watchCountry}
              defaultAddress={address}
              errors={errors}
              showSetDefault={showSetDefault}
            />
            <div className={'buttons flex justify-center'}>
              <Button type={'submit'} className={'save-button'}>Save</Button>
            </div>
          </form>
        </section>
      </Modal>
    </EditAddressStyle>
  )
}

EditAddress.propTypes = {
  address: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showSetDefault: PropTypes.bool,
  subtitle: PropTypes.node,
}

export default EditAddress


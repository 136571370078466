import { useMemo } from 'react'

import { baseApi } from 'dux/queries/baseApi'

import { TAG_TYPE } from './constants'

export const tagsApiUrls = {
  getTags: () => '/api/v2/user/tags',
  createTag: () => '/api/v2/user/tags',
  updateTag: (id) => `/api/v2/user/tags/${id}`,
  deleteTag: (id) => `/api/v2/user/tags/${id}`,
}

export const tagsTextLabelsApi =
  baseApi
    .enhanceEndpoints({ addTagTypes: [TAG_TYPE.TAG] })
    .injectEndpoints({
      overrideExisting: true,
      endpoints: (builder) => ({
        getTags: builder.query({
          query: () => ({
            url: tagsApiUrls.getTags(),
            method: 'GET',
          }),
          transformResponse: (response) => response.data,
          providesTags: (result) => result
            ? [...result.map(({ id }) => ({ type: TAG_TYPE.TAG, id })), TAG_TYPE.TAG]
            : [TAG_TYPE.TAG],
        }),
        createTag: builder.mutation({
          query: (data) => ({
            url: tagsApiUrls.createTag(),
            method: 'POST',
            data,
          }),
          transformResponse: (response) => response.data,
          invalidatesTags: [TAG_TYPE.TAG],
        }),
        updateTag: builder.mutation({
          query: ({ id, ...data }) => ({
            url: tagsApiUrls.updateTag(id),
            method: 'PUT',
            data,
          }),
          invalidatesTags: (result, error, { id }) => [{ type: TAG_TYPE.TAG, id }],
        }),
        deleteTag: builder.mutation({
          query: ({ id }) => ({
            url: tagsApiUrls.deleteTag(id),
            method: 'DELETE',
          }),
          invalidatesTags: (result, error, { id }) => [{ type: TAG_TYPE.TAG, id }],
        }),
      }),
    })

export const useTagOptions = () => {
  const { data: tags = [] } = useGetTagsQuery()

  const tagOptions = useMemo(() => {
    return tags.map((tag) => ({ label: tag.name, value: tag.id }))
  }, [tags])

  return [tagOptions]
}

export const {
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagsTextLabelsApi

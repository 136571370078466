import React, { memo } from 'react'
import { node } from 'prop-types'

import Style, { LabelBtm, LabelTop } from './withCaptions.style'

/**
 * HOC - wraps component in optional top and bottom captions
 * @param  {node} Component - Component to be wrapped
 */
const withCaptions = (Component) => {

  /**
   * WrappedComponent - component to be wrapped
   * @param  {node} props.labelBtm - top label element
   * @param  {node} props.labelTop - bottom label element
   * @param  {object} ...props - rest of the components props minus the HOC props
   */
  const WrappedComponent = ({
    labelTop,
    labelBtm,
    ...props
  }) => (
    <Style>
      <LabelTop>{labelTop}</LabelTop>

      <Component {...props} />

      <LabelBtm>{labelBtm}</LabelBtm>
    </Style>
  )

  WrappedComponent.propTypes = {
    labelTop: node,
    labelBtm: node,
  }

  return memo(WrappedComponent)
}

export default withCaptions


import { styled } from '@mui/material/styles'
import { opacify } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;

  .form-wrap {
    align-items: center;
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    height: 100%;
  }

  .form {
    border: 1px solid ${({ theme }) => opacify(-0.47, theme.colors.medgray)};
    border-radius: 4px;
    max-width: 480px;
    padding: 2rem;
    position: relative;
    width: 100%;

    .input-wrap {
      position: relative;
      margin-bottom: 2.5rem;

      .caption {
        position: absolute;
        bottom: -1.4rem;
        right: 0;
      }
    }

    @media (max-width: ${theme.breakpoints.md}) {
      max-width: none;
      padding: 3rem;

      button {
        width: 100%;
      }
    }
  }

  .link {
    font-size: ${({ theme }) => theme.typography.size.sm};
  }

  .footer {
    align-items: center;
    background-color: ${({ theme }) => opacify(-0.9, theme.colors.blue)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
  }
`

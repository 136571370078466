import { styled } from '@mui/material/styles'
import { transitions } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  height: 100%;
  width: ${(props) => props.showLabel ? 'calc(100% - 350px)' : '100%'};
  ${transitions(['width'], '0.3s ease-in-out')};

  .content-wrap {
    display: flex;
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    margin: 0 auto;
    position: relative;
    padding: 1rem;
    padding-top: 4rem;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-top: 1rem;
    }
  }

  .edit-picture-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    z-index: 999;

    @media (min-width: ${theme.breakpoints.md}) {
      top: 1rem;
      right: 1rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
    padding: 0;
    width: 100%;
    z-index: 11;

    .icon-wrap {
      margin: 0;
      text-align: center;
      cursor: pointer !important;

      .icon {
        fill: ${(props) => props.theme.colors.white};
        margin: 5px auto;
        width: 2rem;
        height: 2rem;
      }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
      bottom: 0;
      justify-content: center;
      margin-bottom: 0;
      padding: 1rem;

      .icon-wrap {
        margin: 0 1rem;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
`

export const ImageMeta = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`

export const ImageWrap = styled('div')`
  /* mobile first */
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 0;
  flex: 1 1 100%;
  padding: 1rem 3rem;
  width: 100%;
  position: relative;
  user-select: none;

  /*
    ELEMENTS
  */

  img {
    max-width: 95%;
    max-height: 100%;
  }

  .prev, .next {
    background-color: ${({ theme }) => theme.colors.medgray};
    border-radius: 50%;
    cursor: pointer;
    padding: 12px;
    position: absolute;
    //top: 50%;
    //transform: translateY(-50%);
    ${transitions(['box-shadow', 'transform', 'background-color'], '0.3s ease-in-out')};

    @media (min-width: ${theme.breakpoints.md}) {
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightgray};
        transform: scale(1.1);
      }
    }

    .icon {
      height: 1rem;
      width: 1rem;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    .prev {
      left: .5rem;
    }

    .next {
      right: .5rem;
    }
  }

`

import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'

import { useIsMobile } from 'dux/slice/ui'
import theme from 'styles/theme'

import PlanCard, { PlanTypes } from './PlanCard'
import { UpgradeDivider } from './UpgradeMembership.style'

const useStyles = makeStyles(({ breakpoints }) => ({
  dividerText: {
    fontFamily: theme.typography.type.montserrat,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.colors.darkgray,
  },
  arrowGutter: {
    width: 70,
    [breakpoints.down('sm')]: {
      width: 42,
      transform: 'rotate(90deg)',
    },
  },
  planCard: {
    flexBasis: 300,
    flexGrow: 1,
  },
  upgradePlanCard: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      background: `url(/images/membership/bubbles.png) no-repeat`,
      backgroundSize: 'contain',
      position: 'absolute',
      content: '""',
      width: 77,
      height: 155,
      display: 'block',
      right: -35,
      top: -35,
      zIndex: -1,
    },
    '&::after': {
      background: `url(/images/membership/bubbles.png) no-repeat`,
      backgroundSize: 'contain',
      position: 'absolute',
      content: '""',
      width: 77,
      height: 155,
      display: 'block',
      left: -45,
      bottom: -10,
      zIndex: -1,
    },
  },
}))

const PlansCards = ({ plans, isCompMembership, planType }) => {
  const classes = useStyles()
  const { isMobile } = useIsMobile()

  if (!plans.length)
    return null

  const isVipMembershipAvailable = plans.length > 1
  const isUpgradePlanAvailable = !isCompMembership && plans.length > 2

  return (
    <>
      <PlanCard
        planType={planType}
        plan={plans[0]}
        topLabel={'Existing'}
        className={cl(classes.planCard, {
          [classes.upgradePlanCard]: plans.length === 1,
        })}
        checkoutLabel={'Renew Existing'}
      />

      {isVipMembershipAvailable && (
        <UpgradeDivider>
          <Box className={classes.dividerText} my={isMobile ? 0 : 3}>Upgrade</Box>
          <img src={'/images/membership/upgradeArrow.svg'} className={classes.arrowGutter} />
        </UpgradeDivider>
      )}

      {isUpgradePlanAvailable && (
        <>
          <PlanCard
            planType={planType}
            plan={plans[1]}
            className={cl(classes.planCard)}
            checkoutLabel={'Checkout'}
          />
          <UpgradeDivider>
            <Box className={classes.dividerText} my={isMobile ? 0 : 3}>or</Box>
          </UpgradeDivider>
        </>
      )}

      {isVipMembershipAvailable && (
        <>
          <PlanCard
            planType={planType}
            plan={plans.at(-1)}
            className={cl(classes.planCard, classes.upgradePlanCard)}
            topLabel={'Most Popular'}
            checkoutLabel={isCompMembership ? 'Upgrade' : 'Checkout'}
          />
        </>
      )}
    </>
  )
}

PlansCards.propTypes = {
  planType: PropTypes.oneOf(Object.values(PlanTypes)).isRequired,
  isCompMembership: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object),
}

export default PlansCards

const getPictureSubCaption = (picture, options = {}) => {
  const str = [
    options?.showName && picture?.child && picture.child.name,
    options?.showGrade && picture?.label && picture.label.name,
  ]

  return str.filter(Boolean).join(', ')
}

export default getPictureSubCaption

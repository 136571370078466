import React, { useState } from 'react'

import Modal from 'components/Modal'
import useModal from 'hooks/useModal'

import ForgotPasswordStyle from './ForgotPassword.style'
import ModalForm from './ModalForm'
import ModalNotificationSent from './ModalNotificationSent'

const ForgotPassword = () => {
  const { unsetModal } = useModal()

  const [emailSent, setEmailSent] = useState(false)

  return (
    <ForgotPasswordStyle>
      <Modal className={'forgot-password-modal'} handleClose={unsetModal}>
        {emailSent ? (
          <ModalNotificationSent />
        ) : (
          <ModalForm onSubmit={() => setEmailSent(true)} />
        )}
      </Modal>
    </ForgotPasswordStyle>
  )
}

export default ForgotPassword

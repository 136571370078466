import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .confirmation-modal {
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
      max-width: 500px;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 50%;
      justify-content: space-around;

      @media (max-width: ${theme.breakpoints.md}) {
        justify-content: center;
      }
    }
  }
`

import { styled } from '@mui/material/styles'
import { opacify, transitions } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  /* mobile first */
  gap: 1rem 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  width: 100%;

  @media (min-width: ${theme.breakpoints.sm}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (min-width: ${theme.breakpoints.lg}) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
`

export const Placeholder = styled('div')`
  align-items: center;
  background-color: ${({ theme }) => opacify(-0.98, theme.colors.blue)};
  border: 2px dashed ${({ theme }) => theme.colors.lightgray};
  border-radius: 4px;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.typography.secondary.fontFamily};
  font-size: ${({ theme }) => theme.typography.size.md};
  justify-content: center;
  ${transitions(['background-color', 'box-shadow'], '0.2s ease 0s')}
  height: 0;
  padding-top: 80%;
  overflow: hidden;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    @media (min-width: ${theme.breakpoints.sm}) {
      border-color: ${({ theme }) => theme.colors.blue};
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }

  .icon-download {
    margin-bottom: 0.5rem;

    svg {
      fill: ${(props) => props.theme.colors.blue};
    }
  }

  .text {
    text-align: center;
  }
`

export const Overlay = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${({ theme }) => opacify(-0.1, theme.colors.darkblue)};
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Header = styled('div')`
  grid-column: 1 / -1;
`

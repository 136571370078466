import React, { forwardRef } from 'react'
import { array, func } from 'prop-types'

import classnames from 'classnames'

import { FilterButton } from 'components/Buttons/Button'
import Dropdown from 'components/Dropdown'
import PictureSort from 'components/PictureSort'

/**
 * Sort dropdown component for picture list
 * */
const PictureSortDropdown = forwardRef(({
  sortQueries,
  setSortQueries,
}, ref) => {
  return (
    <Dropdown
      ref={ref}
      onReset={() => setSortQueries([])}
      anchor={(
        <FilterButton className={classnames('spaced-btn', { active: sortQueries.length > 0 })}>
          Sort By
        </FilterButton>
      )}
    >
      <PictureSort sortQueries={sortQueries} setSortQueries={setSortQueries} />
    </Dropdown>
  )
})

PictureSortDropdown.propTypes = {
  /** sort queries */
  sortQueries: array,
  /** update active sort queries */
  setSortQueries: func,
}

export default PictureSortDropdown

import { styled } from '@mui/material/styles'

export default styled('div')`
  align-items: center;
  display: flex;
  justify-content: center;

  &.fixed {
    background-color: rgba(32, 33, 38, 0.93);
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 99999;

    .spinner-container {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &.contained {
    background: ${({ theme }) => theme.colors.white};
    opacity: 0.7;
    bottom: 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 100;

    .spinner-container {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &.absolute {
    bottom: 0px;
    left: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 100;
  }

  &.relative {
    position: relative;
  }
`

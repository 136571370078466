import React from 'react'
import { func, string } from 'prop-types'

import Button from 'components/Buttons/Button'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import Link from 'components/Text/Link'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'

import ConfirmDeleteStyle from './ConfirmDelete.style'

const ConfirmDelete = ({ onSuccess, onCancel, itemName }) => {
  const { unsetModal } = useModal()

  const handleCancel = onCancel || unsetModal

  const handleSuccess = () => {
    onSuccess()
    unsetModal()
  }

  return (
    <ConfirmDeleteStyle>
      <Modal handleClose={handleCancel} className={'confirm-delete-modal'}>
        <div className={'content-wrap'}>
          <H2 className={'darkblue'}>Are You Sure?</H2>
          <P>You are deleting {itemName} from your library. This cannot be undone.</P>
          <div className={'buttons'}>
            <Link
              className={'pointer blue'}
              onClick={handleCancel}
            >
              Cancel
            </Link>

            <Button
              onClick={handleSuccess}
              type={'primary'}
            >
              <P className={'white'}>Yes, Delete</P>
            </Button>
          </div>
        </div>
      </Modal>
    </ConfirmDeleteStyle>
  )
}

ConfirmDelete.propTypes = {
  itemName: string,
  onSuccess: func,
  onCancel: func,
}

export default ConfirmDelete

import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'

import { StyledButton } from './Button.style'

const Button = ({ className, children, active = false, ...props }) => {
  return (
    <StyledButton className={cl(active && 'Mui-active', className)} {...props}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
}

export default Button

import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import useUserInformation from 'dux/hooks/useUserInformation'
import { pushHistory, setQueryParams } from 'dux/slice/ui'
import * as track from 'utils/tracker'

const useTrackHistoryChange = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const { isLoggedAsUser } = useUserInformation()

  useEffect(() => {
    const handleRouteChange = (url, opts) => {
      dispatch(pushHistory({ url, opts }))
      track.page(isLoggedAsUser)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, dispatch, isLoggedAsUser])

  useEffect(() => {
    // initial page load
    dispatch(pushHistory({ url: window.location.pathname }))
    dispatch(setQueryParams(router.query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useTrackHistoryChange

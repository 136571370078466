import React, { useEffect } from 'react'
import { func, object, string } from 'prop-types'

import { Provider as RTKStoreProvider } from 'react-redux'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import { StoreProvider as EasyPeasyStoreProvider } from 'easy-peasy'
import { useRouter } from 'next/router'

import GlobalTrackersProvider from 'components/GlobalTrackersProvider'
import LayoutWrapper from 'components/LayoutWrapper/LayoutWrapper'
import MessageProvider from 'components/Message/MessageProvider'
import NotificationProvider from 'components/Notification/NotificationProvider'
import { wrapper } from 'dux/index'
import { userApi } from 'dux/queries/user'
import { setCollapsed, setIsMobile } from 'dux/slice/ui'
import { initializeStore, useEasyStore } from 'dux/Store'
import createEmotionCache from 'pages/createEmotionCache'
import { AppStylesProvider } from 'styles/AppStylesProvider'
import api from 'utils/api'

import 'core-js/features/object/from-entries'
import 'core-js/features/array/at'
import 'core-js/features/array/find-last-index'
import 'core-js/features/array/find-last'
import 'core-js/actual/array/to-spliced'
import 'cropperjs/dist/cropper.css'
import 'intersection-observer'

import { getSessionCookie } from '@/lib/cookies'

const clientSideEmotionCache = createEmotionCache()

const ArtkiveApp = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest)
  const { serverEasyStore, emotionCache = clientSideEmotionCache } = props

  const easyStore = useEasyStore(serverEasyStore)
  const router = useRouter()

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <RTKStoreProvider store={store}>
        <EasyPeasyStoreProvider store={easyStore}>
          <AppStylesProvider cache={emotionCache}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en'}>
              <GlobalTrackersProvider>
                <MessageProvider router={router}>
                  <NotificationProvider>
                    <LayoutWrapper>
                      <Component {...props} />
                    </LayoutWrapper>
                  </NotificationProvider>
                </MessageProvider>
              </GlobalTrackersProvider>
            </LocalizationProvider>
          </AppStylesProvider>
        </EasyPeasyStoreProvider>
      </RTKStoreProvider>
    </Sentry.ErrorBoundary>
  )
}

ArtkiveApp.getInitialProps = wrapper.getInitialAppProps((rtkStore) => async ({ ctx }) => {
  let store
  let csrfToken

  if (typeof window === 'undefined' && ctx.res) {
    store = initializeStore()

    const cookies = ctx.res.getHeader('Set-Cookie') || ctx.req.headers.cookie || ''
    const userData = ctx.res.userData || {}
    const adminData = ctx.res.adminData || {}
    const cookie = getSessionCookie(cookies)
    csrfToken = ctx.res.csrfToken

    const userAgent = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent) || ''
    let isMobileView = userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)

    await rtkStore.dispatch(userApi.util.upsertQueryData('getUser', undefined, {
      activeUser: userData,
      adminMeta: adminData,
    }))

    // TODO: investigate why this data isn't set in Sentry
    Sentry.setUser({ email: userData.email, id: userData.id, username: userData.name })

    rtkStore.dispatch(setIsMobile(Boolean(isMobileView)))
    rtkStore.dispatch(setCollapsed(Boolean(isMobileView)))

    api.setCsrfToken(csrfToken)
    if (cookie) {
      api.setCookie(cookie)
    }
  }

  let restProps = {}

  return {
    serverEasyStore: store?.getState(),
    csrfToken,
    ...restProps,
  }
},
)

ArtkiveApp.propTypes = {
  Component: func,
  serverEasyStore: object,
  csrfToken: string,
  emotionCache: object,
}

export default ArtkiveApp

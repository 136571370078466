import React from 'react'
import PropTypes from 'prop-types'

import MuiClose from '@mui/icons-material/Close'

const CloseIcon = ({ variantType = 'gray' }) => {
  return (
    <MuiClose
      aria-hidden={true}
      sx={(theme) => ({
        color:
          variantType === 'white'
            ? theme.colors.white
            : theme.colors.gray[700],
        '&:hover': {
          color: variantType === 'white'
            ? 'rgba(255, 255, 255, 0.7)'
            : theme.colors.gray[900],
        },
      })}
    />
  )
}

CloseIcon.propTypes = {
  variantType: PropTypes.oneOf(['white', 'gray']),
}
export default CloseIcon

import { thunk, thunkOn } from 'easy-peasy'

import api from 'utils/api'

import { ORDER_ITEM_TYPES } from './Checkout.constants'

const mapShippingAddress = (address) => ({
  firstName: address.firstName,
  lastName: address.lastName,
  address: `${address.shippingAddress1} ${address.shippingAddress2 || ''}`.trim(),
  city: address.shippingCity,
  state: address.shippingStateProvince,
  country: address.shippingCountry,
  zipCode: address.shippingPostalCode,
})

const checkoutService = {
  getOrderPricing: thunk(async (actions, order) => {
    const book = order.items.find((item) => item.type === ORDER_ITEM_TYPES.BOOK)
    const addon = order.items.find((item) => item.type === ORDER_ITEM_TYPES.ADDON)

    if (!book?.id) return

    const details = {
      saveBookId: book.id,
    }

    if (addon?.qty > 0) {
      details.addOns = [{
        type: addon.id,
        qty: addon.qty,
      }]
    }

    if (order.promoCode) {
      details.promoCode = order.promoCode.code
    }

    if (order.shippingAddress) {
      details.shippingState = order.shippingAddress?.shippingStateProvince
    }

    if (order.shippingMethod?.shippingId) {
      details.shippingMethodId = order.shippingMethod.shippingId
    }

    const config = {
      method: 'post',
      url: '/api/v2/users/orders/books/pricing',
      data: { details },
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      const data = response.data.data
      actions.setItemPrice({ id: book.id, ...data.orderSummary, prepaid: data.isPrepaid })
      actions.setItemPrice({ id: 'BOOK_COPY', ...data.orderSummary })
      actions.setPricing({ item: ORDER_ITEM_TYPES.BOOK, ...data.orderSummary })
      return response.data.data
    }
  }),

  getPromoCodeInfo: thunk(async (actions, code) => {
    if (!code) {
      actions.setPromoCode(null)
      return
    }
    actions.setPromoLoading(true)
    // axios config object
    const config = {
      method: 'post',
      url: '/api/v1/promo_code/check_code',
      data: {
        promo_code: {
          code,
          product_name: 'book_product',
        },
      },
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError({ message: 'Invalid Promo Code' })
      actions.setPromoLoading(false)
    }

    if (response) {
      actions.setPromoCode(response.data.promo_code)
      actions.setPromoLoading(false)
      return response.data.data
    }
  }),

  getShippingRates: thunk(async (actions, order) => {
    // actions.setIsLoading(true)
    const quantity = order.items.reduce((result, item) => result + (item.qty || 0), 0)
    const state = order.shippingAddress?.shippingStateProvince
    const book = order.items.find((item) => item.type === ORDER_ITEM_TYPES.BOOK)

    const config = {
      method: 'get',
      url: '/api/v2/users/orders/shipping_rates',
      params: {
        quantity: quantity === 0 && book.prepaid ? 1 : quantity,
        isConcierge: book.prepaid,
        state,
      },
    }
    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      actions.setIsLoading(false)
      return { error }
    }

    if (response) {
      actions.setIsLoading(false)
      return response.data.data
    }
  }),

  createOrder: thunk(async (actions, order) => {
    actions.setIsLoading(true)
    const book = order.items.find((item) => item.type === ORDER_ITEM_TYPES.BOOK)
    const addon = order.items.find((item) => item.type === ORDER_ITEM_TYPES.ADDON)

    const reqOrder = {
      saveBookId: book.id,
      shippingMethodId: order.shippingMethod?.shippingId,
      shippingAddress: mapShippingAddress(order.shippingAddress),
      paymentMethod: order.paymentMethod?.cc ?
        {
          ...order.paymentMethod,
          cc: order.paymentMethod.cc.replace(/ /g, ''),
          exp: order.paymentMethod.exp.replace(/ /g, ''),
          zipCode: order.paymentMethod.zipCode,
        } : {},
    }

    if (addon && addon.qty) {
      reqOrder.addOns = [{
        type: addon.id,
        qty: addon.qty,
      }]
    }

    if (order.promoCode) {
      reqOrder.promoCode = order.promoCode.code
    }


    // axios config
    const config = {
      method: 'post',
      url: '/api/v2/users/orders/books',
      data: {
        order: reqOrder,
      },
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      const message = (error.response && error.response.data.message) || error.message
      actions.setError({ message })
      actions.setIsLoading(false)
      return
    }

    if (response) {
      actions.setOrderResult(response.data.data)
      return response.data.data
    }
  }),
  // listeners
  onOrderUpdate: thunkOn(
    (actions) => [
      actions.setOrder,
      actions.setQty,
      actions.setPromoCode,
      actions.setShippingMethod,
      actions.setAddress,
    ],
    async (actions, target, helpers) => {
      if (target.type.includes('setQty') && target.payload.id !== 'BOOK_COPY') {
        return
      }

      const order = helpers.getState().order

      await actions.getOrderPricing(order)
    },
  ),
}

export default checkoutService

import { computed } from 'easy-peasy'

const booksModel_v3 = {
  list_v3: [],
  error_v3: '',
  isLoading_v3: true,
  artkiveBooks_v3: computed((state) => state.list_v3.filter((book) => book.isConcierge)),
  diyBooks_v3: computed((state) => state.list_v3.filter((book) => !book.isConcierge)),
}

export default booksModel_v3

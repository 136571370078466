import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@mui/styles'
import { transparentize } from 'polished'

import Button from './Button'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '.75rem',
    height: 36,
    padding: '0 8px',
  },
  outlinedPrimary: {
    height: 36,
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,

    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: transparentize(0.8, theme.palette.primary.light),
    },

    '&.active': {
      color: theme.palette.primary.light,
      backgroundColor: transparentize(0.8, theme.palette.primary.light),
      borderColor: theme.palette.primary.light,
    },
  },
  label: {
    fontWeight: 400,
  },
}))

const FilterButton = forwardRef((props, ref) => {
  const classes = useStyles()

  return (
    <Button variant={'secondary'} classes={classes} {...props} ref={ref} />
  )
})

FilterButton.propTypes = {
  children: PropTypes.node,
}

export default FilterButton

import React from 'react'
import PropTypes from 'prop-types'

import { useRouter } from 'next/router'

import Head from 'components/Head'
import ModalManager from 'components/ModalManager'

import findRoute from '@/routes/findRoute'

const LayoutWrapper = ({ children }) => {
  const router = useRouter()
  const route = findRoute('pathname', router.pathname)

  return (
    <>
      <Head title={route.title || 'Artkive'} />
      {children}
      <ModalManager />
    </>
  )
}

LayoutWrapper.propTypes = {
  children: PropTypes.node,
}

export default LayoutWrapper

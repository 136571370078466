import React, { useCallback } from 'react'
import { bool, func, object } from 'prop-types'

import cl from 'classnames'
import { useStoreActions } from 'easy-peasy'
import moment from 'moment'

import PictureMetaForm from 'components/Forms/PictureMeta'
import Icon from 'components/Icon'

import EditLabelsStyle from './EditLabelsStyle'

const EditLabels = ({
  picture,
  showLabel,
  setShowLabel,
}) => {
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)

  const handleValuesChange = useCallback((field, value) => {
    if (field === 'hidden') {
      return value.shouldChange ? updatePicture({ picture: { id: picture.id, [field]: value.value } }) : null
    } else {
      return updatePicture({ picture: { id: picture.id, [field]: value } })
    }
  }, [picture])

  return (
    <EditLabelsStyle className={cl({ active: showLabel })}>
      <div>
        <Icon
          name={'close'}
          onClick={() => setShowLabel(false)}
        />
      </div>

      <div className={'labels'}>
        <h3 className={'h3 darkblue'}>
          Edit Labels
        </h3>
      </div>

      <PictureMetaForm
        picture={picture}
        onValuesChange={handleValuesChange}
        showCaption={true}
        key={picture.id}
        indeterminateHidden={false}
      />

      <div className={'details'}>
        <p className={'secondary p demi'}>Details</p>
        <p className={'p darkgray sm'}>
          {picture && picture.image && picture.image.preview && picture.image.preview.match(/[\w-]+\.(jpg|png)/g)}
        </p>
        <p className={'p darkgray sm'}>Date/Time Added: {moment(picture.createdAt).format('MMM DD, YYYY/h:mma')}</p>
      </div>

    </EditLabelsStyle>
  )
}

EditLabels.propTypes = {
  picture: object.isRequired,
  showLabel: bool.isRequired,
  setShowLabel: func.isRequired,
}

export default EditLabels

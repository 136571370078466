import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('p')`
  font-family: ${theme.typography.type.montserrat};
  font-weight: 500;
  font-size: ${theme.typography.size.md};
  line-height: 1.3;

  @media (max-width: ${theme.breakpoints.md}) {
    font-size: ${theme.typography.size.lg};
  }

  &.secondary {
    font-size: ${theme.typography.size.sm};
    font-weight: 400;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.xs};
    }
  }
`


import { action } from 'easy-peasy'

const getPageSectionIndexById = (sections, sectionId) => sections.findIndex((section) => section.id === sectionId)

const booksActions_v3 = {
  setBooks_v3: action((state, books) => {
    state.list_v3 = books.map((book) => ({
      ...book,
      coverImageAspectRatio: book.size && book.size.coverPtHeight / book.size.coverPtWidth,
    }))
    state.isLoading_v3 = false
  }),
  setBook_v3: action((state, book) => {
    const bookIndex = state.list_v3.findIndex((o) => o.id === book.id)
    if (bookIndex > -1) {
      state.list_v3[bookIndex] = {
        ...state.list_v3[bookIndex],
        ...book,
      }
    }
  }),
  removeBook_v3: action((state, bookId) => {
    state.list_v3 = state.list_v3.filter(({ id }) => id !== bookId)
    state.isLoading_v3 = false
  }),
  setIsLoading_v3: action((state, payload) => {
    if (state.isLoading_v3 === payload) return
    state.isLoading_v3 = payload
  }),
  setError_v3: action((state, payload) => {
    state.error = payload
    state.isLoading_v3 = false
  }),

  setCoverSection_v3: action((state, { bookId, sectionId, payload }) => {
    const books = state.list_v3
    const bookToUpdate = books.find((book) => book.id === bookId)
    if (!bookToUpdate) return

    const sectionIndex = getPageSectionIndexById(bookToUpdate.cover.sections, sectionId)
    bookToUpdate.cover.sections[sectionIndex] = {
      ...bookToUpdate.cover.sections[sectionIndex],
      ...payload,
    }
  }),
}

export default booksActions_v3


import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .input-wrap {
    display: flex;
    align-items: center;
    width: 60%;
    margin: 10px auto;
    text-align: left;
  }

  .content {
    @media (max-width: ${theme.breakpoints.md}) {
      display: flex;
      height: 50%;
      flex-direction: column;
      justify-content: center;
      width: 400px;
    }
  }
`

const ordersModel = {
  list: null,
  error: '',
  isLoading: false,
  activeOrder: null,
  activeReceipt: null,
  statusHistory: null,
}

export default ordersModel

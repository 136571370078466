import React, { cloneElement, forwardRef } from 'react'
import { bool, func, node, oneOf, string } from 'prop-types'

import cl from 'classnames'
import MUIButton from '@mui/material/Button'

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'

const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
}

const SIZES = {
  SMALL: 'small',
  LARGE: 'large',
}

const variantsMapping = {
  [TYPES.PRIMARY]: {
    variant: 'contained',
    color: 'primary',
  },
  [TYPES.SECONDARY]: {
    variant: 'outlined',
    color: 'primary',
  },
  [TYPES.TEXT]: {
    color: 'primary',
  },
}

const getVariantMapping = (variant) => variantsMapping[variant] ?? variantsMapping[TYPES.PRIMARY]

const Button = forwardRef(({
  children,
  disabled = false,
  size,
  variant = TYPES.PRIMARY,
  isLoading,
  startIcon,
  endIcon,
  ...rest
}, ref) => {
  return (
    <MUIButton
      {...getVariantMapping(variant)}
      size={size}
      disabled={disabled}
      {...rest}
      startIcon={!isLoading && startIcon && cloneElement(startIcon, {
        className: cl('start-icon'),
      })}
      endIcon={endIcon && cloneElement(endIcon, {
        className: 'end-icon',
      })}
      ref={ref}
    >
      {isLoading ? (
        <LoadingSpinner size={30} />
      ) : children}
    </MUIButton>
  )
})

Button.propTypes = {
  /** button type */
  variant: oneOf(Object.values(TYPES)),
  /** children to render */
  children: node.isRequired,
  /** custom class name */
  className: string,
  /** is button disabled */
  disabled: bool,
  /** handle button click */
  onClick: func,
  /** button size */
  size: oneOf(Object.values(SIZES)),
  color: string,
  isLoading: bool,

  startIcon: node,
  endIcon: node,
}

export default Button

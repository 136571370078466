import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { makeStyles } from '@mui/styles'

import Input from 'components/FormFields/Input'
import Icon from 'components/Icon'
import theme from 'styles/theme'

const useStyles = makeStyles({
  input: {
    fontSize: theme.typography.size.xxs,
    '&.extra-padding input': {
      paddingRight: '30px !important',
    },
    '& input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  icon: {
    top: '50% !important',
  },
})

const CopyToClipboard = ({ value, disabled = false, className = 'disabled' }) => {
  const inputRef = useRef()
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const copyToClipboard = useCallback((e) => {
    inputRef.current.select()
    document.execCommand('copy')
    e.target.focus()

    setCopied(true)
    setTimeout(() => setCopied(false), 5000)
  }, [])
  const endAdornment = useMemo(() => {
    if (disabled) {
      return <CheckBoxIcon className={'darkblue pointer'} />
    } else if (copied) {
      return <span className={'label blue xs'}>Copied!</span>
    } else {
      return <Icon name={'copy'} onClick={copyToClipboard} className={cl('darkblue pointer', classes.icon)} />
    }
  }, [copied, disabled])

  return (
    <Input
      type={'text'}
      value={value}
      className={cl('pointer', classes.input, className, { 'extra-padding': !disabled && !copied })}
      onClick={copyToClipboard}
      ref={inputRef}
      size={'small'}
      endAdornment={endAdornment}
    />
  )
}

CopyToClipboard.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default CopyToClipboard

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .picture-meta-modal {
    @media (min-width: ${theme.breakpoints.md}) {
      width: 400px;
    }

    .picture-meta-content {
      margin: 0 auto;

      .buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 4rem;

        button {
          margin-right: 0;
          margin-left: 0;
        }

        span {
          font-size: ${(props) => props.theme.typography.size.md};
        }
      }
    }
  }
`

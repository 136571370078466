import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { BodyText, SmallGreyText } from 'components/NewModal/NewModal.style'

const MAX_FILE_NAMES_COUNT = 3

const UploadFileTypeErrorContent = ({ fileRejections }) => {
  const hasMore = fileRejections.length > MAX_FILE_NAMES_COUNT
  const filesToDisplay = useMemo(() => (
    hasMore ? fileRejections.slice(0, MAX_FILE_NAMES_COUNT) : fileRejections
  ), [fileRejections])

  const restFiles = useMemo(() => (
    hasMore ? fileRejections.slice(MAX_FILE_NAMES_COUNT, fileRejections.length) : [])
  , [fileRejections])

  return (
    <>
      <BodyText>
        Please choose only <Typography variant={'strong'} component={'span'}>.jpg</Typography> or{' '}
        <Typography variant={'strong'} component={'span'}>.png</Typography> to upload.
        Try uploading your files again once you save them in a correct format.
      </BodyText>
      <Box mt={3} display={'flex'} flexDirection={'column'} gap={1}>
        {!!filesToDisplay.length && filesToDisplay.map(({ file }, index) => (
          <SmallGreyText key={index}>
            {file.name}
          </SmallGreyText>
        ))}
        {!!restFiles.length && (
          <SmallGreyText>
            and{' '}{restFiles.length}{' '}file{restFiles.length > 1 && 's'}{' '}more.
          </SmallGreyText>
        )}
      </Box>
    </>
  )
}

UploadFileTypeErrorContent.propTypes = {
  fileRejections: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default UploadFileTypeErrorContent

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import CheckIcon from '@mui/icons-material/Check'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  copy: {
    fontSize: '1rem',
    cursor: 'pointer',
    marginLeft: 4,
    color: theme.colors.gray[500],
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.colors.gray[900],
    },
  },
  checkmark: {
    fontSize: '1rem',
    marginLeft: 4,
    color: theme.colors.gray[900],
  },
}))

const CopyButton = ({ onClick }) => {
  const classes = useStyles()
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setClicked(false), 2000)
    return () => clearTimeout(timeout)
  }, [clicked])

  return (
    clicked ? (
      <CheckIcon
        size={16}
        className={classes.checkmark}
      />
    ) : (
      <ContentCopyIcon
        className={classes.copy}
        onClick={() => {
          setClicked(true)
          onClick()
        }}
      />
    )
  )
}

CopyButton.propTypes = {
  onClick: PropTypes.func,
}

export default CopyButton

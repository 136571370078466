import { alpha, styled } from '@mui/material/styles'

const telegramBarColor = ({ theme }) => alpha(theme.colors.white, 0.4)
const telegramHighlightedBarColor = 'currentColor'

export const RotateNavigationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 450px;
  width: 100%;
  align-self: center;
  margin-top: 58px;
  margin-bottom: 64px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 40px;
    margin-bottom: 48px;
  }
`

export const RotateBars = styled('div')`
  cursor: grab;
  width: 100%;
  display: flex;
  min-width: 0;
  position: relative;
  height: 15px;

  .is--dragging {
    cursor: grabbing !important;
  }
`

export const RotateBar = styled('div')`
  position: absolute;
  left: 0;
  height: 12px;
  width: 1px;
  flex-shrink: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${telegramBarColor};
  opacity: 0.4;

  &.bar--highlighted {
    background-color: ${telegramHighlightedBarColor};
    opacity: ${({ theme }) => alpha(theme.colors.white, 1)};
  }

  &.bar--zero {
    height: 16px;
  }
`

export const RotateWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  height: 42px;

  .advanced-cropper-draggable-element {
    flex: 1;
  }

  body {
    &.dragging {
      cursor: grabbing !important;
    }
  }
`

export const RotateValue = styled('div')`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  background-color: ${telegramHighlightedBarColor};
  color: ${({ theme }) => theme.colors.sky[700]};
  height: 25px;
  border-radius: 2px;
`

export const RotateValueNumber = styled('div')`
  position: absolute;
  top: -52px;
  left: 50%;
  transform: translateX(-50%);
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: 500;
`

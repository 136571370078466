import { useMemo } from 'react'

import { baseApi } from 'dux/queries/baseApi'

import { TAG_TYPE } from './constants'

export const ageGradeApiUrls = {
  getAgeGrade: () => '/api/v2/user/labels',
  createAgeGrade: () => '/api/v2/user/labels',
  updateAgeGrade: (id) => `/api/v2/user/labels/${id}`,
  deleteAgeGrade: (id) => `/api/v2/user/labels/${id}`,
}

export const ageGradeTextLabelsApi =
  baseApi
    .enhanceEndpoints({ addTagTypes: [TAG_TYPE.AGE_GRADE] })
    .injectEndpoints({
      overrideExisting: true,
      endpoints: (builder) => ({
        getAgeGrade: builder.query({
          query: () => ({
            url: ageGradeApiUrls.getAgeGrade(),
            method: 'GET',
          }),
          transformResponse: (response) => {
            const customLabels = response.data?.custom.map((label) => ({
              ...label,
              custom: true,
            })) || []

            return [
              ...customLabels,
              ...(response.data?.ages || []),
              ...(response.data?.global || []),
            ]
          },
          providesTags: (result) => result
            ? [...result.map(({ id }) => ({ type: TAG_TYPE.AGE_GRADE, id })), TAG_TYPE.AGE_GRADE]
            : [TAG_TYPE.AGE_GRADE],
        }),
        createAgeGrade: builder.mutation({
          query: (data) => ({
            url: ageGradeApiUrls.createAgeGrade(),
            method: 'POST',
            data,
          }),
          transformResponse: (response) => response.data,
          invalidatesTags: [TAG_TYPE.AGE_GRADE, TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE],
        }),
        updateAgeGrade: builder.mutation({
          query: ({ id, ...data }) => ({
            url: ageGradeApiUrls.updateAgeGrade(id),
            method: 'PUT',
            data,
          }),
          invalidatesTags: (result, error, { id }) => [{ type: TAG_TYPE.AGE_GRADE, id }],
        }),
        deleteAgeGrade: builder.mutation({
          query: ({ id }) => ({
            url: ageGradeApiUrls.deleteAgeGrade(id),
            method: 'DELETE',
          }),
          invalidatesTags: (result, error, { id }) => [
            { type: TAG_TYPE.AGE_GRADE, id },
            TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE,
          ],
        }),
      }),
    })

export const useAgeGradeOptions = () => {
  const { data: ageGradeLabels = [], isLoading: isAgeGradeLoading } = useGetAgeGradeQuery()

  const ageGradeOptions = useMemo(() => ageGradeLabels.map((label) => ({
    label: label.name,
    value: label.id,
  })), [ageGradeLabels])

  return [ageGradeOptions, isAgeGradeLoading]
}

export const {
  useGetAgeGradeQuery,
  useCreateAgeGradeMutation,
  useUpdateAgeGradeMutation,
  useDeleteAgeGradeMutation,
} = ageGradeTextLabelsApi

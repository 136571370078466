const cookie = require('cookie')

exports.SESSION_COOKIE_NAME = 'artkive-server-session'

/**
 * Parse string of cookies and return cookie by name
 * @param {string} cookieStr - string of all cookies
 * @param {string} name - name of cookie to find
 * @return {object} cookie object
 */
exports.getCookieByName = (cookieStr, name) => {
  const cookieObj = cookie.parse(cookieStr)
  return cookieObj[name]
}

/**
 * Extracts session cookie value
 * @param {string} cookieStr - string of all cookies
 * @return {object} cookie object
 */
exports.getSessionCookie = (cookieStr) => this.getCookieByName(cookieStr, this.SESSION_COOKIE_NAME)
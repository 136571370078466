export const UPLOAD_FILTER_OPTIONS = [
  {
    label: 'Artkive/My Uploads',
    mobileLabel: 'All Uploads',
    value: {},
  },
  {
    label: 'My Uploads',
    mobileLabel: 'My Uploads',
    value: {
      uploadedByUser: true,
    },
  },
  {
    label: 'Artkive Uploads',
    mobileLabel: 'Artkive Uploads',
    value: {
      uploadedByAdmin: true,
    },
  },
]

export const SET_PICTURES_MODES = {
  APPEND: 'append',
  SET: 'set',
}

export const PAGE_SIZE = 200

export const MAX_LOAD_CHUNK_SIZE = 200

export const UPLOADING_IMAGE_STATUSES = {
  CANCEL: 'cancel',
  FAILED: 'failed',
  IN_PROGRESS: 'in_progress',
  QUEUED: 'queued',
  RETRY: 'retry',
  SUCCESS: 'success',
}

import creditCardType from 'credit-card-type'

import regex from './regex'

export const CHILD_NAME_MAX_LENGTH = 25
export const CAPTION_MAX_LENGTH = 50
export const LABEL_MAX_LENGTH = 25
export const TAG_MAX_LENGTH = 25

export const MIN_TEXT_VALUE_LENGTH = 2

const creditCardValidateCallback = (data) => {
  if (!data) {
    return 'Please specify card data'
  }

  const creditCard = creditCardType(data.cc)

  if (!data.cc || !creditCard.length) {
    return 'Please specify card data'
  }

  if (!data.cvc || !creditCard.some(({ code }) => code.size === data.cvc.length)) {
    return 'Please specify card data'
  }

  if (!data.exp || !regex.cardExpiry.test(data.exp)) {
    return 'Please specify card data'
  }

  return true
}

export const newTitleValidation = {
  maxLength: {
    value: CAPTION_MAX_LENGTH,
    message: 'Title cannot be longer than 50 characters',
  },
  pattern: {
    value: regex.title,
    message: 'Title cannot contain non-English characters',
  },
}

export default {
  email: {
    required: 'Email is required',
    pattern: {
      value: regex.email,
      message: 'Please enter a valid email',
    },
  },
  confirmEmail: (getValues) => ({
    validate: (value) => {
      return value === getValues('email') || 'Emails don\'t match'
    },
  }),
  createPassword: {
    required: 'Password is required',
    pattern: {
      value: regex.password,
      message: 'Password should contain at least 6 symbols, one number and one letter',
    },
  },
  password: {
    required: 'Password is required',
  },
  name: {
    required: 'Name is required',
    validate: (value) => value.split(' ').length > 1 || 'Please specify both first and last name',
    pattern: {
      value: regex.name,
      message: 'Your name should contain English characters, - \' , and .',
    },
  },
  cardName: {
    required: 'Name is required',
    pattern: {
      value: regex.cardName,
      message: 'Please specify first and last name',
    },
  },
  billingZipCode: {
    required: 'Billing postal code is required',
    pattern: {
      value: regex.billingZipCode,
      message: 'Please specify correct postal code',
    },
  },
  shippingPostalCode: {
    required: 'Shipping postal code is required',
    pattern: {
      value: regex.billingZipCode,
      message: 'Please specify correct postal code',
    },
  },
  confirmPassword: (getValues) => ({
    required: 'Please re-enter your password',
    validate: (value) => {
      return value === getValues('password') || 'Passwords don\'t match'
    },
  }),
  title: {
    maxLength: {
      value: CAPTION_MAX_LENGTH,
      message: 'Title cannot be longer than 50 characters',
    },
    pattern: {
      value: regex.title,
      message: 'Title cannot contain non-English characters',
    },
  },
  creditCard: (getValues) => ({
    required: 'Please specify card data',
    validate: () => creditCardValidateCallback(getValues()),
  }),
  cardData: {
    validate: creditCardValidateCallback,
  },
  address: {
    required: 'Street address is required',
    minLength: {
      value: 5,
      message: 'Street address should have at least 5 characters',
    },
    pattern: {
      value: regex.street,
      message: 'Please enter a valid street',
    },
  },
  country: {
    required: 'Country is required',
  },
  state: {
    required: 'State is required',
  },
  city: {
    required: 'City is required',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'City should have at least 2 characters',
    },
    pattern: {
      value: regex.name,
      message: 'City cannot contain non-English characters',
    },
  },
  lastName: {
    required: 'Please enter your last name',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'Last name should have at least 2 characters',
    },
    pattern: {
      value: regex.name,
      message: 'Last name cannot contain non-English characters',
    },
  },
  firstName: {
    required: 'Please enter your first name',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'First name should have at least 2 characters',
    },
    pattern: {
      value: regex.name,
      message: 'First name cannot contain non-English characters',
    },
  },
}

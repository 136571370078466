import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`

  .content-wrap {
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: 20px;
    margin: 0 auto;
    border-radius: 4px;
    text-align: center;

    @media (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50%;
    }

    h2 {
      margin: 20px auto;
    }
  }
`

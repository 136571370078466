import React, { useCallback } from 'react'
import { bool, func, node, object } from 'prop-types'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import Style, { Check, Circle, HiddenOverlay, Overlay } from './withSelect.style'

const withSelect = (Component) => {
  const SelectableComponent = ({ onClick, onIconClick, selected, selectable, picture, ...props }) => {
    const handleCheckClick = useCallback((e) => {
      onIconClick && onIconClick(e, picture.id)
    }, [onIconClick, picture])

    const handleOverlayClick = useCallback((e) => {
      onClick && onClick(e, picture.id)
    }, [onClick, picture])

    return (
      <Style>
        <Overlay
          onClick={handleOverlayClick}
          selected={selected}
          selectable={selectable}
        >
          {picture.hidden && (
            <HiddenOverlay>
              <VisibilityOffIcon color={'inherit'} />
            </HiddenOverlay>
          )}
        </Overlay>

        <Circle selectable={selectable} onClick={handleCheckClick}>
          <Check name={'check'} selected={selected} />
        </Circle>

        <Component {...props} picture={picture} />
      </Style>
    )
  }

  SelectableComponent.propTypes = {
    onClick: func,
    onIconClick: func,
    selected: bool,
    selectable: bool,
    picture: object,
  }

  return SelectableComponent
}

withSelect.propTypes = {
  Component: node.isRequired,
}

export default withSelect

import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { CropperBackgroundImage, mergeRefs } from 'react-advanced-cropper'
import { getBackgroundStyle } from 'advanced-cropper'

import AdjustableImage from 'components/Modal/NewImageEditor/components/AdjustableImage'
import { IMAGE_EDITOR_VARIANT } from 'components/Modal/NewImageEditor/NewImageEditor.constants'

const AdjustableCropperBackground = forwardRef(
  function AdjustableCropperBackground(props, ref) {
    const sourceImageRef = useRef(null)

    const {
      className,
      cropper,
      brightness = 0,
      saturation = 0,
      hue = 0,
      contrast = 0,
      variant,
      onImageLoaded,
      imageRef,
    } = props
    const state = cropper.getState()
    const transitions = cropper.getTransitions()
    const image = cropper.getImage()

    const style = image && state ? getBackgroundStyle(image, state, transitions) : {}

    const composedRef = mergeRefs([ref, imageRef])

    useEffect(() => {

      const imageNode = variant === IMAGE_EDITOR_VARIANT.ADJUST ? sourceImageRef.current : imageRef.current

      if (imageNode.tagName !== 'IMG' || !imageNode) {
        return
      }

      if (imageNode.complete) {
        onImageLoaded()
      }

      const onLoaded = () => {
        onImageLoaded()
      }

      imageNode.addEventListener('load', onLoaded)

      return () => {
        !!imageNode && imageNode.removeEventListener('load', onLoaded)
      }
    }, [image?.src, variant])

    if (variant === IMAGE_EDITOR_VARIANT.ADJUST) {
      return (
        <AdjustableImage
          src={image?.src}
          key={image?.src}
          crossOrigin={'anonymous'}
          isColorAdjustAvailable={variant === IMAGE_EDITOR_VARIANT.ADJUST}
          brightness={brightness}
          saturation={saturation}
          hue={hue}
          contrast={contrast}
          imageSize={state.imageSize}
          ref={composedRef}
          className={className}
          style={style}
          imageRef={sourceImageRef}
        />
      )
    } else {
      return <CropperBackgroundImage {...props} ref={composedRef} />
    }
  },
)

AdjustableCropperBackground.propTypes = {
  className: PropTypes.string,
  cropper: PropTypes.object,
  brightness: PropTypes.number,
  saturation: PropTypes.number,
  hue: PropTypes.number,
  contrast: PropTypes.number,
  onImageLoaded: PropTypes.func,
  variant: PropTypes.oneOf([IMAGE_EDITOR_VARIANT.CROP, IMAGE_EDITOR_VARIANT.ADJUST]),
  imageRef: PropTypes.object,
}

export default AdjustableCropperBackground

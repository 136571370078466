import React from 'react'

import getConfig from 'next/config'

import Logo from 'components/svg/Logo'

import AuthNavStyle from './AuthNav.style'

const { publicRuntimeConfig: { NEXT_PUBLIC_API_URL } } = getConfig()

const AuthNav = () => {
  return (
    <AuthNavStyle>
      <a href={NEXT_PUBLIC_API_URL}><Logo width={200} /></a>
    </AuthNavStyle>
  )
}

export default AuthNav

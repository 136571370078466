import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useLatest } from 'react-use'

import { SliderDot, SliderFill, SliderHandler, SliderLine, SliderValue, SliderWrapper } from './Slider.style'

const Slider = ({ value = 0, className, onChange }) => {
  const lineRef = useRef(null)
  const [focus, setFocus] = useState(false)
  const [width, setWidth] = useState(0)
  const focusRef = useLatest(focus)
  const onChangeRef = useLatest(onChange)

  const onDrag = useCallback((e) => {
    if (focusRef.current && onChangeRef.current) {
      const position = 'touches' in e ? e.touches[0].clientX : e.clientX
      const lineRect = lineRef.current.getBoundingClientRect()
      const { left, width } = lineRect

      onChangeRef.current(Math.max(-1, Math.min(1, (2 * (position - left - width / 2)) / width)))

      if (e.preventDefault) {
        e.preventDefault()
      }
    }
  }, [])

  useEffect(() => {
    const recalculateWidth = () => {
      if (lineRef.current) {
        setWidth(lineRef.current.clientWidth)
      }
    }

    window.addEventListener('resize', recalculateWidth)
    window.addEventListener('orientationchange', recalculateWidth)

    const onStop = () => setFocus(false)

    window.addEventListener('mouseup', onStop, { passive: false })
    window.addEventListener('touchend', onStop, { passive: false })

    const onStart = (e) => {
      setFocus(true)
      onDrag(e)
    }

    const lineCurrent = lineRef.current

    if (lineCurrent) {
      lineCurrent.addEventListener('mousedown', onStart)
      lineCurrent.addEventListener('touchstart', onStart)
    }

    recalculateWidth()

    return () => {
      window.removeEventListener('resize', recalculateWidth)
      window.removeEventListener('orientationchange', recalculateWidth)
      window.removeEventListener('mouseup', onStop)
      window.removeEventListener('touchend', onStop)

      if (lineCurrent) {
        lineCurrent.removeEventListener('mousedown', onStart)
        lineCurrent.removeEventListener('touchstart', onStart)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', onDrag, { passive: false })
    window.addEventListener('touchmove', onDrag, { passive: false })

    return () => {
      window.removeEventListener('mousemove', onDrag)
      window.removeEventListener('touchmove', onDrag)
    }
  }, [])

  const handleInsideDot = width ? Math.abs(value) <= 16 / width : true
  const fillWidth = `${Math.abs(value) * 50}%`
  const fillLeft = `${50 * (1 - Math.abs(Math.min(0, value)))}%`
  const formattedValue = `${value > 0 ? '+' : ''}${Math.round(100 * value)}`

  return (
    <SliderWrapper className={className} ref={lineRef}>
      <SliderLine>
        <SliderFill style={{ width: fillWidth, left: fillLeft }} />
        <SliderDot />
        <SliderValue style={{ left: `${Math.abs(value * 50 + 50)}%`, opacity: value ? 1 : 0 }}>
          {formattedValue}
        </SliderValue>
        <SliderHandler isHidden={handleInsideDot} isFocused={focus} style={{ left: `${value * 50 + 50}%` }} />
      </SliderLine>
    </SliderWrapper>
  )
}

Slider.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export default Slider

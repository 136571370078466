import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import AddBookPicturesStyled from 'components/Modal/AddBookPictures/AddBookPictures.styled'
import { APP_MODALS } from 'components/ModalManager'
import UploadPicture from 'components/UploadPicture/UploadPicture'
import { MIN_BOOK_CAPACITY } from 'containers/Book/Book.constants'
import { picturesValidationMessage } from 'containers/Book/Book.utils'
import useUserInformation from 'dux/hooks/useUserInformation'
import useModal from 'hooks/useModal'
import uploadService from 'services/uploadService'

const AddBookPictures = ({ book }) => {
  const picturesLoading = false
  const { setModal } = useModal()
  const { isLoggedAsUser } = useUserInformation()

  const uploadHandler = useCallback((files, meta) => {
    uploadService.addPicturesBatch({
      pictures: files,
      meta,
      book,
    })
  }, [book])

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) return

    const prepaidPages = book.prepaidPages || Infinity

    const message = picturesValidationMessage(book.numberOfPages + acceptedFiles.length, {
      purchasedLimit: prepaidPages,
      minCapacity: isLoggedAsUser ? 0 : MIN_BOOK_CAPACITY,
    })

    if (message) {
      setModal({ name: APP_MODALS.BookLimitsViolated, message })
    } else {
      setModal({
        active: true,
        name: APP_MODALS.PictureMetaModal,
        indeterminateHidden: false,
        onSuccess: (meta) => uploadHandler(acceptedFiles, meta),
      })
    }

  }, [book])

  return (
    <AddBookPicturesStyled>
      <div className={'content-wrap'}>
        <UploadPicture
          onDrop={onDrop}
          isLoading={picturesLoading}
          multiple={true}
        />
      </div>
    </AddBookPicturesStyled>
  )
}

AddBookPictures.propTypes = {
  book: PropTypes.object,
}

export default AddBookPictures

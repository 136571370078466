import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Controller, useController } from 'react-hook-form'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, FormControlLabel, FormHelperText, Switch } from '@mui/material'

const HiddenImagesSwitch = ({ control, indeterminate = false, name = 'hidden', onModeChange }) => {
  const [disabled, setDisabled] = useState(indeterminate)
  const { field } = useController({
    control,
    name,
  })
  const handleEditingToggle = () => {
    onModeChange(disabled)
    setDisabled((prev) => !prev)
    field.onChange(control._defaultValues.hidden)
  }

  useEffect(() => {
    if (!indeterminate) {
      onModeChange(true)
    }
  }, [indeterminate])

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <FormControlLabel
          control={<Controller
            control={control}
            name={name}
            render={({ field: { ref, onChange, value, onBlur, name } }) => (
              <Switch
                color={'primary'}
                onChange={(e) => {
                  onChange(e.target.checked)
                  onBlur()
                }}
                checked={value}
                ref={ref}
                name={name}
                disabled={disabled}
              />
            )}
          />}
          label={'Make Hidden'}
        />
        {indeterminate && (
          <Button
            startIcon={disabled && <EditIcon />}
            color={'primary'}
            variant={'text'}
            onClick={handleEditingToggle}
          >
            {disabled ? 'Edit' : 'Cancel'}
          </Button>
        )}
      </Box>

      {indeterminate && !disabled && (
        <FormHelperText>
          *Will be applied to all selected images
        </FormHelperText>
      )}
    </Box>
  )
}

HiddenImagesSwitch.propTypes = {
  control: PropTypes.object,
  indeterminate: PropTypes.bool,
  name: PropTypes.string,
  onModeChange: PropTypes.func,
}

export default HiddenImagesSwitch

import React from 'react'

import ErrorIcon from '@mui/icons-material/Error'

import AnimatedDownloadIcon from 'components/svg/AnimatedDownloadIcon/AnimatedDownloadIcon'
import AnimatedProgressIcon from 'components/svg/AnimatedProgressIcon'

export const NOTIFICATION_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  DOWNLOAD: 'download',
  PROGRESS: 'progress',
}

export const infoTypes = [NOTIFICATION_TYPES.INFO, NOTIFICATION_TYPES.PROGRESS, NOTIFICATION_TYPES.DOWNLOAD]

export const infoIconMap = {
  [NOTIFICATION_TYPES.INFO]: <ErrorIcon fontSize={'small'} />,
  [NOTIFICATION_TYPES.PROGRESS]: <AnimatedProgressIcon fontSize={'small'} />,
  [NOTIFICATION_TYPES.DOWNLOAD]: <AnimatedDownloadIcon baseColor={'#9EA0AB'} fillColor={'#3D4156'} />,
}

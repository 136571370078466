import React from 'react'
import { bool, func, string } from 'prop-types'

import { useDropzone } from 'react-dropzone'

import DropzoneEmptyScreen from 'components/DropzoneEmptyScreen'
import withLoader from 'hoc/withLoader'

import UploadPictureStyle from './UploadPicture.style'

const UploadPicture = ({ onDrop, multiple = false, reason }) => {
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
    multiple,
    onDrop,
  })

  return (
    <UploadPictureStyle>
      <input {...getInputProps()} />
      <DropzoneEmptyScreen
        {...getRootProps({ className: 'dropzone', refKey: 'innerRef' })}
        reason={reason}
        getRootProps={getRootProps}
      />
    </UploadPictureStyle>
  )
}

UploadPicture.propTypes = {
  onDrop: func,
  multiple: bool,
  reason: string,
}

const UploadPictureWithLoader = withLoader(UploadPicture)

export default UploadPictureWithLoader

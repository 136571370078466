import React from 'react'
import PropTypes from 'prop-types'

import { Link, Typography } from '@mui/material'

import { BodyText } from 'components/NewModal/NewModal.style'
import { MAX_BOOK_CAPACITY } from 'containers/Book/Book.constants'
import { COMPANY_HELP_EMAIL } from 'utils/constants'

const MaxImagesLimitReachedContent = ({ prepaidPages }) => {
  const isPrepaidBook = Number.isInteger(prepaidPages) && prepaidPages

  const contactLink = (
    <Link sx={{ fontWeight: 700, textDecoration: 'none' }} href={`mailto:${COMPANY_HELP_EMAIL}`}>Contact Support</Link>
  )

  return (
    <BodyText>
      {isPrepaidBook ? (
        <>
          Your book is limited to the{' '}
          <Typography variant={'strong'} component={'span'}>{prepaidPages} images</Typography>{' '}you've paid for.
          {' '}Please remove images before adding more or {contactLink} to add more images to your book.
        </>
      ) : (
        <>
          Books can hold a maximum of{' '}
          <Typography variant={'strong'} component={'span'}>{MAX_BOOK_CAPACITY} pages</Typography>.
          {' '}Please remove pages before adding more or {contactLink} for assistance.
        </>
      )}
    </BodyText>
  )
}

MaxImagesLimitReachedContent.propTypes = {
  prepaidPages: PropTypes.number,
}

export default MaxImagesLimitReachedContent

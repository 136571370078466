import React from 'react'

import { useForm } from 'react-hook-form'

import Button from 'components/Buttons/Button'
import Input from 'components/FormFields/Input'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'
import { useUpdatePaymentInformationMutation } from 'dux/queries/user'
import useModal from 'hooks/useModal'
import validations from 'utils/validations'

import { ccFormat, expDateFormat } from './creditCardFormatUtils'
import UpdatePaymentMethodStyles, { Form, SubCardDetailsStyles } from './UpdatePaymentMethod.style'

const UpdatePaymentMethod = () => {
  const { unsetModal } = useModal()
  const [updatePaymentInformation] = useUpdatePaymentInformationMutation()

  const { register, handleSubmit, getValues, formState: { errors }, setValue } = useForm({
    defaultValues: {
      cc: '',
      exp: '',
    },
  })

  const handleSaveChanges = async (data) => {
    const response = await updatePaymentInformation(data).unwrap()
    if (!response?.error) unsetModal()
  }

  const onSubmit = handleSubmit(handleSaveChanges)

  const handleCreditCardNumberChange = (e) => {
    const value = ccFormat(e.target.value)
    setValue('cc', value)
  }
  const handleExpDateChange = (e) => {
    const value = expDateFormat(e.target.value)
    setValue('exp', value)
  }

  return (
    <UpdatePaymentMethodStyles>
      <Modal handleClose={unsetModal}>
        <H2 className={'center darkblue'}>Update Payment Method</H2>
        <P className={'center'}>
          Edit your membership payment method below.
        </P>

        <Form onSubmit={onSubmit}>
          <Input
            type={'text'}
            label={'Name of Card'}
            {...register('card_name', validations.cardName)}
            error={errors.card_name}
            gutter
          />

          <Input
            type={'text'}
            label={'Card Number'}
            {...register('cc', validations.creditCard(getValues))}
            onChange={handleCreditCardNumberChange}
            error={errors.cc}
            gutter
          />

          <SubCardDetailsStyles>
            <Input
              className={'input'}
              type={'text'}
              label={'Exp Date (MM/YY)'}
              {...register('exp', validations.creditCard(getValues))}
              onChange={handleExpDateChange}
              error={errors.exp}
              gutter
            />
            <Input
              className={'input'}
              type={'text'}
              label={'CVC'}
              {...register('cvc', validations.creditCard(getValues))}
              error={errors.cvc}
              gutter
            />
            <Input
              className={'input'}
              type={'text'}
              label={'Billing Zip'}
              {...register('zip', validations.billingZipCode)}
              error={errors.zip}
              gutter
            />
          </SubCardDetailsStyles>

          <div className={'buttons'}>
            <Button onClick={onSubmit}>
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </UpdatePaymentMethodStyles>
  )
}

export default UpdatePaymentMethod

import { useEffect, useRef } from 'react'

import { use100vh } from 'react-div-100vh'

const useScreenHeight = () => {
  const isTouching = useRef(false)
  const height = use100vh()
  const screenHeight = height ? `${height}px` : '100vh'

  useEffect(() => {
    const handleTouchStart = () => isTouching.current = true
    const handleTouchEnd = () => isTouching.current = false

    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchend', handleTouchEnd)

    return () => {
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])

  useEffect(() => {
    if (screenHeight && !isTouching.current) {
      document.body.style.setProperty('--app-height', screenHeight)
    }

    return () => {
      document.body.style.removeProperty('--app-height')
    }
  }, [screenHeight]) // NOTE: we don't need isTouching.current in dependencies

  return screenHeight
}


export default useScreenHeight

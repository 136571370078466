import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const ImageWrap = styled(Box)`
  background-color: ${({ theme }) => theme.colors.sky[100]};
  display: flex;
  justify-content: center;
  padding: 24px 40px;
  margin: -32px -16px 24px;
  max-height: 220px;

  ${({ theme }) => `${theme.breakpoints.down('md')} and (orientation: landscape)`} {
    padding: 8px 40px;
  }
  
  ${({ theme }) => theme.breakpoints.up('sm')} {
      margin: -48px -64px 24px;
  }
`

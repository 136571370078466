import React from 'react'
import PropTypes from 'prop-types'

import FileUploadIcon from '@mui/icons-material/FileUpload'

import DashedBorder from 'components/UploadDropzone/DashedBorder/DashedBorder'

import { Subtitle, Title, Wrap } from './DragEnterPlaceholder.style'

const DragEnterPlaceholder = ({ fullScreen, className }) => {
  return (
    <Wrap fullScreen={fullScreen} className={className}>
      <DashedBorder sx={(theme) => ({ color: theme.colors.sky[700] })} />
      <FileUploadIcon sx={(theme) => ({ color: theme.colors.sky[700], fontSize: '4rem' })} />
      <Title>Drop files to Upload</Title>
      <Subtitle variant={'subtitle2'}>Only JPG and PNG’s allowed</Subtitle>
    </Wrap>
  )
}

DragEnterPlaceholder.propTypes = {
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
}

export default DragEnterPlaceholder

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import Box from '@mui/material/Box'
import { useStoreActions } from 'easy-peasy'

import PictureChildFilterDropdown from 'components/PictureChildFilterDropdown'
import PictureMobileFiltersDropdown from 'components/PictureMobileFiltersDropdown'
import PictureSortDropdown from 'components/PictureSortDropdown'
import PictureTagsFilterDropdown from 'components/PictureTagsFilterDropdown'
import PictureUploadFilter from 'components/PictureUploadFilter'
import ReplaceButton from 'components/ReplaceButton'
import P from 'components/Text/P'
import useUserInformation from 'dux/hooks/useUserInformation'
import { useGetChildrenWithAgeGradeQuery } from 'dux/queries/nameTextLabels'
import { useGetTagsQuery } from 'dux/queries/tagsTextLabels'

import PictureSortAndFilterStyle, {
  AdditionalControls,
  GridIcon,
  ListIcon,
  SortFilterOptions,
  SortFilterWrap,
} from './PictureSortAndFilter.style'

const PictureSortAndFilter = ({
  bgColor,
  childFilters,
  setSortQueries,
  setTagFilters,
  setUploadFilter,
  setView,
  sortQueries,
  tagFilters,
  uploadFilter,
  view,
  children,
}) => {
  const { isLoggedAsUser } = useUserInformation()

  const { data: tags = [] } = useGetTagsQuery()
  const { data: childrenList = [] } = useGetChildrenWithAgeGradeQuery()

  const resetFilters = useStoreActions((actions) => actions.pictures.resetFilters)

  useEffect(() => {
    return () => resetFilters()
  }, [])

  return (
    <PictureSortAndFilterStyle className={'sort-and-filter-wrap'}>
      <SortFilterWrap bgColor={bgColor}>
        <SortFilterOptions>
          <div className={'sort-wrap hide-mobile'}>
            <PictureSortDropdown sortQueries={sortQueries} setSortQueries={setSortQueries} />
          </div>
          <div className={'filter-wrap-desktop'}>
            <P className={'secondary filter-label'}>Filter by:</P>
            {!!childrenList.length && (
              <PictureChildFilterDropdown childrenOptions={childrenList} />
            )}
            {!!tags.length && (
              <PictureTagsFilterDropdown tags={tags} />
            )}
            <PictureUploadFilter />
          </div>
          <div className={'filter-wrap-mobile'}>
            <PictureMobileFiltersDropdown
              sortQueries={sortQueries}
              setSortQueries={setSortQueries}
              childrenOptions={childrenList}
              childFilters={childFilters}
              tags={tags}
              tagFilters={tagFilters}
              setTagFilters={setTagFilters}
              uploadFilter={uploadFilter}
              setUploadFilter={setUploadFilter}
            />
          </div>
          {children}
        </SortFilterOptions>
        <Box display={'flex'}>
          <GridIcon fontSize={'large'} className={cl({ active: view === 'grid' })} onClick={() => setView('grid')} />
          <ListIcon fontSize={'large'} className={cl({ active: view === 'list' })} onClick={() => setView('list')} />
        </Box>
      </SortFilterWrap>
      {isLoggedAsUser && (
        <AdditionalControls>
          <ReplaceButton />
        </AdditionalControls>
      )}
    </PictureSortAndFilterStyle>
  )
}

PictureSortAndFilter.propTypes = {
  bgColor: PropTypes.string,
  childFilters: PropTypes.object,
  setSortQueries: PropTypes.func,
  setTagFilters: PropTypes.func,
  setUploadFilter: PropTypes.func,
  setView: PropTypes.func,
  sortQueries: PropTypes.array,
  tagFilters: PropTypes.array,
  uploadFilter: PropTypes.object,
  view: PropTypes.string,
  children: PropTypes.node,
}

export default PictureSortAndFilter

import React from 'react'
import PropTypes from 'prop-types'

import CachedIcon from '@mui/icons-material/Cached'

const AnimatedProgressIcon = ({ sx, ...props }) => {
  return (
    <CachedIcon
      {...props}
      sx={{
        '@keyframes rotateProgressIcon': {
          from: {
            transform: 'rotate(360deg)',
          },
          to: {
            transform: 'rotate(0deg)',
          },
        },
        animation: 'rotateProgressIcon infinite 2s linear',
        ...sx,
      }}
    />
  )
}

AnimatedProgressIcon.propTypes = {
  sx: PropTypes.object,
}
export default AnimatedProgressIcon

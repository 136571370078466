import { styled } from '@mui/material/styles'
import MUITable from '@mui/material/Table'

export default styled(MUITable)`
  .td, .th {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }
`

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'
import { IconButton, InputAdornment, InputLabel } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import hexToRgba from 'hex-to-rgba'
import moment from 'moment'

import { isMomentValid, isPictureDateValid, US_DATE_FORMAT } from 'utils/dateUtils'

import ActionBar from './ActionBar'
import { CalendarToday } from './DatePicker.style'

export const MIXED_CONTENT_KEYWORD = 'Mixed'

export const selectMixedContentOnFocus = (e) => {
  if (e.target.value === MIXED_CONTENT_KEYWORD) {
    e.preventDefault()
    e.target.select()
  }
}

const buildInputProps = ({ value, handleOpen, variant, onChange }) => {
  const inputProps = {
    variant,
    endAdornment: (
      <InputAdornment position={'end'}>
        <IconButton size={'small'} onClick={handleOpen}>
          <CalendarToday sx={{ fontSize: '1.25rem' }} />
        </IconButton>
      </InputAdornment>
    ),
  }
  if (typeof value === 'string') {
    inputProps.value = value

    if (value === MIXED_CONTENT_KEYWORD) {
      inputProps.onKeyPress = (event) => {
        const code = event.keyCode || event.charCode
        if (code >= 48 && code <= 57) {
          onChange(null, event)
        }
      }
    }
  }

  return inputProps
}

/**
 * @param {string} color
 * @returns {string}
 */
const buildPaperShadowStyle = (color) => (
  `0px 11px 15px -7px ${hexToRgba(color, 0.2)},
   0px 24px 38px 3px ${hexToRgba(color, 0.14)},
   0px 9px 46px 8px ${hexToRgba(color, 0.12)}`
)

const defaultRules = {
  validate: (date) => date === MIXED_CONTENT_KEYWORD || !date || isPictureDateValid(date) || 'Invalid Date',
}

const DatePicker = ({
  control,
  onChange: handleChange,
  label,
  name,
  defaultValue,
  rules = defaultRules,
  disableFuture = true,
  format = US_DATE_FORMAT,
  variant = 'legacy',
  isBulkEdit,
  onFocus,
  readOnly,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }, [])

  const resolveReadOnly = useCallback((value) => {
    if (typeof readOnly === 'boolean') return readOnly
    if (isOpen) return false

    return isBulkEdit ? value === MIXED_CONTENT_KEYWORD : false
  }, [readOnly, isOpen, isBulkEdit])

  const handleFocus = useCallback((event) => {
    if (isBulkEdit) selectMixedContentOnFocus(event)
    onFocus?.(event)
  }, [onFocus, isBulkEdit])

  const handleKeyDown = useCallback((onChange) => (event) => {
    if (isBulkEdit && event.target.value === MIXED_CONTENT_KEYWORD) {
      const code = event.keyCode || event.charCode
      if (code === 46 || code === 8) {
        onChange(null, event)
      }
    }
  }, [isBulkEdit])

  return (
    <>
      {label && <InputLabel variant={variant}>{label}</InputLabel>}
      <Controller
        control={control}
        rules={rules}
        name={name}
        mode={'onChange'}
        variant={'outlined'}
        defaultValue={isMomentValid(defaultValue) || !defaultValue ? defaultValue : moment(defaultValue)}
        render={({ field, fieldState }) => {
          const { onChange, value, ref } = field
          const composedOnChange = (date, event) => {
            onChange(date, event)
            handleChange?.(date, event)
          }
          const isMomentDate = isMomentValid(value)
          return (
            <MuiDatePicker
              inputRef={ref}
              format={format}
              disableOpenPicker={false}
              disableFuture={disableFuture}
              onChange={composedOnChange}
              value={typeof value === 'string' || !isMomentDate || value === format ? null : value}
              open={isOpen}
              onClose={handleOpen}
              slots={{ actionBar: ActionBar }}
              readOnly={resolveReadOnly(value)}
              slotProps={{
                toolbar: { hidden: true },
                openPickerIcon: { onClick: handleOpen },
                field: { fullWidth: true },
                desktopPaper: { elevation: 3, sx: { marginTop: 1 } },
                textField: {
                  helperText: isMomentDate ? fieldState.error?.message : '',
                  onFocus: handleFocus,
                  onKeyDown: handleKeyDown(composedOnChange),
                  InputProps: buildInputProps({ variant, handleOpen, value, onChange: composedOnChange  }),
                  inputProps: {
                    sx: {
                      fontStyle: value === MIXED_CONTENT_KEYWORD && isBulkEdit ? 'italic' : 'normal',
                    },
                  },
                },
                dialog: {
                  slotProps: {
                    backdrop: {
                      sx: ({ colors }) => ({
                        background: hexToRgba(colors.newBlue[900], 0.8),
                      }),
                    },
                  },
                },
                mobilePaper: {
                  sx: ({ colors }) => ({
                    boxShadow: buildPaperShadowStyle(colors.newPaperShadow),
                  }),
                },
              }}
              {...rest}
            />
          )
        }}
      />
    </>
  )
}

DatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rules: PropTypes.object,
  format: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.node,
  name: PropTypes.string,
  disableFuture: PropTypes.bool,
  variant: PropTypes.oneOf(['legacy', 'new']),
  isBulkEdit: PropTypes.bool,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default DatePicker

import { styled } from '@mui/material/styles'
import { lighten } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  width: 100%;

  .address-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    flex-direction: column;

    /* desktop styles */
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
    }
  }

  .address {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    background-color: ${(props) => props.theme.colors.snowgray};
    justify-content: space-evenly;
    padding: 2rem 1rem;
    margin: 1rem 0;

    .icon-wrap {
      margin-right: 1rem;
    }

    .icon {
      height: 1.5rem;
      width: 1.5rem;
      background-color: ${(props) => props.theme.colors.medgray};
      border-radius: 100%;

      svg {
        padding: 0.125rem;
        fill: ${(props) => props.theme.colors.white};
      }
    }

    &.active {
      background-color: ${(props) => lighten('0.4', props.theme.colors.blue)};

      .icon {
        background-color: ${(props) => props.theme.colors.blue};
      }
    }

    /* desktop styles */
    @media (min-width: ${theme.breakpoints.md}) {
      width: calc(50% - 1rem);
    }
  }
`

import { css } from '@emotion/react'

export default ({ theme }) => css`
  /* Custom Utilities */
  /*
  TODO:
    - break this out to its own styled components
    - replace all components within the Text dir with utility classes
    - update storybook docs
  */

  .label {
    font-family: ${theme.typography.type.montserrat};
    font-weight: 500;
  }

  .link {
    font-family: ${theme.typography.type.montserrat};
    font-weight: 600;
    text-decoration: underline;

    &.secondary {
      text-decoration: none;
    }
  }

  .p {
    font-family: ${theme.typography.type.montserrat};
    font-weight: 500;
    font-size: ${theme.typography.size.md};

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.lg};
    }

    &.secondary {
      font-size: ${theme.typography.size.sm};
      font-weight: 400;

      @media (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.size.xs};
      }
    }
  }

  .h1 {
    font-size: ${theme.typography.size.xl4};
    font-weight: 600;
    font-family: ${theme.typography.type.montserrat};
    font-style: normal;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.xl3};
    }
  }

  .h2 {
    font-size: ${theme.typography.size.xl3};
    font-weight: 600;
    font-family: ${theme.typography.type.montserrat};
    font-style: normal;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.xl2};
    }
  }

  .h3 {
    font-size: ${theme.typography.size.xl2};
    font-weight: 600;
    font-family: ${theme.typography.type.montserrat};
    font-style: normal;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.xl};
    }
  }

  .h4 {
    font-size: ${theme.typography.size.xl};
    font-weight: 500;
    font-family: ${theme.typography.type.montserrat};
    font-style: normal;

    @media (max-width: ${theme.breakpoints.md}) {
      font-size: ${theme.typography.size.lg};
    }
  }

  /* Utility Classes */

  .center {
    text-align: center;
  }

  .regular {
    font-weight: 400 !important;
  }

  .medium {
    font-weight: 500 !important;
  }

  .demi {
    font-weight: 600 !important;
  }

  .bold {
    font-weight: 700;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .pointer {
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
  }

  .truncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`


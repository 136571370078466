import React, { useEffect, useRef } from 'react'

import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'

import Button from 'components/Buttons/Button'
import Input from 'components/FormFields/Input'
import LinkWrap from 'components/LinkWrap'
import AuthNav from 'components/Nav/AuthNav'
import H3 from 'components/Text/H3'
import Label from 'components/Text/Label'
import withLayout from 'hoc/withLayout'
import useModal from 'hooks/useModal'
import uploadService from 'services/uploadService'
import validations from 'utils/validations'

import SignInStyle from '../Auth.style'

const SignIn = () => {
  const { setModal } = useModal()

  const formRef = useRef()
  const { register, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const router = useRouter()
  const redirect = router.query.redirect

  useEffect(() => {
    // as input ref booked by hook form
    formRef.current.querySelector('[name=email]').focus()
  }, [])

  useEffect(() => {
    uploadService.clearCache()
  }, [])

  const handleForgotPassword = () => {
    setModal({
      name: 'ForgotPassword',
    })
  }

  return (
    <SignInStyle>
      <div className={'form-wrap bg-snowgray'}>
        <div className={'form bg-white'}>
          <H3 className={'darkblue center'}>Sign In</H3>
          <form
            action={'/auth/signin'}
            method={'POST'}
            ref={formRef}
          >
            {redirect && <input type={'hidden'} name={'redirect'} value={redirect} />}

            <Input
              label={'Email Address'}
              type={'email'}
              className={'input'}
              error={errors.email}
              {...register('email', validations.email)}
            />

            <div className={'input-wrap'}>
              <Input
                label={'Password'}
                type={'password'}
                className={'input'}
                error={errors.password}
                {...register('password', validations.password)}
                inputProps={{
                  autoComplete: 'current-password',
                }}
              />

              <Label className={'blue link caption pointer'} onClick={handleForgotPassword}>
                Forgot password?
              </Label>
            </div>
            <div className={'flex justify-center'}>
              <Button className={'submit'} type={'submit'}>Sign In</Button>
            </div>
          </form>
        </div>
      </div>
      <div className={'footer'}>
        <Typography variant={'h4'} align={'center'} color={(theme) => theme.colors.newBlue[900]} mb={2}>
          Don&apos;t have an account?
        </Typography>
        <LinkWrap className={'center blue link'} name={'SignUp'}>Create an account</LinkWrap>
      </div>
    </SignInStyle>
  )
}

export default withLayout(SignIn, AuthNav)

/**
 * SEGMENT TRACKING
 * - wraps segment analytics api
 * - handles tracking with internal conditionals handled by helper methods
 * - in cases can dispatch other custom api tracking events
 * - all subsequent tracking is emitted from segment, this is our sole tracking system
 */
import * as Sentry from '@sentry/react'
import getConfig from 'next/config'

import { browserType, ClientUAInstance, device } from './browser/parser'

const { NODE_ENV, NEXT_PUBLIC_AWS_ENV } = getConfig().publicRuntimeConfig

const TRACKING_META = {
  env: NEXT_PUBLIC_AWS_ENV || 'development',
  trackingCategory: 'account-app',
  browserType: browserType,
  deviceOs: ClientUAInstance.getOS().name,
  $os: ClientUAInstance.getOS().name,
  deviceType: device.type || 'desktop',
}

const handleError = (err, msg = 'Error tracking event failed') => {
  console.error(msg, err, JSON.stringify(err))

  Sentry.withScope(function(scope) {
    // group errors together based on their request and response
    scope.setFingerprint(['analytics', err.name])
    Sentry.captureException(err)
  })

  return err
}

/**
 *
 * @param {boolean} isLoggedAsUser flag whether current user impersonates
 * @param {function} func callback that should be executed if tracking is enabled
 * @param {function} callback callback that should be executed after tracking regardless environment
 */
const handleTracking = async (isLoggedAsUser, func, callback) => {
  // catch for non tracking with logging
  if (window && !isLoggedAsUser && NODE_ENV !== 'test') {
    if (!window.analytics || window.analytics.loadFailure) {
      if (NODE_ENV !== 'development') {
        handleError(new Error('Error: analytics is not present or initialized'))
      }
    } else {
      try {
        await func()
      } catch (err) {
        handleError(err)
      }
    }
  }

  await callback?.()
}

const pageName = () => document.title || 'account-app'

// initial tracking. should only be used once per react render
export const load = (isLoggedAsUser, { user, ...attrs }) => handleTracking(isLoggedAsUser, () => {
  window.analytics.page(pageName(), { ...attrs, ...TRACKING_META })

  if (typeof user === 'object' && Object.keys(user).length > 1 && !user.admin) {
    window.analytics.identify(user.email, {
      email: user.email,
      name: user.name,
      uuid: user.uuid,
      $screen_height: window.innerHeight,
      $screen_width: window.innerWidth,
    })
  }
})

export const page = (isLoggedAsUser, attrs = {}) => handleTracking(isLoggedAsUser, () => {
  window.analytics.page(
    pageName(),
    {
      ...attrs,
      ...TRACKING_META,
      $screen_height: window.innerHeight,
      $screen_width: window.innerWidth,
    },
  )
})

export const action = (isLoggedAsUser, event, options) => {
  console.info(`%c tracker event: ${event}`, 'background: #222; color: #bada55', options)
  if (!event) {
    console.warn('No event specified for track action')
    return
  }

  return handleTracking(
    isLoggedAsUser,
    () => window.analytics.track(event, {
      ...TRACKING_META,
      ...options,
      $screen_height: window.innerHeight,
      $screen_width: window.innerWidth,
    }),
  )
}

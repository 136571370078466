import React from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useStoreActions, useStoreState } from 'easy-peasy'
import isEqual from 'lodash/isEqual'

import { UPLOAD_FILTER_OPTIONS } from 'containers/Pictures/Pictures.constants'

import PictureUploadFilterStyle from './PictureUploadFilter.style'

/**
 * Upload Filter Select for picture list
 */
const PictureUploadFilter = () => {
  const uploadFilter = useStoreState((state) => state.pictures.filters.uploadBy)

  const setUploadFilter = useStoreActions((actions) => actions.pictures.setUploadByFilter)
  const setPicturesLoaded = useStoreActions((actions) => actions.pictures.setPicturesLoaded)

  const value = UPLOAD_FILTER_OPTIONS.find(
    (option) => isEqual(option.value, uploadFilter),
  ).value

  const handleChange = (e) => {
    setPicturesLoaded(false)
    setUploadFilter(e.target.value)
  }

  return (
    <PictureUploadFilterStyle>
      <Select
        name={'upload-filter'}
        value={value}
        onChange={handleChange}
        className={'picture-upload__control'}
      >
        {UPLOAD_FILTER_OPTIONS.map(({ value, label }) => (
          <MenuItem value={value} key={label}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </PictureUploadFilterStyle>
  )
}

export default PictureUploadFilter

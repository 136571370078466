import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Wrap = styled(Box)`
  box-shadow: 0 2px 12px 0 rgba(16, 35, 113, 0.15);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  pointer-events: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
  width: calc(100% - 40px);
  max-width: 380px;

  & > div {
    pointer-events: all;
    width: 100%;
  }
`

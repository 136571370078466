export const emailContent = (link) => encodeURIComponent(
  'Hi! \n\n' +
    'I love getting art books made by Artkive. They send you a box, you send it back with all of your kids\' artwork, ' +
    'they professionally photograph it all, and create beautiful hardcover books! Such a great way to declutter and save the memories (and you can get your art back too). \n\n' +
    `Just use my personalized link when purchasing a box to get a discount: ${link}`,
)

export const boxBookEmailContent = (link) => encodeURIComponent(
  'Hi! I love this Artkive Box service and had to share. You send in all of your kids\' artwork,' +
    ' they professionally photograph it all, and create beautiful hardcover books! Just use my ' +
    `personalized link when purchasing a box to get a discount. ${link}`,
)

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

export const EditPageTextFormStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isBulkEdit',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${({ isBulkEdit }) => isBulkEdit && 'margin-top: 12px;'}
  .MuiFormLabel-root {
    margin-bottom: 4px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ isBulkEdit }) => isBulkEdit && 'margin-top: 16px;'}

    .MuiInputBase-root {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
      padding: 2px 4px 2px 5px;
      height: 26px;
    }
  }
`

export const InputGroup = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMixed',
})`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  margin: auto;

  & .MuiOutlinedInput-root, & .MuiFormControlLabel-label {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiOutlinedInput-root {
    font-style: ${({ isMixed }) => (isMixed ? 'italic' : 'normal')};
  }

  & .MuiAutocomplete-endAdornment {
    top: 6px;
  }
`

export const EditPageTextFormChip = styled(Chip)`
  &.MuiAutocomplete-tag.MuiChip-root {
    background-color: ${({ theme }) => theme.colors.sky[300]};
    border-radius: 8px;
    height: 28px;
    padding: 4px 8px;
    margin: 1px 0 1px 2px;

    .MuiChip-label.MuiChip-labelSmall {
      font-size: 0.875rem;
      line-height: 1;
      font-weight: 500;
      padding-left: 0;
      padding-right: 0;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  & .MuiSvgIcon-root {
    margin-right: 0;
    margin-left: 4px;
  }
`

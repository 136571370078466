import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import DashedBorder from 'components/UploadDropzone/DashedBorder/DashedBorder'

export const StyledDashedBorder = styled(DashedBorder)`
  color: ${({ theme }) => theme.colors.sky[600]};
  transition: ${({ theme }) => theme.transitions.create(['color'], { duration: theme.transitions.duration.short })};
`

export const UploadZoneBox = styled(Box, { shouldForwardProp: (propName) => propName !== 'fullScreen' })`
  background-color: ${({ theme }) => theme.colors.sky[300]};
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: ${({ fullScreen }) => fullScreen ? '8px' : '6px'};
  padding: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  transition: ${({ theme }) => theme.transitions.create(['background-color'], { duration: theme.transitions.duration.short })};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.sky[400]};
    
    ${StyledDashedBorder} {
      color: ${({ theme }) => theme.colors.sky[700]};
    }
  }
`

export const Title = styled(Typography, { shouldForwardProp: (propName) => propName !== 'fullScreen' })`
  color: ${({ fullScreen, theme }) => fullScreen ? theme.colors.gray[900] : theme.colors.sky[700]};
  font-size: ${({ fullScreen }) => fullScreen ? '1.125rem' : '0.75rem'};
  font-weight: ${({ fullScreen }) => fullScreen ? 700 : 600};
  line-height: 1.4;
  white-space: initial;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${({ fullScreen }) => fullScreen ? 280 : 200}px;
  }
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 1rem;
  line-height: 1.6;
  max-width: 220px;
  white-space: initial;

  ${({ theme }) => theme.breakpoints.up('md')} {
    line-height: 1.4;
    font-size: 0.875rem;
    max-width: unset;
  }
`

export const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  height: 1px;
  width: 1px;
`

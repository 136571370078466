import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import { ImageWithSelect } from 'components/Image'
import PictureMeta from 'components/PictureMeta'
import UploadPictureWrap from 'components/UploadPictureWrap'
import useUserInformation from 'dux/hooks/useUserInformation'
import withCaptions from 'hoc/withCaptions'
import { UploadDropzoneContext } from 'hooks/useUploadingDropzone'

import Style, { Header, Overlay, Placeholder } from './Grid.style'

const ImageWithCaptions = withCaptions(ImageWithSelect)

const UploadingPictures = ({
  isLoggedAsUser,
  onUploadClick,
  showUploads,
  uploads,
}) => {
  if (uploads.length <= 0) return null

  return (
    <>
      <Header>
        <h3 className={'h3 md darkblue'}>
          Uploading...
        </h3>
      </Header>

      {uploads.map((pic, key) => (
        <UploadPictureWrap
          key={key}
          picture={pic}
          onClick={onUploadClick}
          showUploads={showUploads}
          hideTags={!isLoggedAsUser}
          view={'grid'}
        />
      ))}

      <Header>
        <h3 className={'h3 md darkblue'}>
          Account Pictures
        </h3>
      </Header>
    </>
  )
}

UploadingPictures.propTypes = {
  isLoggedAsUser: PropTypes.bool,
  onUploadClick: PropTypes.func,
  showUploads: PropTypes.bool,
  uploads: PropTypes.array,
}

const PictureGrid = ({
  isPictureSelected,
  onClick,
  onIconClick,
  onUploadClick,
  pictures,
  pictureLen,
  selectable,
  showUploads,
  uploads,
}) => {
  const { isDragReject, openDropzone } = useContext(UploadDropzoneContext)
  const { isLoggedAsUser } = useUserInformation()

  return (
    <Style>
      <UploadingPictures
        isLoggedAsUser={isLoggedAsUser}
        onUploadClick={onUploadClick}
        showUploads={showUploads}
        uploads={uploads}
      />

      {!!pictureLen && (
        <div onClick={openDropzone} role={'button'}>
          <div className={'label'}>&nbsp;</div>

          <Placeholder className={'placeholder'}>
            {isDragReject ? (
              <Overlay>
                <h2 className={'h3 md white center'}>Only JPG and PNG&apos;s allowed.</h2>
                <p className={'p sm white center'}>Please double check the selected files.</p>
              </Overlay>
            ) : (
              <div className={'content'}>
                <Icon name={'download'} />
                <div className={'label sm hide-mobile'}>Drag &amp; Drop or</div>
                <div className={'label sm hide-mobile'}>click to add images</div>
                <div className={'label xs hide-desktop text-center'}>Tap to add images</div>
              </div>
            )}
          </Placeholder>
        </div>
      )}

      {pictures.map((picture) => (
        <ImageWithCaptions
          isBackground={true}
          key={picture.id}
          labelBtm={
            <PictureMeta
              picture={picture}
              color={'darkblue'}
              hideTags={!isLoggedAsUser}
            />
          }
          onClick={() => onClick(picture)}
          onIconClick={onIconClick}
          selectable={selectable}
          selected={isPictureSelected(picture)}
          picture={picture}
        />
      ))}

    </Style>
  )
}

PictureGrid.propTypes = {
  isPictureSelected: PropTypes.func,
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onUploadClick: PropTypes.func,
  pictureLen: PropTypes.number,
  pictures: PropTypes.array.isRequired,
  selectable: PropTypes.bool,
  showUploads: PropTypes.bool,
  uploads: PropTypes.array,
}

export default PictureGrid

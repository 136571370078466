import React from 'react'

import { useForm } from 'react-hook-form'

import Button from 'components/Buttons/Button'
import Input from 'components/FormFields/Input'
import Modal from 'components/Modal'
import { APP_MODALS } from 'components/ModalManager'
import ButtonLabel from 'components/Text/ButtonLabel'
import H2 from 'components/Text/H2'
import Label from 'components/Text/Label'
import { useUpdateUserPasswordMutation } from 'dux/queries/user'
import { useSetErrorMessage } from 'dux/slice/ui'
import useModal from 'hooks/useModal'
import validations from 'utils/validations'

import EditPasswordStyle from './EditPassword.style'

const EditPassword = () => {
  const { unsetModal, setModal } = useModal()
  const setError = useSetErrorMessage()

  const [updateUserPassword] = useUpdateUserPasswordMutation()

  const { handleSubmit, getValues, formState: { errors }, register } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  })

  const handleSaveChanges = async (data) => {
    try {
      await updateUserPassword(data)

      unsetModal()
    } catch (error) {
      setError(error)
    }
  }

  const handleForgotPassword = () => {
    unsetModal()
    setModal({ name: APP_MODALS.ForgotPassword })
  }

  return (
    <EditPasswordStyle>
      <Modal handleClose={unsetModal}>
        <H2 className={'center darkblue'}>Update Password</H2>
        <form className={'content'} onSubmit={handleSubmit(handleSaveChanges)}>
          <Input
            label={'Current Password'}
            type={'password'}
            {...register('currentPassword', validations.password)}
            error={errors.currentPassword}
            inputProps={{
              autoComplete: 'current-password',
            }}
          />

          <Label onClick={handleForgotPassword} className={'forgot-password link blue pointer sm input'}>
            Forgot Password?
          </Label>

          <Input
            className={'input'}
            label={'New Password'}
            type={'password'}
            {...register('password', validations.password)}
            error={errors.password}
          />

          <Input
            className={'input'}
            label={'Confirm Password'}
            type={'password'}
            {...register('passwordConfirmation', validations.confirmPassword(getValues))}
            error={errors.passwordConfirmation}
          />

          <div className={'buttons'}>
            <Button type={'submit'}>
              <ButtonLabel className={'white'}>
                Save Changes
              </ButtonLabel>
            </Button>
          </div>
        </form>
      </Modal>
    </EditPasswordStyle>
  )
}

export default EditPassword

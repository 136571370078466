import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[900]};
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
`

export const Wrap = styled(Box, { shouldForwardProp: (propName) => propName !== 'fullScreen' })`
  background-color: ${({ theme }) => theme.colors.sky[400]};
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: calc(100% - 80px);
  height: ${({ fullScreen, theme }) => `calc(100% - ${fullScreen ? 40 : theme.modalBottomBar.height + 2}px)`};
  position: absolute;
  top: ${({ fullScreen }) => fullScreen ? 20 : 2}px;
  z-index: 6;
  ${({ fullScreen }) =>  !fullScreen && 'margin: 0 40px'};
  
  ${Subtitle} {
    ${({ fullScreen }) =>  fullScreen && 'margin-bottom: 29px'};
  }
`

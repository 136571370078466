import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  color: ${(props) => props.theme.colors.gray};

  .tags-wrap {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px;
    overflow: auto;
    width: 100%;

    & > div {
      margin: 0 0.75rem 0.75rem 0;

      @media (max-width: ${theme.breakpoints.md}) {
        margin: 0 0.5rem 0.5rem 0;
      }
    }

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 0.3rem 1.3rem 0;
    }
  }


  .tag-button {
    margin: 0 1rem 1rem 0;


    &:disabled {
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.lightgray};
      color: ${(props) => props.theme.colors.lightgray};
    }
  }
`

export const PictureTagsFilterStories = styled('div')`
  border: 1px solid black;
  margin: 2rem;
  padding: 2rem;
  width: 500px;
`

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import BookBuilderHeader from 'components/Header/BookBuilderHeader'

const desktopNavHeightPx = 80

export const StyledBookBuilderHeader = styled(BookBuilderHeader)`
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.sky[300]}`};
  min-height: ${({ theme }) => theme.appBar.height}px;
  
  ${({ theme }) => theme.breakpoints.up('md')} {
    border-bottom: none;
    min-height: auto;
    height: auto;
    padding: 16px 40px;
  
    &::after {
      content: '';
      display: block;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.sky[300]};
      width: calc(100% - ${desktopNavHeightPx}px);
      position: absolute;
      bottom: 0;
    }
  }
`

export const Title = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    color: ${({ theme }) => theme.colors.gray[900]};
    font-weight: 700;
    text-align: center;
  }
`

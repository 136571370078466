import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export const UploadPicturesWrapper = styled('div')`
  border: 1px solid ${theme.colors.lightgray};
  border-radius: 4px;
  padding: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 2rem;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 1rem;
  }

  .dropzone-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .icon-download {
      fill: ${theme.colors.blue};
      width: 60px;
      height: 60px;
    }

    & > * {
      margin-bottom: 2rem;
    }

    .extensions {
      color: ${theme.colors.medgray};
    }
  }
`

export default UploadPicturesWrapper

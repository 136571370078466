import React from 'react'
import PropTypes from 'prop-types'

const FormatAlignIcon = ({ align = 'left', fill = '#3D4156', className }) => {
  let pathD = ''
  switch (align) {
    case 'right':
      pathD = 'M7 18H21V16H7V18ZM3 6V8H21V6H3ZM12 13H21V11H12V13Z'
      break
    case 'center':
      pathD = 'M5 18H19V16H5V18ZM3 6V8H21V6H3ZM7 13H17V11H7V13Z'
      break
    case 'left':
    default:
      pathD = 'M3 18H17V16H3V18ZM3 6V8H21V6H3ZM3 13H12V11H3V13Z'
  }
  return (
    <svg
      className={className}
      width={'24'}
      height={'24'}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path fillRule={'evenodd'} clipRule={'evenodd'} d={pathD} fill={fill} />
    </svg>
  )
}

FormatAlignIcon.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  fill: PropTypes.string,
}
export default FormatAlignIcon

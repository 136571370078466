import { useEffect, useRef } from 'react'

import { useForm } from 'react-hook-form'

import countries from 'utils/data/countries'
import states from 'utils/data/states'

const useShippingAddressForm = (address) => {
  const didMountRef = useRef(false)
  const [firstName, lastName] = address?.shippingName ? address.shippingName.split(' ') : ['', '']

  const defaultCountry = countries.find((country) => country.value === address?.shippingCountry) || countries[0]
  const defaultState = address?.shippingStateProvince ?
    states[address.shippingCountry]?.find((state) => state.value === address.shippingStateProvince) : null

  const { control, formState: { errors }, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      shippingAddress1: address?.shippingAddress1 || '',
      shippingAddress2: address?.shippingAddress2 || '',
      shippingCity: address?.shippingCity,
      country: defaultCountry,
      state: defaultState,
      shippingPostalCode: address?.shippingPostalCode,
      setDefault: true,
    },
  })

  const watchCountry = watch('country')

  useEffect(() => {
    if (didMountRef.current) {
      const prevState = getValues('state')
      const countryStates = states[watchCountry.value]

      if (!countryStates.includes(prevState))
        setValue('state', null)
    } else {
      didMountRef.current = true
    }
  }, [watchCountry.value])

  return {
    control,
    setValue,
    errors,
    handleSubmit,
    watchCountry,
  }
}

export default useShippingAddressForm

import React from 'react'
import { array, func, string } from 'prop-types'

import cl from 'classnames'

import SegmentedButtonStyle, { InputStyle } from './SegmentedButton.style'

const SegmentedButton = ({ onChange, idPrefix, options, queryExists, className }) => {
  const handleChange = (option) => {
    onChange && onChange(option)
  }

  const selected = options.some((option) => queryExists(option))

  return (
    <SegmentedButtonStyle
      total={options.length}
      unselected={!selected}
      className={cl('segmented-button', className)}
    >
      {options.map((option, index) => {
        const id = idPrefix
          ? `${idPrefix}-${option.key}-${option.value}`
          : `${option.key}-${option.value}`
        const isChecked = queryExists(option)

        return (
          <React.Fragment key={id}>
            <InputStyle
              className={'segmented-button__input'}
              type={'radio'}
              value={option.value || null}
              id={id}
              checked={isChecked}
              onChange={() => handleChange(option)}
              index={index}
            />
            <label className={'segmented-button__label label sm'} htmlFor={id}>
              {option.label}
            </label>
          </React.Fragment>
        )
      })}
    </SegmentedButtonStyle>
  )
}

SegmentedButton.propTypes = {
  onChange: func,
  idPrefix: string,
  options: array.isRequired,
  queryExists: func.isRequired,
  className: string,
}

export default SegmentedButton

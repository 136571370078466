import React from 'react'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import { Box, MenuItem, OutlinedInput } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { useStoreActions } from 'easy-peasy'

import LoadingSpinner from 'components/LoadingSpinner'
import { APP_MODALS } from 'components/ModalManager'
import { ActionButton, Actions } from 'components/NewModal/NewModal.style'
import useModal from 'hooks/useModal'

import { Form } from './SendToCustomer.style'

const emailOptions = {
  basic: 'Basic Email',
  additional_art: 'Additional Art',
}

const SendToCustomer = ({ bookId, bookTitle, onCompleted }) => {
  const sendToCustomerEmail = useStoreActions((actions) => actions.user.books_v3.sendToCustomerEmail_v3)

  const { setModal } = useModal()

  const { handleSubmit, control, formState: { errors, isSubmitting, isValid } } = useForm({
    defaultValues: {
      type: '',
      message: '',
    },
    mode: 'all',
  })

  const handleSaveChanges = async (values) => {
    const response = await sendToCustomerEmail({ bookId, data: values })
    if (!response?.error) {
      setModal({
        name: APP_MODALS.ArtBookSent,
        bookTitle,
        onClose: onCompleted,
      })
    }
  }

  return (
    <Box mt={2}>
      {isSubmitting && <LoadingSpinner position={'contained'} />}
      <Form onSubmit={handleSubmit(handleSaveChanges)}>
        <Box display={'flex'} width={'100%'} flexDirection={'column'} mb={1.5}>
          <InputLabel id={'send_to_customer_type'}>Email Type</InputLabel>
          <Controller
            name={'type'}
            control={control}
            rules={{
              required: true,
              validate: (value) => !!value,
            }}
            render={({ field }) => (
              <Select
                labelId={'send_to_customer_type'}
                {...field}
                $variant={'new'}
                renderValue={(value) => value ? emailOptions[value] : 'Select Email Type'}
                displayEmpty
                MenuProps={{ PaperProps: { className: 'variant-new' } }}
              >
                {Object.entries(emailOptions).map(([key, value]) => (
                  <MenuItem key={key} value={key}>{value}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
        <Box display={'flex'} width={'100%'} flexDirection={'column'}>
          <InputLabel id={'send_to_customer_note'}>Note to Customer</InputLabel>
          <Controller
            name={'message'}
            control={control}
            render={({ field }) => (
              <OutlinedInput
                labelId={'send_to_customer_note'}
                multiline
                rows={4}
                variant={'new'}
                error={errors.note}
                fullWidth
                {...field}
              />
            )}
          />
        </Box>
        <Actions>
          <ActionButton variant={'new-contained-primary'} type={'submit'} disabled={!isValid || isSubmitting}>
            Send
          </ActionButton>
        </Actions>
      </Form>
    </Box>
  )
}

SendToCustomer.propTypes = {
  bookId: PropTypes.number.isRequired,
  onCompleted: PropTypes.func.isRequired,
  bookTitle: PropTypes.string,
}

export default SendToCustomer

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const initSentry = () => {
  const {
    NEXT_PUBLIC_SENTRY_DSN,
    NODE_ENV,
    NEXT_PUBLIC_RELEASE_VERSION,
    NEXT_PUBLIC_AWS_ENV,
  } = getConfig().publicRuntimeConfig

  if (NEXT_PUBLIC_SENTRY_DSN && NODE_ENV !== 'test') {
    Sentry.init({
      tracesSampleRate: 0.5,
      dsn: NEXT_PUBLIC_SENTRY_DSN,
      release: NEXT_PUBLIC_RELEASE_VERSION,
      environment: NEXT_PUBLIC_AWS_ENV || 'Unknown Environment',
    })
  }
}

export default initSentry

import React from 'react'
import { bool, func, string } from 'prop-types'

// Styled Components
import RadioStyle from './Radio.style'

const Radio = (props) => {
  const {
    checked,
    className,
    name,
    onChange,
  } = props

  return (
    <RadioStyle
      checked={checked}
      className={className}
    >
      <input
        name={name}
        checked={checked}
        onChange={onChange}
        type={'radio'}
      />
      <div
        className={'radio'}
      >
        <span className={`checkmark ${!!checked && 'checked'}`}></span>
      </div>
    </RadioStyle>
  )
}

Radio.propTypes = {
  checked: bool,
  className: string,
  name: string,
  onChange: func,
}

export default Radio


import React from 'react'
import PropTypes from 'prop-types'

import { Button, DialogActions } from '@mui/material'
import { withStyles } from '@mui/styles'

const ActionBar = ({ onAccept, onCancel, ownerState, ...rest }) => (
  ownerState.wrapperVariant === 'mobile' && (
    <DialogActions {...rest}>
      <Button variant={'new-outlined-primary'} onClick={onCancel}>
        Cancel
      </Button>
      <Button variant={'new-contained-primary'} color={'primary'} onClick={onAccept}>
        OK
      </Button>
    </DialogActions>
  )
)

ActionBar.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  ownerState: PropTypes.shape({
    wrapperVariant: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
  }).isRequired,
}

export default withStyles(({ spacing }) => ({
  root: {
    padding: spacing(0, 2.5, 2),

    '& .MuiButton-root': {
      width: '50%',

      '&:not(:first-of-type)': {
        marginLeft: spacing(1.5),
      },
    },
  },
}))(ActionBar)

import React from 'react'
import PropTypes from 'prop-types'

import Collapsable from 'components/Collapsable'
import useNewResponsive from 'hooks/useNewResponsive'

import { AdaptiveImagesGridWrapper, SingleImage, Subtitle } from './AdaptiveImagesGrid.style'
import ImagesGridContent from './ImagesGridContent'

const AdaptiveImagesGrid = ({ pictures, className, title }) => {
  const { isMobile  } = useNewResponsive()

  const hasMultipleSelectedPictures = pictures.length > 1

  const content = hasMultipleSelectedPictures ? (
    <ImagesGridContent pictures={pictures} />
  ) : (
    <SingleImage picture={pictures[0]} />
  )

  return (
    <AdaptiveImagesGridWrapper className={className} hasMultipleImages={hasMultipleSelectedPictures}>
      {isMobile && hasMultipleSelectedPictures ? (
        <Collapsable title={title}>{content}</Collapsable>
      ) : (
        <>
          {hasMultipleSelectedPictures && <Subtitle variant={'subtitle2'}>{title}</Subtitle>}
          {content}
        </>
      )}
    </AdaptiveImagesGridWrapper>
  )
}

AdaptiveImagesGrid.propTypes = {
  pictures: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default AdaptiveImagesGrid

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  width: 100%;
  height: auto;
  text-align: ${({ alignment }) => alignment};
`

export const Label = styled('div')`
  color: ${({ theme }) => theme.colors.medgray};
  font-family: ${theme.typography.type.montserrat};
  font-size: ${({ size, theme }) => size === 'large' ? theme.typography.size.xs : theme.typography.size.xxs};
  text-transform: capitalize;

  &:empty:before {
    content: "\\00a0";
  }
`

export const Caption = styled('div')`
  color: ${({ theme }) => theme.colors.darkgray};
  font-family: ${theme.typography.type.montserrat};
  font-weight: 600;
  font-size: ${({ size, theme }) => size === 'large' ? theme.typography.size.xs : theme.typography.size.xxs};
  text-transform: uppercase;
`

import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import MuiDialog from '@mui/material/Dialog'

import CloseIcon from 'components/svg/CloseIcon'

import { ActionButton, Actions, Backdrop, BodyText, CloseIconButton, Content, Title } from './NewModal.style'
import { NewModalContext } from './useNewModalContext'

const CONFIRMATION_MODAL_WIDTH = 608

const NewModal = ({
  children,
  open,
  onCancel,
  onClose,
  onSubmit,
  title,
  submitText,
  cancelButton,
  cancelText,
  closeIconButton,
  bgColor,
  fullScreen = false,
  persistent = false,
  width = CONFIRMATION_MODAL_WIDTH,
  ...rest
}) => {
  const [modalState, setModalState] = useState({
    disabledCancelButton: false,
    disabledSubmitButton: false,
  })

  const value = useMemo(() => [modalState, setModalState], [modalState])

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && persistent) return

    onClose(event)
  }
  const hasActions = Boolean(onCancel || onSubmit || submitText)

  return (
    <NewModalContext.Provider value={value}>
      <MuiDialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        fullScreen={fullScreen}
        scroll={'body'}
        PaperProps={{
          elevation: 0,
          sx: (theme) => ({
            boxShadow: '0 10px 25px 0 rgba(11, 39, 158, 0.4)',
            borderRadius: 0.5,
            [theme.breakpoints.down('xs')]: {
              margin: '16px',
              maxWidth: 'calc(100% - 32px)',
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
            },
            [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
              overflow: 'auto',
              maxHeight: 'calc(100vh - 64px)',
            },
          }),
        }}
        slots={{ backdrop: Backdrop }}
        disableEscapeKeyDown={persistent}
      >
        <Content width={width} bgColor={bgColor}>
          {closeIconButton !== undefined ? closeIconButton : (
            <CloseIconButton aria-label={'Close'} onClick={handleClose}>
              <CloseIcon />
            </CloseIconButton>
          )}
          {typeof title === 'string'
            ? <Title align={'center'} variant={'h4'}>{title}</Title>
            : typeof title === 'function'
              ? <Title align={'center'} variant={'h4'}>{title({ ...rest })}</Title>
              : title
          }
          {typeof children === 'string'
            ? <BodyText>{children}</BodyText>
            : typeof children === 'function'
              ? children({ ...rest, onClose: handleClose })
              : children
          }
          {hasActions && (
            <Actions>
              {cancelButton !== undefined ? cancelButton : (
                <ActionButton
                  variant={'new-outlined-primary'}
                  disabled={modalState.disabledCancelButton}
                  onClick={onCancel || handleClose}
                >
                  {cancelText || 'Cancel'}
                </ActionButton>
              )}
              {submitText && (
                <ActionButton
                  variant={'new-contained-primary'}
                  disabled={modalState.disabledSubmitButton}
                  onClick={onSubmit || handleClose}
                >
                  {submitText}
                </ActionButton>
              )}
            </Actions>
          )}
        </Content>
      </MuiDialog>
    </NewModalContext.Provider>
  )
}

NewModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  fullScreen: PropTypes.bool,
  persistent: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  submitText: PropTypes.string,
  cancelButton: PropTypes.node,
  cancelText: PropTypes.string,
  closeIconButton: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bgColor: PropTypes.string,
}

export default NewModal

export const PROMO_CODE_TYPES = {
  PERCENTAGE: '*',
  SHIPPING: 'free_book_shipping',
  AMOUNT: 'free_usb',
}

export const ORDER_ITEM_TYPES = {
  BOOK: 'book',
  ADDON: 'addon',
}

/**
 * names of checkout steps
 */
export const CHECKOUT_STEP_NAMES = {
  PRESELL: 'presell',
  ADDRESS: 'address',
  SHIPPING: 'shipping',
  CONFIRMATION: 'confirmation',
  REVIEW: 'review',
}

export const BASE_CHECKOUT_STEPS = [
  {
    isValid: true,
    title: 'offer',
    buttonLabel: 'Continue',
    name: CHECKOUT_STEP_NAMES.PRESELL,
    timeline: false,
  },
  {
    isValid: false,
    title: 'shipping',
    buttonLabel: 'Continue To Payment',
    name: CHECKOUT_STEP_NAMES.SHIPPING,
    timeline: true,
  },
  {
    isValid: false,
    title: 'confirmation',
    buttonLabel: 'Confirm & Place Order',
    name: CHECKOUT_STEP_NAMES.CONFIRMATION,
    timeline: true,
  },
  {
    isValid: false,
    title: 'review',
    // buttonLabel: 'Confirm & Place Order',
    name: CHECKOUT_STEP_NAMES.REVIEW,
    timeline: true,
  },
]

export const CHECKOUT_ADDRESS_STEP = {
  isValid: false,
  title: 'address',
  buttonLabel: 'Continue To Shipping',
  name: CHECKOUT_STEP_NAMES.ADDRESS,
  timeline: true,
}

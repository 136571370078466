import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .content {
    padding: 0 20%;
    margin: 2rem 0;

    @media (max-width: ${theme.breakpoints.md}) {
      min-width: 400px;
    }

    .input-wrap, .input {
      margin: 0.5rem 0;
    }

    .forgot-password {
      display: flex;
      justify-content: flex-end;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 20px;
    }
  }
`

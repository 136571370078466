import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import { baseApi } from './queries/baseApi'
import uiReducer from './slice/ui'

export const makeStore = () => {
  return configureStore({
    reducer: {
      ui: uiReducer,
      [baseApi.reducerPath]: baseApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      // TODO temporarily turning off serializableCheck option, because need to refactor modal management
      serializableCheck: false,
    }).concat(baseApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  })
}
/**
 * used for easy-peasy proxy model
 * @type {import('@reduxjs/toolkit').Store>}
 */
export let sharedRTKStore = null

export const wrapper = createWrapper(() => {
  sharedRTKStore ||= makeStore()

  return sharedRTKStore
}, { debug: false })

import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import Message from 'components/Message/Message'
import { useUIMessages } from 'dux/slice/ui'

const useSnackbarMessage = (router) => {
  const { message, clearMessage, setError, setSuccess } = useUIMessages()

  const routeError = router.query.error || ''
  const routeSuccess = router.query.success || ''

  useEffect(() => {
    if (routeError) {
      setError(routeError)
    }
  }, [routeError])

  useEffect(() => {
    if (routeSuccess) {
      setSuccess(routeSuccess)
    }
  }, [routeSuccess])


  const closeMessage = useCallback(() => {
    clearMessage()
  }, [clearMessage])

  return {
    message,
    closeMessage,
  }
}

const MessageProvider = ({ router, children }) => {
  const { message, closeMessage } = useSnackbarMessage(router)

  return (
    <>
      {message && <Message message={message} closeMessage={closeMessage} />}
      {children}
    </>
  )
}

MessageProvider.propTypes = {
  router: PropTypes.object,
  children: PropTypes.node,
}

export default MessageProvider

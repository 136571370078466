import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { ToggleButtonGroup } from '@mui/material'

import FormatAlignIcon from 'components/svg/FormatAlignIcon'

import { AlignTitleButton } from './AlignTitle.style'

export const ALIGN_TITLE = { LEFT: 'left', CENTER: 'center', RIGHT: 'right' }
export const ALIGN_TITLE_OPTIONS = Object.values(ALIGN_TITLE)

const AlignTitle = forwardRef(({ align, className = '', setAlignTitle }, ref) => {
  const handleAlignment = (_, newAlignment) => {
    if (!newAlignment) return
    setAlignTitle(newAlignment)
  }

  return (
    <ToggleButtonGroup ref={ref} value={align} className={className} exclusive onChange={handleAlignment}>
      {ALIGN_TITLE_OPTIONS.map((align) => (
        <AlignTitleButton key={align} value={align} aria-label={`${align}-align`}>
          <FormatAlignIcon align={align} />
        </AlignTitleButton>
      ))}
    </ToggleButtonGroup>
  )
})

AlignTitle.propTypes = {
  align: PropTypes.string.isRequired,
  className: PropTypes.string,
  setAlignTitle: PropTypes.func.isRequired,
}

export default AlignTitle

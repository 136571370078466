import React, { useMemo, useState } from 'react'
import { bool, object } from 'prop-types'

import { Controller } from 'react-hook-form'

import Autocomplete from 'components/FormFields/Autocomplete'
import Checkbox from 'components/FormFields/Checkbox'
import Input from 'components/FormFields/Input'
import Label from 'components/Text/Label'
import countries from 'utils/data/countries.json'
import states from 'utils/data/states'
import validations from 'utils/validations'

import ShippingAddressStyle from './ShippingAddress.style'

const ShippingAddress = ({
  control,
  shippingCountry,
  errors,
  showSetDefault = false,
}) => {
  const [defaultCountry] = useState(countries[0])

  const stateOptions = useMemo(() => {
    const countryCode = shippingCountry && states[shippingCountry.value] ? shippingCountry.value : defaultCountry.value
    return states[countryCode]
  }, [shippingCountry?.value])

  return (
    <ShippingAddressStyle>
      <div className={'w-1/2 input-wrap pr'}>
        <Controller
          name={'firstName'}
          rules={validations.firstName}
          control={control}
          render={({ field }) => (
            <Input
              label={'First Name'}
              type={'text'}
              {...field}
              error={errors.firstName && 'Please enter your first name'}
            />
          )}
        />
      </div>
      <div className={'w-1/2 input-wrap'}>
        <Controller
          name={'lastName'}
          rules={validations.lastName}
          control={control}
          render={({ field }) => (
            <Input
              label={'Last Name'}
              type={'text'}
              {...field}
              error={errors.lastName}
            />
          )}
        />
      </div>
      <div className={'w-full input-wrap'}>
        <Controller
          name={'shippingAddress1'}
          rules={validations.address}
          control={control}
          render={({ field }) => (
            <Input
              className={'address'}
              label={'Street'}
              type={'text'}
              {...field}
              error={errors.shippingAddress1}
            />
          )}
        />
      </div>
      <div className={'w-full input-wrap'}>
        <Controller
          name={'shippingAddress2'}
          control={control}
          render={({ field }) => (
            <Input
              className={'address'}
              label={'Apartment, Suite, etc. (optional)'}
              type={'text'}
              {...field}
            />
          )}
        />
      </div>

      <div className={'w-1/2 input-wrap pr'}>
        <Controller
          name={'shippingCity'}
          rules={validations.city}
          control={control}
          render={({ field }) => (
            <Input
              label={'City'}
              type={'text'}
              {...field}
              error={errors.shippingCity}
            />
          )}
        />
      </div>

      <div className={'w-1/2 input-wrap'}>
        <Controller
          name={'country'}
          rules={validations.country}
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={countries}
              label={'Select Country'}
              {...field}
              error={errors.country}
            />
          )}
        />
      </div>

      <div className={'w-1/2 input-wrap pr'}>
        <Controller
          name={'state'}
          rules={validations.state}
          control={control}
          render={({ field }) => (
            <Autocomplete
              options={stateOptions}
              label={'Select State'}
              disabled={!stateOptions.length}
              {...field}
              error={errors.state}
            />
          )}
        />
      </div>

      <div className={'w-1/2 input-wrap'}>
        <Controller
          name={'shippingPostalCode'}
          rules={validations.shippingPostalCode}
          control={control}
          render={({ field }) => (
            <Input
              label={'Zip Code'}
              type={'text'}
              {...field}
              error={errors.shippingPostalCode}
            />
          )}
        />
      </div>

      {showSetDefault && (
        <div className={'w-full input-wrap'}>
          <Controller
            control={control}
            name={'setDefault'}
            render={({ field: { value, onChange, ...field } }) => (
              <Label className={'flex align-center darkgray pointer'}>
                <Checkbox
                  color={'primary'}
                  {...field}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                />
                Set As Default
              </Label>
            )}
          />
        </div>
      )}

    </ShippingAddressStyle>
  )
}

ShippingAddress.propTypes = {
  control: object,
  shippingCountry: object,
  showSetDefault: bool,
  errors: object.isRequired,
}

export default ShippingAddress

import React, { useMemo } from 'react'
import { object, shape, string } from 'prop-types'

import Button from 'components/Buttons/Button'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'
import intl, { interpolateMessage } from 'utils/intl'

import BookLimitsViolatedStyle from './BookLimitsViolated.style'

const BookLimitsViolated = ({ message }) => {
  const { unsetModal } = useModal()

  const handleClose = () => unsetModal()

  const texts = useMemo(() => (
    intl(message.key)
  ), [message])

  return (
    <BookLimitsViolatedStyle>
      <Modal handleClose={handleClose} className={'book-maximum-modal'}>
        <div className={'content'}>
          <H2 className={'center darkblue modal__header'}>
            {texts.title}
          </H2>
          <P className={'center'}>
            {interpolateMessage(texts.content, message.opts)}
          </P>
          <div className={'buttons'}>
            <Button onClick={handleClose} variant={'primary'}>
              <P className={'white'}>Got it</P>
            </Button>
          </div>
        </div>
      </Modal>
    </BookLimitsViolatedStyle>
  )
}

BookLimitsViolated.propTypes = {
  message: shape({
    key: string.isRequired,
    opts: object,
  }).isRequired,
}

export default BookLimitsViolated

import React, { forwardRef } from 'react'
import { array } from 'prop-types'

import classnames from 'classnames'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Checkbox from 'components/FormFields/Checkbox'

import Chip from './PictureChildFilterChip'
import Style from './PictureChildFilters.style'
import PictureChildFiltersGroup from './PictureChildFiltersGroup'

/**
 * Child Filter Dropdown for picture list
 */
const PictureChildFilters = forwardRef(({ childrenOptions }, _ref) => {
  const childFilters = useStoreState((state) => state.pictures.filters.children)

  const setChildrenFilter = useStoreActions((actions) => actions.pictures.setChildrenFilter)
  const setPicturesLoaded = useStoreActions((actions) => actions.pictures.setPicturesLoaded)

  const toggleChildFilter = (childId, checked) => {
    setPicturesLoaded(false)

    if (!checked) {
      setChildrenFilter(Object.entries(childFilters).reduce(
        (result, [key, value]) => {
          return Number(key) === Number(childId)
            ? result
            : { ...result, [key]: value }
        }, {},
      ))
    } else {
      const child = childrenOptions.find(({ id }) => id === childId)

      setChildrenFilter({
        ...childFilters,
        [childId]: child ? child.labels.map(({ id }) => id).concat(['']) : true,
      })
    }
  }
  /**
   * select or deselect label for child
   * @param {number} child - toggled child
   * @param {boolean} labelId - toggled label
   */
  const toggleLabelFilter = (child, labelId) => {
    setPicturesLoaded(false)
    const childFilterActive = !!getChildFilter(child.id)

    if (childFilterActive) {
      const labelChecked = childFilters[child.id].includes(labelId)

      if (labelChecked) {
        const labelIds = childFilters[child.id].filter((id) => id !== labelId)
        if (labelIds.length === 0) {
          toggleChildFilter(child.id, false)
        } else {
          setChildrenFilter({
            ...childFilters,
            [child.id]: labelIds,
          })
        }
      } else {
        setChildrenFilter({
          ...childFilters,
          [child.id]: childFilters[child.id].concat(labelId),
        })
      }
    } else {
      setChildrenFilter({
        ...childFilters,
        [child.id]: [labelId],
      })
    }
  }
  /**
   * find child by its id
   * @param {number| ''} childId
   */
  const getChildFilter = (childId) => {
    const key = Object.keys(childFilters).find((key) => Number(key) === Number(childId))
    return key !== undefined && childFilters[key]
  }

  /**
   * check if child includes selected label
   * @param {number} childId
   * @param {number} labelId
   */
  const checkLabelFilter = (childId, labelId) => {
    const childFilter = getChildFilter(childId)

    return !!childFilter && childFilter.includes(labelId)
  }

  return (
    <Style>
      <div className={'children-wrap'}>
        {childrenOptions && childrenOptions.map((child) => {
          const childFilter = getChildFilter(child.id)

          return (
            <PictureChildFiltersGroup
              key={child.id}
              label={child.name}
              control={(
                <Checkbox
                  checked={!!childFilter}
                  color={'primary'}
                  name={child.name}
                  onChange={(e) => toggleChildFilter(child.id, e.target.checked)}
                />
              )}
            >
              {child.labels.map((label) => (
                <Chip
                  key={label.id}
                  className={classnames({ active: checkLabelFilter(child.id, label.id) })}
                  variant={'outlined'}
                  onClick={() => toggleLabelFilter(child, label.id)}
                  label={label.name}
                />
              ))}
            </PictureChildFiltersGroup>
          )
        })}
      </div>
    </Style>
  )
})

PictureChildFilters.propTypes = {
  /** all filter options */
  childrenOptions: array.isRequired,
}

export default PictureChildFilters

import { action } from 'easy-peasy'

import { ORDER_ITEM_TYPES } from './Checkout.constants'

const CheckoutActions = {
  /**
   * setOrder - create order items for new order
   * @param {object} payload - book
   */
  setOrder: action((state, payload) => {
    // if order id already exists bail early to prevent overriding data
    if (state.order.id === payload.id) {
      state.order.items = state.order.items.map((item) => {
        if (item.type === ORDER_ITEM_TYPES.BOOK) {
          return {
            ...item,
            productId: payload.productId,
            cover: payload.cover,
            hideDate: payload.hideDate,
            hideGrade: payload.hideGrade,
            hideName: payload.hideName,
            hideTitle: payload.hideTitle,
            prepaid: payload.isPrepaid,
            prepaidQuantity: payload.prepaidQuantity || 0,
          }
        } else {
          return item
        }
      })
    } else {
      // else reset the activeIndex
      state.activeIndex = 0
      // update the order id
      state.order = {
        ...state.order,
        id: payload.id,
        items: [
          {
            type: ORDER_ITEM_TYPES.BOOK,
            id: payload.id,
            price: payload.isConcierge
              ? 0
              : payload.price,
            qty: payload.isConcierge
              ? 0 : 1,
            productId: payload.productId,
            cover: payload.cover,
            hideDate: payload.hideDate,
            hideGrade: payload.hideGrade,
            hideName: payload.hideName,
            hideTitle: payload.hideTitle,
            prepaid: payload.isPrepaid,
            prepaidQuantity: payload.prepaidQuantity || 0,
          },
          {
            type: ORDER_ITEM_TYPES.ADDON,
            id: 'BOOK_COPY',
            qty: 0,
            price: payload.price,
            picture: {
              image: '/images/book-copies.png',
            },
            title: 'Book Copies',
          },
        ],
        shippingAddress: null,
        shippingMethod: null,
      }
    }
  }),
  /**
   * setQty - sets qty of provided cart item
   * @param {string} payload.id - id of the item
   * @param {number} payload.qty - number of items
   */
  setQty: action((state, payload) => {
    state.order.items =
      state.order.items.map((product) => {
        if (product.id === payload.id) {
          product.qty = payload.qty
        }
        return product
      })
  }),

  /**
   * setItemPrice - sets price of provided cart item
   * @param {string} payload.id - id of the item
   * @param {number} price - price of item
   */

  setItemPrice: action((state, payload) => {
    state.order.items = state
      .order
      .items
      .map((product) => (product.id === payload.id) ? { ...product, ...payload } : product)
  }),
  /**
   * setAddress - sets shipping address
   * @param {object} payload - shipping address
   */
  setAddress: action((state, payload) => {
    state.order.shippingAddress = { ...payload }
  }),
  /**
   * setActiveIndex - sets active index
   * @param {number} payload - active index
   */
  setActiveIndex: action((state, payload) => {
    state.activeIndex = payload
  }),
  /**
   * setShippingMethod - sets shipping method
   * @param {object} payload - shipping method
   */
  setShippingMethod: action((state, payload) => {
    state.order.shippingMethod = payload
  }),
  /**
   * updatePaymentMeta - update payment metaData
   * @param {object} payload - payment metaData
   */
  setPaymentMeta: action((state, payload) => {
    state.order.paymentMeta = payload
  }),
  /**
   * setStep - sets step as valid
   * @param {number} payload - step index
   */
  setStep: action((state, payload) => {
    state.steps.map((step, i) => {
      if (i === payload) {
        step.isValid = true
      }
      return step
    })
  }),
  setSteps: action((state, payload) => {
    state.steps = payload
  }),
  /**
   * setPricing - set pricing fields for order
   * @param {number} payload.subtotal - subtotal price
   * @param {number} payload.total - total price
   * @param {number} payload.credits - amount of credits
   */
  setPricing: action((state, { item, ...payload }) => {
    state.order.pricing[item] = payload
    // state.isLoading = false
  }),
  activateStep: action((state, payload) => {
    state.order.steps.map((step, i) => i === payload ? step.active = true : step.active = false)
  }),
  /**
   * setPromoCode - set order promo code
   * @param {object} payload - promo code data
   */
  setPromoCode: action((state, payload) => {
    state.order.promoCode = payload
    state.promoLoading = false
  }),
  setOrderResult: action((state, payload) => {
    state.order.result = payload
  }),
  /**
   * setPromoLoading - handle global lading state
   * @param {boolean} - payload true/false
   */
  setPromoLoading: action((state, payload) => {
    if (state.promoLoading === payload) return
    state.promoLoading = payload
  }),
  /**
   * setIsLoading - handle global lading state
   * @param {boolean} - payload true/false
   */
  setIsLoading: action((state, payload) => {
    if (state.isLoading === payload) return
    state.isLoading = payload
  }),
  /**
   * setError - handle global lading state
   * @param {object} - error object
   */
  setError: action((state, payload) => {
    state.error = payload
  }),
}

export default CheckoutActions

import React from 'react'
import PropTypes from 'prop-types'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Button from '@mui/material/Button'

import CloseIcon from 'components/svg/CloseIcon'

import {
  ActionButton,
  Center,
  CloseButton,
  CloseButtonWrap,
  InnerWrap,
  LeftSide,
  NavWrap,
  RightSide,
} from './BookBuilderHeader.style'

const BookBuilderHeader = ({
  actionButtonText,
  actionButtonVariant = 'new-text-primary',
  isActionButtonDisabled,
  backButtonContent,
  backButtonAriaLabel,
  className,
  closeButtonAriaLabel,
  children,
  onActionClick,
  onBack,
  onClose,
  showBackButton = true,
  showCloseButton = true,
  rightSideAction,
  ...rest
}) => {
  return (
    <NavWrap className={className} {...rest}>
      <InnerWrap>
        <LeftSide>
          {showBackButton && (
            <Button
              aria-label={backButtonAriaLabel}
              size={'small'}
              variant={'nav-link'}
              disableRipple={true}
              startIcon={<ArrowBackIosIcon />}
              onClick={onBack}
            >
              {backButtonContent}
            </Button>
          )}
        </LeftSide>
        <Center>
          {children}
        </Center>
        <RightSide>
          {rightSideAction}
          {onActionClick && actionButtonText && (
            <ActionButton
              disabled={isActionButtonDisabled}
              disableRipple={true}
              variant={actionButtonVariant}
              onClick={onActionClick}
              sx={{
                fontSize: actionButtonVariant === 'new-contained-primary' ? '.875rem' : '1rem',
                p: actionButtonVariant === 'new-text-primary' ? 0 : undefined,
              }}
            >
              {actionButtonText}
            </ActionButton>
          )}
          {showCloseButton && !onActionClick && onClose && (
            <CloseButtonWrap>
              <CloseButton
                aria-label={closeButtonAriaLabel}
                onClick={onClose}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
              >
                <CloseIcon />
              </CloseButton>
            </CloseButtonWrap>
          )}
        </RightSide>
      </InnerWrap>
    </NavWrap>
  )
}

BookBuilderHeader.propTypes = {
  actionButtonText: PropTypes.string,
  actionButtonVariant: PropTypes.string,
  isActionButtonDisabled: PropTypes.bool,
  backButtonAriaLabel: PropTypes.string,
  backButtonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  closeButtonAriaLabel: PropTypes.string,
  children: PropTypes.node,
  onActionClick: PropTypes.func,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  showBackButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  rightSideAction: PropTypes.node,
}

export default BookBuilderHeader

import { styled } from '@mui/material/styles'

export default styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .label:empty {
    min-height: 1rem;
  }

  .active-page & {
    * {
      border-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blue} !important;
    }
  }
`

export const CaptionWrap = styled('div')`
  margin: 0.5rem auto;
  width: 100%;
`
export const LabelTop = styled('div')`
  margin-bottom: 0.25rem;
  width: 100%;
  min-height: 1rem;
`

export const LabelBtm = styled('div')`
  margin-top: 0.5rem;
  width: 100%;
`

import React, { useEffect, useMemo } from 'react'
import { func, object } from 'prop-types'

import cl from 'classnames'
import { useRouter } from 'next/router'

import Icon from 'components/Icon'
import Label from 'components/Text/Label'

import MessageStyle from './Message.style'

import { convertToQueryString, getUrlParams } from '@/lib/url'
import findRoute from '@/routes/findRoute'

const CLOSE_TIMEOUT_MS = 5000
let closeTimeout = null

/**
 * General Message Component
 */
const Message = ({ message, closeMessage }) => {
  const router = useRouter()

  const [closing, setClosing] = React.useState(false)
  /**
   * handle message banner closing
   */
  const handleClosing = () => {
    setClosing(true)

    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }
  }
  /**
   * close message on hide animation end
   * @param {object} event - event object
   */
  const handleAnimationEnd = (event) => {
    if (event.animationName === 'hide') {
      closeMessage()
      // get params
      const urlParams = document.location.search || null
      // if non bail early
      if (!urlParams) return
      // convert to object
      const options = getUrlParams(document.location.search)
      // remove error and success params
      delete options.error
      delete options.success
      // convert to query string
      const query = convertToQueryString(options)
      // current route
      const route = findRoute('pathname', router.route)
      router.replace(`${route.pathname}${query}`, `${window.location.pathname}${query}`, { shallow: true })
    }
  }

  /**
   * ComponentDidUpdate(message)
   * set new timeout for message closing if new message appears
   */
  useEffect(() => {
    if (message) {
      closeTimeout = setTimeout(handleClosing, CLOSE_TIMEOUT_MS)
    }
  }, [message])


  const messageText = useMemo(() => {
    if (!message.text)
      return ''

    if (typeof message.text === 'string')
      return message.text

    return message.text.message
  }, [message.text])

  return (
    <MessageStyle
      type={message.type}
      className={cl({
        closing: closing,
      })}
      onAnimationEnd={handleAnimationEnd}
    >
      <Label>{messageText}</Label>
      <Icon
        className={'close-button'}
        name={'close'}
        onClick={handleClosing}
      />
    </MessageStyle>
  )
}

Message.propTypes = {
  /** message message */
  message: object,
  /** close message handler */
  closeMessage: func,
}

export default Message

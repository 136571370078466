import { styled } from '@mui/material/styles'

export default styled('div')`
  display: flex;
  width: 100%;
  position: relative;
  user-select: none;
  z-index: 1;
  margin: 0 auto;
  font-size: ${({ theme }) => theme.typography.size.xs};
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  border-radius: 24px;
  color: ${({ theme }) => theme.colors.lightgray};

  .segmented-button__label {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 0.7rem;
    border-radius: 24px;
    transition: color 250ms cubic-bezier(0, .95, .38, .98);

    &:before {
      background-color: ${({ theme }) => theme.colors.blue}14;
      border: 1px solid ${({ theme }) => theme.colors.blue};
      transition: all 250ms cubic-bezier(0, .95, .38, .98);
    }

    &:last-of-type:before {
      border-radius: 24px;
      content: '';
      display: block;
      max-width: calc(calc(100% / ${(props) => props.total}) - 4px);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transform: translateX(0);
    }
  }

  ${(props) => props.unselected && `
    .segmented-button__label:before {
      background-color: transparent;
      border: none;
    }
  `}
`

export const InputStyle = styled('input')`
  display: none;

  &:checked + .segmented-button__label {
    color: ${({ theme }) => theme.colors.blue};
  }

  &:nth-of-type(${(props) => props.index + 1}):checked {
    ~ label:last-of-type:before {
      transform: translateX(calc(calc(100 * ${(props) => props.index}%) + calc(4px * ${(props) => props.index})));
    }
  }
`

import { styled } from '@mui/material/styles'

export default styled('nav')`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  height: ${({ theme }) => theme.nav.mobileHeight};
  
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: ${({ theme }) => theme.nav.height};
  }
`

import { useMemo } from 'react'

import { baseApi } from 'dux/queries/baseApi'

import { TAG_TYPE } from './constants'

export const childrenApiUrls = {
  getChildren: () => '/api/v2/user/children',
  getChildrenWithLabels: () => '/api/v2/user/pictures/available_labels',
  createChild: () => '/api/v2/user/children',
  updateChild: (id) => `/api/v2/user/children/${id}`,
  deleteChild: (id) => `/api/v2/user/children/${id}`,
}

export const nameTextLabelsApi =
  baseApi
    .enhanceEndpoints({ addTagTypes: [TAG_TYPE.NAME, TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE] })
    .injectEndpoints({
      overrideExisting: true,
      endpoints: (builder) => ({
        getChildren: builder.query({
          query: () => ({
            url: childrenApiUrls.getChildren(),
            method: 'GET',
          }),
          transformResponse: (response) => response.data,
          providesTags: (result) => result
            ? [...result.map(({ id }) => ({ type: TAG_TYPE.NAME, id })), TAG_TYPE.NAME]
            : [TAG_TYPE.NAME],
        }),
        getChildrenWithAgeGrade: builder.query({
          query: () => ({
            url: childrenApiUrls.getChildrenWithLabels(),
            method: 'GET',
          }),
          transformResponse: (response) => response.data,
          providesTags: (result) => result
            ? [
              ...result.map(({ id }) => ({ type: TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE, id })),
              TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE,
            ]
            : [TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE],
        }),
        createChild: builder.mutation({
          query: (data) => ({
            url: childrenApiUrls.createChild(),
            method: 'POST',
            data,
          }),
          transformResponse: (response) => response.data,
          invalidatesTags: [TAG_TYPE.NAME, TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE],
        }),
        updateChild: builder.mutation({
          query: ({ id, ...data }) => ({
            url: childrenApiUrls.updateChild(id),
            method: 'PUT',
            data,
          }),
          invalidatesTags: (result, error, { id }) => [{ type: TAG_TYPE.NAME, id }],
        }),
        deleteChild: builder.mutation({
          query: ({ id }) => ({
            url: childrenApiUrls.deleteChild(id),
            method: 'DELETE',
          }),
          invalidatesTags: (result, error, { id }) => [
            { type: TAG_TYPE.NAME, id },
            TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE,
          ],
        }),
      }),
    })

export const useChildrenOptions = () => {
  const { data: children = [] } = useGetChildrenQuery()

  const childrenOptions = useMemo(() => {
    return children.map((label) => ({ label: label.name, value: label.id }))
  }, [children])

  return [childrenOptions]
}

export const triggerChildNameWithAgeGradeInvalidate = () => {
  return nameTextLabelsApi.util.invalidateTags([TAG_TYPE.CHILD_NAME_WITH_AGE_GRADE])
}

export const {
  useGetChildrenQuery,
  useGetChildrenWithAgeGradeQuery,
  useCreateChildMutation,
  useUpdateChildMutation,
  useDeleteChildMutation,
} = nameTextLabelsApi

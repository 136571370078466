import React from 'react'

import ArtBookSent from 'components/NewModal/contents/ArtBookSent/ArtBookSent'
import ConfirmDeleteTextLabelsContent from 'components/NewModal/contents/ConfirmDeleteTextLabelsContent'
import ConfirmPreviewContent from 'components/NewModal/contents/ConfirmPreviewContent'
import DeletePagesConfirmationContent from 'components/NewModal/contents/DeletePagesConfirmationContent'
import DeletePagesMinLimitReachedContent from 'components/NewModal/contents/DeletePagesMinLimitReachedContent'
import MaxImagesLimitReachedContent from 'components/NewModal/contents/MaxImagesLimitReachedContent'
import NewReplaceImagesByAdminContent from 'components/NewModal/contents/NewReplaceImagesByAdminContent'
import OpenInDesktopContent from 'components/NewModal/contents/OpenInDesktopContent/OpenInDesktopContent'
import SendToCustomer from 'components/NewModal/contents/SendToCustomer/SendToCustomer'
import UploadFileTypeErrorContent from 'components/NewModal/contents/UploadFileTypeErrorContent'
import { MIN_BOOK_CAPACITY } from 'containers/Book/Book.constants'
import { pluralize } from 'utils/intl'

export const APP_MODALS = {
  BookSize: 'BookSize',
  AddBookPictures: 'AddBookPictures',
  EditEmail: 'EditEmail',
  EditPassword: 'EditPassword',
  EditAddress: 'EditAddress',
  EditPicture: 'EditPicture',
  CropAdjustPicture: 'CropAdjustPicture',
  CheckoutExit: 'CheckoutExit',
  BookLimitsViolated: 'BookLimitsViolated',
  MaxImagesLimitReached: 'MaxImagesLimitReached',
  MinImagesLimitReached: 'MinImagesLimitReached',
  PictureMetaModal: 'PictureMetaModal',
  ForgotPassword: 'ForgotPassword',
  Referral: 'Referral',
  UpgradeMembership: 'UpgradeMembership',
  ConfirmEdit: 'ConfirmEdit',
  ConfirmPreview: 'ConfirmPreview',
  ConfirmAddress: 'ConfirmAddress',
  ConfirmDelete: 'ConfirmDelete',
  ConfirmationModal: 'ConfirmationModal',
  UpdatePaymentMethod: 'UpdatePaymentMethod',
  SendToCustomer: 'SendToCustomer',
  ReplacePicturesModal: 'ReplacePicturesModal',
  CloseWizard: 'CloseWizard',
  DiscardWizard: 'DiscardWizard',
  BulkEditImagesText: 'BulkEditImagesText',
  NewReplaceImagesByAdmin: 'NewReplaceImagesByAdmin',
  DeletePages: 'DeletePages',
  UploadFileTypeError: 'UploadFileTypeError',
  DeletePagesConfirmation: 'DeletePagesConfirmation',
  DeletePagesMinLimitReached: 'DeletePagesMinLimitReached',
  ArtBookSent: 'ArtBookSent',
  OpenInDesktop: 'OpenInDesktop',
  ConfirmDeleteTextLabels: 'ConfirmDeleteTextLabels',
}

export const ModalVariants = {
  [APP_MODALS.DiscardWizard]: {
    children: 'If you leave the Wizard now, your book will not be saved.',
    title: 'Delete Book Draft',
    cancelText: 'Yes, Delete',
    submitText: 'No, Continue',
  },
  [APP_MODALS.CloseWizard]: {
    children: 'If you leave the Wizard now, none of the changes will be saved.',
    fullScreen: false,
    title: 'Leave Wizard',
    cancelText: 'Yes, Leave',
    submitText: 'No, Continue',
  },
  [APP_MODALS.ConfirmPreview]: {
    children: <ConfirmPreviewContent />,
    title: 'Hooray!',
    cancelText: 'Cancel',
    submitText: 'Continue',
  },
  [APP_MODALS.NewReplaceImagesByAdmin]: {
    children: (props) => <NewReplaceImagesByAdminContent {...props} />,
    title: 'Bulk Replace',
    cancelButton: null,
  },
  [APP_MODALS.MaxImagesLimitReached]: {
    children: (props) => <MaxImagesLimitReachedContent {...props} />,
    title: ({ prepaidPages }) => `Max ${Number.isInteger(prepaidPages) ? 'Image' : 'Page'} Count Reached`,
    submitText: 'Ok, Got It!',
    cancelButton: null,
  },
  [APP_MODALS.UploadFileTypeError]: {
    children: (props) => <UploadFileTypeErrorContent {...props} />,
    title: 'Upload Error',
    submitText: 'Ok, Got It!',
    cancelButton: null,
  },
  [APP_MODALS.DeletePagesConfirmation]: {
    title: ({ pagesToDelete }) => `Remove ${pluralize(pagesToDelete, 'Page')}`,
    children: (props) => <DeletePagesConfirmationContent {...props} />,
    cancelText: 'Yes, Remove',
    submitText: 'No, Keep',
  },
  [APP_MODALS.DeletePagesMinLimitReached]: {
    title: 'Remove Error',
    children: (props) => <DeletePagesMinLimitReachedContent {...props} />,
    submitText: 'Ok, got it!',
    cancelButton: null,
  },
  [APP_MODALS.SendToCustomer]: {
    title: 'Send to Customer',
    children: (props) => <SendToCustomer {...props} />,
    submitText: null,
    cancelButton: null,
  },
  [APP_MODALS.ArtBookSent]: {
    title: 'Art Book was sent',
    children: (props) => <ArtBookSent {...props} />,
    submitText: null,
    cancelButton: null,
  },
  [APP_MODALS.MinImagesLimitReached]: {
    children: `You must select at least ${MIN_BOOK_CAPACITY} images to proceed.`,
    title: 'Select More Images',
    submitText: 'Ok, Got It!',
    cancelButton: null,
  },
  [APP_MODALS.OpenInDesktop]: {
    children: (props) => <OpenInDesktopContent {...props} />,
    submitText: 'Ok, got it!',
    cancelButton: null,
  },
  [APP_MODALS.ConfirmDeleteTextLabels]: {
    title: ({ type }) => `Delete ${type}`,
    children: (props) => <ConfirmDeleteTextLabelsContent {...props} />,
    cancelText: 'Yes, Delete',
    submitText: 'No, Keep',
  },
}

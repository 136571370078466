import React, { useState } from 'react'
import PropTypes from 'prop-types'

import EditInputForm from 'components/FormFields/InlineEdit/EditInputForm'
import Icon from 'components/Icon'

import InlineEditStyle from './InlineEdit.style'

/**
 * FormFields - Inline Editable Input
 */
const InlineEdit = ({
  alignment = 'start',
  placement = 'left',
  onChange,
  defaultValue,
  editDefault,
  dense = false,
  placeholder = null,
}) => {
  const [editable, setEditable] = useState(editDefault)

  /**
   * onSubmit - handle form submission
   * @param {string} param.value - current input value
   */
  const onSubmit = ({ value }) => {
    setEditable(false)

    if (defaultValue !== value) {
      onChange(value)
    }
    setEditable(false)
  }

  return (
    <InlineEditStyle alignment={alignment} placement={placement} $dense={dense}>
      {editable ? (
        <EditInputForm
          onSubmit={onSubmit}
          defaultValue={defaultValue}
        />
      ) : (
        <div className={'__editWrap'} onClick={() => setEditable(true)}>
          {!dense && <div className={'__editIcon'} />}

          <div className={'__p'}>
            {defaultValue || (
              <span className={'medgray'}>{placeholder}</span>
            )}
          </div>

          <Icon className={'__editIcon'} name={'edit'} />
        </div>
      )}
    </InlineEditStyle>
  )
}

InlineEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  alignment: PropTypes.string,
  defaultValue: PropTypes.string,
  editDefault: PropTypes.bool,
  dense: PropTypes.bool,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InlineEdit

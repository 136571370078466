import React, { useEffect, useRef } from 'react'
import { func, string } from 'prop-types'

import { Controller, useForm } from 'react-hook-form'

import Input from 'components/FormFields/Input'
import regex from 'utils/regex'
import { CAPTION_MAX_LENGTH } from 'utils/validations'

const EditInputForm = ({ onSubmit, defaultValue }) => {
  const innerRef = useRef()

  const validations = { maxLength: 50, pattern: regex.title }

  const { handleSubmit, formState: { errors }, control } = useForm({
    defaultValues: {
      value: defaultValue,
    },
  })

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'__form'}>
      <Controller
        control={control}
        name={'value'}
        rules={validations}
        defaultValue={defaultValue}
        render={(
          { field: { ref, ...restProps } },
        ) => (
          <Input
            ref={ref}
            innerRef={innerRef}
            {...restProps}
            className={'inline-input'}
            onBlur={handleSubmit(onSubmit)}
            placeholder={'Maximum 50 characters'}
            maxLength={CAPTION_MAX_LENGTH}
            error={errors.value}
          />
        )}
      />
    </form>
  )
}

EditInputForm.propTypes = {
  onSubmit: func,
  defaultValue: string,
}

export default EditInputForm

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import moment from 'moment'

import Autocomplete from 'components/FormFields/Autocomplete'
import Checkbox from 'components/FormFields/Checkbox/Checkbox.style'
import DatePicker from 'components/FormFields/DatePicker'
import Input from 'components/FormFields/Input'
import HiddenImagesSwitch from 'components/Forms/PictureMeta/HiddenImagesSwitch'
import Label from 'components/Text/Label'
import useUserInformation from 'dux/hooks/useUserInformation'
import { useAgeGradeOptions, useCreateAgeGradeMutation } from 'dux/queries/ageGradeTextLabels'
import { useChildrenOptions, useCreateChildMutation } from 'dux/queries/nameTextLabels'
import { useCreateTagMutation, useTagOptions } from 'dux/queries/tagsTextLabels'
import { isPictureDateValid } from 'utils/dateUtils'
import debounce from 'utils/debounce'
import regex from 'utils/regex'
import { CAPTION_MAX_LENGTH, CHILD_NAME_MAX_LENGTH, LABEL_MAX_LENGTH } from 'utils/validations'

import PictureMetaStyle from './PictureMeta.style'

const PictureMeta = ({
  picture,
  onValuesChange,
  showCaption,
  withReplaceAll,
  indeterminateHidden,
}) => {
  const { child, label, pictureDate, title, tags } = picture

  const { isLoggedAsUser } = useUserInformation()

  const defaultValues = useMemo(() => ({
    pictureDate: new Date(),
    child: null,
    label: null,
    tags: [],
    title: '',
    replaceTags: false,
    hidden: isLoggedAsUser,
    shouldChangeHidden: !indeterminateHidden,
    ...picture,
  }), [picture])

  const { control, formState: { errors }, setValue, watch, register, trigger } = useForm({
    defaultValues,
    mode: 'all',
  })

  register('shouldChangeHidden')

  const watchReplaceTags = watch('replaceTags', false)
  const watchHidden = watch(['hidden', 'shouldChangeHidden'])

  const [triggerCreateChild] = useCreateChildMutation()
  const [triggerCreateAgeGrade] = useCreateAgeGradeMutation()
  const [triggerCreateTag] = useCreateTagMutation()

  const [childrenOptions] = useChildrenOptions()
  const [ageGradeOptions] = useAgeGradeOptions()
  const [tagOptions] = useTagOptions()

  useEffect(() => {
    const [value, shouldChange] = watchHidden
    onValuesChange('hidden', { value, shouldChange })
  }, watchHidden)

  useEffect(() => {
    onValuesChange('replaceTags', watchReplaceTags)
  }, [watchReplaceTags])

  useEffect(() => {
    setValue('child', child && ({ label: child.name, value: child.id }))
    setValue('pictureDate', pictureDate ? moment(pictureDate) : null)
    setValue('label', label && ({ label: label.name, value: label.id }))
    setValue('tags', tags && tags.map((tag) => ({ label: tag.name, value: tag.id })))
    setValue('title', title)
  }, [picture?.updatedAt])

  useEffect(() => {
    // To properly run form validation on mount
    trigger()
  }, [trigger, picture?.updatedAt])

  const handleChildChange = debounce(async (childOption) => {
    // if child object missing value bail early
    if (childOption?.__isNew__) {
      const newChild = await triggerCreateChild({ child: { name: childOption.value } }).unwrap()
      await onValuesChange('child', newChild)
    } else if (childOption === null || !Number.isInteger(childOption.value)) {
      await onValuesChange('child', null)
    } else {
      await onValuesChange('child', {
        id: childOption.value,
        name: childOption.label,
      })
    }
  }, 1000, { leading: true, trailing: false })

  const handleLabelChange = debounce(async (labelOption) => {
    if (labelOption?.__isNew__) {
      const newAgeGrade = await triggerCreateAgeGrade({ name: labelOption.value }).unwrap()
      await onValuesChange('label', newAgeGrade)
    } else if (labelOption === null) {
      await onValuesChange('label', null)
    } else {
      await onValuesChange('label', {
        id: labelOption.value,
        name: labelOption.label,
      })
    }
  }, 1000, { leading: true, trailing: false })

  const handleTitleChange = debounce((e) => {
    if (errors.title) return
    onValuesChange('title', e.currentTarget.value.slice(0, 50))
  }, 1000, { leading: true, trailing: false })

  const handleDateChange = debounce(
    (pictureDate) => isPictureDateValid(pictureDate)
      ? onValuesChange('pictureDate', pictureDate)
      : null
    , 1000, { leading: false, trailing: true })

  const handleTagsChange = debounce(async (values) => {
    // if tagOptions is NOT an array bail early
    if (!Array.isArray(values)) return

    const newTagDraft = values.find((tag) => tag.__isNew__)
    let tags = [...values.filter((tag) => !tag.__isNew__)]
    if (newTagDraft) {
      const newTag = await triggerCreateTag({ tag: { name: newTagDraft.value } }).unwrap()
      tags.push({
        label: newTag.name,
        value: newTag.id,
      })
    }

    const deletedTags = picture.tags.filter((tag) => !tags.find((t) => t.value === tag.id))
      .map((tag) => ({ ...tag, _delete: true }))

    let mappedTags = tags.map((tag) => ({ id: tag.value, name: tag.label }))

    await onValuesChange('tags', [...mappedTags, ...deletedTags])
    setValue('tags', mappedTags.map(({ id: value, name: label }) => ({ value, label })), {
      shouldDirty: true,
      shouldValidate: true,
    })
  }, 1000, { leading: true, trailing: false })

  const validateNewOption = (inputValue, selectValue, selectOptions, regex) => {
    const exists = selectOptions.find((t) => t.label.toLowerCase() === inputValue.toLowerCase())
    const isValid = regex.test(inputValue)
    return !exists && !!isValid
  }

  return (
    <PictureMetaStyle className={'picture-meta'}>
      <div className={'field'}>
        <div className={'select-wrap'}>
          <Controller
            control={control}
            name={'child'}
            rules={{
              validate: (child) => !child || regex.child.test(child.label),
            }}
            render={({ field }) => (
              <Autocomplete
                creatable={true}
                options={childrenOptions}
                label={'Name'}
                {...field}
                onChange={async (nextValue) => {
                  field.onChange(nextValue)
                  await handleChildChange(nextValue)
                }}
                maxLength={CHILD_NAME_MAX_LENGTH}
                error={errors.child && 'Should have from 1 to 25 symbols.'}
                helperHint={'Maximum 25 characters'}
              />
            )}
          />
        </div>
      </div>

      <div className={'field'}>
        <div className={'select-wrap'}>
          <Controller
            control={control}
            name={'label'}
            rules={{ validate: (label) => !label || regex.label.test(label.label) }}
            render={({ field }) => (
              <Autocomplete
                creatable={true}
                label={'Select Age or Grade Label'}
                options={ageGradeOptions}
                {...field}
                onChange={async (nextValue) => {
                  field.onChange(nextValue)
                  await handleLabelChange(nextValue)
                }}
                maxLength={LABEL_MAX_LENGTH}
                error={errors.label && 'Should have from 1 to 25 symbols.'}
                helperHint={'Maximum 25 characters'}
              />
            )}
          />
        </div>
      </div>

      {showCaption && (
        <div className={'field title-field'}>
          <Controller
            control={control}
            name={'title'}
            rules={{ maxLength: CAPTION_MAX_LENGTH, pattern: regex.title }}
            render={({ field }) => (
              <Input
                className={'input-caption input-title'}
                label={'Caption'}
                {...field}
                onBlur={(e) => {
                  field.onBlur(e)
                  handleTitleChange(e)
                }}
                error={errors.title && 'Should have from 1 to 50 symbols.'}
                maxLength={CAPTION_MAX_LENGTH}
                helperHint={'Maximum 50 characters'}
              />
            )}
          />
          <Label className={'label darkgray center sm'}>This will be displayed on the book page</Label>
        </div>
      )}

      <div className={'field'}>
        <DatePicker
          name={'pictureDate'}
          onChange={handleDateChange}
          control={control}
          defaultValue={pictureDate}
          label={'Date'}
        />
      </div>

      <div className={'field'}>

        <Controller
          control={control}
          name={'tags'}
          rules={{ validate: (tags) => tags && tags.every((tag) => regex.tag.test(tag.label)) }}
          render={({ field }) => (
            <Autocomplete
              creatable={true}
              label={'Tags'}
              options={tagOptions}
              {...field}
              onChange={async (nextValue) => {
                field.onChange(nextValue)
                await handleTagsChange(nextValue)
              }}
              isValidNewOption={(inputValue, selectValue, selectOptions) => (
                validateNewOption(inputValue, selectValue, selectOptions, regex.tag)
              )}
              error={errors.tags && 'New tags should have from 1 to 25 symbols.'}
              multiple
              placeholder={'e.g. Tom Artkive Book 2019'}
              noOptionsText={'Type above to create your first tag!'}
              helperHint={'Maximum 25 characters'}
              maxLength={LABEL_MAX_LENGTH}
            />
          )}
        />
      </div>

      {withReplaceAll && (
        <Box>
          <Label className={'darkgray pointer'}>
            <Controller
              control={control}
              name={'replaceTags'}
              render={({ field: { ref, onChange, value, name } }) => (
                <Checkbox
                  color={'primary'}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  ref={ref}
                  name={name}
                />
              )}
            />
            Replace Existing Tags
          </Label>
        </Box>
      )}

      {isLoggedAsUser && (
        <HiddenImagesSwitch
          control={control}
          indeterminate={indeterminateHidden}
          onChange={(value) => onValuesChange('hidden', value)}
          onModeChange={(value) => setValue('shouldChangeHidden', value, {
            shouldDirty: true,
            shouldValidate: true,
          })}
        />
      )}
    </PictureMetaStyle>
  )
}

PictureMeta.propTypes = {
  picture: PropTypes.object.isRequired,
  onValuesChange: PropTypes.func,
  showCaption: PropTypes.bool,
  withReplaceAll: PropTypes.bool,
  indeterminateHidden: PropTypes.bool,
}

export default PictureMeta

import React from 'react'
import PropTypes from 'prop-types'

import { InputLabel } from '@mui/material'

import { FieldLimitText } from './InputLabelWithLimit.style'

const InputLabelWithLimit = ({
  labelState,
  field,
  maxLength,
}) => {
  return (
    <InputLabel>
      {field.label}
      {labelState.focused && (
        <FieldLimitText component={'span'} variant={'subtitle2'}>
          {' '}({labelState.value?.length}/{maxLength || field.maxLength})
        </FieldLimitText>
      )}
    </InputLabel>
  )
}

InputLabelWithLimit.propTypes = {
  field: PropTypes.object,
  labelState: PropTypes.object,
  maxLength: PropTypes.number,
}
export default InputLabelWithLimit

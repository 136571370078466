import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Cropper } from 'react-advanced-cropper'
import { Button } from '@mui/material'
import moment from 'moment/moment'

import { applyFiltersOnVirtualCanvas } from 'components/Modal/NewImageEditor/adjustableCanvasUtils'
import useImageLoader from 'components/Modal/NewImageEditor/useOriginImageLoader'
import NewModal from 'components/NewModal'
import ConfirmImageEdit from 'components/NewModal/contents/ConfirmImageEdit'
import CloseIcon from 'components/svg/CloseIcon'
import { useSetErrorMessage } from 'dux/slice/ui'
import useMetaThemeColor from 'hooks/useMetaThemeColor'
import { getCookie, setCookie } from 'utils/cookieService'

import 'react-advanced-cropper/dist/style.css'

import { saveFile } from '../EditPicture/CropImage/CropImage.helpers'

import AdjustableCropperBackground from './components/AdjustableCropperBackground'
import CropperWrapper from './CropperWrapper'
import { IMAGE_EDITOR_VARIANT } from './NewImageEditor.constants'
import {
  ActionsStack,
  CloseButton,
  CropImageStyle,
  CropImageWrapper,
  EditorSubtitle,
  EditorTitle,
  Heading,
  ResetButton,
  Spinner,
} from './NewImageEditor.style'
import { createFileFromCanvas, exportCanvasStateAsBlob, getDefaultSize } from './NewImageEditor.utils'

const UPDATE_PICTURE_COOKIE_NAME = 'hideWarnUpdateImage'

const META_THEME_COLOR = '#152772'

const defaultAdjustments = {
  brightness: 0,
  hue: 0,
  saturation: 0,
  contrast: 0,
}

const NewImageEditor = forwardRef(({ picture, onSave, onClose, variant }, ref) => {
  const setError = useSetErrorMessage()
  const cropperEnabled = variant === IMAGE_EDITOR_VARIANT.CROP
  const getOriginImage = useImageLoader(picture.image.full)

  const [loading, setLoading] = useState(true)
  const cropperRef = useRef(null)
  const previewRef = useRef(null)
  const imageRef = useRef(null)
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false)
  const [hideConfirmationNextTime, setHideConfirmationNextTime] = useState(false)

  const [adjustments, setAdjustments] = useState(defaultAdjustments)
  const [isResetDisabled, setIsResetDisabled] = useState(true)

  const editorTitle = cropperEnabled ? 'Crop/Rotate' : 'Adjust'

  const handleUpdate = useCallback(() => {
    previewRef.current?.refresh()
  }, [])

  const getCompressedFile = useCallback(async () => {
    const fileName = picture.fileName || ''
    const isTransparentBg = /\.(png|webp)$/.test(picture.fileName || '')

    if (cropperEnabled) {
      let canvas = await exportCanvasStateAsBlob(cropperRef.current.getState(), imageRef.current, isTransparentBg)
      const file = createFileFromCanvas(fileName, canvas)
      canvas.width = 0
      canvas.height = 0
      return file
    } else {
      const originImage = await getOriginImage()
      let { canvas, canvasCleanUp } = await applyFiltersOnVirtualCanvas(originImage, adjustments)
      const file = await createFileFromCanvas(fileName, canvas)
      canvasCleanUp()
      return file
    }
  }, [adjustments, cropperEnabled, getOriginImage, picture])

  const handleHideConfirmationNextTimeChange = () => {
    setHideConfirmationNextTime((prev) => !prev)
  }

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)
      setConfirmationModalVisible(false)
      const compressFile = await getCompressedFile()
      await onSave(compressFile, { variant })

      if (hideConfirmationNextTime) {
        setCookie(UPDATE_PICTURE_COOKIE_NAME, true, {
          expires: moment().add(2, 'month').toDate(),
        })
      }
      onClose()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setError(`Error cropping: ${err.message}`)
    }
  }, [getCompressedFile, onSave, variant, hideConfirmationNextTime, onClose, setError])

  const handleSaveClickWithConfirmation = useCallback(() => {
    const cookie = getCookie(UPDATE_PICTURE_COOKIE_NAME)

    if (cookie) {
      handleSave()
    } else {
      setConfirmationModalVisible(true)
    }
  }, [handleSave])

  const handleResetEdits = useCallback(() => {
    const defaultState = cropperRef.current?.getDefaultState()
    cropperRef.current.setState(defaultState)

    setAdjustments(defaultAdjustments)
    setIsResetDisabled(true)
  }, [])

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false)
  }
  const handleCancelConfirmationModal = () => {
    handleResetEdits()
    setConfirmationModalVisible(false)
    onClose()
  }

  const handleDownload = useCallback(async () => {
    const compressFile = await getCompressedFile()
    saveFile(compressFile, picture.fileName)
  }, [picture, getCompressedFile])

  const handleEnableReset = useCallback(() => {
    if (!isResetDisabled) return
    setIsResetDisabled(false)
  }, [isResetDisabled])

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.preventDefault()
    }

    window.addEventListener('touchmove', handleTouchMove, { passive: false })
    return () => {
      window.removeEventListener('touchmove', handleTouchMove, { passive: false })
    }
  }, [])

  useMetaThemeColor(META_THEME_COLOR, true)

  useEffect(() => {
    const isAdjustmentsUpdated = Object.values(adjustments).some(Boolean)
    if (!isAdjustmentsUpdated) return

    setIsResetDisabled(false)
  }, [adjustments])

  const isLoadingSpinnerVisible = loading
  const cropperSrc = cropperEnabled ? picture.image.full : picture.image.preview

  return (
    <CropImageWrapper ref={ref}>
      <CropImageStyle>
        {isLoadingSpinnerVisible && <Spinner position={'fixed'} />}
        <Heading flexDirection={'column'}>
          <EditorTitle>
            {editorTitle}
          </EditorTitle>
          <EditorSubtitle>
            Original image
          </EditorSubtitle>
          <CloseButton
            onClick={() => onClose()}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            <CloseIcon variantType={'white'} />
          </CloseButton>
        </Heading>

        {cropperSrc && (
          <Cropper
            src={cropperSrc}
            ref={cropperRef}
            defaultSize={getDefaultSize}
            stencilProps={{
              grid: true,
              movable: cropperEnabled,
              resizable: cropperEnabled,
              lines: cropperEnabled,
              handlers: cropperEnabled,
              overlayClassName: !cropperEnabled && 'advanced-cropper-stencil-overlay--faded',
            }}
            backgroundWrapperProps={{
              scaleImage: cropperEnabled,
              moveImage: cropperEnabled,
            }}
            imageRestriction={'fitArea'}
            backgroundComponent={AdjustableCropperBackground}
            backgroundProps={{
              ...adjustments,
              variant,
              onImageLoaded: () => setLoading(false),
              onLoad: () => setLoading(false),
              ref: imageRef,
              imageRef: imageRef,
            }}
            wrapperComponent={CropperWrapper}
            wrapperProps={{
              loading,
              variant,
              adjustments,
              setAdjustments,
              onDownload: handleDownload,
            }}
            canvas={false}
            onUpdate={handleUpdate}
            crossOrigin={'anonymous'}
            onTransformImage={handleEnableReset}
            onResize={handleEnableReset}
          />
        )}
        <Heading alignSelf={'flex-start'}>
          <ActionsStack direction={'row'} spacing={1}>
            <ResetButton
              variant={'new-outlined-primary'}
              disabled={isResetDisabled}
              onClick={handleResetEdits}
            >
              Reset
            </ResetButton>
            <Button
              variant={'new-contained-primary'}
              onClick={handleSaveClickWithConfirmation}
              sx={{ flexGrow: 1 }}
            >
              Save
            </Button>
          </ActionsStack>
        </Heading>
      </CropImageStyle>
      {/* Don't use setModal, because setModal will close Parent(NewImageEditor) modal with required deps */}
      {confirmationModalVisible && (
        <NewModal
          open={confirmationModalVisible}
          title={'Save Changes'}
          submitText={'Save'}
          cancelText={'Cancel'}
          onClose={handleCloseConfirmationModal}
          onCancel={handleCancelConfirmationModal}
          onSubmit={handleSave}

        >
          <ConfirmImageEdit
            checked={hideConfirmationNextTime}
            handleChange={handleHideConfirmationNextTimeChange}
          />
        </NewModal>
      )}
    </CropImageWrapper>
  )
})

NewImageEditor.propTypes = {
  picture: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([IMAGE_EDITOR_VARIANT.CROP, IMAGE_EDITOR_VARIANT.ADJUST]),
}

export default NewImageEditor

import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { CropperSource, mergeRefs } from 'react-advanced-cropper'
import { fabric } from 'fabric'
import omit from 'lodash/omit'

import { isAppleMobileDevice } from 'utils/browser/parser'

import { createAdjustableCanvas, loadAdjustableImage } from '../adjustableCanvasUtils'

// disable GL filtering on mobile devices
fabric.enableGLFiltering = !isAppleMobileDevice
// fabric.enableGLFiltering = false
const tempImageDispose = fabric.Image.prototype.dispose

// reset image/canvas size on dispose to speedup memory release
fabric.Image.prototype.dispose = function() {
  ['_originalElement', '_element', '_filteredEl', '_cacheCanvas'].forEach((function(element) {
    const el = this[element]
    if (!el)
      return

    el.width = 0
    el.height = 0
  }).bind(this))
  tempImageDispose.call(this)
}
const useDrawImage
  = (src, brightness, saturation, hue, contrast, fabricCanvasRef) => {
    const frameRenderer = useRef(null)

    const drawImage = () => {
      if (
        !fabricCanvasRef.current ||
      !fabricCanvasRef.current.filters ||
      !fabricCanvasRef.current.canvas.contextContainer
      )
        return

      try {
        const filters = fabricCanvasRef.current.filters
        filters.brightness.brightness = brightness
        filters.contrast.contrast = contrast
        filters.saturation.saturation = saturation
        filters.hue.rotation = hue

        fabricCanvasRef.current.imgInstance.applyFilters()
        fabricCanvasRef.current.canvas.requestRenderAll()

      } catch (e) {
        console.log(e)
      }
    }

    useEffect(() => {
      clearTimeout(frameRenderer.current)

      frameRenderer.current = setTimeout(drawImage, 100)
    }, [src, brightness, saturation, hue, contrast])

    return drawImage
  }


const AdjustableImage = forwardRef(
  ({
    src,
    className,
    crossOrigin,
    brightness = 0,
    saturation = 0,
    hue = 0,
    contrast = 0,
    style,
    imageSize,
    imageRef,
  }, ref) => {
    const canvasRef = useRef(null)
    const fabricCanvasRef = useRef()

    const drawImage = useDrawImage(src, brightness, saturation, hue, contrast, fabricCanvasRef)

    useEffect(() => {
      const canvas = createAdjustableCanvas(canvasRef.current, imageSize, style)

      loadAdjustableImage(canvas, imageRef.current).then(({ imgInstance, filters }) => {
        if (!canvas.contextContainer)
          return

        canvas.add(imgInstance)

        fabricCanvasRef.current = {
          canvas,
          imgInstance,
          filters,
        }
      })

      return () => {
        !!fabricCanvasRef.current?.imgInstance && fabricCanvasRef.current.imgInstance.dispose()
        !!fabric.filterBackend && fabric.filterBackend.dispose()
        fabricCanvasRef.current = null
        canvas.clear()
        canvas.setWidth(0)
        canvas.setHeight(0)
        canvas.dispose()
      }
    }, [imageSize.width, imageSize.height])

    const composedCanvasRef = mergeRefs([ref, canvasRef])

    return (
      <>
        <canvas
          key={`source-canvas`}
          ref={composedCanvasRef}
          className={className}
          style={omit(style, ['width', 'height'])}
        />

        <CropperSource
          key={`source-img`}
          ref={imageRef}
          src={src}
          crossOrigin={crossOrigin}
          decoding={'sync'}
          onLoad={drawImage}
        />
      </>
    )
  },
)

AdjustableImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  crossOrigin: PropTypes.oneOf(['anonymous', 'use-credentials', true, false]),
  brightness: PropTypes.number,
  saturation: PropTypes.number,
  hue: PropTypes.number,
  contrast: PropTypes.number,
  style: PropTypes.object,
  imageSize: PropTypes.object,
  imageRef: PropTypes.object,
}

AdjustableImage.displayName = 'AdjustableImage'

export default AdjustableImage

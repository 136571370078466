import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  display: flex;
  flex-direction: column;
  margin: 2rem auto 0 auto;
  padding: 2rem;
  min-height: 1000px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 1rem;
    margin: 2rem auto;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    padding: 2rem;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    padding: 1rem;
  }

  .dropzone-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .dz-preview {
    display: none;
  }

  .loading-skeleton {
    display: flex;
    justify-content: space-around;

    @media (max-width: ${theme.breakpoints.md}) {
      div:nth-child(4),
      div:nth-child(5) {
        display: none;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      div:nth-child(3) {
        display: none;
      }
    }
  }
`

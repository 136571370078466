import React from 'react'
import { array, func, number, object } from 'prop-types'

import { useFlexLayout, useTable } from 'react-table'
import { List as FixedSizeList } from 'react-virtualized'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import MUITable from './Table.style'

const Table = React.forwardRef((props, ref) => {

  const {
    columns,
    data,
    onRowClick,
    scrollTop,
    style,
    onRowsRendered,
    height,
    width,
  } = props

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <TableRow
          {...row.getRowProps({ style })}
          className={'tr'}
          component={'div'}
          role={'button'}
          onClick={() => onRowClick(row.original)}
        >
          {row.cells.map((cell) => {
            const { key, style: cellStyle = {}, ...restProps } = cell.getCellProps()
            return (
              <TableCell
                component={'div'}
                key={key}
                {...restProps}
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  ...cellStyle,
                }}
                className={'td'}
              >
                {cell.render('Cell')}
              </TableCell>
            )
          })}
        </TableRow>
      )
    },
    [prepareRow, rows],
  )


  return (
    <MUITable component={'div'} {...getTableProps()} className={'table'}>
      <TableHead component={'div'}>
        {headerGroups.map((headerGroup) => {
          const { key, ...restProps } = headerGroup.getHeaderGroupProps()
          return (
            <TableRow component={'div'} key={key} {...restProps} className={'tr'}>
              {headerGroup.headers.map((column) => {
                const { key: columnKey, ...restHeaderProps } = column.getHeaderProps()
                return (
                  <TableCell component={'div'} key={columnKey} {...restHeaderProps} className={'th'}>
                    {column.render('Header')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableHead>

      <TableBody component={'div'} {...getTableBodyProps()}>
        <FixedSizeList
          ref={ref}
          height={height}
          rowCount={rows.length}
          rowHeight={75}
          autoHeight
          width={Math.max(width, totalColumnsWidth)}
          style={style}
          onRowsRendered={onRowsRendered}
          rowRenderer={RenderRow}
          scrollTop={scrollTop}
          autoContainerWidth={true}
        />
      </TableBody>
    </MUITable>
  )
})

Table.displayName = 'Table'

Table.propTypes = {
  columns: array,
  data: array,
  onRowClick: func,
  height: number,
  total: number,
  width: number,
  style: object,
  onRowsRendered: func,
  scrollTop: number,
}

export default Table

import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { FilterButton } from 'components/Buttons/Button'
import Dropdown from 'components/Dropdown'
import PictureChildFilters from 'components/PictureChildFilters'

/**
 * Child Filter Dropdown for picture list
 */
const PictureChildFilterDropdown = ({ childrenOptions }) => {
  const childFilters = useStoreState((state) => state.pictures.filters.children)
  const setChildrenFilter = useStoreActions((actions) => actions.pictures.setChildrenFilter)

  const handleReset = () => {
    setChildrenFilter({})
  }

  return (
    <Dropdown
      className={'child-filter-dropdown'}
      onReset={handleReset}
      anchor={(
        <FilterButton
          className={classnames('spaced-btn', { active: Object.keys(childFilters).length > 0 })}
        >
          Name
        </FilterButton>
      )}
      keepMounted={false}
    >
      <PictureChildFilters childrenOptions={childrenOptions} />
    </Dropdown>
  )
}

PictureChildFilterDropdown.propTypes = {
  childrenOptions: PropTypes.array.isRequired,
}

export default PictureChildFilterDropdown

import { computed } from 'easy-peasy'
import { BOOK_SIZES } from 'pdf-printer/src/components/Page'

import { BOOK_SECTION_TYPES, PAGE_TYPES } from 'utils/constants'

import { DEFAULT_BOOK, DEFAULT_TEXT_SETTINGS, MAX_BOOK_CAPACITY, MIN_BOOK_CAPACITY } from './Book.constants'
import { hasCover } from './Book.utils'
import {
  bookRealAspectRatio,
  canBeSentToCustomer,
  getImagePageSection,
  getTextPageSection,
  isSentToCustomer as _isSentToCustomer,
} from './Book.utils'

export const FIRST_INSIDE_COVER_INDEX = -1
export const COVER_INDEX = -2
export const LAST_INSIDE_COVER_INDEX = -3
export const BACK_COVER_INDEX = -4
export const INSIDE_COVER_LABEL = 'Inside Cover'

export const mapCoverToCoverSections = (cover) => {
  return ([
    {
      id: 'section_cover_text',
      sectionType: BOOK_SECTION_TYPES.TEXT,
      title: cover?.title,
      align: cover?.titleAlign,
      xPositionPercentage: 0,
      yPositionPercentage: 0,
      rotation: 0,
      picture: null,
    }, {
      id: 'section_cover_image',
      sectionType: BOOK_SECTION_TYPES.IMAGE,
      title: null,
      align: null,
      xPositionPercentage: cover?.picture?.xPositionPercentage,
      yPositionPercentage: cover?.picture?.yPositionPercentage,
      rotation: cover?.picture?.rotationAngle,
      picture: cover?.picture?.image,
    },
  ])
}

// TODO: Temporary duplicated function for easier migration
export const mapSectionsToOldCover = (sections) => {
  const coverImage = getImagePageSection(sections)
  const coverText = getTextPageSection(sections)
  return {
    picture: {
      id: coverImage?.picture?.id,
      image: coverImage?.picture ? { ...coverImage?.picture } : null,
      xPositionPercentage: coverImage?.xPositionPercentage,
      yPositionPercentage: coverImage?.yPositionPercentage,
      rotationAngle: coverImage?.rotation,
    },
    title: coverText?.title,
    titleAlign: coverText?.align,
  }
}

// TODO Temporary duplicated function for easier migration
export const mapSectionsToOldPage = (sections) => {
  const imageSection = sections.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.IMAGE)
  return {
    picture: {
      ...imageSection?.picture,
    },
  }
}

// NOTE: shell book currently may not have cover page
export const mapNewToOldCover = (page) => {
  const coverImage = page?.sections.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.IMAGE)
  const coverText = page?.sections.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.TEXT)
  return {
    picture: {
      id: coverImage?.picture?.id,
      image: coverImage?.picture ? { ...coverImage?.picture } : null,
      xPositionPercentage: coverImage?.xPositionPercentage,
      yPositionPercentage: coverImage?.yPositionPercentage,
      rotationAngle: coverImage?.rotation,
    },
    title: coverText?.title,
    titleAlign: coverText?.align,
  }
}

export const mapNewToOldPage = (page) => {
  const imageSection = page.sections.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.IMAGE)
  return {
    picture: {
      ...imageSection?.picture,
    },
  }
}

export const getSingleImagePagePicture = (page) => {
  return page?.sections?.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.IMAGE)?.picture
}

export const getPages = (state) => {
  let pages = []
  const activeBook = state.activeBook
  pages.push(
    {
      type: PAGE_TYPES.COVER,
      key: COVER_INDEX, // TODO => cover.id
      index: COVER_INDEX,
      label: 'Cover',
      hideDate: activeBook.hideDate,
      hideGrade: activeBook.hideGrade,
      hideName: activeBook.hideName,
      hideTitle: activeBook.hideTitle,
      sections: [{
        id: 'section_cover_text',
        sectionType: BOOK_SECTION_TYPES.TEXT,
        title: activeBook.cover?.title,
        align: activeBook.cover?.titleAlign,
        xPositionPercentage: 0,
        yPositionPercentage: 0,
        rotation: 0,
        picture: null,
      }, {
        id: 'section_cover_image',
        sectionType: BOOK_SECTION_TYPES.IMAGE,
        title: null,
        align: null,
        xPositionPercentage: activeBook.cover?.picture?.xPositionPercentage,
        yPositionPercentage: activeBook.cover?.picture?.yPositionPercentage,
        rotation: activeBook.cover?.picture?.rotationAngle,
        picture: activeBook.cover?.picture?.image,
      }],
    },
    {
      type: PAGE_TYPES.BLANK,
      key: FIRST_INSIDE_COVER_INDEX,
      index: FIRST_INSIDE_COVER_INDEX,
      label: INSIDE_COVER_LABEL,
      sections: [],
    },
  )

  activeBook.pictures.forEach((picture, index) => {
    pages.push({
      type: PAGE_TYPES.PAGE,
      key: picture.id, // TODO => page.id
      index,
      label: index + 1,
      hideDate: activeBook.hideDate,
      hideGrade: activeBook.hideGrade,
      hideName: activeBook.hideName,
      hideTitle: activeBook.hideTitle,
      sections: [{
        id: `section_page_${picture.id}_${index}`,
        sectionType: BOOK_SECTION_TYPES.IMAGE,
        picture,
      }],
    })
  })

  const pagesCount = pages.length - 1
  if (pagesCount % 2 !== 0) {
    pages.push({
      type: PAGE_TYPES.BLANK,
      key: LAST_INSIDE_COVER_INDEX,
      index: LAST_INSIDE_COVER_INDEX,
      label: INSIDE_COVER_LABEL,
      sections: [],
    })
  }

  pages.push({
    type: PAGE_TYPES.BACK_COVER,
    key: BACK_COVER_INDEX,
    index: BACK_COVER_INDEX,
    label: 'Back Cover',
    sections: [],
  })

  return pages
}

const bookModel = {
  activeBook: DEFAULT_BOOK,
  wizardBook: {
    ...DEFAULT_BOOK,
    ...DEFAULT_TEXT_SETTINGS,
  },
  isLoading: false,
  isAddPageSelected: false,
  sizes: {
    large: BOOK_SIZES.LARGE,
    small: BOOK_SIZES.SMALL,
  },
  activePageKey: null,
  activePage: computed((state) => {
    return state.pages.find(({ key }) => key === state.activePageKey) || null
  }),
  selected: [],
  dndState: {
    draggingPages: [],
    draggedPictureIds: [],
    isDragStarted: false,
  },
  /**
   * book.pages - computed property
   * that converts activeBook.pictures
   * into pages array for book components
   * @returns {array}
   */
  pages: computed(getPages),
  /**
   * Number of pages in the book without covers.
   * @returns {number}
   */
  pageCount: computed((state) => state.activeBook.pictures?.length ?? 0),
  isSquare: computed((state) => state.aspectRatio && (state.activeBook.size.width === state.activeBook.size.height)),
  /**
   * Book page aspect ratio (without bleed)
   * @return {number}
   */
  aspectRatio: computed((state) => {
    const { size } = state.activeBook
    if (!size || !size.bleed) return

    return bookRealAspectRatio(size)
  }),
  /**
   * Cover image aspect ratio (with bleed)
   * @returns {number}
   */
  coverImageAspectRatio: computed((state) => {
    const { size } = state.activeBook
    if (!size || !size.coverPtHeight || !size.coverPtWidth) return

    return size.coverPtHeight / size.coverPtWidth
  }),
  hasSize: computed((state) => !!state.activeBook.size),
  selectedPictures: computed((state) => {
    return state.activeBook.pictures.filter((picture) => state.selected.includes(picture.id)) || []
  }),
  pictureIds: computed((state) => state.activeBook.pictures.map(({ id }) => id)),
  isSentToCustomer: computed((state) => _isSentToCustomer(state.activeBook)),
  canApprove: computed((state) => {
    const bookHasCover = hasCover(state.activeBook.cover)
    const bookHasMinPageAmount = state.activeBook.pictures.length >= MIN_BOOK_CAPACITY
    const bookHasMaxPageAmount = state.activeBook.pictures.length <= MAX_BOOK_CAPACITY
    const bookHasAllowedPageAmount = state.activeBook.pictures.length <= state.activeBook.prepaidPages
    const isBoxBook = state.activeBook.isInvoiced
    return isBoxBook
      ? (bookHasCover && bookHasMinPageAmount && bookHasAllowedPageAmount)
      : (bookHasCover && bookHasMinPageAmount && bookHasMaxPageAmount)
  }),
  canSendToCustomer: computed((state) => canBeSentToCustomer(state.activeBook)),
}

export default bookModel

import React from 'react'
import PropTypes from 'prop-types'

import { Box, Stack } from '@mui/material'

import BrightnessIcon from 'components/svg/BrightnessIcon'
import ContrastIcon from 'components/svg/ContrastIcon'
import HueIcon from 'components/svg/HueIcon'
import SaturationIcon from 'components/svg/SaturationIcon'

import Button from './Button'

export const ADJUST_MODES = {
  SATURATION: 'saturation',
  BRIGHTNESS: 'brightness',
  CONTRAST: 'contrast',
  HUE: 'hue',
}

const AdjustNavigation = ({ className, onChange, mode, isDisabled }) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className={className}>
      <Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={2}>
        <Button
          disabled={isDisabled}
          active={mode === ADJUST_MODES.SATURATION}
          onClick={() => onChange(ADJUST_MODES.SATURATION)}
          title={'Saturation'}
        >
          <SaturationIcon />
        </Button>
        <Button
          disabled={isDisabled}
          active={mode === ADJUST_MODES.BRIGHTNESS}
          onClick={() => onChange(ADJUST_MODES.BRIGHTNESS)}
          title={'Brightness'}
        >
          <BrightnessIcon />
        </Button>
        <Button
          disabled={isDisabled}
          active={mode === ADJUST_MODES.CONTRAST}
          onClick={() => onChange(ADJUST_MODES.CONTRAST)}
          title={'Contrast'}
        >
          <ContrastIcon />
        </Button>
        <Button
          disabled={isDisabled}
          active={mode === ADJUST_MODES.HUE}
          onClick={() => onChange(ADJUST_MODES.HUE)}
          title={'Hue rotation'}
        >
          <HueIcon />
        </Button>
      </Stack>
    </Box>
  )
}

AdjustNavigation.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  mode: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default AdjustNavigation

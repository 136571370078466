import React, { useCallback, useState } from 'react'
import { func, object } from 'prop-types'

import classnames from 'classnames'

import Button from 'components/Buttons/Button'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'
import addressToString from 'utils/addressToString'

import ConfirmAddressStyle from './ConfirmAddress.style'

const ConfirmAddress = ({
  userAddress,
  validAddress,
  onSuccess,
  setAddress,
}) => {
  const { unsetModal } = useModal()

  const [active, setActive] = useState(validAddress)

  const handleClick = useCallback((address) => {
    setActive(address)
    setAddress(address)
  }, [setAddress])

  const confirmAddress = useCallback(() => {
    unsetModal()
    onSuccess(active)
  }, [onSuccess, active])

  return (
    <ConfirmAddressStyle>
      <Modal handleClose={unsetModal}>
        <H2 className={'center darkblue'}>Verify Your Address</H2>
        <br />

        <div className={'address-list'}>
          <div
            className={classnames('address', { active: active === userAddress })}
            onClick={() => handleClick(userAddress)}
          >
            <div className={'icon-wrap'}>
              <Icon name={'check'} />
            </div>
            <div className={'address-wrap'}>
              <P className={'demi'}>You Entered</P>
              <P className={'gray secondary'}>{addressToString(userAddress)}</P>
            </div>
          </div>

          <div
            className={classnames('address', { active: active === validAddress })}
            onClick={() => handleClick(validAddress)}
          >
            <div className={'icon-wrap'}>
              <Icon name={'check'} />
            </div>
            <div className={'address-wrap'}>
              <P className={'demi'}>Did You Mean?</P>
              <P className={'gray secondary'}>{addressToString(validAddress)}</P>
            </div>
          </div>
        </div>

        <Button onClick={confirmAddress}>Verify Address</Button>
      </Modal>
    </ConfirmAddressStyle>
  )
}

ConfirmAddress.propTypes = {
  userAddress: object.isRequired,
  validAddress: object.isRequired,
  onSuccess: func.isRequired,
  setAddress: func.isRequired,
}

export default ConfirmAddress

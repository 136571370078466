import { startCase } from 'lodash'

export const extractRequestErrorMessage = (error) => {
  const responseError = error.response?.data?.error || error.response?.data?.message
  let text = error?.message || ''
  if (responseError && typeof responseError === 'object' && Object.keys(responseError).length > 0) {
    const key = Object.keys(responseError)[0]
    text = `${startCase(key)} ${responseError[key]}`
  } else if (responseError && typeof responseError === 'string') {
    text = responseError
  }
  return text
}

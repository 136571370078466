import { useMedia } from 'react-media'

import theme from 'styles/theme'

const useResponsive = () => {
  const isMobile = useMedia({ query: `(max-width: ${theme.breakpoints.sm})` })
  const isTablet = useMedia({ query: `(min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.md})` })
  const isDesktop = useMedia({ query: `(min-width: ${theme.breakpoints.md})` })
  return {
    isMobile,
    isDesktop,
    isTablet,
  }
}

export default useResponsive

import { useCallback, useEffect, useState } from 'react'

import { useStoreActions, useStoreState } from 'easy-peasy'

import { APP_MODALS } from 'components/ModalManager'
import { NOTIFICATION_TYPES } from 'components/Notification/constants'
import { QUERY_AGE_GRADE, QUERY_CHILD_NAME, QUERY_DATE } from 'components/PictureSort/PictureSort.constants'
import { useSetNotification } from 'dux/slice/ui'
import useModal from 'hooks/useModal'
import api from 'utils/api'
import { pluralize } from 'utils/intl'

import { PAGE_SIZE, SET_PICTURES_MODES } from './Pictures.constants'

const prepareSortProperties = (sortQuery) => {
  const keys = sortQuery.map(({ type }) => type)
  if (keys.length === 2 && keys.indexOf(QUERY_CHILD_NAME) !== -1 && keys.indexOf(QUERY_AGE_GRADE) !== -1) {
    return [...sortQuery, { type: QUERY_DATE, direction: 'ASC' }]
  }

  return sortQuery
}

export const useLoadImages = ({ excludeIds = [], bookIds, onLoadSuccess }) => {
  const readPictures = useStoreActions((actions) => actions.pictures.readPictures)
  const deselectAllPictures = useStoreActions((actions) => actions.pictures.deselectAllPictures)

  const filters = useStoreState((state) => state.pictures.filters)
  const sortQueries = useStoreState((state) => state.pictures.sort)
  const pictures = useStoreState((state) => state.pictures.list)
  const selectedList = useStoreState((state) => state.pictures.selectedPictures)
  const selectedPicturesIds = useStoreState((state) => state.pictures.selected)
  const isLoading = useStoreState((state) => state.pictures.isLoading)

  const handleLoadImages = useCallback(
    async (fetchPage, mode = SET_PICTURES_MODES.APPEND, pageSize = 0) => {
      if (mode === SET_PICTURES_MODES.SET && selectedPicturesIds.length) {
        await deselectAllPictures(null)
      }

      const options = {
        pagination: {
          page: fetchPage,
          pageSize,
        },
        sort: prepareSortProperties(sortQueries),
        filter: {
          excludePictureIds: excludeIds,
          bookIds,
          ...filters.uploadBy,
        },
        mode,
      }

      if (Object.keys(filters.children).length) {
        const childFiltersArray = Object.entries(filters.children)
          .reduce((result, [key, value]) => ([
            ...result,
            { childId: key, labelIds: value },
          ]), [])

        options.filter.children = childFiltersArray
      }

      if (filters.tags.length) {
        options.filter.tagIds = filters.tags
      }

      await readPictures(options)

      onLoadSuccess?.(fetchPage)
    }, [sortQueries, excludeIds, filters, bookIds, selectedPicturesIds, onLoadSuccess])

  return {
    pictures,
    isPicturesLoading: isLoading,
    selectedList,
    selectedPicturesIds,
    handleLoadImages,
  }
}

export const usePicturesLoadMore = ({ excludeIds = [], bookIds }) => {
  const readPictures = useStoreActions((actions) => actions.pictures.readPictures)
  const deselectAllPictures = useStoreActions((actions) => actions.pictures.deselectAllPictures)

  const filters = useStoreState((state) => state.pictures.filters)
  const sortQueries = useStoreState((state) => state.pictures.sort)
  const pictures = useStoreState((state) => state.pictures.list)
  const selectedList = useStoreState((state) => state.pictures.selectedPictures)
  const selectedPicturesIds = useStoreState((state) => state.pictures.selected)
  const isLoading = useStoreState((state) => state.pictures.isLoading)

  const [page, setPage] = useState(1)

  const handleLoadMore = useCallback(async (fetchPage = page, mode = SET_PICTURES_MODES.APPEND, pageSize = 0) => {
    if (mode === SET_PICTURES_MODES.SET && selectedPicturesIds.length) {
      await deselectAllPictures(null)
    }

    const options = {
      pagination: {
        page: fetchPage + 1,
        pageSize,
      },
      sort: prepareSortProperties(sortQueries),
      filter: {
        excludePictureIds: excludeIds,
        bookIds,
        ...filters.uploadBy,
      },
      mode,
    }

    if (Object.keys(filters.children).length) {
      const childFiltersArray = Object.entries(filters.children)
        .reduce((result, [key, value]) => ([
          ...result,
          { childId: key, labelIds: value },
        ]), [])

      options.filter.children = childFiltersArray
    }

    if (filters.tags.length) {
      options.filter.tagIds = filters.tags
    }

    await readPictures(options)

    const pageIncremental = Math.ceil(pageSize / PAGE_SIZE) || 1
    setPage(fetchPage + pageIncremental)
  }, [sortQueries, excludeIds, filters, bookIds, selectedPicturesIds])

  return {
    page,
    setPage,
    pictures,
    isPicturesLoading: isLoading,
    selectedList,
    selectedPicturesIds,
    handleLoadMore,
  }
}

export const usePicturesReplace = (onFinish) => {
  const picturesLoading = useStoreState((state) => state.pictures.isLoading)
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)
  const setIsLoading = useStoreActions((actions) => actions.pictures.setIsLoading)

  const setNotification = useSetNotification()
  const { setModal } = useModal()

  const showNotification = useCallback((type, message, duration = null) => {
    setNotification({
      type,
      message,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      duration,
    })
  }, [])

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    const acceptedFilesCount = acceptedFiles.length
    const fileRejectionsCount = fileRejections.length

    if (fileRejectionsCount) {
      onFinish()
      setModal({
        name: APP_MODALS.UploadFileTypeError,
        fileRejections,
      })
      return
    }

    setIsLoading(true)
    showNotification(NOTIFICATION_TYPES.PROGRESS, `Replacing ${acceptedFilesCount} ${pluralize(acceptedFilesCount, 'image')}...`, null)

    const fileNames = acceptedFiles.map((file) => file.name)
    try {
      const response = await api.post({
        url: `/api/v2/user/pictures/search`,
        data: { filter: { file_name: fileNames } },
      })

      const pictures = response.data.data
      const filesToReplace = acceptedFiles.reduce((accumulator, file) => {
        const picture = pictures.find((p) => p.fileName === file.name)

        if (picture) {
          accumulator.push([file, picture])
        }

        return accumulator
      }, [])
      const replacedCount = filesToReplace.length
      if (replacedCount) {
        for (const [file, picture] of filesToReplace) {
          await updatePicture({ picture: { id: picture.id, image: file } })
        }

        const nonReplacedCount = acceptedFilesCount - replacedCount

        if (nonReplacedCount) {
          showNotification(NOTIFICATION_TYPES.WARNING, `${replacedCount}/${acceptedFilesCount} ${pluralize(replacedCount, 'image', 'images')} replaced, ${nonReplacedCount}/${acceptedFilesCount} ${pluralize(nonReplacedCount, 'image', 'images')} - no file name match`, 3000)
        } else {
          showNotification(NOTIFICATION_TYPES.SUCCESS, `${replacedCount} ${pluralize(replacedCount, 'image', 'images')} replaced`, 3000)
        }
      } else {
        showNotification(NOTIFICATION_TYPES.ERROR, 'No file name match', 3000)
      }
    } catch (error) {
      showNotification(NOTIFICATION_TYPES.ERROR, 'Something went wrong. Please try again later', 3000)
    } finally {
      setIsLoading(false)
      onFinish()
    }
  }, [onFinish])

  return {
    onDrop,
    loading: picturesLoading,
  }
}

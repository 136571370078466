import { styled } from '@mui/material/styles'
import { hideVisually } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  display: inline-block;
  vertical-align: middle;

  input {
    ${hideVisually()}
    &:focus + & {
      box-shadow: 0 0 0 3px pink;
    }
  }

  .radio {
    background: ${(props) => props.checked ? theme.colors.white : ''};
    border: ${(props) => props.checked ? `2px solid ${theme.colors.blue}` : `2px solid ${theme.colors.lightgray}`};
    display: flex;
    height: 1rem;
    transition: all 150ms;
    width: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin: 0 !important;
    padding: 0 !important;

    .checkmark {
      background: ${(props) => props.checked ? theme.colors.blue : ''};
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`

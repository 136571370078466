import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useMeasure } from 'react-use'
import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'

import { AdaptiveImagesGridInner, Image, RestImages, RestImagesText } from './AdaptiveImagesGrid.style'

const MAX_GRID_SIZE = {
  cols: 5,
  rows: 4,
  smUpRows: 5,
}

const ImagesGridContent = ({ pictures }) => {
  const [ref, { width }] = useMeasure()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [columns, visiblePictures, restPictures] = useMemo(() => {
    const columns = Math.min(Math.ceil(Math.sqrt(pictures.length)), MAX_GRID_SIZE.cols)
    const maxImages = MAX_GRID_SIZE.cols * (isMobile ? MAX_GRID_SIZE.rows : MAX_GRID_SIZE.smUpRows)
    const visiblePictures = pictures.length > maxImages ? maxImages - 1 : pictures.length
    const restPictures = pictures.length - visiblePictures
    return [columns, visiblePictures, restPictures]
  }, [pictures.length, isMobile])

  return (
    <Box display={'flex'} ref={ref}>
      <AdaptiveImagesGridInner sx={{ '--grid-columns': columns, '--content-width': `${width}px` }}>
        {pictures.slice(0, visiblePictures).map((picture) => (
          <Image key={picture.id} picture={picture} />
        ))}
        {!!restPictures && (
          <RestImages>
            <RestImagesText variant={'subtitle2'}>
            +{restPictures}
            </RestImagesText >
          </RestImages>
        )}
      </AdaptiveImagesGridInner>
    </Box>
  )
}

ImagesGridContent.propTypes = {
  pictures: PropTypes.array,
}

export default ImagesGridContent

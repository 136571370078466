import { createContext, useContext } from 'react'

export const NewUploadDropzoneContext = createContext({})

export const useNewUploadDropzoneContext = () => {
  const context = useContext(NewUploadDropzoneContext)
  if (!context) {
    throw new Error(`useUploadDropzoneContext must be used within a UploadDropzoneContext`)
  }
  return context
}

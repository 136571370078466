import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

import { BodyText } from 'components/NewModal/NewModal.style'
import { MIN_BOOK_CAPACITY } from 'containers/Book/Book.constants'
import { pluralize } from 'utils/intl'

const DeletePagesMinLimitReachedContent = ({ pagesToDelete }) => {
  return (
    <BodyText sx={{ mt: 3 }}>
      You can't remove the{' '}
      <Typography variant={'strong'}>{pagesToDelete} {pluralize(pagesToDelete, 'page')}</Typography> you
        have selected. This will make your book shorter than the {MIN_BOOK_CAPACITY} page minimum.
    </BodyText>
  )
}

DeletePagesMinLimitReachedContent.propTypes = {
  pagesToDelete: PropTypes.number,
}

export default DeletePagesMinLimitReachedContent

import React from 'react'
import { func, node, string } from 'prop-types'

import classNames from 'classnames'

import Icon from 'components/Icon'
import Label from 'components/Text/Label'

import ModalStyle from './Modal.style'

/**
 * Modal - Modal
 */
const Modal = (props) => {
  const {
    className,
    backButtonTitle,
    children,
    handleBack,
    handleClose,
  } = props

  return (
    <ModalStyle className={className}>
      <div
        className={classNames('cta-wrap', {
          'has-back': !!backButtonTitle,
        })}
        onClick={handleBack}
      >
        {backButtonTitle && (
          <div className={'back-wrap'}>
            <Icon name={'back'} className={'back'} width={40} height={40} />
            <Label className={'secondary medgray'}>{backButtonTitle}</Label>
          </div>
        )}
        {handleClose && (
          <Icon name={'close'} className={'close'} onClick={handleClose} height={40} width={40} />
        )}
      </div>
      {children}
    </ModalStyle>
  )
}

Modal.propTypes = {
  backButtonTitle: string,
  children: node.isRequired,
  handleBack: func,
  handleClose: func,
  className: string,
}

export default Modal

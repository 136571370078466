const { convertToQueryString } = require('../../../lib/url')

module.exports = class AuthError extends Error {
  constructor(message, options = { redirectPath: 'signin' }) {
    super(message)
    const { query, redirectPath } = options

    this.errorType = 'auth'
    this.options = options
    this.status = options.status
    const urlOptions = { ...query }
    if (this.status !== 401) {
      urlOptions.error = message
    }
    this.redirectPath = `/${redirectPath}${convertToQueryString(urlOptions)}`
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import { CacheProvider } from '@emotion/react'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

import useScreenHeight from 'hooks/useScreenHeight'

import fontTheme from './fonts'
import GlobalStyles from './global'
import muiTheme from './muiTheme'

export const AppStylesProvider = ({ children, cache }) => {
  useScreenHeight()

  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider>
        <MuiThemeProvider theme={muiTheme}>
          <GlobalStyles />
          {/* eslint-disable-next-line react/no-unknown-property */}
          <style jsx global>
            {fontTheme}
          </style>
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  )
}

AppStylesProvider.propTypes = {
  children: PropTypes.node,
  cache: PropTypes.object,
}

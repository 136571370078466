import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'dux/baseQuery'

import { isHydrateAction } from './utils'

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (isHydrateAction(action)) {
      return action.payload[reducerPath]
    }
  },
})

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const AnnualTip = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkgray};
  position: absolute;
  left: 50%;
  transform: translate(-50%, 8px);
  text-align: center;
`

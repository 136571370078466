import { computed } from 'easy-peasy'

const booksModel = {
  list: [],
  error: '',
  isLoading: true,
  artkiveBooks: computed((state) => state.list.filter((book) => book.isConcierge)),
  diyBooks: computed((state) => state.list.filter((book) => !book.isConcierge)),
}

export default booksModel

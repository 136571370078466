import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'

import { MIXED_CONTENT_KEYWORD } from 'components/FormFields/DatePicker'

// This function provides functionality to determine if a field has Mixed values.
export const getConsistentPropertyValue = (arr, prop) => {
  if (arr.length === 0) {
    return null
  }
  const referenceProperty = arr[0][prop]

  const allSame = arr.every((item) => {
    // differenceWith doesn't work properly with arrays of objects and empty arrays.
    // also it some kind  of optimization for performance
    // check length first and then compare with more complex operation
    if (Array.isArray(item[prop])) {
      if (item[prop].length !== referenceProperty?.length) {
        return false
      }
      return differenceWith(item[prop], referenceProperty, isEqual).length === 0
    }

    if (isObject(item[prop])) {
      return item[prop].id === referenceProperty?.id
    }

    return item[prop] === referenceProperty
  })

  return allSame ? referenceProperty ?? '' : undefined
}

//  Calculate main values for picture and determine if fields have Mixed values
export const getProxiedPictureValues = (pictures) => {
  if (pictures.length === 1) {
    return pictures[0]
  }

  const pictureDate = getConsistentPropertyValue(pictures, 'pictureDate') ?? MIXED_CONTENT_KEYWORD
  const child = getConsistentPropertyValue(pictures, 'child') ?? {
    name: MIXED_CONTENT_KEYWORD,
    value: MIXED_CONTENT_KEYWORD,
  }
  const label = getConsistentPropertyValue(pictures, 'label') ?? {
    name: MIXED_CONTENT_KEYWORD,
    value: MIXED_CONTENT_KEYWORD,
  }
  const title = getConsistentPropertyValue(pictures, 'title') ?? MIXED_CONTENT_KEYWORD
  const tags = getConsistentPropertyValue(pictures, 'tags') ?? MIXED_CONTENT_KEYWORD
  const hidden = getConsistentPropertyValue(pictures, 'hidden') ?? MIXED_CONTENT_KEYWORD
  return {
    id: pictures[0].id,
    pictureDate,
    child,
    label,
    title,
    tags,
    hidden,
  }
}

// This is onFocus handler for Autocomplete field

// This UX was discussed with the designer. If the Autocomplete field has a mixed value,
// it should be selected when focused. This prevents the need for deleting the "Mixed" value letter by letter
// and allows the user to delete it with a single backspace.
// The selection will notify the user that they can delete it with a single backspace or any other symbol input.

export { selectMixedContentOnFocus, MIXED_CONTENT_KEYWORD } from 'components/FormFields/DatePicker'

// This is onSelect handler for Autocomplete field
// This handler is related to the previous one.
// It resolves the problem with "Mixed" fields when the user decides to select only a part of the "Mixed" keyword.
// It will reset the user's selection and select the entire "Mixed" keyword regardless.

export const preventDeselectionForMixedContent = (e) => {
  if (
    e.target.value === MIXED_CONTENT_KEYWORD &&
    (e.target.selectionStart !== 0 || e.target.selectionEnd !== MIXED_CONTENT_KEYWORD.length)
  ) {
    e.target.select()
    e.preventDefault()
  }
}

export const EDIT_PAGE_TEXT_FORM_FIELDS_ERRORS = {
  child: 'Should have from 1 to 25 letters or numbers.',
  label: 'Should have from 1 to 25 letters or numbers.',
  tags: 'New tags should have from 1 to 25 letters or numbers.',
}

export const getTagsWithDeleteFlag = (tags, pictures) => {
  return pictures.reduce((acc, picture) => {
    if (Array.isArray(picture?.tags)) {
      picture?.tags.forEach((tag) => {
        if (!tags.find((t) => t.value === tag.id)) {
          acc.push({ ...tag, _delete: true })
        }
      })
    }
    return acc
  }, [])
}

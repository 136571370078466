import React from 'react'

import { useForm } from 'react-hook-form'

import Button from 'components/Buttons/Button'
import Input from 'components/FormFields/Input'
import Modal from 'components/Modal'
import { APP_MODALS } from 'components/ModalManager'
import ButtonLabel from 'components/Text/ButtonLabel'
import H2 from 'components/Text/H2'
import Label from 'components/Text/Label'
import { useUpdateUserEmailMutation } from 'dux/queries/user'
import useModal from 'hooks/useModal'
import validations from 'utils/validations'

import EditEmailStyle from './EditEmail.style'

const EditEmail = () => {
  const { unsetModal, setModal } = useModal()
  const [updateUserEmail] = useUpdateUserEmailMutation()

  const { register, handleSubmit, getValues, formState: { errors } } = useForm()

  const handleSaveChanges = async (data) => {
    await updateUserEmail({
      email: data.email,
      password: data.password,
    })

    unsetModal()
  }

  const handleForgotPassword = () => {
    unsetModal()
    setModal({ name: APP_MODALS.ForgotPassword })
  }

  return (
    <EditEmailStyle>
      <Modal handleClose={unsetModal}>
        <H2 className={'center darkblue'}>Update Email</H2>
        <form className={'content'} onSubmit={handleSubmit(handleSaveChanges)}>
          <Input
            label={'Current Password'}
            type={'password'}
            {...register('password', validations.password)}
            error={errors.password}
            inputProps={{
              autoComplete: 'current-password',
            }}
          />
          <Label className={'blue link pointer forgot-password sm'} onClick={handleForgotPassword}>
            Forgot password?
          </Label>
          <Input
            className={'input'}
            label={'New Email'}
            type={'email'}
            {...register('email', validations.email)}
            error={errors.email}
          />
          <Input
            className={'input'}
            label={'Confirm Email'}
            type={'email'}
            {...register('confirmEmail', validations.confirmEmail(getValues))}
            error={errors.confirmEmail}
          />
          <div className={'buttons'}>
            <Button type={'submit'}>
              <ButtonLabel className={'white'}>
                Save Changes
              </ButtonLabel>
            </Button>
          </div>
        </form>
      </Modal>
    </EditEmailStyle>
  )
}

export default EditEmail

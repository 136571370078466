import React from 'react'
import { array, number, string } from 'prop-types'

import cl from 'classnames'
import Chip from '@mui/material/Chip'
import { makeStyles, withStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: '0 -4px',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& > *': {
      margin: '2px 4px',
    },
  },
})

const TagLabelChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    borderRadius: 3,
  },
}), {})(Chip)

const PictureTagsList = ({ tags, limit = 5, alignment }) => {
  const classes = useStyles()

  if (!tags) {
    return null
  }

  const reverseTagsList = [...tags].reverse()
  const tagsToShow = tags.length > limit ? reverseTagsList.slice(0, limit - 1) : reverseTagsList

  return (
    <div className={cl(classes.root, alignment)}>
      {tagsToShow.map((tag) => (
        <TagLabelChip key={tag.id} label={tag.name} />
      ))}
      {tags.length > limit && (
        <TagLabelChip label={'...'} />
      )}
    </div>
  )
}

PictureTagsList.propTypes = {
  tags: array.isRequired,
  limit: number,
  alignment: string,
}

export default PictureTagsList

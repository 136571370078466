import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

export const NavWrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.sky[100]};
  padding: 0 16px;
  height: ${({ theme }) => theme.nav.mobileHeight};
  width: 100%;
  position: fixed;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 48px;
    z-index: ${({ theme }) => theme.zIndex.modal};
  }
`

export const InnerWrap = styled(Box)`
  --side-width: 24px;

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    --side-width: 48px;
    grid-template-columns: var(--side-width) 1fr minmax(var(--side-width), auto);
    min-height: 48px;
  }
`

export const LeftSide = styled('div')`
  position: relative;
  z-index: 1;
`
export const Center = styled('div')`
  text-align: center;
  width: 100%;
  position: absolute;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    width: auto;
  }
`
export const RightSide = styled('div')`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
  }
`

export const ActionButton  = styled(Button)`
  margin-top: 8px;
  margin-bottom: 8px;
  height: auto;
  min-width: auto;

  @media screen and (orientation: landscape) {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`

export const CloseButtonWrap = styled(Box)`
  align-self: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-self: flex-start;
    position: absolute;
    right: 0;
    width: 48px;
    height: 48px;
  }
`

export const CloseButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing(-1)};

  &:hover {
    background-color: transparent;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;
    height: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`

import axios from 'axios'
import getConfig from 'next/config'

import { SESSION_COOKIE_NAME } from '@/lib/cookies'
import { convertToQueryString } from '@/lib/url'

const { publicRuntimeConfig } = getConfig()

class Api {
  constructor() {
    this.agent = axios.create({
      baseURL: publicRuntimeConfig?.NEXT_PUBLIC_API_URL || process.env.NEXT_PUBLIC_API_URL,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Key-Inflection': 'camel',
      },
      withCredentials: true,
    })
  }

  setCookie(cookie) {
    this.agent.defaults.headers.common.Cookie = `${SESSION_COOKIE_NAME}=${cookie}`
  }

  setCsrfToken(token) {
    if (!token) return

    const headerName = 'X-CSRF-Token'
    const { delete: deleteHeaders, patch, post, put } = this.agent.defaults.headers
    deleteHeaders[headerName] = patch[headerName] = post[headerName] = put[headerName] = token
  }

  // NOTE: legacy response handler defaulting to true when not explicitly set
  async handleRequest(config, opts = { legacyResponse: true }) {
    try {
      const response = await this.agent(config)

      return opts.legacyResponse ? { response } : response
    } catch (error) {
      if (axios.isCancel(error)) return {}

      console.error('api request error------>', error)

      // handle unauthenticated requests
      if (error.response && error.response.status === 401 && typeof window !== 'undefined') {
        const options = {
          error: 'Session Expired please sign in.',
          redirect: window.location.pathname,
        }

        window.location.replace(`/signin${convertToQueryString(options)}`)
        if (opts.legacyResponse) {
          return {
            error: new Error(options.error),
          }
        } else {
          throw error
        }
      } else if (opts.legacyResponse) {
        return { error }
      } else {
        throw error
      }
    }
  }

  /*
  NOTE:
  this section responds differently
  returns response.data object not nested in another object
  throws error if caught
  */

  delete(config, opts) {
    return this.handleRequest({ ...config, method: 'delete' }, {
      legacyResponse: false,
      ...opts,
    })
  }

  get(config, opts) {
    return this.handleRequest({ ...config, method: 'get' }, {
      legacyResponse: false,
      ...opts,
    })
  }

  patch(config, opts) {
    return this.handleRequest({ ...config, method: 'patch' }, {
      legacyResponse: false,
      ...opts,
    })
  }

  post(config, opts) {
    return this.handleRequest({ ...config, method: 'post' }, {
      legacyResponse: false,
      ...opts,
    })
  }

  put(config, opts) {
    return this.handleRequest({ ...config, method: 'put' }, {
      legacyResponse: false,
      ...opts,
    })
  }
}

const instance = new Api()

export default instance

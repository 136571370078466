import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(IconButton)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: transparent;
  fill: currentColor;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: currentColor;
  padding: 0;

  &.Mui-active {
    background: rgba(255, 255, 255, 0.10);
  }

  .MuiSvgIcon-root {
    font-size: 1.75rem;
  }

  &:hover, &:focus {
    background: rgba(255, 255, 255, 0.03);
  }
`

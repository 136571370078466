import { useEffect, useReducer } from 'react'

const imageLoadingReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      }
    case 'complete':
      return {
        src: action.src,
        loading: false,
        error: null,
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      throw new Error()
  }
}

export const fetchImage = (src, callback) => new Promise(((resolve, reject) => {
  const tester = new Image()
  tester.onload = resolve
  tester.onerror = reject
  callback && callback(tester)
  tester.src = src
}))

const useSilentImageLoading = (initialSrc, onLoad) => {
  const [state, dispatch] = useReducer(imageLoadingReducer, {
    src: initialSrc,
    loading: true,
    error: null,
  })

  if (typeof window === 'undefined') {
    return state
  }

  // TODO fix lint error
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetchImage(initialSrc).then(() => {
      dispatch({
        type: 'complete',
        src: initialSrc,
      })
      onLoad?.()
    }).catch(() => {
      dispatch({
        type: 'error',
        error: 'Error Loading Image',
      })
    })
  }, [initialSrc, onLoad])

  return state
}

export default useSilentImageLoading

import { CropperFade } from 'react-advanced-cropper'
import Div100vh from 'react-div-100vh'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { alpha, styled } from '@mui/material/styles'

import LoadingSpinner from 'components/LoadingSpinner'
import theme from 'styles/theme'

import { IMAGE_EDITOR_VARIANT } from './NewImageEditor.constants'

export const CropImageStyle = styled('div')`
  flex: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 84px calc(100% - 84px - 56px) 56px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: 108px calc(100% - 108px - 52px) 52px;
  }
`

export const Spinner = styled(LoadingSpinner)`
  &.fixed {
    background-color: rgba(16, 35, 113, 0.95);
  }

  svg {
    stroke: rgba(255, 255, 255, 0.6);
  }
`

export const StyledCropperWrapper = styled(Box)`
  --border-width: 2px;
  --border-color: ${({ theme }) => theme.colors.sky[700]};

  &.advanced-cropper {
    padding-top: 8px;
    padding-bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background: transparent;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .advanced-cropper-source {
    display: none;
  }

  .advanced-cropper-background-image {
    will-change: transform;
  }

  .advanced-cropper-stencil-overlay {
    color: rgba(255, 255, 255, 0.05);
    box-shadow: ${({ variant }) => variant === IMAGE_EDITOR_VARIANT.CROP ? '0 0 0 1000px currentColor' : 'unset'};
  }

  .advanced-cropper-simple-line, .advanced-cropper-simple-line--hover {
    border-color: var(--border-color);
  }

  .advanced-cropper-simple-line--east {
    border-right-width: var(--border-width);
  }

  .advanced-cropper-simple-line--west {
    border-left-width: var(--border-width);
  }

  .advanced-cropper-simple-line--north {
    border-top-width: var(--border-width);
  }

  .advanced-cropper-simple-line--south {
    border-bottom-width: var(--border-width);
  }

  .advanced-cropper-handler-wrapper {
    width: 24px;
    height: 24px;
  }

  .advanced-cropper-simple-handler {
    background-color: unset;
  }

  .advanced-cropper-bounding-box__handler-wrapper--north .advanced-cropper-simple-handler,
  .advanced-cropper-bounding-box__handler-wrapper--south .advanced-cropper-simple-handler {
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='3' viewBox='0 0 24 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='3' fill='%23009FFF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  .advanced-cropper-bounding-box__handler-wrapper--east .advanced-cropper-simple-handler,
  .advanced-cropper-bounding-box__handler-wrapper--west .advanced-cropper-simple-handler {
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='24' viewBox='0 0 3 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3' width='24' height='3' transform='rotate(90 3 0)' fill='%23009FFF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }

  .advanced-cropper-simple-handler--east-north.advanced-cropper-simple-handler,
  .advanced-cropper-simple-handler--west-north.advanced-cropper-simple-handler,
  .advanced-cropper-simple-handler--east-south.advanced-cropper-simple-handler,
  .advanced-cropper-simple-handler--west-south.advanced-cropper-simple-handler {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: -10px 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='3' transform='matrix(-1 0 0 1 21 0)' fill='%23009FFF'/%3E%3Crect width='23' height='3' transform='matrix(4.37114e-08 -1 -1 -4.37114e-08 24 23)' fill='%23009FFF'/%3E%3C/svg%3E%0A");
  }

  .advanced-cropper-simple-handler--east-north.advanced-cropper-simple-handler {
    transform: rotate(0deg)
  }

  .advanced-cropper-simple-handler--west-north.advanced-cropper-simple-handler {
    transform: rotate(-90deg)
  }

  .advanced-cropper-simple-handler--west-south.advanced-cropper-simple-handler {
    transform: rotate(-180deg)
  }

  .advanced-cropper-simple-handler--east-south.advanced-cropper-simple-handler {
    transform: rotate(90deg)
  }

  .advanced-cropper-bounding-box__handler-wrapper--north .advanced-cropper-simple-handler {
    background-position: center calc(50% - 2px);
  }

  .advanced-cropper-bounding-box__handler-wrapper--east .advanced-cropper-simple-handler {
    background-position: calc(50% + 1px) center;
  }

  .advanced-cropper-bounding-box__handler-wrapper--west .advanced-cropper-simple-handler {
    background-position: calc(50% - 2px) center;
  }
`

export const AdjustSliderTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: 600;
`

export const StyledCropperFade = styled(CropperFade)`
  flex-grow: 1;
  min-height: 0;
  max-height: 100%;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const AdjustNavigationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 44px;
  height: 126px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

export const CropImageWrapper = styled(Div100vh)`
  display: flex;
  background-color: rgba(16, 35, 113, 0.95);
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  max-height: calc(100vh - env(safe-area-inset-bottom));
  font-family: ${theme.typography.type.primary};
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  padding: 0;
  right: 16px;
  top: 16px;
  z-index: 99999;

  ${({ theme }) => theme.breakpoints.up('md')} {
    right: 40px;
    top: 28px;
  }
`

export const Heading = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0 16px;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 40px;
  }
`

export const EditorTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.size.md};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.typography.size.xl};
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 1.4;
  }
`

export const EditorSubtitle = styled(Typography)`
  font-size: 0.8125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: ${({ theme }) => theme.typography.size.sm};
  }
`

export const ActionsStack = styled(Stack)`
  width: 100%;
  flex: 1;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 336px;
  }
`

export const ResetButton = styled(Button)`
  flex-grow: 1;
  background-color: ${({ theme }) => alpha(theme.colors.sky[700], 0.1)};
  border-color: ${({ theme }) => theme.colors.sky[700]};
  
  &.Mui-disabled {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
  }
`

export const ImageEditorCropperContainer = styled('div')`
  max-height: 100%;
  background: transparent;
  position: relative;
  flex: 1;
  overflow: hidden;
  display: flex;
  padding: 2px;
  align-self: center;
  max-width: calc(100vw - 32px);
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 800px;
  }

  .advanced-cropper-stencil-overlay {
    transition: 0.5s;
  }

  .advanced-cropper-stencil-overlay--faded {
    color: rgba(0, 0, 0, 0.9);
  }
`

import numeral from 'numeral'

import messages from './messages'

const findMessage = (path) => {
  let namespace = messages
  path.split('.').forEach((segment) => {
    const value = namespace[segment]
    if (value) {
      namespace = value
    } else {
      return ''
    }
  })
  return namespace
}

/**
 *
 * @param {string} message
 * @param {object} opts
 * @returns {string}
 */
export const interpolateMessage = (message, opts) => {
  if (typeof message === 'string') {
    let cumulatedMessage = message

    Object.keys(opts).forEach((key) => {
      cumulatedMessage = cumulatedMessage.replace(`{${key}}`, opts[key])
    })

    return cumulatedMessage
  } else {
    return message(opts)
  }
}

/**
 *
 * @param {number} count
 * @param {string} singularForm
 * @param {string} pluralForm
 * @returns {string}
 */
export const pluralize = (count, singularForm, pluralForm = `${singularForm}s`) => (
  count > 1 ? pluralForm : singularForm
)

/**
 * @param {string} key
 * @param {object,null} opts
 * @returns {string}
 */
export const intl = (key, opts = null) => {
  const message = findMessage(key)
  if (!opts) return message

  return interpolateMessage(message, opts)
}

/**
 * Formats given value using predefined `type` pattern
 *
 * @param {number} value
 * @param {string} type
 * @returns {string}
 */
export const format = (value, type = 'money') => {
  switch (type) {
    case 'money':
      return numeral(value).format('$0,0.00')
    default:
      throw `Invalid format type - ${type}`
  }
}

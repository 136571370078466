import { styled } from '@mui/material/styles'

export const LazyLoadWrap = styled('div')`
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 100%;
  height: 100%;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    height: 0;
    padding-top: 80%;
  }
`

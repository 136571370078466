import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const PaperV2Styled = styled(Paper)`
  &.MuiPaper-root {
    margin-top: 4px;
    box-shadow: ${({ theme }) => theme.boxShadow.small};

    .MuiAutocomplete-listbox {
      border: 1px solid ${({ theme }) => theme.colors.sky[300]};
      border-radius: 8px;
      max-height: 168px;

      ${({ theme }) => theme.breakpoints.up('md')} {
        max-height: 138px;
      }
    }

    .MuiAutocomplete-option {
      color: ${({ theme }) => theme.colors.black};
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-weight: 500;
      font-size: 1rem;
      min-height: 44px;
      line-height: 1.5;

      ${({ theme }) => theme.breakpoints.up('md')} {
        min-height: 36px;
        font-size: 0.875rem;
        line-height: 1.4;
      }
    }
  }
`

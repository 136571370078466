// Add a utility function to calculate max dimensions while maintaining aspect ratio
export const calculateMaxDimensions = (width, height, maxArea) => {
  const aspectRatio = width / height
  let maxWidth = Math.sqrt(maxArea * aspectRatio)
  let maxHeight = maxWidth / aspectRatio

  if (maxWidth > width || maxHeight > height) {
    maxWidth = width
    maxHeight = height
  }
  // earlier we had to set canvas size is divisible by 4 as Webgl restrictions for large images, but
  // at new image editor we divide large images by tiles 512x512, so
  // it's not critical  to canvas size to be divisible by 4

  return { maxWidth, maxHeight }
}

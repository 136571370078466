import { CancelToken } from 'axios'
import { thunk } from 'easy-peasy'

import api from 'utils/api'

let updateBookTokens = new Map()

const booksService = {
  readDetailedBook: thunk(async (actions, id) => {
    const config = {
      method: 'get',
      url: `/api/v3/user/books/${id}`,
    }

    try {
      const response = await api.get(config)

      actions.setBook(response.data.data)

      return response.data.data

    } catch (error) {
      actions.setError(error)
      return { error }
    }

  }),
  readBooks: thunk(async (actions, params) => {
    actions.setIsLoading(true)
    // axios config object
    const config = {
      method: 'get',
      url: '/api/v3/user/books',
      params,
    }

    const { error, response } = await api.handleRequest(config)
    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setBooks(response.data.data)
      return response.data.data
    }
  }),
  addBookPictures: thunk(async (actions, book, { getState }) => {
    const books = getState().list
    const storeBook = books.find((o) => o.id === book.id)
    const nextPictures = Array.from(new Set([
      ...storeBook.pictureIds,
      ...book.pictures,
    ]))
    actions.updateBook({
      id: book.id,
      pictures: nextPictures,
    })
  }),
  updateBook: thunk(async (actions, { id, pictures: pictureIds, ...updatedBook }) => {
    actions.setBook({
      id,
      ...updatedBook,
    })

    if (updateBookTokens.has(id)) {
      updateBookTokens.get(id).cancel()
    }

    updatedBook.pictures = pictureIds

    updateBookTokens.set(id, CancelToken.source())

    // axios config object
    const config = {
      method: 'put',
      url: `/api/v2/user/books/${id}`,
      data: updatedBook,
      cancelToken: updateBookTokens.get(id).token,
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setBook({
        ...response.data.data,
        pictureIds: response.data.data.pictures.map(({ id }) => id),
      })
      return response.data.data
    }
  }),
  sendToCustomerEmail: thunk(async (actions, { id, data }) => {
    try {
      await api.post({
        url: `/api/v2/user/books/${id}/send_to_customer`,
        data,
      })
    } catch (error) {
      console.log(error)
    }
  }),
}

export default booksService

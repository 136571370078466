import { createTheme } from '@mui/material/styles'
import hexToRgba from 'hex-to-rgba'

import theme from './theme'

const defaultTheme = createTheme()

const DatePickerButtonStyles = {
  fontSize: '0.875rem',
  lineHeight: '0.875rem',
  fontWeight: 500,

  '&:hover': {
    backgroundColor: theme.colors.gray[100],
  },

  '&.Mui-selected': {
    color: theme.colors.white,
    backgroundColor: theme.colors.sky[700],

    '&:focus': {
      backgroundColor: theme.colors.sky[700],
    },
    '&:hover': {
      backgroundColor: theme.colors.sky[700],
      color: theme.colors.white,
    },
  },
}

const overrides = {
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.colors.gray[500],
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
        marginTop: 0,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      primary: {
        color: theme.colors.white,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.colors.gray[700],
        fontSize: '0.75rem',
        fontWeight: 500,
        marginBottom: '4px',
        lineHeight: '1.4em',
      }),
    },
    variants: [
      {
        props: { variant: 'new' },
        style: {
          marginBottom: 0,
        },
      },
    ],
  },
  MuiPickersDay: {
    styleOverrides: {
      root: DatePickerButtonStyles,
      today: {
        background: theme.colors.sky[300],
        borderColor: `${theme.colors.sky[500]}!important`,
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      yearButton: DatePickerButtonStyles,
    },
  },
  MuiPickersMonth: {
    styleOverrides: {
      monthButton: DatePickerButtonStyles,
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      labelContainer: {
        fontWeight: 600,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        /**
         * TODO: once we figure out why official fix doesn't work for us rework to use
         * ownerState.variant instead
         * https://github.com/mui/material-ui/issues/34404
         */
        '&.variant-new': {
          border: `1px solid ${theme.colors.sky[300]}`,
          boxShadow: theme.boxShadow.small,
        },
      }),
    },
  },
}

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xxs: 376,
      xs: 414,
      sm: 768,
      md: 992, // 810 or 1024?
      lg: 1280,
      xl: 1564, // 1440?
      xxl: 1920, // 1680?
    },
  },
  palette: {
    primary: {
      light: theme.colors.lightblue,
      main: theme.colors.blue,
      dark: theme.colors.darkblue,
    },
    secondary: {
      light: theme.colors.lightgray,
      main: theme.colors.medgray,
      dark: theme.colors.darkgray,
    },
    darkblue: {
      main: theme.colors.darkblue,
    },
  },
  typography: {
    fontFamily: theme.typography.type.montserrat,
    h1: {
      fontSize: theme.typography.size.xl4,
      fontWeight: 600,
      fontFamily: theme.typography.type.montserrat,
      fontStyle: 'normal',
      lineHeight: 1,
    },
    secondary: {
      fontFamily: theme.typography.type.montserrat,
    },
    h6: {
      color: theme.colors.primary.dark,
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '700',
      fontSize: '1.125rem',
    },
    subtitle1: {
      color: theme.colors.black,
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.6,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    caption: {
      color: theme.colors.grey.darkgray,
      fontFamily: '"Montserrat", sans-serif',
      fontSize: '0.8125rem',
      lineHeight: 1.4,
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  permanentDrawer: {
    width: '90px',
    zIndex: 10,
  },
  temporaryLeftDrawer: {
    width: '260px',
    zIndex: 9,
  },
  temporaryRightDrawer: {
    width: '260px',
    zIndex: 7,
  },
  pagesDrawer: {
    buttonHeight: 22,
    closedHeight: 138,
    topPadding: 8,
    zIndex: 8,
  },
  sortControlPopper: {
    width: '260px',
  },
  menuPopper: {
    width: '180px',
  },
  wizard: {
    header: {
      height: '48px',
      mobileHeight: '56px',
    },
    sidebar: {
      width: '300px',
    },
  },
  shape: {
    borderRadius: 8,
  },
  appBar: {
    height: 54,
  },
  modalBottomBar: {
    height: 68,
  },
  imageGridItem: {
    paddingTop: 4,
    label: {
      height: 17,
      bottomOffset: 2,
    },
    tags: {
      height: 22,
    },
  },
  boxShadow: {
    small: '0 4px 8px 0 rgba(11, 39, 158, 0.04)',
    large: '0 10px 25px 0 rgba(11, 39, 158, 0.11)',
    fab: '2px 8px 15px 0 rgba(11, 39, 158, 0.10)',
    activeFab: '2px 8px 15px 0 rgba(11, 39, 158, 0.15)',
  },
  dropShadow: {
    large: 'drop-shadow(0 10px 25px rgba(11, 39, 158, 0.11))',
  },
  pageContent: {
    mobilePaddingTop: 28,
    mobilePaddingBottom: 64,
    paddingTop: 40,
    paddingBottom: 96,
  },
})

const StatusChipStyles = {
  height: '26px',
  [muiTheme.breakpoints.up('md')]: {
    height: '24px',
  },
  '.MuiChip-label': {
    fontSize: '0.8125rem',
    lineHeight: 1.4,
    padding: '3px 10px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '0.75rem',
      lineHeight: 1.6,
      padding: '0 12px',
    },
  },
}

muiTheme.typography.size = theme.typography.size
muiTheme.colors = theme.colors
muiTheme.nav = theme.nav
muiTheme.components = {
  ...overrides,
  MuiTypography: {
    variants: [
      {
        props: { variant: 'strong' },
        style: {
          fontWeight: 700,
          color: muiTheme.colors.black,
        },
      },
    ],
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        letterSpacing: 'normal',
        height: 44,
        borderRadius: 8,
        padding: '0 1.25rem',
        whiteSpace: 'nowrap',
        fontWeight: 600,
        lineHeight: 1.4,
      },
      textPrimary: {
        justifyContent: 'flex-start',
      },
      containedPrimary: {
        backgroundColor: muiTheme.colors.sky[700],
        color: muiTheme.colors.white,
        boxShadow: 'none',

        '&:hover, &:active': {
          backgroundColor: muiTheme.colors.sky[900],
          boxShadow: 'none',
        },
      },
      containedSizeLarge: {
        fontSize: '1rem',
      },
    },
    variants: [
      {
        props: { variant: 'new-contained-white' },
        style: {
          backgroundColor: 'white',
          borderRadius: 8,
          border: `1px solid ${muiTheme.colors.sky[300]}`,
          color: muiTheme.colors.gray[900],
          padding: '10px 20px',
          height: 44,

          '&:hover, &:active': {
            border: `1px solid ${muiTheme.colors.sky[500]}`,
            backgroundColor: muiTheme.colors.sky[300],
          },
          '&.Mui-disabled': {
            color: muiTheme.colors.gray[900],
            opacity: 0.3,
          },
        },
      },
      {
        props: { variant: 'new-text-white' },
        style: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          border: 'none',
          color: muiTheme.colors.white,
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.4,
          padding: '13px 16px',
          height: 48,

          '&:hover, &:active': {
            opacity: 0.7,
          },
          '&.Mui-disabled': {
            color: muiTheme.colors.white,
            opacity: 0.4,
          },
          [muiTheme.breakpoints.up('md')]: {
            fontSize: '0.875rem',
            padding: '0 8px',
            height: 36,
          },
        },
      },
      {
        props: { variant: 'new-text-red' },
        style: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          border: 'none',
          color: muiTheme.colors.newRed[700],
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.4,
          padding: '13px 16px',
          height: 48,

          '&:hover, &:active': {
            color: muiTheme.colors.newRed[900],
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            opacity: 0.4,
          },
          [muiTheme.breakpoints.up('md')]: {
            fontSize: '0.875rem',
            padding: '0 8px',
            height: 36,
          },
        },
      },
      {
        props: { variant: 'nav-link' },
        style: {
          backgroundColor: 'transparent',
          color: muiTheme.colors.gray[700],
          padding: muiTheme.spacing(0, 0, 0, 1),
          marginLeft: muiTheme.spacing(-1),
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: 1.5,
          minWidth: 'unset',
          height: 48,

          '&:hover, &:active': {
            backgroundColor: 'transparent',
            color: muiTheme.colors.black,
          },
          '& .MuiButton-startIcon': {
            margin: 0,
            '.MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          },
          [muiTheme.breakpoints.up('md')]: {
            height: 36,
            fontSize: '0.875rem',
            lineHeight: 1.4,
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          },
        },
      },
      {
        props: { variant: 'new-contained-primary' },
        style: {
          backgroundColor: muiTheme.colors.sky[700],
          color: muiTheme.colors.white,
          height: 48,
          fontSize: '1rem',
          '&:active, &:hover': {
            backgroundColor: muiTheme.colors.sky[900],
          },
          '&.Mui-disabled': {
            color: muiTheme.colors.white,
            backgroundColor: muiTheme.colors.sky[500],
          },
          [muiTheme.breakpoints.up('md')]: {
            height: 36,
            fontSize: '0.875rem',
            borderRadius: 6,
          },
        },
      },
      {
        props: { variant: 'new-outlined-primary' },
        style: {
          backgroundColor: muiTheme.colors.white,
          border: `1px solid ${muiTheme.colors.sky[500]}`,
          borderRadius: 8,
          color: muiTheme.colors.sky[700],
          height: 48,
          fontSize: '1rem',
          '&:active, &:hover': {
            backgroundColor: muiTheme.colors.white,
            border: `1px solid ${muiTheme.colors.sky[900]}`,
            color: muiTheme.colors.sky[900],
          },
          '&.Mui-disabled': {
            backgroundColor: muiTheme.colors.white,
            border: `1px solid ${muiTheme.colors.sky[500]}`,
            color: muiTheme.colors.sky[500],
          },
          [muiTheme.breakpoints.up('md')]: {
            height: 36,
            fontSize: '0.875rem',
            borderRadius: 6,
          },
        },
      },
      {
        props: { variant: 'new-text-primary' },
        style: {
          color: muiTheme.colors.sky[700],
          height: 48,
          fontSize: '1rem',
          '&:hover, &:active': {
            backgroundColor: 'transparent',
            color: muiTheme.colors.sky[900],
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: muiTheme.colors.sky[500],
            backgroundColor: 'transparent',
          },
          [muiTheme.breakpoints.up('md')]: {
            height: 36,
            fontSize: '0.875rem',
          },
        },
      },
      {
        props: { variant: 'new-text-gray' },
        style: {
          color: muiTheme.colors.gray[900],
          height: 48,
          fontSize: '1rem',
          padding: 0,
          '&:hover, &:active': {
            backgroundColor: 'transparent',
            color: muiTheme.colors.sky[900],
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: muiTheme.colors.gray[900],
            backgroundColor: 'transparent',
            opacity: 0.3,
          },
          [muiTheme.breakpoints.up('md')]: {
            height: 36,
            fontSize: '0.875rem',
          },
        },
      },
    ],
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: muiTheme.colors.gray[500],
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: muiTheme.colors.white,
        boxShadow: '0 0 15px 0 rgba(16, 35, 113, 0.15)',
        zIndex: muiTheme.zIndex.drawer + 1,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: muiTheme.appBar.height,
        [muiTheme.breakpoints.up('sm')]: {
          minHeight: muiTheme.appBar.height,
        },
        [muiTheme.breakpoints.up('md')]: {
          padding: '8px 20px',
        },
      },
    },
    variants: [
      {
        props: { variant: 'tall' },
        style: {
          minHeight: muiTheme.appBar.height,
          [muiTheme.breakpoints.up('sm')]: {
            minHeight: theme.nav.height,
          },
        },
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: muiTheme.colors.gray[50],
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      anchorOriginTopCenter: {
        top: muiTheme.appBar.height + 20,
        [muiTheme.breakpoints.up('sm')]: {
          top: muiTheme.appBar.height + 24,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: '44px',
        padding: '12px 24px',
        boxShadow: muiTheme.boxShadow.small,
      },
      filledWarning: {
        backgroundColor: muiTheme.colors.orange[500],
      },
      filledError: {
        backgroundColor: muiTheme.colors.newRed[700],
      },
      filledSuccess: {
        backgroundColor: muiTheme.colors.green[700],
      },
      filledInfo: {
        backgroundColor: muiTheme.colors.gray[100],
        color: muiTheme.colors.gray[900],
      },
      message: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1,
        padding: '3px 0',
      },
      icon: {
        padding: 0,
        marginRight: '8px',
        opacity: 1,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        color: muiTheme.colors.black,
      },
      label: {
        [muiTheme.breakpoints.up('md')]: {
          fontSize: '0.875rem',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        color: ownerState.value ? theme.colors.black : theme.colors.gray[500],
        fontSize: '0.75rem',
        backgroundColor: theme.colors.white,
        boxShadow: muiTheme.boxShadow.small,
      }),
      outlined: {
        paddingTop: 9,
        paddingBottom: 8,
      },
      icon: {
        color: theme.colors.gray[500],
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: '#EAF3FB',
        transform: 'none',
        height: '100%',
        width: '100%',
        borderRadius: '1px',
        '&::after': {
          background: `linear-gradient(90deg, transparent, ${muiTheme.colors.sky[200]}, transparent)`,
        },
      },
      rounded: {
        borderRadius: '4px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const isNew = ownerState.variant === 'new' || ownerState.$variant === 'new'

        return {
          color: isNew ? theme.colors.black : theme.colors.darkblue,
          fontSize: '1rem',
          fontWeight: 500,
          margin: isNew ? '0 0 4px 0' : 0,
          '&:hover:not(.Mui-focused, .Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: isNew ? theme.colors.sky[500] : 'hsl(0,0%,70%)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            color: theme.colors.gray[300],
            borderColor: theme.colors.gray[300],
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.newRed[700],
            color: theme.colors.newRed[700],
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: isNew ? 7 : 14,
          },
          '& ::placeholder': {
            color: theme.colors.gray[500],
            fontWeight: 500,
            opacity: 1,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: '0.875rem',
          },
        }
      },
      notchedOutline: ({ ownerState, theme }) => ({
        borderColor: ownerState.variant === 'new' || ownerState.$variant === 'new'
          ? theme.colors.sky[300]
          : theme.colors.gray[300],
      }),
      input: ({ ownerState, theme }) => ({
        padding: ownerState.variant === 'new' || ownerState.$variant === 'new' ? '13px 16px' : '11px 14px',
        height: '22px',
        [theme.breakpoints.up('md')]: {
          padding: ownerState.variant === 'new' ? '10px 12px' : '11px 14px',
          height: ownerState.variant === 'new' ? '20px' : '22px',
        },
      }),
      multiline: ({ theme }) => ({
        padding: 0,

        [theme.breakpoints.up('md')]: {
          '& textarea': {
            padding: theme.spacing(1.25, 1.5),
          },
        },
      }),
      inputAdornedEnd: {
        paddingRight: 4,
      },
    },
    variants: [
      {
        props: { variant: 'new' },
        style: {
          backgroundColor: 'white',
          borderRadius: 8,
          boxShadow: muiTheme.boxShadow.small,
        },
      },
    ],
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          padding: '5.5px 9px',
          [muiTheme.breakpoints.up('md')]: {
            padding: '3.5px 9px',
          },
        },
      },
      popper: {
        zIndex: 9999999,
      },
      popupIndicator: {
        color: muiTheme.colors.blue,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.colors.gray[700],
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.4,
        marginBottom: '4px',
        [theme.breakpoints.up('md')]: {
          fontSize: '0.75rem',
        },
      }),
    },
    variants: [
      {
        props: { variant: 'new' },
        style: {
          marginBottom: 0,
        },
      },
    ],
  },
  MuiCard: {
    styleOverrides: {
      root: {
        color: muiTheme.colors.gray[700],
        border: `1px solid ${muiTheme.colors.sky[300]}`,
        boxShadow: `0 4px 4px 0 ${hexToRgba(muiTheme.colors.newPaperShadow, 0.02)}`,
        padding: '8px 16px',
        transition: `${muiTheme.transitions.create(
          ['box-shadow', 'background-color'],
          { duration: muiTheme.transitions.duration.short },
        )}`,
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        color: muiTheme.colors.gray[500],
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1,
      },
    },
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'gray' },
        style:{
          ...StatusChipStyles,
          backgroundColor: muiTheme.colors.gray[100],
          '.MuiChip-label': {
            ...StatusChipStyles['.MuiChip-label'],
            color: muiTheme.colors.gray[700],
            fontWeight: 500,
          },
        },
      },
      {
        props: { variant: 'yellow' },
        style:{
          ...StatusChipStyles,
          backgroundColor: muiTheme.colors.yellow[100],
          '.MuiChip-label': {
            ...StatusChipStyles['.MuiChip-label'],
            color: muiTheme.colors.yellow[700],
            fontWeight: 500,
          },
        },
      },
      {
        props: { variant: 'pink' },
        style:{
          ...StatusChipStyles,
          backgroundColor: muiTheme.colors.pink[100],
          '.MuiChip-label': {
            ...StatusChipStyles['.MuiChip-label'],
            color: muiTheme.colors.pink[700],
            fontWeight: 500,
          },
        },
      },
      {
        props: { variant: 'purple' },
        style:{
          ...StatusChipStyles,
          backgroundColor: muiTheme.colors.purple[100],
          '.MuiChip-label': {
            ...StatusChipStyles['.MuiChip-label'],
            color: muiTheme.colors.purple[700],
            fontWeight: 500,
          },
        },
      },
      {
        props: { variant: 'green' },
        style:{
          ...StatusChipStyles,
          backgroundColor: muiTheme.colors.green[100],
          '.MuiChip-label': {
            ...StatusChipStyles['.MuiChip-label'],
            color: muiTheme.colors.green[700],
            fontWeight: 500,
          },
        },
      },
    ],
  },
}

muiTheme.typography.h2 = {
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1.75rem', // 28px
  fontStyle: 'normal',
  fontWeight: 600, // TODO change to 700 after styles migration
  lineHeight: 1.4,
  letterSpacing: 0,
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '2rem', // 32px
  },
}

muiTheme.typography.h3 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1.5rem', // 24px
  fontStyle: 'normal',
  fontWeight: 600, // TODO change to 700 after styles migration
  lineHeight: 1.4,
  letterSpacing: 0,
  [muiTheme.breakpoints.up('md')]: {
    fontSize: '1.75rem', // 28px
  },
}

muiTheme.typography.h4 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1.25rem', // 20px
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 1.4,
  letterSpacing: 0,
}

muiTheme.typography.h5 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1.125rem', // 18px
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 1.4, // 25.2px
  letterSpacing: 0,
}

muiTheme.typography.subtitle2 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '0.875rem', // 14px
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 1.4, // 19.6px
  letterSpacing: 0,
}

// NOTE: "body1" is a legacy variant.
// Always use "body2", instead of "body1"
muiTheme.typography.body1 = {
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1rem', // 16px
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 1.6, // 25.6px
  letterSpacing: 0,
}

muiTheme.typography.body2 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '1rem', // 16px
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 1.5, // 24px
  letterSpacing: 0,
}

muiTheme.typography.body3 = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '0.875rem', // 14px
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 1.6, // 22.4px
  letterSpacing: 0,
}

muiTheme.typography.caption = {
  color: muiTheme.colors.black,
  fontFamily: theme.typography.type.montserrat,
  fontSize: '0.8125rem', // 13px
  fontStyle: 'normal',
  lineHeight: 1.4, // 18.2px
  fontWeight: 500,
  letterSpacing: 0,
}

muiTheme.typography.link = {
  color: muiTheme.colors.sky[700],
  fontWeight: 600,
  ['&:hover']: {
    color: muiTheme.colors.sky[900],
  },
}
// console.log(muiTheme)

// Combinations

muiTheme.typography.bodySubtitle = {
  ...muiTheme.typography.body2,
  [muiTheme.breakpoints.up('md')]: {
    fontSize: muiTheme.typography.subtitle2.fontSize,
    lineHeight: muiTheme.typography.subtitle2.lineHeight,
  },
}

muiTheme.typography.bodyCaption = {
  ...muiTheme.typography.body2,
  [muiTheme.breakpoints.up('md')]: {
    fontSize: muiTheme.typography.caption.fontSize,
    lineHeight: muiTheme.typography.caption.lineHeight,
  },
}

export default muiTheme

import { thunk } from 'easy-peasy'

import api from 'utils/api'
import { BOX_ORDER_STATUS, ORDER_TYPE } from 'utils/constants'

const ordersApiUrls = {
  getOrder: (orderType, orderId) => `/api/v3/user/orders/${orderType}/${orderId}`,
  getOrderHistory: (orderType, orderId) => `/api/v3/user/orders/${orderType}/${orderId}/statuses`,
  getOrders: () => `/api/v3/user/orders`,
  getReceipt: (orderType, orderId, receiptUuid) => `/api/v3/user/orders/${orderType}/${orderId}/payments/${receiptUuid}`,
}

const ordersService = {
  readOrders: thunk(async (actions, params) => {
    actions.setIsLoading(true)

    const config = {
      method: 'get',
      url: ordersApiUrls.getOrders(),
      params,
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading(false)

    if (error) {
      error.isV2 = true
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setOrders(response.data.data)
      return response.data.data
    }
  }),
  readOrderById: thunk(async (actions, { type, id }) => {
    actions.setIsLoading(true)

    const config = {
      method: 'get',
      url: ordersApiUrls.getOrder(type, id),
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading(false)

    if (error) {
      error.isV2 = true
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setActiveOrder(response.data.data)
      return response.data.data
    }
  }),
  readReceiptByUuid: thunk(async (actions, { type, id, uuid }) => {
    actions.setIsLoading(true)

    const config = {
      method: 'get',
      url: ordersApiUrls.getReceipt(type, id, uuid),
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading(false)

    if (error) {
      error.isV2 = true
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setActiveReceipt(response.data.data)
      return response.data.data
    }
  }),
  readOrderHistory: thunk(async (actions, { orderType, type, id }) => {
    actions.setIsLoading(true)

    const config = {
      method: 'get',
      url: ordersApiUrls.getOrderHistory(type, id),
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading(false)

    if (error) {
      error.isV2 = true
      actions.setError(error)
      return { error }
    }

    if (response) {
      const history = response.data.data
      const isAddArtOrder = orderType === ORDER_TYPE.ADD_ART

      // NOTE: Filter "Order Placed" status for Add Art order
      if (isAddArtOrder) {
        if (history[0]?.status === BOX_ORDER_STATUS.ORDER_PLACED) {
          history.shift()
        }
      }
      actions.setStatusHistory(history)
      return history
    }
  }),
}

export default ordersService

import { styled } from '@mui/material/styles'
import { transitions } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  background: ${({ theme }) => theme.colors.white};
  display: none;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  padding: 1rem 1rem 5rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  ${transitions(['transform'], '0.3s ease-in-out')};
  z-index: 1301;
  will-change: display;

  &.active {
    display: flex;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    width: 350px;
  }

  .icon-close {
    fill: ${({ theme }) => theme.colors.darkgray};
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
  }

  .labels {
    margin: 1rem 0;
  }

  .details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  }
`

import React from 'react'

import { css, Global } from '@emotion/react'

import theme from 'styles/theme'
import objectToClass from 'utils/objectToClass'

import display from './utils/display'
import typography from './utils/typography'


const generatedStyles = ({ theme }) => css`
  /* Font Sizes */
  ${objectToClass(theme.typography.size, 'font-size')};

  /* Background Colors */
  ${objectToClass(theme.colors, 'background-color', 'bg-')};

  /* Fill Colors */
  ${objectToClass(theme.colors, 'fill')};

  /* Typography Colors */
  ${objectToClass(theme.colors, 'color')};
`

const globalStyles = css`
  :root {
    --app-height: 100%;
  }

  html, body {
    display: flex;
    width: 100%;
    min-height: 100%;
    max-height: var(--app-height);
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    // Note: Helps to have a design-like text display within the app
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: darkgrayscale;
    font-family: "Montserrat", sans-serif;
  }

  html {
    background-color: ${theme.colors.sky[100]};
    // Disable text adjustment (scaling) on Safari iOS on screen rotation
    -webkit-text-size-adjust: none;
  }

  /* Avoid Chrome to see Safari hack */
  /*@supports (-webkit-touch-callout: none) {
    html, body {
      !* The hack for Safari *!
      height: -webkit-fill-available;
    }
  }*/

  #__next {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  ${generatedStyles({ theme })};
  /* Typography */
  ${typography({ theme })};

  /* Display */
  ${display};
`

const GlobalStyles = () => (
  <Global styles={globalStyles} />
)

export default GlobalStyles

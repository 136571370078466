import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'fillColor' && prop !== 'baseColor' })(
  ({ theme, fillColor, baseColor }) => ({
    clipPath: "path('M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z')",
    background: `linear-gradient(to top, ${fillColor || theme.colors.sky[600]} 50%, ${baseColor || theme.colors.sky[300]} 50%)`,
    backgroundSize: '100% 200%',
    backgroundPosition: 'top left',
    transition: 'all .5s ease-out',
    width: '20px',
    height: '20px',
    animation: 'slideUp 1.5s linear infinite',
    '@keyframes slideUp': {
      '0%': {
        backgroundPosition: 'top left',
      },
      '100%': {
        backgroundPosition: 'bottom left',
      },
    },
  }),
)

const AnimatedUploadIcon = ({ baseColor, fillColor }) => {
  return (
    <StyledBox baseColor={baseColor} fillColor={fillColor} />
  )
}

AnimatedUploadIcon.propTypes = {
  baseColor: PropTypes.string,
  fillColor: PropTypes.string,
}

export default AnimatedUploadIcon

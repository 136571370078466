import { styled } from '@mui/material/styles'

export default styled('div')`
  .buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    button {
      margin: 0;
    }
  }

  .segmented-button {
    margin: 10px 0;

    button {
      margin: 0;
      width: 50%;
      border: 0 none;
      z-index: 9;

      &.active {
        z-index: 10;
        border: 1px solid ${(props) => props.theme.colors.clearskyblue};
      }

      &.default {
        border: 1px solid ${(props) => props.theme.colors.lightgray};
      }

      &:hover {
        box-shadow: none;
        transform: none;
      }
    }
  }
`


export const PictureSortStories = styled('div')`
  border: 1px solid black;
  margin: 2rem;
  padding: 2rem;
  width: 500px;
`

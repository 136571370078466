import React, { useEffect, useState } from 'react'

import cl from 'classnames'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { startCase } from 'lodash/string'
import getConfig from 'next/config'

import Modal from 'components/Modal'
import { useGetMembershipQuery } from 'dux/queries/user'
import { useIsMobile } from 'dux/slice/ui'
import theme from 'styles/theme'
import api from 'utils/api'

import { PlanTypes } from './PlanCard'
import PlansCards from './PlansCards'
import { Header, RoundedButton, StyledButtonGroup, UpgradeMembershipStyles } from './UpgradeMembership.style'

const { publicRuntimeConfig: { NEXT_PUBLIC_API_URL } } = getConfig()

const fetchMemberships = async (membership) => {
  const { response } = await api.handleRequest({
    method: 'get',
    url: '/api/v1/user/subscription_plans/list_open',
  })

  const subscription_plans = Object.values(response.data.grouped_plans).flat()
  const plans = response.data.subscriptions.map((sub) => ({
    ...sub,
    bullets: sub?.bullet_points?.split('||'),
    annualBullets: sub?.annual_bullet_points?.split('||'),
    pricing: response.data.grouped_plans[sub.id],
  }))

  const activePlan = subscription_plans.find(({ id }) => id === membership.subscriptionPlanId)
  const subscriptionIndex = plans.findIndex(({ id }) => id === activePlan?.subscription_id)
  return plans.slice(subscriptionIndex === -1 ? 0 : subscriptionIndex)
}

const UpgradeMembership = () => {
  const { isMobile } = useIsMobile()
  const { data: membership } = useGetMembershipQuery()

  const isSmallDesktop = useMediaQuery('@media (max-width:1200px)')
  const [plans, setPlans] = useState([])
  const [planType, setPlanType] = useState(PlanTypes.ANNUAL)

  const isMonthlyPlan = planType === PlanTypes.MONTHLY
  const isCompMembership = !!membership?.compExpirationDate

  useEffect(() => {
    if (!membership) return

    fetchMemberships(membership).then((plans) => setPlans(plans))
  }, [setPlans, membership])

  if (!membership) return null

  const cardsDirection = isMobile || plans.length > 2 && isSmallDesktop ? 'column' : 'row'
  const expirationDate = membership.expirationDate

  return (
    <UpgradeMembershipStyles>
      <Modal className={'modal'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            margin: isMobile ? '64px 0' : '48px',
          }}
        >
          <Box sx={{ padding: isMobile ? '0' : '0 160px' }}>
            <Header className={'darkblue center'}>
              Your {isCompMembership ? 'Free Artkive' : 'Artkive'} Membership Has Expired
            </Header>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '16px 0 28px',
              }}>
              {isCompMembership ? (
                <Typography paragraph sx={{ color: theme.colors.darkgray }}>
                  Join the family! We have memberships for both DIY and Artkive Box customers with great perks.
                </Typography>
              ) : (
                <Typography align={'center'} paragraph sx={{ color: theme.colors.darkgray, margin: 0 }}>
                  Your{' '}
                  <Typography component={'span'} sx={{ fontWeight: 500, color: theme.colors.black }}>
                    {startCase(membership.title)}
                  </Typography>{' '}membership has expired
                  {!!expirationDate && (
                    <>
                      {' '}on{' '}
                      <Typography
                        component={'span'}
                        sx={{ fontWeight: 500, color: theme.colors.black }}
                      >
                        {expirationDate}
                      </Typography>
                    </>
                  )}. You can’t access your account without having membership.
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={{ marginBottom: isMobile ? '48px' : '32px' }}>
            <StyledButtonGroup variant={'contained'} disableElevation>
              <RoundedButton
                className={cl({
                  active: isMonthlyPlan,
                })}
                onClick={() => setPlanType(PlanTypes.MONTHLY)}
              >
                Pay Monthly
              </RoundedButton>
              <RoundedButton
                className={cl({
                  active: !isMonthlyPlan,
                })}
                onClick={() => setPlanType(PlanTypes.ANNUAL)}
              >
                Pay Annually
              </RoundedButton>
            </StyledButtonGroup>
          </Box>

          <Box
            sx={{
              marginBottom: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: cardsDirection,
              padding: isMobile ? '8px' : 0,
            }}
          >
            <PlansCards planType={planType} plans={plans} isCompMembership={isCompMembership} />
          </Box>
          {isCompMembership && (
            <Box sx={{ marginTop: isMobile ? '48px' : '16px' }}>
              <Button
                variant={'outlined'}
                color={'primary'}
                component={'a'}
                href={`${NEXT_PUBLIC_API_URL}/plans`}
                sx={{
                  fontSize: '1rem',
                  width: 220,
                }}
              >
                All Memberships
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </UpgradeMembershipStyles>
  )
}

export default UpgradeMembership

import React from 'react'
import { bool, func, number, string } from 'prop-types'

import classnames from 'classnames'

import icons from './icons'
import IconStyle from './IconStyle'

const defaultSize = 24

/**
 * Icon - Abstracted icon component for ease of use
 */
const Icon = (props) => {
  const {
    name,
    className,
    onClick,
    height = defaultSize,
    width = defaultSize,
    inline = false,
  } = props
  const icon = icons[name]

  if (!icon) {
    throw new Error(`Icon: icon name ${name} not found`)
  }

  const { path, viewBox } = icon

  return (
    <IconStyle
      className={classnames(className, `icon icon-${name}`)}
      height={height}
      onClick={onClick}
      width={width}
      inline={inline}
    >
      <svg
        version={'1.1'}
        baseProfile={'full'}
        xmlns={'http://www.w3.org/2000/svg'}
        viewBox={viewBox}
      >
        <path d={path} />
      </svg>
    </IconStyle>
  )
}

Icon.propTypes = {
  /**
   * Name of icon to be rendered.
   */
  name: string.isRequired,
  /**
   * React className attached to button for style.
   */
  className: string,
  /**
   * onClick callback event.
   */
  onClick: func,
  /**
   * sizing props (optional)
   */
  height: number,
  width: number,
  /**
   * Whether icon should appear as inline block.
   */
  inline: bool,
}

export default Icon

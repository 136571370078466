import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionDetails, AccordionSummary, Arrow, Title } from './Collapsable.style'

const Collapsable = ({ actionControl, title, children, expanded = true }) => {
  const [expand, setExpand] = useState(expanded)

  const toggleExpand = useCallback(() => setExpand((prevState) => !prevState), [])

  return (
    <Accordion disableGutters expanded={expand} onChange={actionControl ? undefined : toggleExpand}>
      <AccordionSummary expandIcon={children && <Arrow onClick={actionControl ? toggleExpand : undefined} />}>
        {actionControl || <Title>{title}</Title>}
      </AccordionSummary>
      {children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  )
}

Collapsable.propTypes = {
  actionControl: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  expanded: PropTypes.bool,
}

export default Collapsable

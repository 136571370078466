import { styled } from '@mui/material/styles'
import { opacify, transitions } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  background-color: ${(props) => opacify(-0.98, props.theme.colors.blue)};
  border: 2px dashed ${(props) => props.theme.colors.lightgray};
  padding: 3rem;
  ${transitions(['background-color', 'box-shadow'], '0.2s ease 0s')};

  @media (max-width: ${theme.breakpoints.lg}), (max-height: 850px) {
    justify-content: center;
    padding: 1rem;
  }

  &:hover {
    @media (min-width: ${theme.breakpoints.sm}) {
      border-color: ${(props) => props.theme.colors.blue};
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }

  .top, .bottom {
    & > * {
      margin-bottom: 1rem;
    }
  }

  .icon {
    height: 4rem;
    margin: 0 auto 1rem;
    width: 4rem;
  }

  h3 {
    font-size: 1.375rem;
  }

  .bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .center-horizontal {

    .reset-button {
      display: inline;
      font-size: 1.375rem;
      font-weight: 600;
      text-decoration: underline;
      position: relative;
      z-index: 1;
    }
  }

  .center {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .inline {
    display: inline;
  }
`

import { FormControlLabel, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Style = styled('div')`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 36px;
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  margin-bottom: 8px;
  text-align: center;
  line-height: 1.5;
`
export const Wrap = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 0 auto;
    max-width: 420px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 480px;
  }
`

export const Label = styled(FormControlLabel)`
  margin-left: -12px;
  display: flex;
  align-items: baseline;
  
  .MuiCheckbox-root {
    top: 6px;
  }
`

import React, { forwardRef } from 'react'
import { array, func, string } from 'prop-types'

import SegmentedButton from 'components/Buttons/SegmentedButton'
import P from 'components/Text/P'

import { QUERIES } from './PictureSort.constants'
import Style from './PictureSort.style'

/**
 * Sort dropdown component for picture list
 * */
const PictureSort = forwardRef(({
  sortQueries,
  setSortQueries,
  idPrefix,
}, _ref) => {
  const addSortQuery = (query) => {
    const queryIndex = queryTypeIndex(query)

    if (queryIndex !== -1) {
      if (sortQueries[queryIndex].direction === query.value) {
        return
      }

      sortQueries[queryIndex].direction = query.value
      setSortQueries([...sortQueries])
    } else {
      setSortQueries([
        ...sortQueries,
        {
          type: query.key,
          direction: query.value,
        },
      ])
    }
  }

  const queryExists = (query) => {
    return sortQueries.find((q) => q.type === query.key
      && q.direction === query.value,
    )
  }

  const queryTypeIndex = (query) => {
    return sortQueries.findIndex((q) => q.type === query.key)
  }

  return (
    <Style>
      {Object.values(QUERIES).map((query) => (
        <div key={query.label} className={'sort-option-wrap'}>
          <P className={'secondary darkgray'}>{query.label}</P>
          <SegmentedButton
            queryExists={queryExists}
            options={query.buttons}
            idPrefix={idPrefix}
            onChange={addSortQuery}
          />
        </div>
      ))}
    </Style>
  )
})

PictureSort.propTypes = {
  /** sort queries */
  sortQueries: array,
  /** update active sort queries */
  setSortQueries: func,
  /** handle close click */
  idPrefix: string,
}

export default PictureSort

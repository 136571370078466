import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { NewUploadDropzoneProvider } from 'components/UploadDropzone/NewUploadDropzoneProvider/NewUploadDropzoneProvider'
import { usePicturesReplace } from 'containers/Pictures/Pictures.hooks'

import { StyledDragEnterPlaceholder, StyledUploadButton, Subtitle } from './NewReplaceImagesByAdminContent.style'

const NewReplaceImagesByAdminContent = ({ onClose }) => {
  const { onDrop } = usePicturesReplace(onClose)

  return (
    <NewUploadDropzoneProvider onDrop={onDrop}>
      {({ getRootProps, isDragActive }) => (
        <Box
          {...getRootProps({ className: 'dropzone' })}
          height={'100%'}
          position={'relative'}
          mt={4}
        >
          {isDragActive && <StyledDragEnterPlaceholder />}
          <StyledUploadButton
            title={'Drag & Drop images here or'}
            buttonText={'Browse Files'}
            fullScreen={true}
          />
          <Subtitle variant={'caption'}>Accepted files types include JPG or PNG.</Subtitle>
        </Box>
      )}
    </NewUploadDropzoneProvider>
  )
}

NewReplaceImagesByAdminContent.propTypes = {
  onClose: PropTypes.func,
}

export default NewReplaceImagesByAdminContent

import { styled } from '@mui/material/styles'
import { lighten } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  animation: show 0.7s linear forwards;
  padding: 1rem 2rem 1rem .5rem;
  text-align: center;
  font-style: italic;
  position: fixed;
  width: 100%;
  z-index: 10000000;
  top: 0;
  left: 0;

  &.closing {
    animation: hide 0.7s linear forwards;
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: 2%;
    top: 25%;

    @media (max-width: ${theme.breakpoints.md}) {
      right: 0.5rem;
    }
  }

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  ${(props) => props.type === 'error' && `
    background-color: ${lighten(0.5, props.theme.colors.red)};
    color: ${props.theme.colors.red};

    .close-button svg {
      fill: ${props.theme.colors.red};
    }
  `}


  ${({ type, theme }) => type === 'success' && `
    background-color: ${lighten(0.4, theme.colors.green[700])};
    color: ${theme.colors.green[700]};

    .close-button svg {
      fill: ${theme.colors.green[700]};
    }
  `}
`

import { Box, Typography } from '@mui/material'
import { css, styled } from '@mui/material/styles'

import { PureImage } from 'components/Image'

const dynamicSingleImageStyle = ({ hasMultipleImages, theme }) => hasMultipleImages
  ? css`
    border-bottom: 2px solid ${theme.colors.sky[300]};
    padding-bottom: 12px;
  ` : css`
    max-height: 240px;
    display: grid;
    justify-items: center;
`

export const AdaptiveImagesGridWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasMultipleImages',
})`
  // The content width you use
  --content-width: 312px;

  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${dynamicSingleImageStyle}
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 360px;
    width: 360px;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colors.sky[300]};
    padding: 24px;
    height: 400px;
    border-radius: 4px;
    max-height: unset;
  }
`

export const AdaptiveImagesGridInner = styled(Box)`
  // The size of the gutter  
  --gutter: 8px;

  // The amount of columns
  --columns: 1;

  // This is the calculation for the row height.   
  --row-size: calc((var(--content-width) - (var(--gutter) * (var(--grid-columns) - 1))) / var(--grid-columns));

  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-auto-rows: var(--row-size);

  grid-column-gap: var(--gutter);
  grid-row-gap: var(--gutter);
  width: var(--content-width);
  height: auto;
  align-content: center;
  justify-items: stretch;
`

export const RestImages = styled(Box)`
  border-radius: 2px;
  outline: 1px solid ${({ theme }) => theme.colors.sky[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.boxShadow.large};
`

export const RestImagesText = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: 600;
`

export const Image = styled(PureImage)`
  border-radius: 2px;
  overflow: hidden;
  object-fit: contain;
  outline: 1px solid ${({ theme }) => theme.colors.sky[300]};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.large};
`

export const SingleImage = styled(PureImage)`
  box-shadow: ${({ theme }) => theme.boxShadow.large};
  min-height: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: auto;
  }
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  text-align: center;
  margin-bottom: 12px;
`

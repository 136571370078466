import { action } from 'easy-peasy'

import { SET_PICTURES_MODES, UPLOAD_FILTER_OPTIONS } from './Pictures.constants'

const picturesActions = {
  /**
   * deselect all pictures - deselects all pictures
   * @param {object} state - redux state
   */
  deselectAllPictures: action((state, hidden) => {
    if (hidden === true || hidden === false) {
      state.selected = state.selected.filter((id) => {
        const picture = state.list.find((picture) => picture.id === id)
        return !!picture && picture.hidden !== hidden
      })
    } else {
      state.selected = []
    }
  }),
  /**
   * select all pictures - selects all pictures
   * @param {object} state - redux state
   */
  selectAllPictures: action((state, hidden) => {
    if (hidden === undefined) {
      state.selected = state.list.map((picture) => picture.id)
    } else {
      state.selected = state.list
        .filter((picture) => picture.hidden === hidden || state.selected.includes(picture.id))
        .map((picture) => picture.id)
    }
  }),
  selectAllPicturesWithLimit: action((state, { hidden, limit }) => {
    const limitedPicturesList = state.list
      .filter(
        (picture) =>  !state.selected.find((id) => id === picture.id),
      ).slice(0, limit)

    if (hidden === undefined) {
      state.selected = [ ...limitedPicturesList.map((picture) => picture.id), ...state.selected ]
    } else {
      state.selected = [ ...limitedPicturesList
        .filter((picture) => picture.hidden === hidden)
        .map((picture) => picture.id), ...state.selected ]
    }
  }),
  setIsMultipleSelectionAllowed: action((state, payload) => {
    if (state.isMultipleSelectionAllowed === payload) return
    state.isMultipleSelectionAllowed = payload
  }),
  /**
   * toggleSelectPicture - set picture selected as either true or false
   * @param {object} state - redux state
   * @param {array} pictureId - picture id to toggle
   */
  toggleSelectPicture: action((state, pictureId) => {
    if (state.selected.includes(pictureId)) {
      state.selected = state.selected.filter((id) => id !== pictureId)
    } else {
      if (state.isMultipleSelectionAllowed) {
        state.selected.push(pictureId)
      } else {
        state.selected = [pictureId]
      }
    }
  }),
  /**
   * setPictures - set or append pictures to the list
   * @param {object} state - redux state
   * @param {array} payload.pictures - array of pictures
   * @param {number} payload.total - total number of pictures in DB
   * @param {array} payload.mode - SET or APPEND pictures
   */
  setPictures: action((state, { pictures, total, mode }) => {
    state.picturesLoaded = true
    if (mode === SET_PICTURES_MODES.SET) {
      state.list = pictures
      state.selected = []
      state.totalFiltered = total
    } else {
      const picturesMap = new Map()
      const nextList = [
        ...state.list,
        ...pictures,
      ]

      nextList.forEach((picture) => {
        picturesMap.set(picture.id, picture)
      })

      state.list = Array.from(picturesMap.values())
      state.totalFiltered = pictures.length ? total : state.list.length
    }
    state.isLoading = false
  }),

  setTotal: action((state, total) => {
    state.total = total
  }),
  /**
   * removePictures - remove selected pictures from the list and update total
   * @param {object} state - redux state
   */
  removePictures: action((state) => {
    const removedPicturesNumber = state.selectedPictures.length

    state.list = state.list.filter((pic) => !state.selected.includes(pic.id))
    state.selected = []
    state.total = state.total - removedPicturesNumber
    state.totalFiltered = state.totalFiltered - removedPicturesNumber
    state.isLoading = false
  }),

  /**
   * addPicture - adds picture at the start of the list
   * @param {object} state - redux state
   * @param {array} picture - picture to add
   */
  addPicture: action((state, picture) => {
    const prevLength = state.list.length
    state.list = state.list.filter((pic) => picture.id !== pic.id)
    const incrementTotal = +(prevLength === state.list.length)
    state.list.unshift(picture)
    state.total += incrementTotal
    state.totalFiltered += incrementTotal
    state.isLoading = false
  }),

  setChildrenFilter: action((state, payload) => {
    state.filters.children = payload
  }),
  setTagsFilter: action((state, payload) => {
    state.filters.tags = payload
  }),
  setUploadByFilter: action((state, payload) => {
    state.filters.uploadBy = payload
  }),
  resetList: action((state) => {
    state.list = []
  }),
  resetFilters: action((state) => {
    state.filters = {
      children: {},
      tags: [],
      uploadBy: UPLOAD_FILTER_OPTIONS[0].value,
    }
    state.picturesLoaded = false
  }),
  setPicturesLoaded: action((state, payload) => {
    state.picturesLoaded = payload
  }),
  /**
   * setIsLoading - handle global lading state
   * @param {boolean} - payload true/false
   */
  setIsLoading: action((state, payload) => {
    if (state.isLoading === payload) return
    state.isLoading = payload
  }),
  /**
   * setError - handle global lading state
   * @param {object} - error object
   */
  setError: action((state, payload) => {
    state.error = payload
    state.isLoading = false
  }),

  removePicture: action((state, payload) => {
    const pictures = Array.isArray(payload) ? payload : [payload]

    state.list = state.list.filter((picture) => !pictures.includes(picture.id))
    state.selected = state.selected.filter((id) => !pictures.includes(id))
    state.total -= 1
    state.totalFiltered -= 1
  }),

  setPicture: action((state, { picture }) => {
    state.list = state.list.map((pic) => pic.id === picture.id ? { ...pic, ...picture } : pic)
  }),

  bulkSetPictures: action((state, { pictures }) => {
    state.list = state.list.map((pic) => {
      const newPicture = pictures.find((item) => item.id === pic.id)
      return newPicture ? { ...pic, ...newPicture } : pic
    })
  }),

  setSortOptions: action((state, sortOptions) => {
    state.sort = sortOptions
  }),
}

export default picturesActions

import React, { useCallback } from 'react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useStoreActions } from 'easy-peasy'

import { NoFilterResultsWrap } from './NoFilterResults.style'

const NoFilterResults = () => {
  const resetFilters = useStoreActions((actions) => actions.pictures.resetFilters)
  const setIsLoading = useStoreActions((actions) => actions.pictures.setIsLoading)

  const handleResetFilters = useCallback((e) => {
    e.stopPropagation()
    resetFilters()
    // Set isLoading true for not having wrong empty result state
    setIsLoading(true)
  }, [])

  return (
    <NoFilterResultsWrap>
      <Typography align={'center'} variant={'h5'} mb={1}>No Images Found</Typography>
      <Typography align={'center'} variant={'subtitle2'} mb={4} sx={(theme) => ({ color: theme.colors.gray[700] })}>
        No images matching your filter setting.<br />Try changing filters or reset filters
      </Typography>
      <Button sx={{ maxWidth: '160px' }} variant={'new-outlined-primary'} onClick={handleResetFilters}>Reset Filters</Button>
    </NoFilterResultsWrap>
  )
}

export default NoFilterResults

import React from 'react'
import { array } from 'prop-types'

import classnames from 'classnames'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { FilterButton } from 'components/Buttons/Button'
import Dropdown from 'components/Dropdown'
import PictureTagsFilter from 'components/PictureTagsFilter'

/**
 * Tags Filter Dropdown for picture list
 */
const PictureTagsFilterDropdown = ({ tags }) => {
  const setTagFilters = useStoreActions((actions) => actions.pictures.setTagsFilter)
  const tagFilters = useStoreState((state) => state.pictures.filters.tags)

  return (
    <Dropdown
      className={'tags-filter-dropdown'}
      onReset={() => setTagFilters([])}
      anchor={(
        <FilterButton className={classnames('spaced-btn', { active: tagFilters.length > 0 })}>
          Tags
        </FilterButton>
      )}
    >
      <PictureTagsFilter tags={tags} />
    </Dropdown>
  )
}

PictureTagsFilterDropdown.propTypes = {
  /** all tags */
  tags: array.isRequired,
}

export default PictureTagsFilterDropdown

import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

const NavWrap = ({ children }) => {
  return (
    <AppBar position={'fixed'}>
      <Toolbar>{children}</Toolbar>
    </AppBar>
  )
}

NavWrap.propTypes = {
  children: PropTypes.node,
}
export default NavWrap

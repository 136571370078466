import React from 'react'
import PropTypes from 'prop-types'

import { useDropzone } from 'react-dropzone'

import { NewUploadDropzoneContext } from './useNewUploadDropzoneContext'

export const NewUploadDropzoneProvider = ({
  children,
  onDrop,
  onDropAccepted,
  onDropRejected,
  disabled = false,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragReject,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      // 'image/heic': [], // TODO discuss it's support
    },
    onDrop,
    onDropAccepted,
    onDropRejected,
    noClick: true,
    disabled,
  })

  return (
    <NewUploadDropzoneContext.Provider
      value={{
        getRootProps,
        getInputProps,
        isDragReject,
        openDropzone,
      }}
    >
      {children({ getRootProps, isDragActive })}
    </NewUploadDropzoneContext.Provider>
  )
}

NewUploadDropzoneProvider.propTypes = {
  children: PropTypes.func,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
}

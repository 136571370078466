import { ArrowDropDown } from '@mui/icons-material'
import { Typography } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

export const Accordion = styled(MuiAccordion)`
  border: none;
  background: none;
  box-shadow: none;
  
  &:before {
    display: none;
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 0;
  height: 44px;
  min-height: auto;

  & .MuiAccordionSummary-content {
    margin: 0;
    align-items: center;
  }
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.black};
  flex-grow: 1;
  font-weight: 600;
  
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 0.875rem;
  }
`

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding-left: 0;
  padding-right: 0;
`

export const Arrow = styled(ArrowDropDown)`
  color: ${({ theme }) => theme.colors.gray[700]};
`

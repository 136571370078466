import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .pobox {
    margin: 1rem auto;
  }

  .input-wrap {
    margin: 0.25rem auto;

    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }

    &.pr {
      padding-right: 1rem;

      @media (max-width: ${theme.breakpoints.sm}) {
        padding-right: 0rem;
      }
    }
  }


`

import React, { Children, cloneElement, useCallback, useEffect, useRef, useState } from 'react'
import { bool, func, node, string } from 'prop-types'

import cl from 'classnames'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import Button from 'components/Buttons/Button'
import Icon from 'components/Icon'
import useResponsive from 'utils/useResponsive'

import DropdownStyle from './Dropdown.style'

/**
 * Dropdown Component consists of toggle button and dropdown
 * passed as a children
 */
const Dropdown = ({
  children,
  className,
  onClick,
  onReset,
  anchor,
  keepMounted = true,
}) => {
  const targetRef = useRef()
  const wrapperRef = useRef()
  const { isMobile } = useResponsive()

  const [active, setActive] = useState(false)

  /**
   * handle dropdown close
   * @param {object} e - event object
   */
  const handleClose = useCallback((e) => {
    e.stopPropagation()

    isMobile && enableBodyScroll(targetRef.current)

    setActive(false)
  }, [])

  /**
   * handle dropdown toggle click
   */
  const handleClick = (e) => {
    if (active) {
      handleClose(e)
      return
    }

    setActive(true)

    isMobile && disableBodyScroll(targetRef.current)

    onClick && onClick(e)
  }

  const dropdownButton = cloneElement(
    anchor,
    {
      onClick: handleClick,
    },
  )

  const dropdownElement = cloneElement(
    Children.only(children),
    {
      onClose: handleClose,
    },
  )

  /**
   * if the click target is outside of the wrapper close
   * @param {object} e
   */
  const handleOuterClick = useCallback((e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      handleClose(e)
    }
  }, [handleClose])

  useEffect(() => {
    document.addEventListener('click', handleOuterClick, false)

    return () => document.removeEventListener('click', handleOuterClick, false)
  }, [])

  return (
    <DropdownStyle
      className={className}
      ref={wrapperRef}
    >
      {dropdownButton}
      <div className={cl('dropdown', active && 'active')} ref={targetRef}>
        <div className={'hide-desktop flex justify-end'}>
          <Icon className={'pointer darkgray'} name={'close'} onClick={handleClose} />
        </div>
        {(active || keepMounted) && dropdownElement}
        <div className={'buttons'}>
          <span className={'link darkgray pointer'} onClick={handleClose}>Close</span>
          {!!onReset && <Button size={'small'} onClick={onReset}>Reset</Button>}
        </div>
      </div>
    </DropdownStyle>
  )
}

Dropdown.propTypes = {
  /**
   Button Component
   */
  anchor: node.isRequired,
  /**
   * Dropdown Content
   */
  children: node.isRequired,

  keepMounted: bool,
  /** custom class name */
  className: string,
  /** on dropdown toggle click */
  onClick: func,
  onReset: func,
}

export default Dropdown

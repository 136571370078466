import React, { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import FlipIcon from '@mui/icons-material/Flip'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import { Box, Stack } from '@mui/material'

import Button from './Button'
import RotateComponent from './RotateComponent'
import { RotateNavigationWrapper } from './RotateComponent.style'

const RotateNavigation = forwardRef(
  (
    {
      className,
      buttonClassName,
      rotateComponentClassName,
      barClassName,
      highlightedBarClassName,
      zeroBarClassName,
      valueBarClassName,
      disabled,
      value,
      onRotate,
      onRotateEnd,
      onFlip,
    },
    ref,
  ) => {
    const [quarter, setQuarter] = useState(0)
    const [adjustmentAngle, setAdjustmentAngle] = useState(0)
    const rotateComponentRef = useRef(null)

    useLayoutEffect(() => {
      const absRotate = Math.abs(value)

      let rotate
      if (absRotate % 90 > 45) {
        rotate = (absRotate - (absRotate % 90) + 90) / 90
      } else if (absRotate % 90 < 45) {
        rotate = (absRotate - (absRotate % 90)) / 90
      } else {
        rotate = quarter
      }
      rotate = Math.sign(rotate) * rotate

      if (rotate !== quarter) {
        setQuarter(rotate)
      }
      setAdjustmentAngle(Math.sign(value) * (Math.abs(value) - Math.abs(rotate) * 90))
    }, [value])

    useImperativeHandle(ref, () => {
      return {
        refresh() {
          if (rotateComponentRef.current) {
            rotateComponentRef.current.refresh()
          }
        },
      }
    })

    const rotateTo = (angle) => {
      if (onRotate && !disabled) {
        onRotate(angle, {
          transitions: false,
          interaction: true,
          immediately: true,
        })
      }
    }

    const rotateLeft = () => {
      if (onRotate && !disabled) {
        if (adjustmentAngle > 0) {
          onRotate(-adjustmentAngle)
        } else if (adjustmentAngle < 0) {
          onRotate(-90 - adjustmentAngle)
        } else {
          onRotate(-90)
        }
      }
    }

    const rotateRight = () => {
      if (onRotate && !disabled) {
        if (adjustmentAngle > 0) {
          onRotate(90 - adjustmentAngle)
        } else if (adjustmentAngle < 0) {
          onRotate(-adjustmentAngle)
        } else {
          onRotate(90)
        }
      }
    }

    const flip = (horizontal, vertical) => {
      const evenQuarter = quarter % 2 === 0
      onFlip?.(evenQuarter ? horizontal : vertical, evenQuarter ? vertical : horizontal, {
        normalize: false,
      })
    }

    const flipHorizontal = () => {
      flip(true, false)
    }

    const flipVertical = () => {
      flip(false, true)
    }

    return (
      <RotateNavigationWrapper className={className}>
        <Box display={'flex'} justifyContent={'center'}>
          <Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={2}>
            <Button className={buttonClassName} onClick={flipHorizontal} title={'Flip horizontal'}>
              <FlipIcon />
            </Button>
            <Button className={buttonClassName} onClick={rotateLeft} title={'Rotate left'}>
              <RotateLeftIcon />
            </Button>
          </Stack>
          <Box sx={{ width: '88px' }} />
          <Stack display={'flex'} alignItems={'center'} direction={'row'} spacing={2}>
            <Button className={buttonClassName} onClick={rotateRight} title={'Rotate right'}>
              <RotateRightIcon />
            </Button>
            <Button className={buttonClassName} onClick={flipVertical} title={'Flip vertical'}>
              <FlipIcon sx={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Stack>
        </Box>
        <RotateComponent
          ref={rotateComponentRef}
          className={rotateComponentClassName}
          barClassName={barClassName}
          zeroBarClassName={zeroBarClassName}
          valueBarClassName={valueBarClassName}
          highlightedBarClassName={highlightedBarClassName}
          onChange={rotateTo}
          onBlur={onRotateEnd}
          from={-45}
          to={45}
          value={adjustmentAngle}
        />
      </RotateNavigationWrapper>
    )
  },
)

RotateNavigation.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  rotateComponentClassName: PropTypes.string,
  barClassName: PropTypes.string,
  highlightedBarClassName: PropTypes.string,
  zeroBarClassName: PropTypes.string,
  valueBarClassName: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  onRotate: PropTypes.func,
  onRotateEnd: PropTypes.func,
  onFlip: PropTypes.func,
}

RotateNavigation.displayName = 'RotateNavigation'

export default RotateNavigation

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/Error'
import { Alert, Slide, Snackbar } from '@mui/material'

import { useSetNotification } from 'dux/slice/ui'

import { infoIconMap, infoTypes, NOTIFICATION_TYPES } from './constants'

const isInfo = (type) => infoTypes.includes(type)
const DEFAULT_DURATION = 5000

const Notification = ({
  message,
  onClose,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  duration = DEFAULT_DURATION,
  type = NOTIFICATION_TYPES.INFO,
}) => {
  const setNotification = useSetNotification()

  const [open, setOpen] = useState(true)

  const direction = anchorOrigin?.vertical === 'bottom' ? 'up' : 'down'

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return

    setOpen(false)
    onClose?.()
    setTimeout(() => setNotification(null), 100) // setTimeout is for animation purposes
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={duration}
      TransitionComponent={Slide}
      TransitionProps={{
        direction,
      }}
    >
      <Alert
        severity={isInfo(type) ? 'info' : type}
        variant={'filled'}
        iconMapping={{
          error: <ErrorIcon fontSize={'small'} />,
          info: isInfo(type) && infoIconMap[type],
          success: <CheckCircleOutlineIcon fontSize={'small'} />,
          warning: <ErrorIcon fontSize={'small'} />,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

Notification.propTypes = {
  anchorOrigin: PropTypes.object,
  type: PropTypes.oneOf(['success', 'info', 'progress', 'warning', 'error', 'download']),
  message: PropTypes.string,
  duration: PropTypes.number,
  onClose: PropTypes.func,
}
export default Notification

import { styled } from '@mui/material/styles'

export const TooltipContent = styled('div')`
  position: absolute;
  z-index: 100;

  .box {
    background-color: ${(props) => props.theme.colors[props.color || 'white']};
    border: 1px solid ${({ theme }) => theme.colors.darkgray}1F;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #00000014;
    max-width: 400px;
    min-width: 200px;
    padding: 28px;
    text-align: center;
  }

  .arrow-container {
    height: 20px;
    position: absolute;
    width: 20px;

    .arrow {
      background-color: ${(props) => props.theme.colors[props.color || 'white']};
      height: 20px;
      transform: rotate(45deg);
      width: 20px;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.top {
    left: 50%;
    bottom: 35px;
    transform: translate(-50%);

    .arrow-container {
      left: 50%;
      bottom: -9px;
      transform: translate(-50%);

      .arrow {
        box-shadow: 1.5px 1.5px 2px #00000014;
      }
    }
  }

  &.bottom {
    left: 50%;
    top: calc(100% + 20px);
    transform: translate(-50%);


    .arrow-container {
      left: 50%;
      top: -9px;
      transform: translate(-50%);

      .arrow {
        box-shadow: -1.5px -1.5px 2px #00000014;
      }
    }

  }

  &.left {
    right: 35px;
    top: 50%;
    transform: translateY(-50%);


    .arrow-container {
      right: -9px;
      top: 50%;
      transform: translateY(-50%);

      .arrow {
        box-shadow: 1.5px -1.5px 2px #00000014;
      }
    }
  }

  &.right {
    left: 35px;
    top: 50%;
    transform: translateY(-50%);


    .arrow-container {
      left: -9px;
      top: 50%;
      transform: translateY(-50%);

      .arrow {
        box-shadow: -1.5px 1.5px 2px #00000014;
      }
    }
  }
`

export default styled('div')`
  position: relative;
`

export const TooltipStoriesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 98vh;
  justify-content: space-between;

  .centered {
    display: flex;
    justify-content: center;
  }

  .sides {
    display: flex;
    justify-content: space-between;
  }
`

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  width: 100%;

  .content {
    @media (max-width: ${theme.breakpoints.md}) {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 400px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      min-width: 400px;
    }
  }

  .input {
    margin-bottom: 2rem;
  }

  .forgot-password-modal {
    @media (min-width: ${theme.breakpoints.md}) {
      max-width: 500px;
    }
  }

  h2, div.label {
    margin-bottom: 1rem;
  }

  .content {
    padding: 0 4rem;
  }

  button {
    width: auto;
  }
`

import { useCallback, useMemo } from 'react'

import moment from 'moment'

import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import useUploadService from 'hooks/useUploadService'
import uploadService from 'services/uploadService'
import { isPictureInQueue, isPictureUploaded } from 'services/uploadService/uploadService'

const combineUploadsTree = (photos) => {
  const grouped = {}

  photos.forEach((picture) => {
    const date = typeof picture.pictureDate === 'string'
      ? picture.pictureDate.slice(0, 10)
      : moment(picture.pictureDate, 'YYYY-MM-DD')
    const keyIndex = `${picture.child?.id}-${picture.label?.id}-${date}`

    if (!grouped[keyIndex]) {
      grouped[keyIndex] = {
        key: keyIndex,
        child: picture.child,
        label: picture.label,
        pictureDate: picture.pictureDate,
        files: [],
      }
    }

    grouped[keyIndex].files.push(picture)
  })

  return Object.values(grouped)
}

const filterCallback = () => {
  return true
}

const getUploadingSubtitle = (files) => {
  const uploadingFiles = files.filter(isPictureInQueue).length
  const uploadedFiles = files.filter(isPictureUploaded).length

  return [
    uploadedFiles && `${uploadedFiles} images uploaded`,
    uploadingFiles && `${uploadingFiles} images uploading...`,
  ].filter(Boolean).join(', ')
}

const combineQueuedBooks = (files) => {
  const book_ids = new Set(files.map(({ book_id }) => book_id))
  return [...book_ids].map((book_id) => {
    return uploadService.booksCache.get(book_id)
  }).filter(Boolean)
}

const prepareUploadTab = (tabPictures, key, title) => {
  const tabTree = combineUploadsTree(tabPictures)

  const isTabCloseDisabled = tabPictures.some(({ status }) => [
    UPLOADING_IMAGE_STATUSES.IN_PROGRESS,
    UPLOADING_IMAGE_STATUSES.QUEUED,
  ].includes(status))

  return {
    key,
    title,
    subtitle: getUploadingSubtitle(tabPictures),
    disabledClose: isTabCloseDisabled,
    tree: tabTree.map(({ files, child, label, pictureDate, key }) => {
      return {
        key,
        title: [
          [child?.name, label?.name].filter(Boolean).join(', '),
          pictureDate && moment(pictureDate).format('MM/DD/YY'),
        ].filter(Boolean).join(' • '),
        name: [child?.name, label?.name].filter(Boolean).join(', '),
        date: pictureDate && moment(pictureDate).format('MM/DD/YY'),
        subtitle: getUploadingSubtitle(files),
        files,
      }
    }).sort((a, b) => {
      const nameA = a.title.toUpperCase()
      const nameB = b.title.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }),
  }
}

const useUploaderQueue = () => {

  const uploads = useUploadService(filterCallback)

  const tabs = useMemo(() => {
    const uploadingBooks = combineQueuedBooks(uploads)

    const tabs = uploadingBooks.map((book) => {
      const tabPictures = uploads.filter(({ book_id }) => book_id === book.id)
      return prepareUploadTab(tabPictures, book.id, book.title)
    })

    const uncategorizedPictures = uploads.filter(({ book_id }) => !book_id)

    if (uncategorizedPictures.length) {
      tabs.push(
        prepareUploadTab(uncategorizedPictures, 'default', 'My Images'),
      )
    }

    return tabs
  }, [uploads])

  const clearTab = useCallback((book_id) => {
    uploadService.clearPicturesByBookId(book_id)
  }, [uploads, tabs])

  return {
    isVisible: !!uploads.length,
    tabs,
    clearTab,
  }

}

export default useUploaderQueue

import React from 'react'
import PropTypes from 'prop-types'

import { useRouter } from 'next/router'

import Button from 'components/Buttons/Button'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import TextLink from 'components/Text/Link'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'

import CheckoutExitStyle from './CheckoutExit.style'

import findRoute from '@/routes/findRoute'

const editorLinkRoute = findRoute('name', 'AllPages')

const CheckoutExit = ({ id }) => {
  const router = useRouter()
  const { unsetModal } = useModal()

  const handleClick = () => {
    router.push(
      editorLinkRoute.pathname,
      editorLinkRoute.mapSlugToPath({ id: id }),
    )
    unsetModal()
  }

  return (
    <CheckoutExitStyle>
      <Modal handleClose={unsetModal}>
        <div className={'content'}>
          <H2 className={'center darkblue capitalize modal__header'}>Are you sure you want to leave?</H2>
          <P className={'center p'}>You are leaving the checkout and your progress will not be saved.</P>

          <div className={'buttons'}>
            <TextLink
              className={'pointer blue'}
              onClick={unsetModal}
            >
              Cancel
            </TextLink>
            <Button
              onClick={handleClick}
              type={'primary'}
            >
              <P className={'p white sm'}>Yes, I Want To Leave Checkout</P>
            </Button>
          </div>
        </div>
      </Modal>
    </CheckoutExitStyle>
  )
}

CheckoutExit.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default CheckoutExit

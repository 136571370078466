import { useCallback, useMemo, useState } from 'react'

export const useFieldLabelStateMap = (fields) => {
  const defaultState = useMemo(() => fields.reduce((state, fieldName) => {
    state[fieldName] = {
      focused: false,
      value: null,
    }
    return state
  }, {}), [fields])
  const [fieldLabelStateMap, setFieldLabelStateMap] = useState(defaultState)

  const updateLabelState = useCallback((fieldName, focused, value) => {
    setFieldLabelStateMap((prevState) => ({ ...prevState, [fieldName]: { ...prevState[fieldName], focused, value } }))
  }, [])

  return {
    fieldLabelStateMap,
    updateLabelState,
  }
}

import { useMemo } from 'react'

import { useGetUserQuery } from 'dux/queries/user'
import { useIsCustomerView } from 'dux/slice/ui'

const useUserInformation = () => {
  const { isCustomerView } = useIsCustomerView()
  const {
    data = {
      activeUser: {},
      adminMeta: {},
    },
    isLoading,
  } = useGetUserQuery()
  const { activeUser } = data

  const defaultShippingAddress = useMemo(() => {
    if (!activeUser || !activeUser.shippingAddress1) {
      return null
    }

    const [firstName, lastName] = activeUser.shippingName ? activeUser.shippingName.split(' ') : ['', '']

    return {
      firstName,
      lastName,
      shippingName: activeUser.shippingName,
      shippingAddress1: activeUser.shippingAddress1,
      shippingAddress2: activeUser.shippingAddress2,
      shippingCity: activeUser.shippingCity,
      shippingStateProvince: activeUser.shippingStateProvince,
      shippingCountry: activeUser.shippingCountry,
      shippingPostalCode: activeUser.shippingPostalCode,
    }
  }, [activeUser])

  const isLoggedAsUser = !!data.adminMeta?.email
  return {
    activeUser,
    isAdminView: isLoggedAsUser && !isCustomerView,
    defaultShippingAddress,
    isLoggedAsUser,
    isLoading,
  }
}

export default useUserInformation

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import AdaptiveImagesGrid from 'components/AdaptiveImagesGrid'

export const AdaptiveImagesGridStyle = styled(AdaptiveImagesGrid)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    height: 100%;
  }
`

export const ModalBodyBox = styled(Box)`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  justify-content: stretch;
  padding: 16px;
  padding-bottom: 80px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: min-content;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
    padding: 0;
  }
`

export const FormWrapperBox = styled(Box)`
  margin-top: 28px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    width: 382px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ActionsMobile = styled('div')`
  background-color: inherit;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  flex-grow: 1;
  padding: 8px 16px;
  position: sticky;
  bottom: 0;

  & > * {
    flex: 1;
  }
`

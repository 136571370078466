import { styled } from '@mui/material/styles'

export default styled('div')`
  .field {
    margin-top: 1rem;
  }

  .input-caption {
    width: 100%;

    input {
      margin-right: 5px;
    }
  }

  .input-title {
    padding-bottom: 0.5rem;
  }

  .title-field {
    .label {
      margin-top: 0.2rem;
    }
  }

  select {
    font-family: "Montserrat", sans-serif;
  }

  .react-select__menu {
    z-index: 1000;
  }

  .react-select-tags__placeholder {
    font-size: 1.1rem;
  }

  .input-caption {
    .error-label {
      position: static;
    }
  }
`

export const PictureMetaStories = styled('div')`
  margin: 0 auto;
  width: 300px;
`

import React from 'react'
import { func, number } from 'prop-types'

import { AutoSizer, InfiniteLoader, WindowScroller } from 'react-virtualized'

const TableInfiniteWrapper = (props) => {
  const {
    total,
    isItemLoaded,
    loadMoreItems,
    children,
  } = props

  return (
    <InfiniteLoader
      isRowLoaded={isItemLoaded}
      rowCount={total}
      loadMoreRows={loadMoreItems}
      minimumBatchSize={50}
      threshold={15}
    >
      {({ onRowsRendered, registerChild: ref }) => (
        <WindowScroller>
          {({ ref: scrollerRef, outerRef, style, onScroll, height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                children({
                  ref,
                  scrollerRef,
                  outerRef,
                  style,
                  height,
                  width,
                  onScroll,
                  onRowsRendered,
                  scrollTop,
                })
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  )
}

TableInfiniteWrapper.propTypes = {
  isItemLoaded: func,
  total: number,
  loadMoreItems: func,
  children: func,
}

export default TableInfiniteWrapper

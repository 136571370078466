import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  position: relative;
  overscroll-behavior: contain;

  .dropdown {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.lightgray};
    position: absolute;
    top: 160%;
    left: 0;
    flex-direction: column;
    padding: 1rem 1rem 2rem;
    z-index: 14;
    display: none;
    align-items: flex-start;
    border-radius: 4px;
    width: 100%;
    min-width: 300px;

    @media (max-width: ${theme.breakpoints.md}) {
      border: none;
      position: fixed;
      top: ${({ theme }) => theme.appBar.height}px;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 10001;
      overflow: auto;
      padding-bottom: 7.5rem;
    }

    &:after {
      content: '';
      height: 28px;
      width: 28px;
      position: absolute;
      background-color: ${(props) => props.theme.colors.white};
      border-left: 1px solid ${(props) => props.theme.colors.lightgray};
      border-top: 1px solid ${(props) => props.theme.colors.lightgray};
      top: -15px;
      left: 10%;
      transform: rotate(45deg);
      z-index: 13;
    }

    &.active {
      display: block;
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;

    & > * {
      margin-left: 32px;
    }
  }
`

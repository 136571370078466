import React from 'react'
import { object } from 'prop-types'

import getConfig from 'next/config'

import Button from 'components/Buttons/Button'
import CopyToClipboard from 'components/CopyToClipboard'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import { emailContent } from 'dux/constants/referralContent'
import useModal from 'hooks/useModal'

import ReferralModalStyle from './ReferralModal.style'

const { publicRuntimeConfig: { NEXT_PUBLIC_API_URL } } = getConfig()

const ReferralModal = ({ user }) => {
  const { unsetModal } = useModal()

  const referralLink = `${NEXT_PUBLIC_API_URL}/${user.boxReferralLink}`

  return (
    <ReferralModalStyle>
      <Modal handleClose={unsetModal} className={'referral-modal'}>
        <div className={'__content'}>
          <div className={'__picture'}>
            <img src={'/images/give-get.png'} width={'100%'} />
          </div>
          <div className={'__info'}>
            <h1 className={'h1 darkblue center'}>Give $20, Get $20</h1>
            <span className={'label darkgray'}>
              Give $20 to a friend! For every friend that makes a purchase using your code,
              you’ll get $20 added to your account.
            </span>
            <a
              className={'__share-button'}
              href={`mailto:?subject=${encodeURIComponent('You will love this!')}&body=${emailContent(referralLink)}`}
            >
              <Button>
                <span className={'label flex align-center'}>
                  <Icon name={'envelope'} className={'white'} /> &nbsp; Share With Email
                </span>
              </Button>
            </a>
            <div className={'__referral-link-wrapper'}>
              <div className={'label darkgray center'}>Or share your link:</div>
              <CopyToClipboard value={referralLink} />
            </div>
            <span className={'medgray label xs2 center'}>
              By sharing this promotion, you agree to our&nbsp;
              <a
                className={'link blue pointer'}
                href={`${NEXT_PUBLIC_API_URL}/privacypolicy'`}
                target={'_blank'}
                rel={'noreferrer'}
              >
                Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a className={'link blue pointer'} href={`${NEXT_PUBLIC_API_URL}/tou'`} target={'_blank'}
                rel={'noreferrer'}>
                Terms &amp; Conditions
              </a>.
            </span>
          </div>
        </div>
      </Modal>
    </ReferralModalStyle>
  )
}

ReferralModal.propTypes = {
  user: object,
}

export default ReferralModal

import { useMemo } from 'react'

import { createStore } from 'easy-peasy'

import Model from './Model'

const initStore = (initialState = {}) => {
  return createStore(Model, { initialState })
}

let store

// NOTE: Took from the example:
// https://github.com/ctrlplusb/easy-peasy/blob/master/examples/nextjs-ssr/store/store.js
export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({ ...store.getState(), ...preloadedState })

    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store

  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export const useEasyStore = (initialState) => {
  // NOTE: Do not remove initialState from the dependency array!
  const easyStore = useMemo(() => initializeStore(initialState), [initialState])
  return easyStore
}

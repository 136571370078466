import { CancelToken } from 'axios'
import { thunk, thunkOn } from 'easy-peasy'

import api from 'utils/api'

/**
 * CRUD operations for dux book
 */

let silentUpdate

let updateBookToken

const bookService = {
  createBook: thunk(async (actions, book) => {
    actions.setIsLoading(true)
    // axios config object
    const config = {
      method: 'post',
      url: '/api/v2/user/books',
      data: {
        ...book,
        pictures: book.pictureIds ? book.pictureIds : book.pictures.map((picture) => picture.id),
      },
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setBook(response.data.data)
      return response.data.data
    }
  }),

  readBook: thunk(async (actions, id) => {
    actions.setIsLoading(true)
    // axios config object
    const config = {
      method: 'get',
      url: `/api/v2/user/books/${id}`,
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading(false)
    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setBook(response.data.data)
      return response.data.data
    }

  }),

  /**
   * updateBook -
   * @param  {} actions
   * @param  {} book
   * @param  {} {getState}
   */
  updateBook: thunk(async (actions, book, { getState }) => {
    const activeBook = getState().activeBook

    const updatedBook = {
      ...activeBook,
      ...book,
      cover: {
        ...activeBook.cover,
        ...book?.cover,
      },
    }

    if (updateBookToken) {
      updateBookToken.cancel()
    }

    updateBookToken = CancelToken.source()

    // axios config object
    const config = {
      method: 'put',
      url: `/api/v2/user/books/${updatedBook.id}`,
      data: {
        ...updatedBook,
        pictures: updatedBook.pictures.map((picture) => picture.id),
      },
      cancelToken: updateBookToken.token,
    }

    actions.setBook(updatedBook)

    const { error, response } = await api.handleRequest(config)

    if (error) {
      error.isV2 = true
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setBook(response.data.data)
      return response.data.data
    }
  }),

  deletePages: thunk(async (actions, selectedPictures, { getState }) => {
    const activeBook = getState().activeBook
    const picturesToUpdate = activeBook.pictures.filter(
      (pic) => !selectedPictures.includes(pic.id),
    )
    actions.updateBook({
      pictures: picturesToUpdate,
    })
  }),

  silentBookUpdate: thunk(async (actions, book, { getState }) => {
    const activeBook = getState().activeBook
    const updatedBook = {
      ...activeBook,
      ...book,
    }

    if (silentUpdate) {
      silentUpdate.cancel()
    }

    silentUpdate = CancelToken.source()

    // axios config object
    const config = {
      method: 'put',
      url: `/api/v2/user/books/${updatedBook.id}`,
      data: {
        ...updatedBook,
        pictures: updatedBook.pictures.map((picture) => picture.id),
      },
      cancelToken: silentUpdate.token,
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      return response.data.data
    }
  }),

  deleteBook: thunk(async (actions, bookId, { getStoreActions }) => {
    actions.setIsLoading(true)
    // axios config object
    const config = {
      method: 'delete',
      url: `/api/v2/user/books/${bookId}`,
    }
    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      getStoreActions().user.books.removeBook(response.data.data.id)
      return response.data.data
    }

  }),
  bulkUpdateBookPictures: thunk(async (actions, { ids, picture: { image, replaceTags, ...picture } }) =>
  {
    try {
      const payload = { picture, ids }
      if (replaceTags) {
        payload.picture._replace_tags = true
      }
      actions.setIsLoading(true)
      const { data: { data: updatedPictures } } = await api.put({
        // this is fix for updating before new data structure.
        // seems like it will be fixed after we will migrate on new data structure
        url: '/api/v2/user/pictures',
        data: payload,
      })
      actions.bulkSetBookPictures({ pictures: updatedPictures })

      return updatedPictures
    } catch (error) {
      error.isV2 = true
      actions.setError(error)

    } finally {
      actions.setIsLoading(false)
    }
  }),

  // listeners

  onBookUpdate: thunkOn(
    (actions) => [
      actions.setSize,
      actions.addPictures,
      actions.removePicture,
      actions.swapPicture,
      actions.setCoverPicture,
      actions.setTextDisplaySettings,
    ],
    async (actions, target, helpers) => {
      const activeBook = helpers.getStoreState().book.activeBook

      if (!activeBook.id) {
        return
      }
      actions.setIsLoading(true)
      await actions.updateBook(activeBook)
    },
  ),
}

export default bookService

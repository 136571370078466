import { styled } from '@mui/material/styles'

/**
 * Identical to `components/Text/Label` but uses `<span />` as a tag.
 *
 * Because of this can be used inside of `<button />` tag and doesn't suppress "submit" event.
 */
export default styled('span')`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1;
`

// import getConfig from 'next/config'
import qs from 'qs'

// const { publicRuntimeConfig } = getConfig()
// const { NEXT_PUBLIC_SMARTYSTREETS_API_KEY } = publicRuntimeConfig

const USA_COUNTRY_KEY = 'US'

const allTrimString = (str) => str.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '')

const trimAndLoverCase = (value) => allTrimString(`${value}`).toLowerCase()

class AddressValidationService {
  ADDRESS_TYPE = {
    BILLING: 'billing',
    SHIPPING: 'shipping',
    MILITARY: 'military',
  }
  COUNTRIES = [USA_COUNTRY_KEY]
  VALIDATION_FIELDS = [
    'shippingAddress1',
    'shippingCity',
    'shippingStateProvince',
    'shippingPostalCode',
  ]

  compareAddresses = (source, dest) => {
    if (!source || !dest) {
      return true
    }

    if (source.shippingCountry !== USA_COUNTRY_KEY)
      return true

    return this.VALIDATION_FIELDS.every((key) => trimAndLoverCase(source[key]) === trimAndLoverCase(dest[key]))
  }

  checkAddress = async () => null

  /* checkAddress = async (address) => {
    try {
      const apiKey = NEXT_PUBLIC_SMARTYSTREETS_API_KEY

      const url = `https://us-street.api.smartystreets.com/street-address?auth-id=${apiKey}&candidates=1&match=invalid&${this.mapAddressUrl(address)}`

      const { data } = await axios.get(url)
      if (!data) {
        return
      }

      const suggestion = this.mapAddressSuggestion(data[0])

      if (!this.compareAddresses(address, suggestion)) {
        return {
          ...address,
          ...suggestion,
        }
      }
    } catch (error) {
      console.error('address validation error', error)
      return null
    }
  }*/

  mapAddressUrl = (address) => {
    const urlAddress = {
      city: address.shippingCity,
      state: address.shippingStateProvince,
      street: address.shippingAddress1,
      secondary: address.shippingAddress2,
      zipcode: address.shippingPostalCode,
    }

    return qs.stringify(urlAddress)
  }

  mapAddressSuggestion = (address) => {
    const isMilitary = !address.metadata
      || typeof address.metadata.zip_type === 'string'
      && address.metadata.zip_type.toLowerCase() === this.ADDRESS_TYPE.MILITARY
    const isValid = !!address.components && !isMilitary

    if (!isValid) {
      return
    }

    const addressRes = {
      city_name: address.components.city_name || '',
      extra_secondary_designator: address.components.extra_secondary_designator || '',
      extra_secondary_number: address.components.extra_secondary_number || '',
      plus4_code: address.components.plus4_code || '',
      primary_number: address.components.primary_number || '',
      secondary_designator: address.components.secondary_designator || '',
      secondary_number: address.components.secondary_number || '',
      state_abbreviation: address.components.state_abbreviation || '',
      street_name: address.components.street_name || '',
      street_postdirection: address.components.street_postdirection || '',
      street_predirection: address.components.street_predirection || '',
      street_suffix: address.components.street_suffix || '',
      zipcode: address.components.zipcode || '',
    }

    const mappedAddress = {
      shippingAddress1: this.removeWhitespaces([
        addressRes.primary_number,
        addressRes.street_predirection,
        addressRes.street_name,
        addressRes.street_suffix,
        addressRes.street_postdirection,
      ].join(' ')),
      shippingCity: addressRes.city_name,
      shippingCountry: this.COUNTRIES[0],
      shippingStateProvince: addressRes.state_abbreviation,
      shippingPostalCode:
        [addressRes.zipcode, addressRes.plus4_code]
          .filter((w) => !!w).join('-'),
    }

    const street2 = this.removeWhitespaces([
      addressRes.secondary_designator,
      addressRes.secondary_number,
      addressRes.extra_secondary_designator,
      addressRes.extra_secondary_number,
    ].join(' '))

    if (street2) {
      mappedAddress.shippingAddress2 = street2
    }

    return mappedAddress
  }

  removeWhitespaces = (string) => string.replace(/\s\s+/g, ' ').trim()
}

export default new AddressValidationService()

import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Buttons/Button'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'

import ConfirmEditStyle from './ConfirmEdit.style'

/**
 * ConfirmEdit - Modal
 */
const ConfirmEdit = ({
  unsetModal,
  onSuccess,
}) => {
  const handleClick = () => {
    onSuccess()
    unsetModal()
  }

  return (
    <ConfirmEditStyle>
      <Modal handleClose={unsetModal} className={'confirm-edit-modal'}>
        <div className={'content'}>
          <H2 className={'center darkblue modal__header'}>Are You Sure?</H2>
          <P className={'center'}>
            Your book has been carefully and thoughtfully created by a professional. You will not be
            able to undo any changes you make.
          </P>
          <div className={'buttons'}>
            <Button onClick={handleClick} type={'primary'}>
              <P className={'white sm'}>Yes, I want to edit my book</P>
            </Button>
            <span className={'link pointer blue md'} onClick={unsetModal}>Cancel</span>
          </div>
        </div>
      </Modal>
    </ConfirmEditStyle>
  )
}

ConfirmEdit.propTypes = {
  unsetModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default ConfirmEdit

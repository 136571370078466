/**
 * List of globally used regular expressions
 */

const validationRegex = {
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  password: /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/,
  name: /^[a-z &,.'-]+$/i,
  label: /^[a-z0-9 &,.'-]{1,25}$/i,
  child: /^([a-z0-9 &,.'-]{1,25})|(-)$/i,
  tag: /^[a-z0-9 &,.'-]{1,25}$/i,
  title: /^.{0,50}$/i,
  cardName: /^((?:[A-Za-z]+ ?){1,})$/i,
  cardNumber: /\d{4} \d{4} \d{4} \d{4}/,
  cardExpiry: /\d{2} \/ \d{2}/,
  cardCvc: /\d{3}/,
  billingZipCode: /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-z0-9]$/,
  street: /^[a-z0-9. \-']{2,50}$/i,
}

export default validationRegex

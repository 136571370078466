/**
 * addressToString - Accepts an address object and returns a stringified response.
 *  @param {object} address - Array of Redux Actions
 * @return {string}
 * @example
 *
 *     addressToString({ city: 'Winterfell', state: 'CA', ... })
 */
const addressToString = (address) => (
  [address.shippingAddress1, address.shippingAddress2, address.shippingCity].filter(Boolean).join(' ')
  + ', '
  + [address.shippingStateProvince, address.shippingPostalCode].join(' ')
)

export default addressToString
import { css } from '@emotion/react'

import theme from 'styles/theme'

const display = css`
  /* Display Utilities */

  .block {
    display: block;
  }

  .hide {
    display: none !important;
  }

  .hide-mobile {
    @media (max-width: ${theme.breakpoints.md}) {
      display: none !important;
    }
  }

  .hide-desktop {
    @media (min-width: ${theme.breakpoints.md}) {
      display: none !important;
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .align-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-between {
    justify-content: space-between;
  }

  .text-center {
    text-align: center;
  }

  .min-h-screen {
    min-height: calc(100% - ${theme.nav.mobileHeight});

    @media (min-width: ${theme.breakpoints.xl}) {
      min-height: calc(100vh - ${theme.nav.height});
    }
  }

  .flex-1 {
    flex: 1 1 0;
  }

  .w-full {
    width: 100%;
  }

  .w-1\\\/2 {
    width: 50%;
  }

  /* Aspect Ratio */

  .ratio {
    &:after {
      padding-top: 77.27%;
      display: block;
      content: '';
    }
  }

  .ratio-square {
    &:after {
      padding-top: 100%;
      display: block;
      content: '';
    }
  }

  /* Image Utils */

  .responsive {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .container {
    margin: 0 auto;
    padding: 1rem;
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
      max-width: 768px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      max-width: 1024px;
      padding: 2rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      max-width: 1220px;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
      max-width: 1440px;
    }

    &.full {
      max-width: 100vw;
      width: 100%;
    }
  }

  .mt-1 {
    margin-top: 1rem;
  }

  /* cursor */

  .pointer {
    cursor: pointer;
  }
`

export default display

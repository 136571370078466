import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { linearGradient, transitions } from 'polished'

import Icon from 'components/Icon'

/**
 * Check Icon that appears when hovering over Circle
 */
export const Check = styled(Icon)`
  left: 8px;
  position: absolute;
  top: 8px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 24px;
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.white};
  height: 24px;
  opacity: ${({ selected }) => selected ? '1' : '0'};
  padding: 5px;
  text-align: center;
  width: 24px;
  z-index: 2;
  ${transitions(['background-color', 'opacity', 'fill'], '0.3s ease-in-out')};
  user-select: none;

  @media (pointer: fine) {
    &:hover {
      opacity: 1;
      background-color: ${({ selected, theme }) => selected ? theme.colors.blue : theme.colors.white};
      fill: ${({ selected, theme }) => selected ? theme.colors.white : theme.colors.black};
    }
  }
`

/**
 * Circle icon that appears when the wrapped Component is selectable
 */
export const Circle = styled('div')`
  padding: 8px;
  position: absolute;
  z-index: 2;

  &::before {
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.white};
    height: 20px;
    width: 20px;
    opacity: ${({ selectable }) => selectable ? '1' : '0'};

    display: block;
    content: "";
  }

  ${transitions(['opacity'], '0.3s ease-in-out')};
`

/**
 * Overlay to provide contrast from wrapped Component and Circle/Check elements
 */
export const Overlay = styled('div')`
  border-radius: 4px;
  height: 100%;
  opacity: ${({ selected, selectable }) => selected || selectable ? '1' : '0'};
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  ${transitions(['opacity', 'background-image'], '0.3s ease-in-out')};
  ${linearGradient({
  colorStops: ['rgba(0, 0, 0, 0) 0%', 'rgba(16, 35, 113, 0.3) 0%', 'rgba(0, 0, 0, 0) 60%'],
  toDirection: 'to bottom',
  fallback: 'transparent',
})}
`

export const HiddenOverlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(16, 35, 113, 0.4);
  color: #B7B7B7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
`

const dynamicSelectable = ({ selectable }) => selectable && css`

  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);

    ${Circle} {
      opacity: 1;
    }

    ${Overlay} {
      opacity: 1;
    }
  }
`

/**
 * Root styled component
 */
export default styled('div')`
  position: relative;
  width: 100%;
  cursor: pointer;

  ${dynamicSelectable};
`

import { styled } from '@mui/material/styles'

const dynamicStyle = ({ width, height, inline }) => [
  width && `
    width: ${width}px;
  `,
  height && `
    height: ${height}px;
  `,
  inline && `
    display: inline-block;
    vertical-align: text-bottom;
  `,
].join('')

export default styled('div')`
  cursor: pointer;

  ${dynamicStyle}
  svg {
    width: 100%;
  }
`

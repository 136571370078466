import qs from 'qs'

export const createQueryString = (object, name) => {
  if (!object) return console.warn('Warning: createQueryString requires an object at first place')

  return qs.stringify({ [name]: object }, { encode: false, arrayFormat: 'brackets' })
}

export const queryParamsSerializer = (params, options = {}) => (
  qs.stringify(params, { encode: false, arrayFormat: 'brackets', ...options })
)

const formatValue = (data) => {
  if (!data) {
    return ''
  } else if (data instanceof Date) {
    return data.toISOString()
  } else {
    return data
  }
}

function buildFormData(formData, data, dataKey) {
  if (data instanceof File) {
    formData.append(dataKey, data, data.name)
  } else if (data && typeof data === 'object' && !(data instanceof Date)) {
    if (data instanceof Array) {
      if (!dataKey) throw new Error('Error: parent key required for this case')

      Object.values(data).forEach((value) => buildFormData(
        formData,
        value,
        `${dataKey}[]`,
      ))
    } else {
      Object.keys(data).forEach((key) => buildFormData(
        formData,
        data[key],
        dataKey ? `${dataKey}[${key}]` : key,
      ))
    }
  } else {
    formData.append(dataKey, formatValue(data))
  }
}

function createRequestBodyWithRecursion(object, name) {
  const formData = new FormData()
  const data = Object.fromEntries(Object.entries(object).filter((value) => value[1] !== undefined))

  if (name) {
    buildFormData(formData, { [name]: data })
  } else {
    buildFormData(formData, data)
  }

  return formData
}

export const createRequestBody = (object, name) => {
  return createRequestBodyWithRecursion(object, name)
}

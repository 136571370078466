import { action } from 'easy-peasy'

const ordersActions = {
  setOrders: action((state, orders) => {
    state.list = orders
  }),
  setActiveOrder: action((state, order) => {
    state.activeOrder = order
  }),
  setActiveReceipt: action((state, receipt) => {
    state.activeReceipt = receipt
  }),
  setStatusHistory: action((state, history) => {
    state.statusHistory = history
  }),
  setIsLoading: action((state, payload) => {
    if (state.isLoading === payload) return
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
}

export default ordersActions

import { isAppleMobileDevice } from 'utils/browser/parser'

// https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e
export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',')
  const bstr = atob(arr[1])
  // console.log({ length: dataurl?.length, type: (typeof dataurl), dataurl })
  const mime = arr[0].match(/:(.*?);/)[1]

  let n = bstr.length
  let u8arr = new Uint8Array(n)

  while (n--) u8arr[n] = bstr.charCodeAt(n)

  return new Blob([u8arr], { type: mime })
}

export function downloadFile(url, filename) {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = filename
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, 0)
}

export function saveFile(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename || blob.name)
  } else {
    const url = window.URL.createObjectURL(blob)
    downloadFile(url, filename || blob.name)
  }
}

const getFileType = (extension) => {
  switch (extension) {
    case 'png':
      return 'image/png'
    case 'webp':
      return 'image/webp'
    case 'jpg':
    case 'jpeg':
    default:
      return 'image/jpeg'
  }
}

const getFileNameData = (picture) => {
  const extension = picture.fileName.split('.').pop()

  return {
    fileName: picture.fileName,
    fileType: getFileType(extension),
  }
}

export const fileFromDataUrl = async ({ cropper, picture }) => {
  const { fileType, fileName } = getFileNameData(picture)

  const { width, height } = cropper.getCropBoxData()
  const canvasLimitation = await getCanvasMaxSize(width / height)

  const imageData = cropper.getCroppedCanvas(canvasLimitation).toDataURL(fileType)
  const blob = dataURLtoBlob(imageData)

  return new File([blob], fileName, { type: blob.type })
}

export const isRotated = (rotationAngle) => rotationAngle % 180 !== 0

const isMobile = () => isAppleMobileDevice || /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

// unfortunately,  sometimes webgl gives wrong size of maxTextureSize
// so we need to set it manually, we split images on 512 x 512 pieces to provide support for old laptop
// and not powerful mobile devices
// using 512px pieces should provide better picture quality then stretching maxTextureSize (for example 4096px) to the 6000px image for powerful devices
export const CANVAS_PIECE_MAX_SIZE = 512

export const getCanvasMaxSize = () => {
  if (isMobile()) {
    return isAppleMobileDevice ? { maxHeight: 4_096, maxWidth: 4_096 } : { maxHeight: 10_836, maxWidth: 10_836 }
  } else {
    return { maxHeight: 16_384, maxWidth: 16_384 }
  }
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { DraggableArea } from 'react-advanced-cropper'
import cl from 'classnames'

import { RotateBar, RotateBars, RotateValue, RotateValueNumber, RotateWrapper } from './RotateComponent.style'
import { recalculateBars } from './RotateComponent.utils'

const RotateComponent = forwardRef(
  (
    {
      from,
      to,
      value,
      step = 2.5,
      thickness = 2,
      onBlur,
      onChange,
      className,
      valueBarClassName,
      barsClassName,
      barClassName,
      highlightedBarClassName,
      zeroBarClassName,
      density = 10,
    },
    ref,
  ) => {
    const barsRef = useRef(null)

    const [dragging, setDragging] = useState(false)

    const [items, setItems] = useState([])

    const recalculate = () => {
      if (!barsRef.current) return

      setItems(recalculateBars(barsRef.current.clientWidth, density, thickness, from, to, value, step))
    }

    useEffect(recalculate, [density, thickness, from, to, value, step])

    useImperativeHandle(ref, () => {
      return {
        refresh: recalculate,
      }
    })

    const onMove = (directions) => {
      if (!barsRef.current)
        return

      const width = barsRef.current.clientWidth
      const count = width / density
      const shift = -(directions.left / barsRef.current.clientWidth) * count * step
      if (onChange) {
        if (value + shift > to) {
          onChange(to - value)
        } else if (value + shift < from) {
          onChange(from - value)
        } else {
          onChange(shift)
        }
      }
    }

    const onMoveEnd = () => {
      document.body.classList.remove('dragging')
      setDragging(false)
      onBlur?.()
    }

    const onMoveStart = () => {
      document.body.classList.add('dragging')
      setDragging(true)
    }

    return (
      <RotateWrapper className={className}>
        <DraggableArea onMoveStart={onMoveStart} onMove={onMove} onMoveEnd={onMoveEnd} useAnchor={false}>
          <RotateBars className={cl(dragging && 'is--dragging', barsClassName)} ref={barsRef}>
            {items.map((bar) => (
              <RotateBar
                className={cl(
                  bar.zero && 'bar--zero',
                  bar.highlighted && 'bar--highlighted',
                  barClassName,
                  bar.highlighted && highlightedBarClassName,
                  bar.zero && zeroBarClassName,
                )}
                key={bar.value}
                style={{
                  width: bar.opacity ? thickness : 0,
                  opacity: bar.opacity,
                  transform: `translate(${bar.translate}px, -50%)`,
                }}
              />
            ))}
            <RotateValue className={valueBarClassName}>
              <RotateValueNumber>{value.toFixed(1)}°</RotateValueNumber>
            </RotateValue>
          </RotateBars>
        </DraggableArea>
      </RotateWrapper>
    )
  },
)

RotateComponent.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  thickness: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  valueBarClassName: PropTypes.string,
  barsClassName: PropTypes.string,
  barClassName: PropTypes.string,
  highlightedBarClassName: PropTypes.string,
  zeroBarClassName: PropTypes.string,
  density: PropTypes.number,
}

RotateComponent.displayName = 'RotateComponent'

export default RotateComponent

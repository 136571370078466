import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

const dynamicStyles = (props) => props.size === 'small' && css`
  .MuiInputBase-input {
    font-size: ${theme.typography.size.xs};
    padding: 0.25rem;
    height: 28px;
  }

  .icon {
    right: 0.5rem;
    top: 0.85rem;
    height: 1rem;
    width: 1rem;
  }

  .error-label {
    top: 1.8rem;
  }

  ${props.gutter && `
    padding-bottom: 1.5rem;
  `}
`

export default styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  ${(props) => props.gutter && `
    padding-bottom: 1.5rem;
  `}

  .input-wrapper {
    position: relative;
  }

  .secondary {
    color: ${theme.colors.medgray};
    font-style: italic;
    margin-top: 4px;
  }

  .icon {
    fill: ${theme.colors.darkblue};
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.5rem;

    &.error {
      fill: ${theme.colors.red};
    }
  }

  .error-label {
    color: ${theme.colors.red};
    font-weight: 500;
    text-transform: capitalize;
    font-style: normal;

    &:empty {
      min-height: 1rem;
    }
  }

  &.disabled {
    .MuiInputBase-input {
      background-color: #EFEFEF4D;
      caret-color: transparent;
      cursor: default;
    }
  }

  ${dynamicStyles}
`

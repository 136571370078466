import React from 'react'

import { useRouter } from 'next/router'

import NavWrap from 'components/NavWrap'

/**
 * High Order Component that wraps a component with the following:
 * head, optional nav, global errors, and modals
 *
 * @param {Component} Component - The component to wrap and enhance.
 * @param {Component} Nav - Top Nav component.
 * @param {object} props - Top level properties passed to component.
 */
const withLayout = (Component, Nav = null) => {
  const WithLayoutWrapper = (props) => {
    const router = useRouter()

    return (
      <>
        {Nav && (
          <NavWrap {...props}>
            <Nav {...props} />
          </NavWrap>
        )}
        <Component router={router} {...props} />
      </>
    )
  }

  WithLayoutWrapper.displayName = 'WithLayoutWrapper'

  return WithLayoutWrapper
}

export default withLayout

import React from 'react'
import { string } from 'prop-types'

import Button from 'components/Buttons/Button'
import H2 from 'components/Text/H2'
import P from 'components/Text/P'
import useModal from 'hooks/useModal'

import Modal from '../Modal'

import ConfirmationModalStyle from './ConfirmationModal.style'

const ConfirmationModal = ({ title, description, buttonLabel = 'Got it!' }) => {
  const { unsetModal } = useModal()

  return (
    <ConfirmationModalStyle>
      <Modal handleClose={unsetModal} className={'confirmation-modal'}>
        <div className={'content'}>
          <H2 className={'center darkblue modal__header'}>{title}</H2>
          <P className={'center'}>{description}</P>
          <div className={'buttons'}>
            <Button onClick={unsetModal}>{buttonLabel}</Button>
          </div>
        </div>
      </Modal>
    </ConfirmationModalStyle>
  )
}

ConfirmationModal.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  buttonLabel: string,
}

export default ConfirmationModal

/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { array, bool, func } from 'prop-types'

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import TableContainer from '@mui/material/TableContainer'

import Checkbox from 'components/FormFields/Checkbox'
import Image from 'components/Image'
import LoadingSpinner from 'components/LoadingSpinner'
import PictureTagsList from 'components/PictureMeta/PictureTagsList'
import ReactTable, { TableInfiniteWrapper } from 'components/ReactTable'
import UploadPictureWrap from 'components/UploadPictureWrap'
import { PAGE_SIZE } from 'containers/Pictures/Pictures.constants'
import useUserInformation from 'dux/hooks/useUserInformation'
import { HiddenOverlay } from 'hoc/withSelect/withSelect.style'

import Style, { ListPageWrap } from './List.style'

const PictureList = ({
  isAllSelected,
  isPictureSelected,
  multiselect,
  onClick,
  onIconClick,
  onSelectAll,
  onUploadClick,
  total,
  pictures,
  showUploads,
  loadMoreItems,
  isItemLoaded,
  uploads,
}) => {
  const { isLoggedAsUser } = useUserInformation()

  const ref = useRef()
  const [dimensions, setDimensions] = useState({ width: 0 })

  const containerStyle = useMemo(() => ({
    overflowX: !total ? 'hidden' : 'auto',
  }), [dimensions, total])

  const [loading, setLoading] = useState(false)
  const handleLoadMore = ({ startIndex }) => {
    if (loading) return

    setLoading(true)

    const promise = loadMoreItems(Math.ceil(startIndex / PAGE_SIZE))
    return promise.then(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setDimensions({
      width: ref.current.offsetWidth,
    })
  }, [])

  const columns = [
    {
      accessor: 'selected',
      Header: () => multiselect ? (
        <Checkbox color={'primary'} onClick={onSelectAll} checked={isAllSelected} />
      ) : (
        <label className={'label sm darkgray'}>Select</label>
      ),
      sortable: false,
      width: 75,
      Cell: ({ row: { original } }) => (
        <Checkbox
          color={'primary'}
          checked={isPictureSelected(original)}
          onClick={(e) => {
            e.stopPropagation()
            onIconClick(e, original.id)
          }}
        />
      ),
    },
    {
      accessor: 'image',
      Header: () => <label className={'label sm darkgray'}>Image</label>,
      sortable: false,
      width: 100,
      Cell: ({ row: { original } }) => {
        return original?.status ? (
          <ListPageWrap>
            <UploadPictureWrap
              size={'small'}
              key={original._key}
              onClick={onUploadClick}
              picture={original}
              showUploads={showUploads}
              view={'list'}
            />
          </ListPageWrap>
        ) : (
          <ListPageWrap>
            <Image picture={original} className={'img'} isBackground={true} />
            {original.hidden && (
              <HiddenOverlay>
                <VisibilityOffIcon color={'inherit'} />
              </HiddenOverlay>
            )}
          </ListPageWrap>
        )
      },
    },
    {
      accessor: 'fileName',
      Header: () => <label className={'label sm darkgray'}>File name</label>,
      sortable: false,
      minWidth: 320,
      Cell: ({ row: { original } }) => (
        <label className={'label xs'}>
          {original?.status ? original.name : original.fileName}
        </label>
      ),
    },
    {
      id: 'child',
      accessor: (row) => row?.child?.name,
      Header: () => <label className={'label sm darkgray'}>Name</label>,
      sortable: false,
      width: 150,
    },
    {
      id: 'label',
      Header: () => <label className={'label sm darkgray'}>Age/Grade Label</label>,
      sortable: false,
      width: 150,
      accessor: (row) => row?.label?.name,
    },
  ]

  if (isLoggedAsUser) {
    columns.push({
      accessor: 'tags',
      Header: () => <label className={'label sm darkgray'}>Tags</label>,
      sortable: false,
      minWidth: 320,
      Cell: ({ row: { original } }) => {
        const { tags } = original

        return (<PictureTagsList tags={tags} />)
      },
    })
  }

  return (
    <Style ref={ref}>
      <TableContainer style={containerStyle}>
        <TableInfiniteWrapper
          total={total}
          isItemLoaded={isItemLoaded}
          loadMoreItems={handleLoadMore}
        >
          {({ ref, ...restProps }) => (
            <ReactTable
              ref={ref}
              data={[
                ...uploads,
                ...pictures,
              ]}
              columns={columns}
              onRowClick={onClick}
              total={total}
              {...restProps}
            />
          )}
        </TableInfiniteWrapper>
        {loading && <LoadingSpinner key={0} />}
      </TableContainer>
    </Style>
  )
}

PictureList.propTypes = {
  isPictureSelected: func,
  multiselect: bool,
  isAllSelected: bool,
  onClick: func,
  onIconClick: func,
  onUploadClick: func,
  onSelectAll: func,
  pictures: array.isRequired,
  showUploads: bool,
  selectAllPictures: func,
  uploads: array,
}

export default PictureList

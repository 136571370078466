import React from 'react'
import PropTypes from 'prop-types'

import { Wrap } from './DashedBorder.style'

const DashedBorder = ({ sx, className }) => {
  return (
    <Wrap sx={sx} className={className}>
      <svg
        xmlns={'http://www.w3.org/2000/svg'}
        width={'100%'}
        height={'100%'}
        overflow={'visible'}
      >
        <rect
          rx={2}
          ry={2}
          width={'100%'}
          height={'100%'}
          fill={'none'}
          stroke={'currentColor'}
          strokeWidth={'2'}
          strokeDasharray={'6 6'}
          strokeLinecap={'round'}
        />
      </svg>
    </Wrap>
  )
}

DashedBorder.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
}

export default DashedBorder

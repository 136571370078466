import { CancelToken } from 'axios'
import { thunk } from 'easy-peasy'
import omit from 'lodash/omit'

import { createPage } from 'containers/Book/Book.utils'
import api from 'utils/api'
// TODO: After full migration to v3 and testing,
// optimize store actions for optimistic UI updates and
// check if Books/Book stores need updates based on backend results.

let updateBookTokens = new Map()

const getBooksApiUrl = {
  getBook: (bookId) => `/api/v3/user/books/${bookId}`,
  getBooks: () => `/api/v3/user/books`,
  updateBook: (bookId) => `/api/v3/user/books/${bookId}`,
  sendToCustomerEmail: (bookId) => `/api/v2/user/books/${bookId}/send_to_customer`,
  deleteBook: (bookId) => `/api/v3/user/books/${bookId}`,
  createBook: () => `/api/v3/user/books`,
  addBookPages: (bookId) => `/api/v3/user/books/${bookId}/pages`,
  updateSection: (bookId, sectionId) => `/api/v3/user/books/${bookId}/sections/${sectionId}`,
}

export const getUpdateSectionConfig = (bookId, sectionId, payload, updateBookTokens) => (
  {
    method: 'put',
    url: getBooksApiUrl.updateSection(bookId, sectionId),
    data: {
      section: omit(payload, 'id'),
    },
    cancelToken: updateBookTokens?.get(bookId).token,
  }
)

const booksService_v3 = {
  readBookById_v3: thunk(async (actions, id) => {
    const config = {
      method: 'get',
      url: getBooksApiUrl.getBook(id),
    }

    try {
      const response = await api.get(config)

      actions.setBook_v3(response.data.data)

      return response.data.data

    } catch (error) {
      actions.setError_v3(error)
      return { error }
    }

  }),
  readBooks_v3: thunk(async (actions, params) => {
    actions.setIsLoading_v3(true)
    const config = {
      method: 'get',
      url: getBooksApiUrl.getBooks(),
      params,
    }

    const { error, response } = await api.handleRequest(config)
    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.setBooks_v3(response.data.data)
      return response.data.data
    }
  }),

  updateCoverSection_v3: thunk(async (actions, { bookId, sectionId, payload }) => {
    actions.setCoverSection_v3({ bookId, sectionId, payload })

    if (updateBookTokens.has(bookId)) {
      updateBookTokens.get(bookId).cancel()
    }
    updateBookTokens.set(bookId, CancelToken.source())

    const config = getUpdateSectionConfig(bookId, sectionId, payload, updateBookTokens)
    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      const data = response.data.data
      actions.setCoverSection_v3({ bookId, sectionId, payload: { ...data } })
      return response.data.data
    }
  }),


  addBookPages_v3: thunk(async (actions, { bookId, selectedPictures, position }, helpers) => {
    const pagesPayload = selectedPictures.map((picture) => createPage(picture, false))
    const activeBookId = helpers.getStoreState().book_v3.activeBook_v3.id
    if (activeBookId === bookId) {
      const setActiveBookPages = helpers.getStoreActions().book_v3.setPagesByPictureIds_v3
      setActiveBookPages({ addedPicturesIds: selectedPictures, position })
    }
    if (updateBookTokens.has(bookId)) {
      updateBookTokens.get(bookId).cancel()
    }
    updateBookTokens.set(bookId, CancelToken.source())

    const config = {
      method: 'post',
      url: getBooksApiUrl.addBookPages(bookId),
      data: {
        position: position ?? -1,
        pages: pagesPayload,
      },
      cancelToken: updateBookTokens.get(bookId).token,
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      return response.data.data
    }
  }),

  updateBook_v3: thunk(async (actions, { id, pictures: pictureIds, ...updatedBook }) => {
    actions.setBook_v3({
      id,
      ...updatedBook,
    })

    if (updateBookTokens.has(id)) {
      updateBookTokens.get(id).cancel()
    }

    // TODO remove?
    // updatedBook.pictures = pictureIds

    updateBookTokens.set(id, CancelToken.source())

    const config = {
      method: 'put',
      url: getBooksApiUrl.updateBook(id),
      data: {
        book: {
          ...updatedBook,
        },
      },
      cancelToken: updateBookTokens.get(id).token,
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.setBook_v3({
        ...response.data.data,
        // TODO recheck
        // pictureIds: response.data.data.pictures.map(({ id }) => id),
      })
      return response.data.data
    }
  }),

  sendToCustomerEmail_v3: thunk(async (actions, { bookId, data }) => {
    actions.setIsLoading_v3(true)
    const config = {
      method: 'post',
      url: getBooksApiUrl.sendToCustomerEmail(bookId),
      data,
    }
    const { error } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }
  }),
  deleteBook_v3: thunk(async (actions, bookId) => {
    actions.setIsLoading_v3(true)
    const config = {
      method: 'delete',
      url: getBooksApiUrl.deleteBook(bookId),
    }
    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.removeBook_v3(response.data.data.id)
      return response.data.data
    }
  }),
}

export default booksService_v3

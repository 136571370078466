import { useEffect, useMemo, useState } from 'react'

import { useBeforeunload } from 'react-beforeunload'

import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import uploadService from 'services/uploadService'

const defaultFilter = (picture) => picture.status !== UPLOADING_IMAGE_STATUSES.SUCCESS

const useUploadService = (filterCallback = defaultFilter) => {
  const [uploads, setUploads] = useState([])

  const isTabCloseDisabled = useMemo(() => {
    return uploads.some(({ status }) => [
      UPLOADING_IMAGE_STATUSES.IN_PROGRESS,
      UPLOADING_IMAGE_STATUSES.QUEUED,
    ].includes(status))
  }, [uploads])

  useBeforeunload((event) => {
    if (isTabCloseDisabled) {
      event.preventDefault()
      event.returnValue = ''
    }
  })

  useEffect(() => {
    const emitterHandler = (pics) => {
      setUploads(pics.filter(filterCallback))
    }

    uploadService.emitter.on('update', emitterHandler)

    return () => {
      uploadService.emitter.removeListener('update', emitterHandler)
    }
  }, [])

  return uploads
}

export default useUploadService

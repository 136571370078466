import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

const ConfirmImageEdit = ({ checked, handleChange }) => {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography  sx={(theme) => ({ color: theme.colors.gray[700], textAlign: 'center', mt: '16px', mb: '24px' })} variant={'body1'} >
        The changes will be applied to your <Typography variant={'strong'}>ORIGINAL IMAGE</Typography> and <Typography variant={'strong'}>CANNOT BE UNDONE</Typography>.
      </Typography>
      <FormControlLabel
        label={'Don’t show this message again'}
        sx={(theme) =>  ({ lineHeight: '1.4', marginRight: {
          [theme.breakpoints.down('xs')]: 'auto',
        } })}
        control={<Checkbox
          checked={checked}
          onChange={handleChange}
          color={'primary'}
        />}
      />
    </Box>

  )
}

ConfirmImageEdit.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
}

export default ConfirmImageEdit

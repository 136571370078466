import React from 'react'

import { Link, Typography } from '@mui/material'

import { COMPANY_HELP_EMAIL } from 'utils/constants'

export default {
  book: {
    pictures: {
      less_than: {
        title: 'Max Image Count Reached',
        content: () => {
          return (
            <Typography variant={'body1'}>
              Please remove images before adding more or <Link href={`mailto:${COMPANY_HELP_EMAIL}`}> contact
              customer</Link> support to add pages to your book.
            </Typography>
          )
        },
      },
      more_than: {
        title: 'You need to select more pictures!',
        content: 'Your book should contain at least {count} pictures. Please add or select more pictures to proceed.',
      },
    },
  },
}

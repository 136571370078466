function range(from, to, step = 1) {
  let index = -1
  let length = Math.max(Math.ceil((to - from) / (step || 1)), 0)

  const result = length ? new Array(length) : []

  while (length--) {
    result[++index] = from
    from += step
  }
  return result
}


export const recalculateBars = (width, density, thickness, from, to, value, step) => {
  const count = width / density

  const neededLeftBarsCount = Math.max(0, Math.floor(count / 2) - Math.round((value - from) / step))

  const neededRightBarsCount = Math.max(0, Math.floor(count / 2) - Math.round((to - value) / step))

  const values = [
    ...range(from - neededLeftBarsCount * step, from, step),
    ...range(from, to + step, step),
    ...range(to + step, to + step + neededRightBarsCount * step, step),
  ]

  const radius = Math.abs(Math.ceil(count / 2) * step)

  return values.map((barValue) => {
    const sign = Math.sign(barValue - value)

    // Opacity
    let translate
    if (Math.abs(barValue - value) / step <= Math.ceil(count / 2)) {
      const multiplier =
        Math.sqrt(Math.pow(radius, 2) - Math.pow(value + sign * radius - barValue, 2)) / radius
      translate = width / 2 + sign * (width / 2) * Math.pow(multiplier, 2.5)
    } else {
      translate = width / 2 + (sign * width) / 2
    }

    // Translate
    let opacity = 0
    if (count > 0 && Math.abs(barValue - value) / step <= Math.ceil(count / 2)) {
      opacity = Math.pow(
        Math.sqrt(Math.pow(radius, 2) - Math.pow(value - barValue, 2)) / radius,
        4,
      )
    }

    return {
      value: barValue,
      highlighted:
        (value < 0 && barValue >= value && barValue <= 0) ||
        (value > 0 && barValue <= value && barValue >= 0),
      zero: barValue === 0,
      opacity,
      translate: translate - thickness / 2,
    }
  })
}

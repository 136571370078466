import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import EditPageTextForm from 'components/EditPageTextForm'
import NewModal from 'components/NewModal'
import PlainFullScreenModal from 'components/PlainFullScreenModal'
import useMetaThemeColor from 'hooks/useMetaThemeColor'
import useNewResponsive from 'hooks/useNewResponsive'

import { ActionsMobile, AdaptiveImagesGridStyle, FormWrapperBox, ModalBodyBox } from './BulkEditImagesTextModal.style'

const BulkEditImagesTextModal = ({
  open,
  onClose,
  pictures,
  closeImmediatelyOnSave = false,
  onEditSuccess,
  title,
  imagesTitle,
}) => {
  const scrollBodyRef = useRef()
  const theme = useTheme()
  const { isMobile  } = useNewResponsive()

  const [nextPicturesData, setNextPicturesData] = useState({})
  const [formIsValid, setFormIsValid] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useMetaThemeColor(theme.colors.sky[100], true)

  const onValuesChangeSubmit = (key, value) => {
    setNextPicturesData((prev) => ({ ...prev, [key]: value }))
  }

  const onValuesFieldReset = (key) => {
    const updatedNextPicturesData = { ...nextPicturesData }
    delete updatedNextPicturesData[key]
    setNextPicturesData(updatedNextPicturesData)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      if (closeImmediatelyOnSave) {
        onClose()
        await onEditSuccess(nextPicturesData)
      } else {
        const result = await onEditSuccess(nextPicturesData)
        if (result) onClose()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleFormValidation = useCallback((isValid) => {
    setFormIsValid(isValid)
  }, [])

  const ModalWrapper = isMobile ? PlainFullScreenModal : NewModal
  const restProps = useMemo(() => (!isMobile ? {
    cancelButton: null,
    width: '100%',
    bgColor: theme.colors.sky[100],
  } : {}), [isMobile, theme])

  return (
    <ModalWrapper
      open={open}
      title={isMobile ? title : undefined}
      onClose={onClose}
      {...restProps}
    >
      {isMobile && <Toolbar />}
      <ModalBodyBox ref={scrollBodyRef}>
        <AdaptiveImagesGridStyle pictures={pictures} title={imagesTitle} />
        <FormWrapperBox>
          {!isMobile && (
            <Typography variant={'h4'} mb={1} align={'center'}>{title}</Typography>
          )}
          <EditPageTextForm
            isBulkEdit
            pictures={pictures}
            onFormIsValidChange={handleFormValidation}
            onValuesChangeSubmit={onValuesChangeSubmit}
            onValuesFieldReset={onValuesFieldReset}
            parentNodeRef={scrollBodyRef}
          />
          {!isMobile && (
            <Button
              disabled={!formIsValid || isLoading}
              sx={{ width: '220px', marginTop: '32px' }}
              variant={'new-contained-primary'}
              onClick={handleSave}
            >
              Save
            </Button>
          )}
        </FormWrapperBox>
      </ModalBodyBox>
      {isMobile && (
        <ActionsMobile>
          <Button
            disabled={!formIsValid || isLoading}
            variant={'new-contained-primary'}
            fullWidth={true}
            onClick={handleSave}
          >
            Save
          </Button>
        </ActionsMobile>
      )}
    </ModalWrapper>
  )
}

BulkEditImagesTextModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pictures: PropTypes.array,
  closeImmediatelyOnSave: PropTypes.bool,
  onEditSuccess: PropTypes.func,
  title: PropTypes.string,
  imagesTitle: PropTypes.string,
}

export default BulkEditImagesTextModal

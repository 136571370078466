import React from 'react'

import UploadQueueList from 'components/UploadManager/UploadQueueList'
import useUploaderQueue from 'hooks/useUploaderQueue'

import { Wrap } from './UploadManager.style'

const UploadManager = () => {
  const { isVisible, tabs, clearTab } = useUploaderQueue()

  if (!isVisible) return null

  const handleClose = (tabKey) => {
    clearTab(tabKey)
  }

  return (
    <Wrap>
      {tabs.map((tab) => (
        <UploadQueueList
          key={tab.key}
          id={tab.key}
          title={tab.title}
          subtitle={tab.subtitle}
          tree={tab.tree}
          disabledClose={tab.disabledClose}
          onClose={handleClose}
        />
      ))}
    </Wrap>
  )
}

export default UploadManager

import React from 'react'
import PropTypes from 'prop-types'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import Button from '@mui/material/Button'

import {
  useNewUploadDropzoneContext,
} from 'components/UploadDropzone/NewUploadDropzoneProvider/useNewUploadDropzoneContext'

import { StyledDashedBorder, Subtitle, Title, UploadZoneBox, VisuallyHiddenInput } from './NewUploadButton.style'

const NewUploadButton = ({ className, fullScreen, subtitle, title, buttonText }) => {
  const { openDropzone, getInputProps } = useNewUploadDropzoneContext()

  return (
    <UploadZoneBox
      className={className}
      fullScreen={fullScreen}
      onClick={buttonText ? undefined : openDropzone}
      sx={!buttonText ? {
        '&:hover': {
          cursor: 'pointer',
        },
      } : undefined}
    >
      <StyledDashedBorder />
      <FileUploadIcon
        sx={(theme) => ({
          color: theme.colors.sky[700],
          fontSize: fullScreen ? '64px' : '28px',
          mb: fullScreen ? '12px' : 0,
        })}
      />
      <Title fullScreen={fullScreen}>{title}</Title>
      {!!subtitle && (
        <Subtitle variant={'subtitle2'}>{subtitle}</Subtitle>
      )}
      {buttonText && (
        <Button
          variant={'new-contained-primary'}
          onClick={openDropzone}
          sx={(theme) => ({
            mt: 4,
            width: '220px',
            [theme.breakpoints.up('md')]: {
              mt: '20px',
            },
          })}
        >
          {buttonText}
        </Button>
      )}
      <VisuallyHiddenInput type={'file'} {...getInputProps()} />
    </UploadZoneBox>
  )
}

NewUploadButton.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  subtitle: PropTypes.string,
  fullScreen: PropTypes.bool,
  buttonText: PropTypes.string,
}

export default NewUploadButton

import React from 'react'
import PropTypes from 'prop-types'

import theme from 'styles/theme'

import LoadingSpinnerStyle from './LoadingSpinner.style'

const positions = {
  absolute: 'absolute',
  fixed: 'fixed',
  relative: 'relative',
  contained: 'contained',
}

/**
 * Loading Spinner component with different types of positioning
 */
const LoadingSpinner = ({
  className,
  color,
  onClick,
  position,
  size,
}) => {
  const { colors } = theme

  const colorValue = colors[color] || colors.blue
  const positionClass = positions[position] || ''
  const sizeValue = size
    ? size
    : position === positions.fixed || position === positions.contained
      ? 100
      : 60

  const handleClick = (e) => onClick ? onClick(e) : null

  return (
    <LoadingSpinnerStyle
      color={color}
      className={`${className} ${positionClass}`}
      onClick={handleClick}
      height={sizeValue}
      width={sizeValue}
    >
      <div className={'spinner-container'}>
        <svg width={sizeValue} height={sizeValue} viewBox={'0 0 45 45'} xmlns={'http://www.w3.org/2000/svg'}
          stroke={colorValue} aria-label={'audio-loading'}>
          <g fill={'none'} fillRule={'evenodd'} transform={'translate(1 1)'} strokeWidth={'2'}>
            <circle cx={'22'} cy={'22'} r={'6'} strokeOpacity={'0'}>
              <animate attributeName={'r'} begin={'1.5s'} dur={'3s'} values={'6;22'} calcMode={'linear'}
                repeatCount={'indefinite'}></animate>
              <animate attributeName={'stroke-opacity'} begin={'1.5s'} dur={'3s'} values={'1;0'} calcMode={'linear'}
                repeatCount={'indefinite'}></animate>
              <animate attributeName={'stroke-width'} begin={'1.5s'} dur={'3s'} values={'2;0'} calcMode={'linear'}
                repeatCount={'indefinite'}></animate>
            </circle>
            <circle cx={'22'} cy={'22'} r={'8'}>
              <animate attributeName={'r'} begin={'0s'} dur={'1.5s'} values={'6;1;2;3;4;5;6'} calcMode={'linear'}
                repeatCount={'indefinite'}></animate>
            </circle>
          </g>
        </svg>
      </div>
    </LoadingSpinnerStyle>
  )
}

LoadingSpinner.propTypes = {
  /** custom class name */
  className: PropTypes.string,
  /** spinner color */
  color: PropTypes.string,
  /** onclick handler */
  onClick: PropTypes.func,
  /** position */
  position: PropTypes.string,
  /** size (height and width - always square) */
  size: PropTypes.number,
}

export default LoadingSpinner

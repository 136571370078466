import React, { useCallback, useState } from 'react'
import { func, object, string } from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'

import Button from 'components/Buttons/Button'
import DropzoneEmptyScreen from 'components/DropzoneEmptyScreen'
import { EMPTY_SCREEN_REASONS } from 'components/DropzoneEmptyScreen/DropzoneEmptyScreen.constants'
import Icon from 'components/Icon'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import TabPanel from 'components/Tabs/TabPanel'
import P from 'components/Text/P'
import UploadPicture from 'components/UploadPicture'
import Pictures from 'containers/Pictures'
import { usePicturesLoadMore } from 'containers/Pictures/Pictures.hooks'
import { useAppIsLoading } from 'dux/slice/ui'
import { UploadDropzoneProvider } from 'hooks/useUploadingDropzone'
import uploadService from 'services/uploadService'
import theme from 'styles/theme'

import { TAB_IDS } from './ReplaceSwapImage.constants'
import ReplaceSwapImageStyle from './ReplaceSwapImage.style'

const ReplaceSwapImage = ({ onClose, picture, defaultTabId = TAB_IDS.SWAP }) => {
  const swapPicture = useStoreActions((actions) => actions.book_v3.swapPicture_v3)
  const toggleSelectPicture = useStoreActions((actions) => actions.pictures.toggleSelectPicture)
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)
  const excludeIds = useStoreState((state) => state.book_v3.pictureIds_v3)
  const isLoading = useAppIsLoading()
  const picturesLoading = useStoreState((state) => state.pictures.isLoading)
  const pictures = useStoreState((state) => state.pictures.list)
  const filtersApplied = useStoreState((state) => state.pictures.filtersApplied)

  const [contentRef, setContentRef] = useState(null)
  const [swapPictureId, setSwapPictureId] = useState(null)
  const [visibleElements, setVisibleElements] = useState({
    notice: 'Swapping this image will remove it from your book',
    saveButton: true,
  })
  const { handleLoadMore } = usePicturesLoadMore({ excludeIds })

  const updateContentRef = useCallback((node) => {
    if (node !== null) {
      setContentRef(node)
    }
  }, [])

  const handleSelectPicture = (pictureId) => {
    toggleSelectPicture(pictureId)
    setSwapPictureId(pictureId)
  }

  const handleSave = () => {
    if (!swapPictureId) return

    const newPicture = pictures.find((p) => p.id === swapPictureId)

    if (!newPicture) return

    swapPicture({ pictureToSwapId: picture.id, newPicture })

    onClose(newPicture.id)
  }

  const handleClose = useCallback(() => {
    onClose(picture.id)
  }, [picture])

  const replaceAction = async (files) => {
    await updatePicture({ picture: { id: picture.id, image: files[0] } })

    onClose()
  }

  const handleTabChange = (tabId) => {
    if (tabId === TAB_IDS.SWAP) {
      setVisibleElements({
        notice: 'Swapping this image will remove it from your book',
        saveButton: true,
      })
    } else if (tabId === TAB_IDS.REPLACE) {
      setVisibleElements({
        notice: 'Replacing this image will remove it from your account',
        saveButton: false,
      })
    }
  }

  const renderEmptyScreen = useCallback((props) => (
    <DropzoneEmptyScreen
      {...props}
      reason={
        filtersApplied
          ? EMPTY_SCREEN_REASONS.FILTERS
          : EMPTY_SCREEN_REASONS.NO_AVAILABLE_DATA
      }
    />
  ), [filtersApplied])

  const onDrop = useCallback(async (files) => {
    files.forEach((picture) => uploadService.addPicture({ picture }))
  }, [])

  return (
    <ReplaceSwapImageStyle style={{ display: isLoading ? 'none' : 'block' }}>
      <div className={'buttons'}>
        <P onClick={handleClose} className={'p white desktop-btn'}>
          Cancel
        </P>
        {visibleElements.saveButton && (
          <P onClick={handleSave} className={'p white desktop-btn'}>
            Save
          </P>
        )}

        <Button onClick={handleClose} className={'mobile-btn'}>
          Cancel
        </Button>

        {visibleElements.saveButton && (
          <Button onClick={handleSave} className={'mobile-btn'}>
            Save
          </Button>
        )}
      </div>

      <div className={'notice hide-mobile'}>
        {visibleElements.notice ? (
          <>
            <Icon name={'error-outline'} className={'white'} />
            <P className={'p white'}>
              {visibleElements.notice}
            </P>
          </>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>

      <div className={'content-wrap'} ref={updateContentRef}>
        <Tabs defaultTabId={defaultTabId} onTabChange={handleTabChange}>
          {defaultTabId === TAB_IDS.SWAP && (
            <div className={'tabs-wrap'}>
              <Tab id={TAB_IDS.SWAP}>
                Swap Image
              </Tab>
              <Tab id={TAB_IDS.REPLACE}>
                Replace Image
              </Tab>
            </div>
          )}

          <div className={'notice hide-desktop'}>
            {visibleElements.notice && (
              <>
                <Icon name={'error-outline'} className={'darkblue'} />
                <P className={'sm darkblue'}>
                  {visibleElements.notice}
                </P>
              </>
            )}
          </div>
          <div className={'tab-panel-wrap'}>
            <UploadDropzoneProvider onDrop={onDrop}>
              {({ getRootProps }) => {
                const { onFocus, tabIndex, ...rootProps } = getRootProps({ className: 'dropzone', refKey: 'innerRef' })
                return (
                  <TabPanel id={TAB_IDS.SWAP} {...rootProps}>
                    <Pictures
                      showModal={false}
                      handleLoadMore={handleLoadMore}
                      selectable={true}
                      scrollParentRef={contentRef}
                      multiselect={false}
                      onSelectPicture={handleSelectPicture}
                      useWindowScroll={false}
                      renderEmptyScreen={renderEmptyScreen}
                      showUploads={false}
                      isLoading={isLoading}
                      bgColor={theme.colors.white}
                      showEditPictureModal={false}
                    />
                  </TabPanel>
                )
              }}
            </UploadDropzoneProvider>

            <TabPanel id={TAB_IDS.REPLACE}>
              <UploadPicture
                onDrop={replaceAction}
                isLoading={picturesLoading}
              />
            </TabPanel>
          </div>

        </Tabs>
      </div>
    </ReplaceSwapImageStyle>
  )
}

ReplaceSwapImage.propTypes = {
  onClose: func.isRequired,
  picture: object,
  defaultTabId: string,
}

export default ReplaceSwapImage

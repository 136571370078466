import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import CachedIcon from '@mui/icons-material/Cached'
import ErrorIcon from '@mui/icons-material/Error'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import ImageIcon from '@mui/icons-material/Image'
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'

import AnimatedUploadIcon from 'components/svg/AnimatedUploadIcon'
import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import uploadService from 'services/uploadService'
import copyTextToClipboard from 'utils/copyToClipboard'

import CopyButton from './CopyButton'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
  },
  nested: {
    padding: '6px 16px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200,
  },
  subtitle: {
    color: theme.colors.gray[500],
  },
  copyIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    marginLeft: 4,
    color: theme.colors.gray[500],
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.colors.gray[900],
    },
  },
  folderRoot: {
    paddingTop: 0,
    bottomOffset: 0,
    background: theme.colors.gray[50],
  },
  folderIcon: {
    color: '#7A68AE',
  },
  iconRoot: {
    minWidth: 36,
  },
  fileIcon: {
    color: '#102371CC',
  },
  doneIcon: {
    color: theme.colors.green[700],
    marginLeft: 4,
  },
  retryButton: {
    height: 24,
    padding: '0 8px',
    marginRight: -4,
  },
}))

const UploaderQueueFolder = ({
  subtitle,
  name,
  date,
  files,
}) => {
  const classes = useStyles()

  const isFolderAvailable = !!name || !!date

  const [open, setOpen] = useState(!isFolderAvailable)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleNameCopy = (name) => {
    copyTextToClipboard(name)
  }

  const handleRetryClick = () => {
    uploadService.requeueFailed()
  }

  return (
    <>
      {isFolderAvailable && (
        <ListItem button onClick={handleClick} className={classes.folderRoot}>
          <ListItemIcon className={classes.iconRoot}>
            <FolderOpenIcon className={classes.folderIcon} />
          </ListItemIcon>
          <ListItemText
            primary={(<Typography
              variant={'body2'}
              noWrap={true}
              sx={(theme) => ({
                fontSize: '0.875rem',
                color: theme.colors.black,
              })}
            >
              {name}<span className={classes.subtitle}>{name && ' • '}{date}</span>
            </Typography>)}
            secondary={(<Typography
              variant={'subtitle2'}
              noWrap={true}
              className={classes.subtitle}
              sx={() => ({ fontSize: '0.75rem' })}
            >
              {subtitle}
            </Typography>)}
          />
          {open ? <ArrowDropUp color={'action'} /> : <ArrowDropDown color={'action'} />}
        </ListItem>
      )}

      <Collapse in={open} timeout={'auto'} unmountOnExit>
        <List component={'div'} dense disablePadding>
          {files.map((file) => (
            <ListItem
              key={file._key}
              className={classes.nested}
            >
              <ListItemIcon className={classes.iconRoot}>
                {file.status === UPLOADING_IMAGE_STATUSES.RETRY && (
                  <ErrorIcon color={'error'} />
                )}
                {file.status !== UPLOADING_IMAGE_STATUSES.RETRY && (
                  <ImageIcon className={classes.fileIcon} />
                )}
              </ListItemIcon>

              <ListItemText
                classes={{
                  primary: classes.text,
                }}
                primary={(
                  <>
                    <Typography
                      variant={'body2'}
                      noWrap={true}
                      sx={(theme) => ({
                        fontSize: '0.8125rem',
                        color: theme.colors.gray[700],
                      })}
                    >
                      {file.name}
                    </Typography>
                    <CopyButton onClick={() => handleNameCopy(file.name)} />
                  </>
                )}
              />

              {file.status === UPLOADING_IMAGE_STATUSES.SUCCESS && (
                <FileDownloadDoneIcon className={classes.doneIcon} />
              )}

              {[UPLOADING_IMAGE_STATUSES.IN_PROGRESS, UPLOADING_IMAGE_STATUSES.QUEUED].includes(file.status) && (
                <AnimatedUploadIcon />
              )}

              {[UPLOADING_IMAGE_STATUSES.RETRY, UPLOADING_IMAGE_STATUSES.FAILED].includes(file.status) && (
                <Button
                  size={'small'}
                  startIcon={ <CachedIcon />}
                  onClick={handleRetryClick}
                  className={classes.retryButton}
                  variant={'new-text-gray'}
                >Retry</Button>
              )}

            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

UploaderQueueFolder.propTypes = {
  subtitle: PropTypes.node,
  name: PropTypes.string,
  date: PropTypes.string,
  files: PropTypes.array,
}


export default UploaderQueueFolder

import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ className, width, height }) => {
  return (
    <img alt={'Artkive Logotype'} src={'/images/artkiveLogo.svg'} className={className} width={width} height={height} />
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Logo

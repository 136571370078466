/**
 * shiftSelect - Takes the current redux store state and converts it to a JSON encoded object
 * @param {object} event - event
 * @param {array} selectedPictures - pictures currently selected in redux state
 * @param {array} pictures - all pictures in redux state
 * @param {string} pictureId - ID of clicked picture
 * @param {function} cb - callback
 * @return {object}
 */
const shiftSelect = (event, selectedPictures, pictures, pictureId, cb) => {
  if (event.shiftKey && selectedPictures.length) {
    const lastPic = selectedPictures.pop()
    const lastIndex = pictures.findIndex((pic) => pic.id === lastPic.id)
    const newIndex = pictures.findIndex((pic) => pic.id === pictureId)
    const firstSlice = lastIndex < newIndex ? lastIndex : newIndex
    const lastSlice = lastIndex < newIndex ? newIndex : lastIndex
    pictures.slice(firstSlice, lastSlice)
      .filter((pic) => pic.id !== lastPic.id && !pic.selected)
      .map((pic) => cb(pic.id))
  }
}

export default shiftSelect

import { computed } from 'easy-peasy'

import { ORDER_ITEM_TYPES, PROMO_CODE_TYPES } from './Checkout.constants'

/**
 * Calculates promo code discount
 * @param {object} promoCode - applied promo code object
 * @param {number} total - order total
 * @returns {number}
 */
const calculatePromoCodeDiscount = (promoCode, total) => {
  switch (promoCode.discount_method) {
    case PROMO_CODE_TYPES.PERCENTAGE:
      return (total * Number(promoCode.amount))
    case PROMO_CODE_TYPES.AMOUNT:
      return promoCode.amount
    default:
      return 0
  }
}

const CheckoutModel = {
  isLoading: false,
  promoLoading: false,
  activeIndex: 0,
  steps: [],
  order: {
    shippingAddress: null,
    shippingMethod: null,
    paymentMeta: {},
    promoCode: null,
    items: [
      {
        type: ORDER_ITEM_TYPES.BOOK,
      },
      {
        type: ORDER_ITEM_TYPES.ADDON,
      },
    ],
    pricing: {
      [ORDER_ITEM_TYPES.BOOK]: {
        subtotal: 0,
        credits: 0,
        total: 0,
        tax: 0,
      },
    },
  },
  // Computed props
  addons: computed((state) => state.order.items.filter((item) => item.type === ORDER_ITEM_TYPES.ADDON)),
  items: computed((state) => state.order.items.filter((p) => p.qty > 0 || p.prepaid)),
  count: computed((state) => state.order.items.filter((p) => p.qty > 0).length),
  addon: computed((state) => state.order.items.find((item) => item.type === ORDER_ITEM_TYPES.ADDON)),
  // addon: computed((state) => state.order.items.find((item) => item.type === ORDER_ITEM_TYPES.ADDON)),
  book: computed((state) => {
    const book = { ...state.order.items.find((item) => item.type === ORDER_ITEM_TYPES.BOOK) }

    if (book.prepaid) {
      const addon = state.order.items.find((item) => item.type === ORDER_ITEM_TYPES.ADDON)
      book.qty += addon.qty
    }

    return book
  }),
  discount: computed((state) => {
    const { promoCode, pricing } = state.order
    const { membershipDiscount } = pricing.book

    if (pricing.book && membershipDiscount) {
      return membershipDiscount
    }
    if (promoCode) {
      return calculatePromoCodeDiscount(promoCode, pricing.book.subtotal)
    }

    return 0
  }),
  isFreeShipping: computed((state) => !state.order.shippingMethod
      || !state.order.shippingMethod?.price
      || state.order.prepaid
      || (
        state.order.promoCode
        && state.order.promoCode.discount_method === PROMO_CODE_TYPES.SHIPPING
      ),
  ),
}

export default CheckoutModel

import { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    collapsed: false,
    modal: null,
    message: null,
    notification: null,
    loading: [],
    history: [],
    isLoading: false,
    isMobile: false, // TODO rewise its usage, migrate to isMobile from useNewResponsive
    isWizardSplashShown: false,
    isCustomerView: false,
    isOpenPagesDrawer: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      if (state.isLoading === action.payload) return
      state.isLoading = action.payload
    },
    setIsCustomerView: (state, action) => {
      state.isCustomerView = action.payload
    },
    setModal: (state, action) => {
      state.modal = action.payload
    },
    unsetModal: (state) => {
      state.modal = null
    },
    setError: (state, action) => {
      state.message = {
        // TODO: Roman's comment, move to constant
        type: 'error',
        text: action.payload,
      }
      state.isLoading = false
    },
    setSuccess: (state, action) => {
      state.message = {
        type: 'success',
        text: action.payload,
      }
    },
    clearMessage: (state) => {
      state.message = null
    },
    setCollapsed: (state, action) => {
      if (typeof action.payload !== 'boolean') {
        const msg = 'Missing Argument: ui.setCollapsed requires a boolean as the second argument'
        throw Error(msg)
      }
      state.collapsed = action.payload
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed
    },
    togglePagesDrawer: (state) => {
      state.isOpenPagesDrawer = !state.isOpenPagesDrawer
    },
    toggleIsWizardSplashShown: (state) => {
      state.isWizardSplashShown = !state.isWizardSplashShown
    },
    setNotification: (state, action) => {
      if (state.notification === action.payload) return
      state.notification = action.payload
    },
    pushHistory: (state, action) => {
      state.history.unshift(action.payload)
      if (state.history.length > 10) state.history.pop()
    },
    setQueryParams: (state, action) => {
      state.isOpenPagesDrawer = Boolean(action.payload.openDrawer)
    },
  },
  selectors: {
    selectMessage: (state) => state.message,
    selectModal: (state) => state.modal,
    selectNotification: (state) => state.notification,
    selectCollapsed: (state) => state.collapsed,
    isMobile: (state) => state.isMobile,
    isLoading: (state) => state.isLoading,
    selectIsWizardSplashShown: (state) => state.isWizardSplashShown,
    selectHistory: (state) => state.history,
    selectIsCustomerView: (state) => state.isCustomerView,
    selectIsOpenPagesDrawer: (state) => state.isOpenPagesDrawer,
  },
})

export const useSetNotification = () => {
  const dispatch = useDispatch()

  return useCallback((notification) => dispatch(setNotification(notification)), [dispatch])
}

export const useSetErrorMessage = () => {
  const dispatch = useDispatch()

  return useCallback((error) => dispatch(setError(error)), [dispatch])
}

export const useCollapsed = () => {
  const dispatch = useDispatch()
  return {
    collapsed: useSelector(uiSlice.selectors.selectCollapsed),
    setCollapsed: useCallback((value) => dispatch(setCollapsed(value)), [dispatch]),
    toggleCollapsed: useCallback(() => dispatch(toggleCollapsed()), [dispatch]),
  }
}

export const useIsCustomerView = (isLoggedAsUser) => {
  const dispatch = useDispatch()
  return {
    isCustomerView: useSelector(uiSlice.selectors.selectIsCustomerView),
    setIsCustomerView: useCallback((value) => {
      if(!isLoggedAsUser) return

      dispatch(uiSlice.actions.setIsCustomerView(value))
    }, [isLoggedAsUser, dispatch]),
  }
}

export const useUIMessages = () => {
  const dispatch = useDispatch()
  return {
    message: useSelector(uiSlice.selectors.selectMessage),
    clearMessage: useCallback(() => dispatch(uiSlice.actions.clearMessage()), [dispatch]),
    setError: useCallback((text) => dispatch(uiSlice.actions.setError(text)), [dispatch]),
    setSuccess: useCallback((text) => dispatch(uiSlice.actions.setSuccess(text)), [dispatch]),
  }
}

export const useIsWizardSplashShown = () => {
  const dispatch = useDispatch()
  return {
    isWizardSplashShown: useSelector(uiSlice.selectors.selectIsWizardSplashShown),
    toggleIsWizardSplashShown: useCallback(() => dispatch(uiSlice.actions.toggleIsWizardSplashShown()), [dispatch]),
  }
}

export const useIsMobile = () => {
  const dispatch = useDispatch()
  return {
    isMobile: useSelector(uiSlice.selectors.isMobile),
    setIsMobile: useCallback((value) => dispatch(uiSlice.actions.setIsMobile(value)), [dispatch]),
  }
}

export const usePagesDrawerToggle = () => {
  const dispatch = useDispatch()
  return {
    isOpenPagesDrawer: useSelector(uiSlice.selectors.selectIsOpenPagesDrawer),
    togglePagesDrawer: useCallback((value) => dispatch(uiSlice.actions.togglePagesDrawer(value)), [dispatch]),
  }
}

export const useCurrentModal = () => useSelector(uiSlice.selectors.selectModal)
export const useAppIsLoading = () => useSelector(uiSlice.selectors.isLoading)
export const useNotificationMessage = () => useSelector(uiSlice.selectors.selectNotification)
export const useNavigationHistory = () => useSelector(uiSlice.selectors.selectHistory)

export const {
  setIsLoading,
  setModal,
  unsetModal,
  setError,
  setNotification,
  setSuccess,
  clearMessage,
  setCollapsed,
  setIsMobile,
  toggleCollapsed,
  toggleIsWizardSplashShown,
  pushHistory,
  setQueryParams,
} = uiSlice.actions

export default uiSlice.reducer

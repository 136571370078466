import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { debounce } from 'lodash'
import moment from 'moment'

import BorderedSwitch from 'components/BorderedSwitch'
import Autocomplete, { PaperV2Styled } from 'components/FormFields/Autocomplete'
import DatePicker from 'components/FormFields/DatePicker'
import InputLabelWithLimit from 'components/InputLabelWithLimit'
import useUserInformation from 'dux/hooks/useUserInformation'
import { useAgeGradeOptions, useCreateAgeGradeMutation } from 'dux/queries/ageGradeTextLabels'
import { useChildrenOptions, useCreateChildMutation } from 'dux/queries/nameTextLabels'
import { useCreateTagMutation, useTagOptions } from 'dux/queries/tagsTextLabels'
import { useFieldLabelStateMap } from 'hooks/useFieldLabelStateMap'
import { isPictureDateValid } from 'utils/dateUtils'
import regex from 'utils/regex'
import removeLineBreaks from 'utils/removeLineBreaks'

import { PAGE_TEXT_FIELDS } from './EditPageTextForm.constants'
import { EditPageTextFormChip, EditPageTextFormStyled, InputGroup } from './EditPageTextForm.style'
import {
  EDIT_PAGE_TEXT_FORM_FIELDS_ERRORS,
  getProxiedPictureValues,
  getTagsWithDeleteFlag,
  MIXED_CONTENT_KEYWORD,
  preventDeselectionForMixedContent,
  selectMixedContentOnFocus,
} from './EditPageTextForm.utils'
import useScrollParentToBottom from './useScrollParentToBottom'

const EditPageTextForm = ({
  pictures,
  onValuesChangeSubmit,
  onValuesFieldReset,
  onTitleChange,
  onFormIsValidChange,
  isBulkEdit = false,
  parentNodeRef,
}) => {
  const { isAdminView } = useUserInformation()

  const [childrenOptions] = useChildrenOptions()
  const [ageGradeOptions] = useAgeGradeOptions()
  const [tagOptions] = useTagOptions()

  const [triggerCreateChild] = useCreateChildMutation()
  const [triggerCreateAgeGrade] = useCreateAgeGradeMutation()
  const [triggerCreateTag] = useCreateTagMutation()

  const scrollParentToBottom = useScrollParentToBottom(parentNodeRef)

  const pictureValues = useMemo(() => {
    return getProxiedPictureValues(pictures)
  }, [pictures])

  // Memoizing default values to avoid unnecessary re-renders.
  // The dependency on pictureValues.id ensures these values are recalculated
  // only when a new picture is selected. This prevents excessive updates when
  // typing in the title field. Avoid using an empty dependency array ([])
  // to handle cases where navigating between images with the arrows
  // requires updates to the memoized values.
  const defaultValues = useMemo(() => ({
    pictureDate: null,
    child: null,
    label: null,
    title: pictureValues.title || '',
    tags: [],
    hidden: false,
  }), [pictureValues.id])

  const initialValues = useRef({})

  const { control, setValue, formState: { errors }, trigger } = useForm({
    defaultValues,
    mode: 'all',
  })

  const isFormValid = Object.keys(errors).length === 0
  const shouldReloadForm = JSON.stringify(pictures?.map(({ id, updatedAt }) => [id, updatedAt]))

  useEffect(() => {
    onFormIsValidChange?.(isFormValid)
  }, [isFormValid, onFormIsValidChange])

  useEffect(() => {
    setValue(
      'pictureDate',
      pictureValues.pictureDate
        ? pictureValues.pictureDate === MIXED_CONTENT_KEYWORD
          ? MIXED_CONTENT_KEYWORD
          : moment(pictureValues.pictureDate)
        : null,
    )
    setValue('child', pictureValues.child && { label: pictureValues.child.name, value: pictureValues.child.id })
    setValue('label', pictureValues.label && { label: pictureValues.label.name, value: pictureValues.label.id })
    setValue('title', pictureValues.title || '')
    setValue(
      'tags',
      pictureValues?.tags && pictureValues.tags === MIXED_CONTENT_KEYWORD
        ? MIXED_CONTENT_KEYWORD
        : pictureValues?.tags?.length
          ? pictureValues.tags.map((tag) => ({ label: tag.name, value: tag.id }))
          : [],
    )
    setValue('hidden', pictureValues.hidden)
    if (initialValues.current.hidden !== pictureValues.hidden) {
      initialValues.current.hidden = pictureValues.hidden
    }
  }, [shouldReloadForm])

  useEffect(() => {
    // To properly run form validation on mount
    trigger()
  }, [trigger, shouldReloadForm])

  const handleTitleChange = useCallback(
    debounce((e) => {
      const value = removeLineBreaks(e.target.value)
      const title = value.length > PAGE_TEXT_FIELDS.title.maxLength
        ? value.slice(0, PAGE_TEXT_FIELDS.title.maxLength)
        : value

      const pictureId = pictureValues.id
      setValue('title', title)

      if (isBulkEdit) return
      onTitleChange?.({ pictureId, title })
    }, 300),
    [removeLineBreaks, pictureValues.id, isBulkEdit, onTitleChange, setValue],
  )

  const handleTitleBlur = (e) => {
    if (isBulkEdit && e.currentTarget.value === MIXED_CONTENT_KEYWORD) return
    // Value should be uppercased only on blur event to avoid cursor jumping to the end of the text value
    onValuesChangeSubmit('title', e.currentTarget.value.toUpperCase())
  }

  const handleChildChange = async (childOption) => {
    const propertyName = isBulkEdit ? 'childAttributes' : 'child'
    if (childOption?.__isNew__) {
      // creating new child only when it has valid label
      // React hook form validate field only after value changed
      // so it's "to late"
      if (regex.child.test(childOption.label)) {
        const newChild = await triggerCreateChild({ child: { name: childOption.value } }).unwrap()
        await onValuesChangeSubmit(propertyName, newChild)
      }
    } else if (childOption === null || !Number.isInteger(childOption.value)) {
      await onValuesChangeSubmit(propertyName, null)
    } else {
      await onValuesChangeSubmit(propertyName, {
        id: childOption.value,
        name: childOption.label,
      })
    }
  }

  const handleLabelChange = async (labelOption) => {
    const propertyName = isBulkEdit ? 'labelAttributes' : 'label'
    if (labelOption?.__isNew__) {
      // creating new label only when it has valid label
      // React hook form validate field only after value changed
      // so it's "to late"
      if (regex.label.test(labelOption.label)) {
        const newAgeGrade = await triggerCreateAgeGrade({ name: labelOption.value }).unwrap()
        await onValuesChangeSubmit(propertyName, newAgeGrade)
      }
    } else if (labelOption === null) {
      await onValuesChangeSubmit(propertyName, null)
    } else {
      await onValuesChangeSubmit(propertyName, {
        id: labelOption.value,
        name: labelOption.label,
      })
    }
  }

  const handleDateChange = (pictureDate) => {
    return !pictureDate || isPictureDateValid(pictureDate)
      ? onValuesChangeSubmit('pictureDate', pictureDate)
      : null
  }

  const onDateChange = isBulkEdit
    ? handleDateChange
    : debounce(handleDateChange, 300)

  const handleTagsChange = debounce(
    async (values) => {
      const propertyName = isBulkEdit ? 'tagAttributes' : 'tags'
      const isValid = values.every((tag) => regex.tag.test(tag.label))
      if (!Array.isArray(values)) return

      const newTagDraft = values.find((tag) => tag.__isNew__)
      let tags = [...values.filter((tag) => !tag.__isNew__)]
      if (newTagDraft) {
        if (!isValid) {
          // for showing user wrong new tag
          // field will have an error
          tags.push({
            label: newTagDraft.label,
            value: newTagDraft.label,
          })
        } else {
          const newTag = await triggerCreateTag({ tag: { name: newTagDraft.value } }).unwrap()
          tags.push({
            label: newTag.name,
            value: newTag.id,
          })
        }
      }
      // using Pictures instead of picture, of cases of bulk edit
      // and removing tags from each edited picture
      const deletedTags = getTagsWithDeleteFlag(tags, pictures)

      let mappedTags = tags.map((tag) => ({ id: tag.value, name: tag.label }))
      if (isValid) {
        await onValuesChangeSubmit(propertyName, [...mappedTags, ...deletedTags])
      }
      setValue(
        'tags',
        mappedTags.map(({ id: value, name: label }) => ({ value, label })),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      )
    },
    1000,
    { leading: true, trailing: false },
  )

  const handleHiddenChange = debounce(
    (nextHidden) => {
      onValuesChangeSubmit('hidden', nextHidden)
    },
    1000,
    { leading: true, trailing: false },
  )
  const handleHiddenReset = debounce(() => {
    setValue('hidden', initialValues.current.hidden)
    onValuesFieldReset('hidden')
  }, 1000, { leading: true, trailing: false })
  const { fieldLabelStateMap, updateLabelState } = useFieldLabelStateMap(Object.keys(PAGE_TEXT_FIELDS))

  const isMixedDate = isBulkEdit && pictureValues.pictureDate === MIXED_CONTENT_KEYWORD
  const dateDefaultValue = useMemo(() => !pictureValues.pictureDate || isMixedDate
    ? pictureValues.pictureDate
    : moment(pictureValues.pictureDate)
  , [isMixedDate, pictureValues.pictureDate])

  return (
    <EditPageTextFormStyled isBulkEdit={isBulkEdit}>
      <Controller
        control={control}
        name={PAGE_TEXT_FIELDS.title.name}
        render={({ field }) => {
          const isMixed = isBulkEdit && field.value === MIXED_CONTENT_KEYWORD
          return (
            <InputGroup isMixed={isMixed}>
              <InputLabelWithLimit
                labelState={fieldLabelStateMap[PAGE_TEXT_FIELDS.title.name]}
                field={PAGE_TEXT_FIELDS.title}
              />
              <OutlinedInput
                {...field}
                value={field.value}
                variant={'new'}
                inputProps={{
                  maxLength: PAGE_TEXT_FIELDS.title.maxLength,
                  // Uppercasing was added intentionally to avoid adding extra JS code of maintaining cursor position,
                  // if uppercase is added on the value change.
                  // That way, while typing we accept upper- and lower- cased values,
                  // but on CSS side convert all of them to uppercase.
                  // And on Blur event setting the value to uppercase by JS.
                  sx: !isMixed && { textTransform: 'uppercase' },
                }}
                multiline={true}
                onSelect={isBulkEdit ? preventDeselectionForMixedContent : null}
                onFocus={selectMixedContentOnFocus}
                onChange={(e) => {
                  field.onChange(e)
                  handleTitleChange(e)
                  updateLabelState(PAGE_TEXT_FIELDS.title.name, true, e.target.value)
                }}
                onBlur={(e) => {
                  field.onBlur(e)
                  handleTitleBlur(e)
                  updateLabelState(PAGE_TEXT_FIELDS.title.name, false)
                }}
              />
            </InputGroup>
          )
        }}
      />
      <Controller
        control={control}
        name={PAGE_TEXT_FIELDS.child.name}
        rules={{ validate: (child) => !child || regex.child.test(child.label) }}
        render={({ field }) => (
          <InputGroup>
            <Autocomplete
              isBulkEdit={isBulkEdit}
              creatable={true}
              options={childrenOptions}
              label={
                <InputLabelWithLimit
                  labelState={fieldLabelStateMap[PAGE_TEXT_FIELDS.child.name]}
                  field={PAGE_TEXT_FIELDS.child}
                />
              }
              variant={'new'}
              placeholder={PAGE_TEXT_FIELDS.child.label}
              {...field}
              disableAutofill={true}
              maxLength={PAGE_TEXT_FIELDS.child.maxLength}
              error={errors.child && EDIT_PAGE_TEXT_FORM_FIELDS_ERRORS.child}
              paperComponent={({ children }) => <PaperV2Styled>{children}</PaperV2Styled>}
              onSelect={isBulkEdit ? preventDeselectionForMixedContent : null}
              onFocus={isBulkEdit ? selectMixedContentOnFocus : null}
              onInputChange={(e) => {
                updateLabelState(PAGE_TEXT_FIELDS.child.name, true, e.target.value)
              }}
              onChange={async (nextValue) => {
                field.onChange(nextValue)
                await handleChildChange(nextValue)
              }}
              onAutocompleteChange={() => {
                updateLabelState(PAGE_TEXT_FIELDS.child.name, false)
              }}
              onInputBlur={(e) => {
                field.onBlur(e)
                updateLabelState(PAGE_TEXT_FIELDS.child.name, false)
              }}
            />
          </InputGroup>
        )}
      />
      <Controller
        control={control}
        name={PAGE_TEXT_FIELDS.label.name}
        rules={{ validate: (label) => !label || regex.child.test(label.label) }}
        render={({ field }) => (
          <InputGroup isMixed={field?.value?.label === MIXED_CONTENT_KEYWORD}>
            <Autocomplete
              creatable={true}
              isBulkEdit={isBulkEdit}
              label={
                <InputLabelWithLimit
                  labelState={fieldLabelStateMap[PAGE_TEXT_FIELDS.label.name]}
                  field={PAGE_TEXT_FIELDS.label}
                />
              }
              options={ageGradeOptions}
              variant={'new'}
              {...field}
              disableAutofill={true}
              onSelect={isBulkEdit ? preventDeselectionForMixedContent : null}
              onFocus={isBulkEdit ? selectMixedContentOnFocus : null}
              onChange={async (nextValue) => {
                field.onChange(nextValue)
                await handleLabelChange(nextValue)
              }}
              onAutocompleteChange={() => updateLabelState(PAGE_TEXT_FIELDS.label.name, false)}
              onInputChange={(e) => {
                updateLabelState(PAGE_TEXT_FIELDS.label.name, true, e.target.value)
              }}
              onInputBlur={(e) => {
                field.onBlur(e)
                updateLabelState(PAGE_TEXT_FIELDS.label.name, false)
              }}
              maxLength={PAGE_TEXT_FIELDS.label.maxLength}
              error={errors.label && EDIT_PAGE_TEXT_FORM_FIELDS_ERRORS.label}
              paperComponent={({ children }) => <PaperV2Styled>{children}</PaperV2Styled>}
            />
          </InputGroup>
        )}
      />
      <InputGroup>
        <DatePicker
          control={control}
          defaultValue={dateDefaultValue}
          variant={'new'}
          name={PAGE_TEXT_FIELDS.pictureDate.name}
          label={PAGE_TEXT_FIELDS.pictureDate.label}
          isBulkEdit={isBulkEdit}
          onChange={onDateChange}
          trigger={trigger}
        />
      </InputGroup>
      {isAdminView && (
        <Controller
          control={control}
          name={PAGE_TEXT_FIELDS.tags.name}
          rules={{ validate: (tags) => Array.isArray(tags) ? tags.every((tag) => regex.tag.test(tag.label)) : true }}
          render={({ field }) => (
            <InputGroup isMixed={field.value === MIXED_CONTENT_KEYWORD}>
              <Autocomplete
                isBulkEdit={isBulkEdit}
                creatable={true}
                label={
                  <InputLabelWithLimit
                    labelState={fieldLabelStateMap[PAGE_TEXT_FIELDS.tags.name]}
                    field={PAGE_TEXT_FIELDS.tags}
                  />
                }
                variant={'new'}
                isMixedTagsEdit={field.value === MIXED_CONTENT_KEYWORD}
                paperComponent={({ children }) => <PaperV2Styled>{children}</PaperV2Styled>}
                renderTags={(value, getTagProps) => value.map((option, index) => {
                  return (
                    <EditPageTextFormChip
                      key={option.value}
                      deleteIcon={<ClearIcon />}
                      size={'small'}
                      label={option.label}
                      {...getTagProps({ index })}
                    />
                  )
                })}
                options={tagOptions}
                {...field}
                disableAutofill={true}
                multiple={true}
                maxLength={PAGE_TEXT_FIELDS.tags.maxLength}
                error={errors.tags && EDIT_PAGE_TEXT_FORM_FIELDS_ERRORS.tags}
                onFocus={isBulkEdit ? selectMixedContentOnFocus : null}
                onSelect={isBulkEdit ? preventDeselectionForMixedContent : null}
                onChange={async (nextValue) => {
                  field.onChange(nextValue)
                  await handleTagsChange(nextValue)
                  scrollParentToBottom()
                }}
                onAutocompleteChange={() => updateLabelState(PAGE_TEXT_FIELDS.tags.name, false)}
                onInputChange={(e) => {
                  updateLabelState(PAGE_TEXT_FIELDS.tags.name, true, e.target.value)
                }}
                onInputBlur={(e) => {
                  field.onBlur(e)
                  updateLabelState(PAGE_TEXT_FIELDS.tags.name, false)
                }}
              />
            </InputGroup>
          )}
        />
      )}
      {isAdminView && (
        <Controller
          control={control}
          name={'hidden'}
          render={({ field }) => (
            <InputGroup isMixed={isBulkEdit && field.value === MIXED_CONTENT_KEYWORD}>
              <InputLabel sx={{
                display: 'flex',
                justifyContent: 'space-between',
                maxHeight: '17px',
                height: '17px',
              }}> {/* 17px stands from design used it to avoid jumping  because actual size of ResetButton 20px */}
                Visibility for Customer
                {initialValues.current.hidden === MIXED_CONTENT_KEYWORD && field.value !== MIXED_CONTENT_KEYWORD && (
                  <Button
                    sx={{ height: 'auto!important', padding: 0, minWidth: '40px' }}
                    color={'primary'}
                    onClick={handleHiddenReset}
                    size={'small'}
                    variant={'new-text-primary'}>
                    Reset
                  </Button>
                )}
              </InputLabel>
              <BorderedSwitch
                checked={isBulkEdit && field.value === MIXED_CONTENT_KEYWORD ? false : !!field.value}
                label={isBulkEdit && field.value === MIXED_CONTENT_KEYWORD ? 'Mixed' : 'Hidden'}
                {...field}
                isMixed={isBulkEdit && field.value === MIXED_CONTENT_KEYWORD}
                onChange={(e) => {
                  field.onChange(e)
                  handleHiddenChange(e.target.checked)
                }}
              />
            </InputGroup>
          )}
        />
      )}
    </EditPageTextFormStyled>
  )
}

EditPageTextForm.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.object).isRequired,
  onValuesChangeSubmit: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func,
  onFormIsValidChange: PropTypes.func,
  onValuesFieldReset: PropTypes.func,
  // don't use pictures.length > 1 to define bulk edit, because it can be case when users edit 1 picture
  // via bulk edit modal and it has slightly different logic especially for tags
  isBulkEdit: PropTypes.bool,
  parentNodeRef: PropTypes.object,
}

export default EditPageTextForm

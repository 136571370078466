import { approximateSize, isInitializedState, isNumeric, rotateSize, updateCanvas } from 'advanced-cropper'
import { fabric } from 'fabric'

import { dataURLtoBlob, getCanvasMaxSize } from '../EditPicture/CropImage/CropImage.helpers'

const getEncoderStateSetting = (ext) => {
  switch (ext) {
    case 'png':
      return [
        'image/png',
        false,
      ]

    case 'webp':
      return ['image/png', null]

    case 'jpeg':
    case 'jpg':
    default:
      return [
        'image/jpeg',
        {
          type: 'browserJPEG',
          options: {
            quality: 75,
          },
        },
      ]
  }
}

export const CANVAS_EXPORT_QUALITY = 0.95

export const createFileFromCanvas = async (inputFileName, canvas) => {
  const lastDotIndex = inputFileName.lastIndexOf('.')
  const fileExtension = lastDotIndex > -1 ? inputFileName.slice(lastDotIndex + 1).toLowerCase() : 'jpg'
  const baseFileName = lastDotIndex > -1 ? inputFileName.substring(0, lastDotIndex) : inputFileName

  const [canvasMimeType] = getEncoderStateSetting(fileExtension)
  // for crop tool canvas will be a node, for adjust tool it will be a fabric canvas instance
  // toDataURL have different interface for both cases
  let canvasDataURL
  if (canvas instanceof Node) {
    canvasDataURL = canvas.toDataURL(canvasMimeType, CANVAS_EXPORT_QUALITY)
  } else {
    // call toDataURL from fabric.util to avoid additional scale canvas instance
    canvasDataURL = fabric.util.toDataURL(canvas.lowerCanvasEl, canvasMimeType.replace('image/', ''), CANVAS_EXPORT_QUALITY)
  }

  const fileBlob = dataURLtoBlob(canvasDataURL)

  return new File([fileBlob], `${baseFileName}.${fileExtension}`, { type: fileBlob.type })
}

/*

// will keep it here for now, maybe we will need it later
function addElementToPageCenter(element, { width = '500px' } = {}) {
  // Set CSS styles for centering and width
  element.style.position = 'absolute'
  element.style.left = '50%'
  element.style.top = '50%'
  element.style.transform = 'translate(-50%, -50%)' // This centers the element
  element.style.width = width // Set the width to 500px
  element.style.maxWidth = '100%' // Ensure it doesn't exceed the viewport width
  element.style.boxSizing = 'border-box' // Include padding and borders in the element's width

  // Add the element to the body of the page
  document.body.appendChild(element)
}*/
/**
 * Converts a canvas to an image element
 * @param canvas
 * @returns {Promise<unknown>}
 */
function convertCanvasToImage(canvas) {
  return new Promise((resolve, reject) => {
    // Create a new Image element
    const img = new Image()

    // Set up an onload handler to resolve the promise once the image is fully loaded
    img.onload = () => {
      resolve(img)
    }

    // Set up an onerror handler to reject the promise in case of an error
    img.onerror = () => {
      reject(new Error('Could not load the image from the canvas.'))
    }

    // Set the src of the image to the base64 string returned by toDataURL()
    img.src = canvas.toDataURL()

    // Immediately resolve if the image is already loaded
    if (img.complete) {
      resolve(img)
    }
  })
}

/**
 * Prepares the source image for cropping by applying transformations such as rotation and flipping.
 * It also scales the image to fit within the provided maximum width and height.
 *
 * @param {HTMLCanvasElement} canvas - The canvas element to draw the transformed image on.
 * @param {HTMLImageElement} image - The image element containing the original image.
 * @param {Object} transforms - The transformations to apply on the image. It includes rotation (in degrees) and flipping (horizontal and vertical).
 * @param {Object} options - The maximum width and height to scale the image to.
 * @returns {Promise<[HTMLImageElement,number]>} - An array containing the transformed image source and the scale factor.
 */
export async function prepareSource(
  canvas,
  image,
  { rotate, flip },
  options,
) {
  const originalSize = {
    width: 'naturalWidth' in image ? image.naturalWidth : image.width,
    height: 'naturalHeight' in image ? image.naturalHeight : image.height,
  }
  const { maxWidth, maxHeight } = options
  const transformedSize = rotateSize(originalSize, rotate)

  const ctx = canvas.getContext('2d')

  // Calculate scale to fit within maxWidth and maxHeight
  const scale = Math.min(maxWidth / transformedSize.width, maxHeight / transformedSize.height, 1)
  const scaledWidth = transformedSize.width * scale
  const scaledHeight = transformedSize.height * scale

  // Set the canvas size
  canvas.width = scaledWidth
  canvas.height = scaledHeight

  if (ctx) {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    if (options.fillColor) {
      ctx.fillStyle = options.fillColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    }

    ctx.save()

    // Translate and rotate around the center of the canvas
    ctx.translate(scaledWidth / 2, scaledHeight / 2)
    ctx.rotate((rotate * Math.PI) / 180)

    // Apply reflection if needed
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)

    // Adjust position for the rotation
    const dx = -originalSize.width * scale / 2
    const dy = -originalSize.height * scale / 2
    // Draw the image centered on the canvas
    // console.log(image, image.complete, dx, dy, originalSize.width * scale, originalSize.height * scale)
    ctx.drawImage(image, dx, dy, originalSize.width * scale, originalSize.height * scale)

    ctx.restore()
  }

  // Convert the canvas to an image element to be used as the source for the cropper. With canvas as the source, the cropper will not work on Safari.
  const img = await convertCanvasToImage(canvas)
  return [img, scale]
}

/**
 * overrider advanced-cropper drawCroppedArea method to fix bug with rotated image temporary canvas
 * @param state
 * @param image
 * @param resultCanvas
 * @param spareCanvas
 * @param options
 * @returns {HTMLCanvasElement|null}
 */
async function drawCroppedArea(
  state,
  image,
  resultCanvas,
  spareCanvas,
  options,
) {
  if (isInitializedState(state)) {
    const { transforms, coordinates } = state

    const imageTransformed = transforms.rotate !== 0 || transforms.flip.horizontal || transforms.flip.vertical

    let source = image
    let scaleFactor = 1

    if (imageTransformed) {
      [source, scaleFactor] = await prepareSource(spareCanvas, image, transforms, options)
    }

    // addElementToPageCenter(source)

    const params = {
      minWidth: 0,
      minHeight: 0,
      maxWidth: Infinity,
      maxHeight: Infinity,
      maxArea: Infinity,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      fillColor: 'transparent',
      ...options,
    }

    const firstNumeric = (array) => array.find((el) => isNumeric(el))

    let size = approximateSize({
      sizeRestrictions: {
        minWidth: firstNumeric([params.width * scaleFactor, params.minWidth]) || 0,
        minHeight: firstNumeric([params.height * scaleFactor, params.minHeight]) || 0,
        maxWidth: firstNumeric([params.width * scaleFactor, params.maxWidth]) || Infinity,
        maxHeight: firstNumeric([params.height * scaleFactor, params.maxHeight]) || Infinity,
      },
      width: coordinates.width * scaleFactor,
      height: coordinates.height * scaleFactor,
      aspectRatio: {
        minimum: coordinates.width / coordinates.height,
        maximum: coordinates.width / coordinates.height,
      },
    })

    if (params.maxArea && size.width * size.height > params.maxArea) {
      const scale = Math.sqrt(params.maxArea / (size.width * size.height))
      size = {
        width: Math.round(scale * size.width * scaleFactor),
        height: Math.round(scale * size.height * scaleFactor),
      }
    }

    const scaledCoordinates = Object.fromEntries(
      Object.entries(coordinates).map(([key, value]) => [key, value * scaleFactor]),
    )

    return updateCanvas(resultCanvas, source, scaledCoordinates, size, params)
  } else {
    return null
  }
}


export const exportCanvasStateAsBlob = async (state, image, isTransparentBg) => {
  const maxArea = getCanvasMaxSize()

  const canvasEl = document.createElement('canvas')
  const spareCanvasEl = document.createElement('canvas')
  const canvas = await drawCroppedArea(
    state,
    image,
    canvasEl,
    spareCanvasEl,
    {
      maxHeight: maxArea.maxHeight,
      maxWidth: maxArea.maxWidth,
      maxArea: maxArea.maxWidth * maxArea.maxHeight,
      fillColor: isTransparentBg ? 'transparent' : 'white',
    },
  )

  spareCanvasEl.width = 0
  spareCanvasEl.height = 0

  // addElementToPageCenter(canvas, { width: '200px' })

  return canvas
}

export const getDefaultSize = ({ imageSize, visibleArea }) => ({
  left: 0,
  top: 0,
  width: (visibleArea || imageSize).width,
  height: (visibleArea || imageSize).height,
})

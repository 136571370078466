import React from 'react'
import PropTypes from 'prop-types'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { ListItemText } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#102371',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 11px 6px 16px',
    width: '100%',
  },
  primary: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.4,
  },
  secondary: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '0.75rem',
    lineHeight: 1.4,
  },
  actions: {
    display: 'flex',
    gap: 6,
  },
  actionButton: {
    color: 'rgba(255, 255, 255, 0.8)',
    '&.Mui-disabled': {
      color: 'rgba(255, 255, 255, 0.26)',
    },
  },
}))

const UploadQueueHeader = ({
  title,
  subtitle,
  active,
  onToggle,
  onClose,
  disabledClose,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ListItemText
        primary={title}
        secondary={subtitle}
        classes={{
          secondary: classes.secondary,
          primary: classes.primary,
        }}
      />
      <div className={classes.actions}>
        <IconButton aria-label={'toggle'} onClick={onToggle} className={classes.actionButton} size={'small'}>
          {active ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
        <IconButton
          aria-label={'cancel'}
          onClick={onClose}
          className={classes.actionButton}
          size={'small'}
          disabled={disabledClose}
        >
          <CloseIcon style={{ fontSize: '1.25rem' }} />
        </IconButton>
      </div>
    </div>
  )
}

UploadQueueHeader.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  active: PropTypes.bool,
  disabledClose: PropTypes.bool,
  onToggle: PropTypes.func,
  onClose: PropTypes.func,
}

export default UploadQueueHeader

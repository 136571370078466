import { useEffect } from 'react'

import { useTheme } from '@mui/material/styles'

const useMetaThemeColor = (colorResolver, revertOnUnmount) => {
  const theme = useTheme()
  const color = typeof colorResolver === 'string' ? colorResolver : colorResolver(theme)

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]')
    if (!metaThemeColor)
      return

    const prevThemeColor = metaThemeColor.getAttribute('content')
    if (prevThemeColor === color)
      return

    metaThemeColor.setAttribute('content', color)

    return () => {
      // most common case is to revert the color back for modals, when theme color is contrasted
      if (revertOnUnmount) {
        metaThemeColor.setAttribute('content', prevThemeColor)
      }
    }
  }, [])
}

export default useMetaThemeColor

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/react'
import { useStoreActions } from 'easy-peasy'

import UploadManager from 'components/UploadManager'
import useUserInformation from 'dux/hooks/useUserInformation'
import useTrackHistoryChange from 'hooks/useTrackHistoryChange'
import useUnsetModalOnRouteChange from 'hooks/useUnsetModalOnRouteChange'
import uploadService from 'services/uploadService'
import * as track from 'utils/tracker'

const useUploadManagerActionSubscribe = () => {
  const addPicture = useStoreActions((actions) => actions.pictures.addPicture)
  const addBookPages = useStoreActions((actions) => actions.user.books_v3.addBookPages_v3)

  uploadService.setStoreActions({
    onUploadSuccess: addPicture,
    onBatchAddPictures: addBookPages,
  })
}

// 1. Closes modals on route change
// 2. Sets a User for Sentry and Segment
// 3. Sets user history on change
// 4. Adds an Upload Manager
const GlobalTrackersProvider = ({ children }) => {
  useUnsetModalOnRouteChange()

  useTrackHistoryChange()

  const { activeUser, isLoggedAsUser } = useUserInformation()

  const isAuthenticated = !!activeUser

  useUploadManagerActionSubscribe()

  useEffect(() => {
    if (!activeUser?.id) return

    Sentry.setUser({ email: activeUser.email, id: activeUser.id, username: activeUser.name })

    const segmentUser = activeUser.email
      ? { email: activeUser.email, name: activeUser.name, uuid: activeUser.uuid }
      : {}
    track.load(isLoggedAsUser, { user: segmentUser })
    // NOTE: this should be executed only once per user visit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser?.id, activeUser?.uuid])

  return (
    <>
      {children}
      {!!isAuthenticated && (
        <UploadManager />
      )}
    </>
  )
}

GlobalTrackersProvider.propTypes = {
  children: PropTypes.node,
}

export default GlobalTrackersProvider

import { createContext, useContext } from 'react'

export const NewModalContext = createContext({})

export const useNewModalContext = () => {
  const context = useContext(NewModalContext)
  if (!context) {
    throw new Error(`useModalContext must be used within a ModalContextProvider`)
  }
  return context
}

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import DragEnterPlaceholder from 'components/UploadDropzone/DragEnterPlaceholder/DragEnterPlaceholder'
import NewUploadButton from 'components/UploadDropzone/NewUploadButton/NewUploadButton'

export const StyledDragEnterPlaceholder = styled(DragEnterPlaceholder)`
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  padding-bottom: 56px;
`

export const StyledUploadButton = styled(NewUploadButton)`
  padding: 32px 20px;
  
  ${({ theme }) => theme.breakpoints.up('xs')} {
    padding: 56px 20px;
  }
`

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[500]};
  position: absolute;
  bottom: -32px;
  left: 0;
  right: 0;
  font-size: 0.75rem;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 0.8125rem;
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Check } from '@mui/icons-material'
import { Box, Button, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import getConfig from 'next/config'

import useUserInformation from 'dux/hooks/useUserInformation'
import theme from 'styles/theme'

import { AnnualTip } from './PlanCard.style'
import { formatNumber, getPrice } from './utils'

const { publicRuntimeConfig: { NEXT_PUBLIC_API_URL } } = getConfig()

export const PlanTypes = {
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY',
}

const useStyles = makeStyles(() => ({
  fontWeight: 'bold',
  root: {
    boxShadow: '2px 4px 20px rgba(16, 35, 113, 0.1)',
    borderRadius: 8,
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    minWidth: 300,
    maxWidth: 400,
  },
  checkIcon: {
    marginRight: 12,
  },
  mostPopularLabel: {
    backgroundImage: `url(/images/membership/popularLabel.svg)`,
    fontFamily: theme.typography.type.montserrat,
    padding: '6px 0',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    transform: 'translateX(-50%)',
    left: '50%',
    position: 'absolute',
    top: -1,
    color: '#202126',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: 0,
    width: 134,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  active: {
    color: '#009FFF',
  },
  planImage: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    '&$card': {
      width: 100,
      height: 100,
    },
  },
  card: {},
  cardDisabled: {
    filter: 'opacity(0.5)',
    pointerEvents: 'none',
  },
  checkoutBtn: {
    width: 220,
  },
}))

export const BulletItem = ({ children, icon, disabled }) => {
  return (
    <Box display={'flex'} my={1} color={disabled ? theme.colors.lightgray : theme.colors.darkgray}>
      {icon || <Check color={disabled ? '#E3F2FD' : 'primary'} />}
      <Typography variant={'body1'} style={{ marginLeft: 12, letterSpacing: 'inherit' }} color={'inherit'}>
        {children}
      </Typography>
    </Box>
  )
}

BulletItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
}

const PlanCard = ({ plan, planType, className, topLabel, checkoutLabel }) => {
  const classes = useStyles()

  const { activeUser } = useUserInformation()

  const isMonthlyPlan = planType === PlanTypes.MONTHLY

  const priceString = (price, currentPlanType, parentPlanType) => {
    let cost
    if (price.trial_fee !== '0.0') {
      cost = price.trial_fee
    } else {
      cost = price.fee
    }

    cost = cost.replace('.0', '')

    cost = parseInt(cost)
    if (currentPlanType !== parentPlanType) {
      cost = cost / 12
    }
    return `$${formatNumber(cost)}/${currentPlanType === PlanTypes.ANNUAL ? 'year' : 'month'}`
  }

  const price = getPrice(plan.pricing, planType)


  const mainPlanPrice = PlanTypes.MONTHLY

  const pricing = price ? (
    <Box color={theme.colors.black} textAlign={'center'} mt={3}>
      <Typography variant={'h6'} component={'div'}>
        {priceString(price, mainPlanPrice, planType)}
      </Typography>

      {!isMonthlyPlan && (
        <Typography variant={'caption'} color={theme.colors.darkgray}>
          {priceString(price, planType, planType)} - BILLED ANNUALLY
        </Typography>
      )}
    </Box>
  ) : (
    <Box color={theme.colors.black} textAlign={'center'}>
      <Typography variant={'h6'} component={'div'}>
        {priceString(getPrice(plan.pricing, PlanTypes.ANNUAL), PlanTypes.MONTHLY, PlanTypes.ANNUAL)}
      </Typography>

      <Typography variant={'caption'} color={theme.colors.darkgray}>
        {priceString(getPrice(plan.pricing, PlanTypes.ANNUAL), PlanTypes.ANNUAL, PlanTypes.ANNUAL)} - BILLED ANNUALLY
      </Typography>
    </Box>
  )

  const checkoutHref = `${NEXT_PUBLIC_API_URL}/plans/${price?.id}/checkout?email=${encodeURIComponent(activeUser.email)}`

  return (
    <Box className={className} height={'100%'}>
      <Paper elevation={0} className={cl(classes.root)}>
        <Box
          px={3}
          pt={5}
          pb={3}
          height={'100%'}
          position={'relative'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          className={{
            [classes.cardDisabled]: !price,
          }}
        >
          {topLabel && (
            <Box className={classes.mostPopularLabel}>
              {topLabel}
            </Box>
          )}

          <Box mb={2} textAlign={'center'} p={0}>
            <img
              src={plan.image_url_path}
              className={cl(classes.planImage, { [classes.card]: true })}
            />
          </Box>

          <Box
            mb={.5}
            textAlign={'center'}
            px={6}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Typography variant={'h3'} style={{ textTransform: 'capitalize' }}>
              {plan.title}
            </Typography>
          </Box>

          <Box ml={0} flexGrow={1}>
            {plan?.bullets?.map((bullet, i) => (
              <BulletItem disabled={false} key={i}>
                {bullet}
              </BulletItem>
            ))}

            {plan?.annualBullets?.map((bullet, index) => (
              <BulletItem disabled={isMonthlyPlan && !!price} key={index}>
                {bullet}
              </BulletItem>
            ))}
          </Box>

          {pricing}

          <Box textAlign={'center'} mt={3}>
            <Button
              color={'primary'}
              size={'large'}
              variant={'contained'}
              className={classes.checkoutBtn}
              href={checkoutHref}
            >
              {checkoutLabel}
            </Button>
          </Box>

        </Box>
      </Paper>
      {plan.pricing.length === 1 && (
        <AnnualTip variant={'body2'}>*only annually</AnnualTip>
      )}
    </Box>
  )
}

PlanCard.propTypes = {
  plan: PropTypes.object,
  planType: PropTypes.string,
  className: PropTypes.string,
  topLabel: PropTypes.string,
  checkoutLabel: PropTypes.string,
}

export default PlanCard

import css from 'styled-jsx/css'

// Use styled-jsx instead of global CSS because global CSS
// does not work for AMP pages: https://github.com/vercel/next.js/issues/10549
export default css.global`

  /* Foco - Regular */
  @font-face {
      font-display: auto;
      font-family: "Foco";
      font-weight: 400;
      src: url("/fonts/foco-regular-webfont.woff2") format("woff2"), url("/fonts/foco-regular-webfont.woff") format("woff"), url("/fonts/foco-regular.ttf") format("truetype");
  }

  /* Foco - Bold */
  @font-face {
      font-display: auto;
      font-family: "FocoBold";
      font-weight: 700;
      src: url("/fonts/foco-bold-webfont.woff2") format("woff2"), url("/fonts/foco-bold-webfont.woff") format("woff"), url("/fonts/foco-bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Montserrat";
    font-style: "bold";
    font-weight: 700;
    src: url("/fonts/montserrat/montserrat-bold-webfont.woff2") format("woff2"), url("/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: "normal";
    font-weight: 600;
    src: url("/fonts/montserrat/montserrat-semibold-webfont.woff2") format("woff2"), url("/fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: "normal";
    font-weight: 500;
    src: url("/fonts/montserrat/montserrat-medium-webfont.woff2") format("woff2"), url("/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: "normal";
    font-weight: 400;
    src: url("/fonts/montserrat/montserrat-regular-webfont.woff2") format("woff2"), url("/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: "normal";
    font-weight: 300;
    src: url("/fonts/montserrat/montserrat-light-webfont.woff2") format("woff2"), url("/fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  }
 
  
`

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const NewBubble = styled(Typography)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.violet[700]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1.4;
  text-align: center;
  width: 56px;
  height: 56px;
  position: absolute;
  top: -4px;
  left: -24px;
`

export const StyledImg = styled('img')`
  border-radius: 6px;
  box-shadow: 6px 10px 25px 0 rgba(11, 39, 158, 0.10);
  height: 158px;
  min-width: 200px;
  
  ${({ theme }) => theme.breakpoints.up('xs')} {
    height: 169px;
  }

  ${({ theme }) => `${theme.breakpoints.down('md')} and (orientation: landscape)`} {
    height: 132px;
  }
`

import React, { useState } from 'react'
import { bool, func, node, string } from 'prop-types'

import { Waypoint } from 'react-waypoint'

import Style from './InfiniteScroll.style'

/**
 * Infinite Scroll custom component that wraps the Waypoint library component
 */
const InfiniteScroll = ({
  children,
  loadMore,
  hasMore,
  loader,
  className,
}) => {
  const [loading, setLoading] = useState(false)

  const handleOnEnter = async () => {
    if (loading) return

    setLoading(true)
    await loadMore()
    setLoading(false)
  }

  return (
    <Style className={className}>
      {children}
      {loadMore && !loading && hasMore && (
        <Waypoint bottomOffset={'-20%'} onEnter={handleOnEnter} />
      )}
      {loadMore && loading && loader}
    </Style>
  )
}

InfiniteScroll.propTypes = {
  /** infinite scroll items */
  children: node,
  /** function that loads more items */
  loadMore: func,
  /** if component needs to load more items */
  hasMore: bool,
  /** loader placeholder */
  loader: node,
  /** custom class name */
  className: string,
}

export default InfiniteScroll

import React, { useCallback } from 'react'

import Modal from '@mui/material/Modal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import { useStoreState } from 'easy-peasy'
import { useRouter } from 'next/router'

import BulkEditImagesTextModal from 'components/BulkEditImagesTextModal'
import { Backdrop, LightDialog } from 'components/Modal'
import AddBookPictures from 'components/Modal/AddBookPictures'
import BookLimitsViolated from 'components/Modal/BookLimitsViolated'
import CheckoutExit from 'components/Modal/CheckoutExit'
import ConfirmAddress from 'components/Modal/ConfirmAddress'
import ConfirmationModal from 'components/Modal/ConfirmationModal'
import ConfirmDelete from 'components/Modal/ConfirmDelete'
import ConfirmEdit from 'components/Modal/ConfirmEdit'
import EditAddress from 'components/Modal/EditAddress'
import EditEmail from 'components/Modal/EditEmail'
import EditPassword from 'components/Modal/EditPassword'
import EditPicture from 'components/Modal/EditPicture'
import ForgotPassword from 'components/Modal/ForgotPassword'
import NewImageEditor from 'components/Modal/NewImageEditor'
import PictureMetaModal from 'components/Modal/PictureMetaModal'
import ReferralModal from 'components/Modal/ReferralModal'
import ReplacePicturesModal from 'components/Modal/ReplacePicturesModal'
import UpdatePaymentMethod from 'components/Modal/UpdatePaymentMethod'
import UpgradeMembership from 'components/Modal/UpgradeMembership'
import NewModal from 'components/NewModal'
import { useAppIsLoading } from 'dux/slice/ui'
import useModal from 'hooks/useModal'

import { APP_MODALS, ModalVariants } from './ModalManager.constants'

const newModals = [
  APP_MODALS.CloseWizard,
  APP_MODALS.DiscardWizard,
  APP_MODALS.ConfirmPreview,
  APP_MODALS.NewReplaceImagesByAdmin,
  APP_MODALS.MaxImagesLimitReached,
  APP_MODALS.MinImagesLimitReached,
  APP_MODALS.UploadFileTypeError,
  APP_MODALS.DeletePagesConfirmation,
  APP_MODALS.DeletePagesMinLimitReached,
  APP_MODALS.SendToCustomer,
  APP_MODALS.ArtBookSent,
  APP_MODALS.OpenInDesktop,
  APP_MODALS.ConfirmDeleteTextLabels,
]
/**
 * Modal Wrapper that encapsulates global modals
 */
const ModalManager = () => {
  const activeBook = useStoreState((state) => state.book_v3.activeBook_v3)
  const router = useRouter()
  const theme = useTheme()
  const fullScreen = !useMediaQuery(theme.breakpoints.up('sm'))

  const isLoading = useAppIsLoading()
  const { modal, unsetModal } = useModal()

  const handleClose = useCallback(() => {
    modal?.onClose?.()
    unsetModal()
  }, [modal?.onClose, unsetModal])

  if (!modal) return null

  if (newModals.includes(modal.name)) {
    return (
      <NewModal
        {...ModalVariants[modal.name]}
        {...modal}
        open={true}
        onClose={handleClose}
      />
    )
  }

  if (modal.name === APP_MODALS.CropAdjustPicture) {
    return (
      <Modal open={!!modal} onClose={unsetModal}>
        <NewImageEditor {...modal} onClose={unsetModal} />
      </Modal>
    )
  }

  if (modal.name === APP_MODALS.BulkEditImagesText) {
    return (
      <BulkEditImagesTextModal open={!!modal} onClose={handleClose} {...modal} />
    )
  }

  if (modal.name === APP_MODALS.EditPicture) {
    return (
      <Modal
        open={!!modal}
        onClose={handleClose}
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            variant: 'dark',
          },
        }}
      >
        <span>
          <EditPicture isLoading={isLoading} position={'fixed'} {...modal} />
        </span>
      </Modal>
    )
  }

  const isModalPersistent = [APP_MODALS.UpgradeMembership].includes(modal.name)

  return (
    <LightDialog
      open={!!modal}
      onClose={handleClose}
      fullScreen={fullScreen && !modal.name.includes('Confirm')}
      persistent={isModalPersistent}
    >
      {modal.name === APP_MODALS.ReplacePicturesModal && (
        <ReplacePicturesModal
          onClose={handleClose}
          onCompleted={modal.onCompleted}
          pictures={modal.pictures}
        />
      )}

      {modal.name === APP_MODALS.AddBookPictures && (
        <AddBookPictures
          onClose={handleClose}
          book={modal.book}
        />
      )}

      {modal.name === APP_MODALS.EditEmail && (
        <EditEmail />
      )}

      {modal.name === APP_MODALS.EditPassword && (
        <EditPassword />
      )}

      {modal.name === APP_MODALS.EditAddress && (
        <EditAddress
          address={modal.address}
          onSuccess={modal.onSuccess}
          title={modal.title}
          subtitle={modal.subtitle}
          showSetDefault={modal.showSetDefault}
        />
      )}

      {modal.name === APP_MODALS.CheckoutExit && (
        <CheckoutExit id={activeBook.id} />
      )}

      {modal.name === APP_MODALS.BookLimitsViolated && (
        <BookLimitsViolated message={modal.message} />
      )}

      {modal.name === APP_MODALS.PictureMetaModal && (
        <PictureMetaModal
          onSuccess={modal.onSuccess}
          picture={modal.picture}
          withReplaceTags={modal.withReplaceTags}
          isLoading={modal.isLoading}
          indeterminateHidden={modal.indeterminateHidden}
        />
      )}

      {modal.name === APP_MODALS.ForgotPassword && (
        <ForgotPassword />
      )}

      {modal.name === APP_MODALS.Referral && (
        <ReferralModal {...modal} />
      )}

      {modal.name === APP_MODALS.UpgradeMembership && (
        <UpgradeMembership />
      )}

      {modal.name === APP_MODALS.ConfirmEdit && (
        <ConfirmEdit
          id={activeBook.id}
          router={router}
          unsetModal={unsetModal}
          onSuccess={modal.onSuccess}
        />
      )}

      {modal.name === APP_MODALS.ConfirmAddress && (
        <ConfirmAddress
          userAddress={modal.userAddress}
          validAddress={modal.validAddress}
          onSuccess={modal.onSuccess}
          setAddress={modal.setAddress}
        />
      )}

      {modal.name === APP_MODALS.ConfirmDelete && (
        <ConfirmDelete {...modal} />
      )}

      {modal.name === APP_MODALS.ConfirmationModal && (
        <ConfirmationModal {...modal} />
      )}

      {modal.name === APP_MODALS.UpdatePaymentMethod && (
        <UpdatePaymentMethod />
      )}
    </LightDialog>
  )
}

export default ModalManager

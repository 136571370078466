import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  .content {
    padding: 0 20%;
    margin: 2rem 0;

    @media (max-width: ${theme.breakpoints.md}) {
      min-width: 400px;
    }

    .input {
      margin-bottom: 2rem;
    }

    .forgot-password {
      display: flex;
      justify-content: flex-end;
    }
  }

`

import Chip from '@mui/material/Chip'
import { withStyles } from '@mui/styles'

const PictureChildFilterChip = withStyles((theme) => ({
  root: {
    color: 'inherit',
    '&.active': {
      background: '#009FFF14 0% 0% no-repeat padding-box',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
}))(Chip)

export default PictureChildFilterChip

/**
 * Converts URL query string parameters to object.
 * @param {string} search
 * @return {object}
 */
const getUrlParams = (search) => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=')
    return Object.assign(params, { [key]: decodeURIComponent(val) })
  }, {})
}

/**
 * Concerts object to URL query string parameters (including '?' at the beginning)
 * @param {object} obj
 * @return {string}
 */
const convertToQueryString = (obj) => {
  let arr = []
  for (let p in obj) {
    if ({}.hasOwnProperty.call(obj, p)) {
      arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }

  return arr.length === 0 ? '' : `?${arr.join('&')}`
}

module.exports = { getUrlParams, convertToQueryString }
import useMediaQuery from '@mui/material/useMediaQuery'

export const isTouchScreen = typeof window !== 'undefined' ? 'ontouchstart' in window : false

const useNewResponsive = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isXsMobile = useMediaQuery((theme) => theme.breakpoints.up('xs'))
  const isSmMobile = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const isLgDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isXlDesktop = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const isXxlDesktop = useMediaQuery((theme) => theme.breakpoints.up('xxl'))

  return {
    isMobile,
    isXsMobile,
    isSmMobile,
    isLgDesktop,
    isXlDesktop,
    isXxlDesktop,
    isDesktop: !isMobile,
    isNonMobileTouchDevice: !isMobile && isTouchScreen,
  }
}

export default useNewResponsive

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const NoFilterResultsWrap = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

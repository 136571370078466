export const QUERY_CHILD_NAME = 'CHILD'
export const QUERY_AGE_GRADE = 'LABEL'
export const QUERY_DATE = 'DATE_CREATED'
export const QUERIES = {
  childName: {
    label: 'Name',
    buttons: [
      {
        key: QUERY_CHILD_NAME,
        value: 'ASC',
        label: 'A-Z',
      },
      {
        key: QUERY_CHILD_NAME,
        value: 'DESC',
        label: 'Z-A',
      },
    ],
  },
  ageGrage: {
    label: 'Age/Grade',
    buttons: [
      {
        key: QUERY_AGE_GRADE,
        value: 'ASC',
        label: 'Asc',
      },
      {
        key: QUERY_AGE_GRADE,
        value: 'DESC',
        label: 'Desc',
      },
    ],
  },
  creationDate: {
    label: 'Creation Date',
    buttons: [
      {
        key: 'PICTURE_DATE_CREATED',
        value: 'ASC',
        label: 'Asc',
      },
      {
        key: 'PICTURE_DATE_CREATED',
        value: 'DESC',
        label: 'Desc',
      },
    ],
  },
  imageName: {
    label: 'File Name',
    buttons: [
      {
        key: 'IMAGE_NAME',
        value: 'ASC',
        label: 'Asc',
      },
      {
        key: 'IMAGE_NAME',
        value: 'DESC',
        label: 'Desc',
      },
    ],
  },
  date: {
    label: 'Date',
    buttons: [
      {
        key: QUERY_DATE,
        value: 'ASC',
        label: 'Asc',
      },
      {
        key: QUERY_DATE,
        value: 'DESC',
        label: 'Desc',
      },
    ],
  },
}

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  width: 100%;
  overflow-x: auto;
  font-family: ${theme.typography.type.secondary} !important;

  .ReactVirtualized__Grid__innerScrollContainer {
    max-width: none !important;
  }
`

export const ListPageWrap = styled('div')`
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;
  position: relative;

  .img {
    width: 100%;
  }
`

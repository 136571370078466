import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

import { unsetModal } from 'dux/slice/ui'

const useUnsetModalOnRouteChange = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    const handler = () => dispatch(unsetModal())
    router.events.on('routeChangeStart', handler)
    return () => {
      router.events.off('routeChangeStart', handler)
    }
  }, [router, dispatch])
}

export default useUnsetModalOnRouteChange

import { computed } from 'easy-peasy'

import { PAGE_TYPES } from 'utils/constants'

import { DEFAULT_BOOK_v3, DEFAULT_TEXT_SETTINGS, MAX_BOOK_CAPACITY, MIN_BOOK_CAPACITY } from './Book.constants'
import { hasCover } from './Book.utils'
import { canBeSentToCustomer, getPageRenderKey, isSentToCustomer as _isSentToCustomer } from './Book.utils'

export const FIRST_INSIDE_COVER_INDEX = -1
export const COVER_INDEX = -2
export const LAST_INSIDE_COVER_INDEX = -3
export const BACK_COVER_INDEX = -4
export const INSIDE_COVER_LABEL = 'Inside Cover'

const getMapPagesToView = (activeBook) => {
  let pages = []
  pages.push(
    {
      type: PAGE_TYPES.COVER,
      key: COVER_INDEX,
      ...activeBook.cover,
      hideDate: activeBook.hideDate,
      hideGrade: activeBook.hideGrade,
      hideName: activeBook.hideName,
      hideTitle: activeBook.hideTitle,
    },
    {
      type: PAGE_TYPES.BLANK,
      key: FIRST_INSIDE_COVER_INDEX,
      index: FIRST_INSIDE_COVER_INDEX,
      label: INSIDE_COVER_LABEL,
      sections: [],
    },
  )

  activeBook.pages.forEach((page, index) => {
    pages.push({
      type: PAGE_TYPES.PAGE,
      index,
      label: index + 1,
      key: getPageRenderKey(page),
      ...page,
      hideDate: activeBook.hideDate,
      hideGrade: activeBook.hideGrade,
      hideName: activeBook.hideName,
      hideTitle: activeBook.hideTitle,
    })
  })

  const pagesCount = pages.length - 1
  if (pagesCount % 2 !== 0) {
    pages.push({
      type: PAGE_TYPES.BLANK,
      key: LAST_INSIDE_COVER_INDEX,
      index: LAST_INSIDE_COVER_INDEX,
      label: INSIDE_COVER_LABEL,
      sections: [],
    })
  }

  pages.push({
    type: PAGE_TYPES.BACK_COVER,
    key: BACK_COVER_INDEX,
    index: BACK_COVER_INDEX,
    label: 'Back Cover',
    sections: [],
  })

  return pages
}
const bookModel_v3 = {
  activeBook_v3: DEFAULT_BOOK_v3,
  wizardBook_v3: { ...DEFAULT_BOOK_v3, ...DEFAULT_TEXT_SETTINGS },
  isLoading_v3: false,
  pictureIds_v3: computed(
    (state) => state.activeBook_v3.pages.flatMap(({ sections }) => sections
      .filter(({ picture }) => picture && picture.id)
      .map(({ picture }) => picture.id)) || [],
  ),
  mapPagesToView_v3: computed((state) => getMapPagesToView(state.activeBook_v3)),
  orderedPageIds_v3: [],
  dndState_v3: {
    draggingPages_v3: [],
    draggedPagesIds_v3: [],
    isDragStarted_v3: false,
  },
  selected_v3: [],
  isAddPageSelected_v3: false,
  activePageKey_v3: null,
  isSentToCustomer_v3: computed((state) => _isSentToCustomer(state.activeBook_v3)),
  canSendToCustomer_v3: computed((state) => canBeSentToCustomer(state.activeBook_v3)),
  canApprove_v3: computed((state) => {
    const bookHasCover = hasCover(state.activeBook_v3.cover)
    const bookHasMinPageAmount = state.activeBook_v3.pages.length >= MIN_BOOK_CAPACITY
    const bookHasMaxPageAmount = state.activeBook_v3.pages.length <= MAX_BOOK_CAPACITY
    const bookHasAllowedPageAmount = state.activeBook_v3.pages.length <= state.activeBook_v3.prepaidPages
    const isBoxBook = state.activeBook_v3.isInvoiced

    return isBoxBook
      ? (bookHasCover && bookHasMinPageAmount && bookHasAllowedPageAmount)
      : (bookHasCover && bookHasMinPageAmount && bookHasMaxPageAmount)
  }),
  activePage_v3: computed((state) => {
    return state.mapPagesToView_v3.find(({ key }) => key === state.activePageKey_v3) || null
  }),
  selectedPages_v3: computed((state) => {
    return state.activeBook_v3.pages.filter((page) => state.selected_v3.includes(page.id)) || []
  }),
  pageCount_v3: computed((state) => state.activeBook_v3.pages?.length ?? 0),
}

export default bookModel_v3

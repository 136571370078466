import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

import { BodyText } from 'components/NewModal/NewModal.style'
import useMetaThemeColor from 'hooks/useMetaThemeColor'
// This color is not in the theme palette. It's a combination of several colors with different opacities.
export const MOBILE_BOOK_SETTINGS_META_THEME_COLOR = '#3f4e8d'
const DeletePagesConfirmationContent = ({ pagesToDelete }) => {
  useMetaThemeColor(MOBILE_BOOK_SETTINGS_META_THEME_COLOR, true)
  return (
    <BodyText sx={{ mt: 3 }}>
      Are sure you want to remove{' '}
      {pagesToDelete > 1 ? (
        <Typography variant={'strong'}>{pagesToDelete} pages</Typography>
      ) : 'this page'}?
    </BodyText>
  )
}

DeletePagesConfirmationContent.propTypes = {
  pagesToDelete: PropTypes.number,
}

export default DeletePagesConfirmationContent

import React, { useContext } from 'react'
import { bool, node, string } from 'prop-types'

import cl from 'classnames'

import { TabsContext } from '../Tabs'

/**
 * Clickable tab component
 */
const Tab = ({ id, disabled, children, className = '' }) => {
  const { setActiveTab, activeTab } = useContext(TabsContext)

  const handleClick = () => {
    if (disabled)
      return

    setActiveTab(id)
  }

  return (
    <span
      className={cl('tab', className, {
        active: activeTab === id,
        disabled,
      })}
      onClick={handleClick}
    >
      {children}
    </span>
  )
}

Tab.propTypes = {
  id: string,
  disabled: bool,
  children: node,
  className: string,
}

export default Tab

import { styled } from '@mui/material/styles'

export default styled('div')`
  .picture-upload__control {
    width: 200px;
  }
`

export const PictureUploadFilterStories = styled('div')`
  margin: 2rem;
  width: 500px;
`

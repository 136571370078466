import { baseApi } from './baseApi'
import { TAG_TYPE } from './constants'
import { isHydrateAction } from './utils'

const USER_TAG_ID = {
  USER: 'User',
  PAYMENT: 'Payment',
  MEMBERSHIP: 'Membership',
  SHIPPING: 'Shipping',
}

export const userApiUrls = {
  getUser: () => '/api/v2/auth',
  getMembership: () => '/api/v2/user/membership',
  getPaymentInformation: () => '/api/v1/user/net_profile/show_plan_info',
  getShippingAddresses: () => '/api/v1/user/addresses',
  updatePaymentInformation: () => '/api/v1/user/net_profile/update_profile_item',
  updateUserAccount: () => '/api/v2/user',
  updateUserEmail: () => '/api/v2/users/email',
  updateUserPassword: () => '/api/v2/users/password',
  requestPasswordReset: () => '/api/v2/users/password_reset',
}

export const userApi =
  baseApi
    .enhanceEndpoints({ addTagTypes: [TAG_TYPE.USER] })
    .injectEndpoints({
      overrideExisting: true,
      extractRehydrationInfo(action, { reducerPath }) {
        if (isHydrateAction(action)) {
          return action.payload[reducerPath]
        }
      },
      endpoints: (builder) => ({
        getUser: builder.query({
          query: () => ({
            url: userApiUrls.getUser(),
            method: 'GET',
          }),
          transformResponse: (response) => {
            return { activeUser: response.user, adminMeta: response.admin }
          },
          providesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.USER }],
        }),
        getMembership: builder.query({
          query: () => ({
            url: userApiUrls.getMembership(),
            method: 'GET',
          }),
          transformResponse: (response) => response.data,
          providesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.MEMBERSHIP }],
        }),
        getShippingAddresses: builder.query({
          query: () => ({
            url: userApiUrls.getShippingAddresses(),
            method: 'GET',
          }),
          transformResponse: (response) => response.data,
          providesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.SHIPPING }],
        }),
        getPaymentInformation: builder.query({
          query: () => ({
            url: userApiUrls.getPaymentInformation(),
            method: 'GET',
          }),
          transformResponse: (response) => response.plan_info,
          providesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.PAYMENT }],
        }),
        updatePaymentInformation: builder.mutation({
          query: ({ cc, exp, cvc: cvv, ...payload }) => ({
            url: userApiUrls.updatePaymentInformation(),
            method: 'PUT',
            data: {
              ...payload,
              cvv,
              credit_card: `${cc}`.replaceAll(' ', ''),
              card_expiration_date: `${exp}`.replaceAll(' / ', ''),
            },
          }),
          invalidatesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.PAYMENT }],
          extraOptions: {
            successMessage: 'Account payment method updated.',
          },
        }),
        updateUserAccount: builder.mutation({
          query: ({ name, ...address }) => ({
            url: userApiUrls.updateUserAccount(),
            method: 'PUT',
            data: { user: { name, address } },
          }),
          invalidatesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.USER }],
          extraOptions: {
            successMessage: 'Account details successfully updated.',
          },
        }),
        updateUserEmail: builder.mutation({
          query: (payload) => ({
            url: userApiUrls.updateUserEmail(),
            method: 'PUT',
            data: { user: payload },
          }),
          invalidatesTags: () => [{ type: TAG_TYPE.USER, id: USER_TAG_ID.USER }],
          extraOptions: {
            successMessage: 'Email updated successfully.',
          },
        }),
        updateUserPassword: builder.mutation({
          query: (payload) => ({
            url: userApiUrls.updateUserPassword(),
            method: 'PUT',
            data: { user: payload },
          }),
          extraOptions: {
            successMessage: 'Password updated successfully.',
          },
        }),
        requestPasswordReset: builder.mutation({
          query: (email) => ({
            url: userApiUrls.requestPasswordReset(),
            method: 'POST',
            data: {
              user: {
                email,
              },
            },
          }),
        }),
      }),
    })

export const useGetAccessibleMembership = () => {
  const { data: membership, isLoading: isMembershipLoading } = useGetMembershipQuery()
  if (membership?.status !== 'canceled') {
    return [membership, isMembershipLoading]
  }
  return [null, isMembershipLoading]
}

export const {
  useGetUserQuery,
  useGetMembershipQuery,
  useGetPaymentInformationQuery,
  useGetShippingAddressesQuery,
  useUpdateUserEmailMutation,
  useUpdatePaymentInformationMutation,
  useUpdateUserPasswordMutation,
  useRequestPasswordResetMutation,
  useUpdateUserAccountMutation,
} = userApi

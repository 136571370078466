import { styled } from '@mui/material/styles'

export const SubCardDetailsStyles = styled('div')`
  display: flex;
  gap: 8px;
`

export default styled('div')`
  .buttons > button {
    margin: 0;
    width: 236px
  }
`

export const Form = styled('form')`
  padding: 0;
  margin-top: 32px;

  @media (min-width: 600px) {
    padding: 0 20%;
  }
`

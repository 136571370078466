import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import MobileNewBookBuilderImage from 'components/MobileNewBookBuilderImage/MobileNewBookBuilderImage'
import { BodyText, Title } from 'components/NewModal/NewModal.style'

import { ImageWrap } from './OpenInDesktopContent.style'

const OpenInDesktopContent = ({ isEdit = false }) => {
  return (
    <Box>
      <ImageWrap>
        <MobileNewBookBuilderImage />
      </ImageWrap>
      <Title align={'center'} variant={'h4'}>Open in desktop to {isEdit ? 'edit' : 'create'} your book</Title>
      <BodyText textAlign={'left'}>
        We are working hard to make the mobile book {isEdit ? 'editing' : 'creation'} process better for you.{' '}
        In the meantime, you can {isEdit ? 'edit' : 'create'} your book in the newly updated desktop version.
      </BodyText>
    </Box>
  )
}

OpenInDesktopContent.propTypes = {
  isEdit: PropTypes.bool,
}

export default OpenInDesktopContent

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'

import Button from 'components/Buttons/Button'
import Input from 'components/FormFields/Input'
import ButtonLabel from 'components/Text/ButtonLabel'
import H2 from 'components/Text/H2'
import { useRequestPasswordResetMutation } from 'dux/queries/user'
import { useSetErrorMessage } from 'dux/slice/ui'
import validations from 'utils/validations'

const ModalForm = ({ onSubmit }) => {
  const setError = useSetErrorMessage()
  const [requestPasswordReset] = useRequestPasswordResetMutation()

  const [loading, setLoading] = useState(false)

  const onFormSubmit = async ({ email }) => {
    try {
      setLoading(true)
      await requestPasswordReset(email)

      onSubmit()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
    },
  })

  return (
    <div className={'content'}>
      <H2 className={'center darkblue'}>
        Forgot Password?
      </H2>
      <div className={'label center darkgray sm'}>
        Please enter your email address below and we&apos;ll send you a link to reset your password.
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Input
          className={'input'}
          label={'Email Address'}
          type={'email'}
          {...register('email', validations.email)}
          error={errors.email}
        />
        <div className={'flex justify-center'}>
          <Button type={'submit'} disabled={loading}>
            <ButtonLabel className={'white'}>
              Submit
            </ButtonLabel>
          </Button>
        </div>
      </form>
    </div>
  )
}

ModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ModalForm

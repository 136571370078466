import React, { useCallback, useMemo, useState } from 'react'
import { bool, func, object } from 'prop-types'

import Button from 'components/Buttons/Button'
import PictureMetaForm from 'components/Forms/PictureMeta'
import Modal from 'components/Modal'
import H2 from 'components/Text/H2'
import Link from 'components/Text/Link'
import useModal from 'hooks/useModal'

import PictureMetaModalStyle from './PictureMetaModal.style'

const PictureMetaModal = ({
  onSuccess,
  picture = {},
  isMultipleImages,
  isLoading,
  withReplaceTags = false,
  indeterminateHidden = false,
}) => {
  const { unsetModal } = useModal()

  const [child, setChild] = useState(picture.child || '')
  const [label, setLabel] = useState(picture.label || '')
  const [tags, setTags] = useState(picture.tags || [])
  const [pictureDate, setPictureDate] = useState(picture.pictureDate || null)
  const [replaceTags, setReplaceTags] = useState(false)
  const [hidden, setHidden] = useState({ value: false, shouldUpdate: false })

  const initialPicture = useMemo(() => ({
    ...picture,
    child: picture.child || '',
    label: picture.label || '',
    tags: picture.tags || [],
    pictureDate: picture.pictureDate || null,
    replaceTags: false,
  }), [picture])

  const handleSuccess = async () => {
    const childAttributes = { ...picture.child, ...child }
    const labelAttributes = { ...picture.label, ...label }
    const submitValues = { replaceTags }

    if (pictureDate) {
      submitValues.pictureDate = pictureDate.toISOString()
    }

    if (Object.keys(childAttributes).length > 0) {
      submitValues.childAttributes = childAttributes
    }

    if (Object.keys(labelAttributes).length > 0) {
      submitValues.labelAttributes = labelAttributes
    }

    if (tags && tags.length > 0) {
      submitValues.tagAttributes = tags
    }

    if (hidden.shouldChange) {
      submitValues.hidden = hidden.value
    }

    onSuccess(submitValues)
    unsetModal()
  }

  const handleSkip = () => {
    unsetModal()
    onSuccess()
  }

  const handleValuesChange = useCallback((field, value) => {
    if (field === 'child') setChild(value)
    if (field === 'label') setLabel(value)
    if (field === 'tags') setTags(value.filter((tag) => !tag._delete))
    if (field === 'pictureDate') setPictureDate(value)
    if (field === 'replaceTags') setReplaceTags(value)
    if (field === 'hidden') setHidden(value)
  }, [])

  return (
    <PictureMetaModalStyle>
      <Modal className={'picture-meta-modal'} handleClose={unsetModal}>
        <H2 className={'center darkblue'}>
          Label this Set of Photos
        </H2>
        <div className={'picture-meta-content'}>
          <PictureMetaForm
            picture={initialPicture}
            onValuesChange={handleValuesChange}
            isMultipleImages={isMultipleImages}
            isLoading={isLoading}
            showCaption={false}
            withReplaceAll={withReplaceTags}
            indeterminateHidden={indeterminateHidden}
          />
          <div className={'buttons'}>
            <Link className={'link blue pointer secondary'} onClick={handleSkip}>Skip this Step</Link>
            <Button onClick={handleSuccess} type={'primary'}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </PictureMetaModalStyle>
  )
}

PictureMetaModal.propTypes = {
  onSuccess: func.isRequired,
  picture: object,
  isMultipleImages: bool,
  isLoading: bool,
  withReplaceTags: bool,
  indeterminateHidden: bool,
}

export default PictureMetaModal

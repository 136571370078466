import React from 'react'
import PropTypes from 'prop-types'

import Switch from '@mui/material/Switch'

import { BorderedSwitchFormLabelStyled, BorderedSwitchStyled } from './BorderedSwitchStyle.style'

const BorderedSwitch = ({ label, checked, isMixed, ...switchProps }) => {
  return (
    <BorderedSwitchStyled>
      <BorderedSwitchFormLabelStyled
        labelPlacement={'start'}
        control={<Switch size={'small'} checked={checked} {...switchProps} />}
        isMixed={isMixed}
        label={label}
      />
    </BorderedSwitchStyled>
  )
}

BorderedSwitch.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  isMixed: PropTypes.bool,
}

export default BorderedSwitch

import * as Sentry from '@sentry/react'

import api from 'utils/api'

const formatPictureData = (picture) => ({
  ...picture,
  child_attributes: picture.child,
  label_attributes: picture.label,
  tag_attributes: picture.tags,
})

export const calculateImageData = async (file) => new Promise((resolve, reject) => {
  try {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = ({ target }) => {
      const image = new Image()

      image.crossOrigin = 'anonymous'
      image.src = target.result

      image.onload = () => resolve(image)
      image.onerror = () => reject(new Error('Error: failed to resolve and read image'))
    }
  } catch (err) {
    reject(err)
  }
})

export const createPictureRecord = async (picture) => {
  if (picture?.id) return picture

  const formatted = formatPictureData(picture)
  const { width, height } = await calculateImageData(picture.file)

  const { data: { data } } = await api.post({
    data: {
      picture: {
        ...formatted,
        height,
        image: picture.file.name,
        title: picture.title || '',
        width,
      },
    },
    url: '/api/v2/user/pictures',
  })

  return data
}

export const getPictureRecord = async (picture) => {
  const { data: { data: pictureData } } = await api.get({
    url: `/api/v2/user/pictures/${picture.id}`,
  })

  return pictureData
}

export const handleFailedUpload = async (picture) => {
  if (!picture || !picture.id) return

  try {
    await updatePictureRecord({ id: picture.id, uploaded: false })
  } catch (err) {
    console.error('handle failed upload error:', err)

    Sentry.captureException(err)
  }
}

export const updatePictureRecord = async (picture) => {
  const formatted = formatPictureData(picture)

  const { data: { data: updateData } } = await api.patch({
    data: { picture: { ...formatted } },
    url: `/api/v2/user/pictures/${picture.id}`,
  })

  return updateData
}

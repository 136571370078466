import React, { useCallback } from 'react'

import CachedIcon from '@mui/icons-material/Cached'
import Button from '@mui/material/Button'

import { APP_MODALS } from 'components/ModalManager'
import { useSetNotification } from 'dux/slice/ui'
import useModal from 'hooks/useModal'

const ReplaceButton = () => {
  const { setModal } = useModal()
  const setNotification = useSetNotification()

  const handleReplaceClick = useCallback(() => {
    setModal({
      name: APP_MODALS.ReplacePicturesModal,
      pictures: [],
      onCompleted: (notification) => setNotification(notification),
    })
  }, [setNotification, setModal])

  return (
    <Button
      variant={'text'}
      color={'primary'}
      startIcon={<CachedIcon color={'inherit'} />}
      onClick={handleReplaceClick}
    >
      Replace
    </Button>
  )
}

export default ReplaceButton

import { action, actionOn, thunkOn } from 'easy-peasy'
import isEqual from 'lodash/isEqual'

import { DEFAULT_BOOK, DEFAULT_POSITION, DEFAULT_TEXT_SETTINGS } from './Book.constants'
import { FIRST_INSIDE_COVER_INDEX, getSingleImagePagePicture, LAST_INSIDE_COVER_INDEX } from './Book.model'
import { adjustBookCoverPosition, maxBookTitleLength } from './Book.utils'

const bookActions = {
  /**
   * setDefaultBook - resets the state of activeBook
   */
  setDefaultBook: action((state) => {
    state.activeBook = DEFAULT_BOOK
  }),
  /**
   * setBook - set activeBook from the payload
   * @param {object} payload - activeBook to set
   */
  setBook: action((state, payload) => {
    const activeBook = state.activeBook

    // Reset activePageKey if the book id has changed
    if (!!payload.id && state.activeBook.id !== payload.id) {
      state.activePageKey = null
    }

    const hasNewPictures = activeBook.pictures.some((picture, index) => {
      const otherPicture = payload.pictures?.[index]
      return picture.id !== otherPicture?.id || picture.updatedAt !== otherPicture?.updatedAt
    })
    const arePicturesEqual = activeBook.pictures.length === payload.pictures.length && !hasNewPictures

    const areBookEqual = isEqual(
      // Omitting the pictures property from the comparison
      // because it has another structure
      { ...activeBook, pictures: undefined, updatedAt: undefined },
      { ...payload, pictures: undefined, updatedAt: undefined })

    if (!areBookEqual || !arePicturesEqual) {
      state.activeBook = {
        ...state.activeBook,
        ...payload,
        pictures: payload.pictures.map((picture, sortIndex) => ({ ...picture, sortIndex })),
      }
    }
    state.isLoading = false
  }),
  /**
   * setSize - sets size of activeBook represented as productId
   * @param {number} payload - id of the size
   */
  setSize: action((state, payload) => {
    const activeBook = state.activeBook

    activeBook.productId = payload.id
    activeBook.cover.title = activeBook.cover.title.slice(0, maxBookTitleLength(state.sizes, payload.id))
    state.isLoading = false
  }),
  /**
   * setTitle - sets title of activeBook.cover
   * @param {string} payload - title
   */
  setTitle: action((state, payload) => {
    if (state.activeBook.cover.title !== payload) {
      state.activeBook.cover.title = payload
    }
  }),
  /**
   * setCoverTitle - sets title of activeBook.cover and bypasses thunkOn watcher
   * @param {string} payload - title
   */
  setCoverTitle: action((state, payload) => {
    if (state.activeBook.cover.title !== payload) {
      state.activeBook.cover.title = payload
    }
  }),
  setAlignTitle: action((state, payload) => {
    state.activeBook.cover.titleAlign = payload
  }),
  /**
   * togglePicture - toggles selected state of pictures
   * @param {string} payload.id - id of the picture
   */
  togglePicture: action((state, id) => {
    if (state.selected.includes(id)) {
      state.selected = state.selected.filter((selectedId) => selectedId !== id)
    } else {
      state.selected.push(id)
    }
  }),
  deselectPictures: action((state) => {
    state.selected = []
  }),
  addDragging: action((state, id) => {
    state.dndState.isDragStarted = true

    // TODO migration to the new pages structure:
    //  make sure to pass page.id here instead of picture.id
    state.dndState.draggedPictureIds.push(id)

    const draggedPage = state.pages.find((page) => page.key === id)
    state.dndState.draggingPages.push(draggedPage)

    if (state.selected.length) {
      const filteredSelected = state.selected.filter((id) => !state.dndState.draggedPictureIds.includes(id))
      state.dndState.draggedPictureIds.push(...filteredSelected)

      const selectedPages = state.pages?.filter(
        (page) => state.selected.includes(page.key) && !state.dndState.draggingPages.includes(page),
      ) || []
      state.dndState.draggingPages.push(...selectedPages)
    }

    // TODO rewrite to page id after migration
    // const isPictureSelected = state.selected.includes(id)
    // if (!isPictureSelected) {
    //   state.selected.push(id)
    // }
  }),
  endDragging: action((state) => {
    state.dndState.draggingPages = []
    state.dndState.draggedPictureIds = []
  }),
  /**
   * Sets cover picture
   * @param {{picture: object}} payload - picture
   */
  setCoverPicture: action((state, payload) => {
    state.activeBook.cover.picture = {
      id: payload.picture.id,
      image: payload.picture,
      ...adjustBookCoverPosition(DEFAULT_POSITION, state.activeBook.size, payload.picture),
      rotationAngle: 0,
    }
  }),

  /**
   * setCoverPosition - sets cover position and rotation angle
   * @param {object} payload - coordinates of the picture and rotation angle
   */
  setCoverPosition: action((state, payload) => {
    const position = {
      xPositionPercentage: payload.xPositionPercentage,
      yPositionPercentage: payload.yPositionPercentage,
    }
    const { image } = state.activeBook.cover.picture
    state.activeBook.cover.picture = {
      ...state.activeBook.cover.picture,
      ...adjustBookCoverPosition(position, state.activeBook.size, image, payload.rotate),
      rotationAngle: payload.rotate,
    }
  }),
  setCoverRotation: action((state, rotationAngle) => {
    const { image } = state.activeBook.cover.picture
    state.activeBook.cover.picture = {
      ...state.activeBook.cover.picture,
      ...adjustBookCoverPosition(DEFAULT_POSITION, state.activeBook.size, image, rotationAngle),
      rotationAngle,
    }
  }),
  setPictureTitle: action((state, payload) => {
    state.activeBook.pictures = state.activeBook.pictures.map((picture) => {
      if (picture.id === payload.pictureId) {
        return { ...picture, title: payload.title }
      }
      return picture
    })
  }),
  bulkSetBookPictures: action((state, { pictures }) => {
    state.activeBook.pictures = state.activeBook.pictures.map((pic) => {
      const newPicture = pictures.find((item) => item.id === pic.id)
      return newPicture ? { ...pic, ...newPicture } : pic
    })
  }),
  setTextDisplaySettings: action((state, payload) => {
    state.activeBook = {
      ...state.activeBook,
      ...payload,
    }
  }),
  movePages: action((state, { indexTo }) => {
    const pictures = state.activeBook.pictures
    const draggedPictureIds = state.dndState.draggedPictureIds
    /*
     * NOTE: this can be rewritten to use draggedPictureIds at top level but in theory
     * we may get nulls when can't find images
     */
    const sortedDraggedPictures = pictures
      .filter((picture) => draggedPictureIds.includes(picture.id))
      .sort((a, b) => a.sortIndex - b.sortIndex)

    if (indexTo === FIRST_INSIDE_COVER_INDEX) {
      const filteredPictures = pictures.filter((pic) => !draggedPictureIds.includes(pic.id))

      state.activeBook.pictures = [
        ...sortedDraggedPictures,
        ...filteredPictures,
      ]
    } else if (indexTo === LAST_INSIDE_COVER_INDEX) {
      const filteredPictures = pictures.filter((pic) => !draggedPictureIds.includes(pic.id))

      state.activeBook.pictures = [
        ...filteredPictures,
        ...sortedDraggedPictures,
      ]
    } else {
      let upperHalfRemainingPics = pictures.slice(0, indexTo)
      if (indexTo > sortedDraggedPictures[0].sortIndex) {
        upperHalfRemainingPics = upperHalfRemainingPics
          .filter((picture) => !draggedPictureIds.includes(picture.id))
      }
      let lowerHalfRemainingPics = pictures.slice(indexTo)
      if (indexTo <= sortedDraggedPictures[sortedDraggedPictures.length - 1].sortIndex) {
        lowerHalfRemainingPics = lowerHalfRemainingPics
          .filter((picture) => !draggedPictureIds.includes(picture.id))
      }

      state.activeBook.pictures = [
        ...upperHalfRemainingPics,
        ...sortedDraggedPictures,
        ...lowerHalfRemainingPics,
      ]
    }
    state.activeBook.pictures.forEach((picture, sortIndex) => picture.sortIndex = sortIndex)
  }),
  onMovePages: thunkOn(
    (actions) => actions.movePages,
    (actions, target, helpers) => {
      const sortedDraggedPages = helpers.getStoreState().book.dndState.draggingPages.sort(
        (a, b) => getSingleImagePagePicture(a)?.sortIndex - getSingleImagePagePicture(b)?.sortIndex,
      )
      const firstSelectedPage = sortedDraggedPages?.[0]
      if (firstSelectedPage?.key) {
        actions.setActivePageKey(firstSelectedPage.key)
      }
    },
  ),
  setIsDragStarted: action((state, payload) => {
    state.dndState.isDragStarted = payload
  }),
  movePictures: action((state, { index, pictures, dir }) => {
    let picArray = dir === 'back' ? pictures.reverse() : pictures

    const moveList = pictures.map(({ id }) => id)

    const newPictures = state.activeBook.pictures.filter((pic) => !moveList.includes(pic.id))

    newPictures.splice(index, 0, ...picArray.sort((a, b) => a.sortIndex - b.sortIndex))
    state.activeBook.pictures = newPictures
  }),

  /**
   * addPicture - adds picture to the collection of existing pictures
   * @param {string} picture
   */
  addPicture: action((state, picture) => {
    state.activeBook.pictures.push(picture)
    state.isLoading = false
  }),
  /**
   * swapPicture - swap picture with provided id for a new one
   * @param {string} payload.pictureToSwapId
   * @param {object} payload.newPicture
   */
  swapPicture: action((state, payload) => {
    const updatePix = state.activeBook.pictures.map(
      (picture) => (picture.id === payload.pictureToSwapId) ? payload.newPicture : picture,
    )
    state.activeBook.pictures = updatePix.filter(Boolean)
    state.isLoading = false
  }),
  /**
   * setIsLoading - handle global lading state
   * @param {boolean} - payload true/false
   */
  setIsLoading: action((state, payload) => {
    if (state.isLoading === payload) return
    state.isLoading = payload
  }),
  /**
   * setError - handle global lading state
   * @param {object} - error object
   */
  setError: action((state, payload) => {
    state.error = payload
  }),

  /**
   * store listeners: https://easy-peasy.now.sh/docs/api/listeners.html
   * listen to pictures.setPicture event
   */
  onSetPicture: actionOn(
    (actions, storeActions) => [storeActions.pictures.setPicture],
    (state, target) => {
      const { payload: { picture } } = target
      state.activeBook.pictures = state.activeBook.pictures.map(
        (pic) => pic.id === picture.id ? { ...pic, ...picture } : pic,
      )
      // update cover picture if it's the same picture
      if (state.activeBook.cover.picture?.id === picture.id) {
        state.activeBook.cover.picture.image = {
          ...state.activeBook.cover.picture.image,
          ...picture,
        }
      }
    },
  ),

  resetWizardBook: action((state) => {
    state.wizardBook = {
      ...DEFAULT_BOOK,
      ...DEFAULT_TEXT_SETTINGS,
    }
  }),
  setWizardBook: action((state, payload) => {
    state.wizardBook = {
      ...state.wizardBook,
      ...payload,
    }
  }),
  setWizardBookTitle: action((state, payload) => {
    if (state.wizardBook.cover.title !== payload) {
      state.wizardBook.cover.title = payload
    }
  }),
  setWizardBookSize: action((state, payload) => {
    const book = state.wizardBook
    book.productId = payload.id
    book.cover.title = book.cover.title.slice(0, maxBookTitleLength(state.sizes, payload.id))
  }),
  setWizardCoverPicture: action((state, payload) => {
    state.wizardBook.cover.picture = {
      id: payload.picture.id,
      image: payload.picture,
      ...adjustBookCoverPosition(DEFAULT_POSITION, state.activeBook.size, payload.picture),
      rotationAngle: 0,
    }
  }),

  setWizardBookTextSettings: action((state, payload) => {
    state.wizardBook = {
      ...state.wizardBook,
      ...payload,
    }
  }),
  setActivePage: action((state, payload) => {
    state.activePageKey = payload.key
  }),
  setActivePageKey: action((state, payload) => {
    state.activePageKey = payload
  }),
  setIsAddPageSelected: action((state, isAddPageSelected) => {
    if (state.isAddPageSelected === isAddPageSelected) return
    state.isAddPageSelected = isAddPageSelected
  }),
}

export default bookActions

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('span')`
  font-family: ${theme.typography.type.montserrat};
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.size.lg};
  text-decoration: underline;
  line-height: 1;

  @media (max-width: ${theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.typography.size.sm};
  }

  &.secondary {
    text-decoration: none;
  }
`


import { useCallback } from 'react'

const useScrollParentToBottom = (ref) => {
  return useCallback(() => {
    setTimeout(() => {
      // time out to handle height increase after tag added
      const node = ref?.current?.parentNode
      if (!ref || !node) return
      node.scrollTo(0, node.scrollHeight)
    }, 0)
  }, [ref])
}

export default useScrollParentToBottom

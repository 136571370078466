const qs = require('qs')

const { cookieAuth, validateResetToken, validateActivationToken } = require('../../server/modules/auth/auth.service')

/**
 * Converts slug with query object to route path
 * @param {object} query - url query parameters
 * @return {string} path
 */
const mapSlugToPath = function(query = {}) {
  const { asPath } = this

  let p = asPath
  const search = {}

  for (let k in query) {
    const key = `:${k}`
    const q = query[k]

    if (!asPath.includes(key)) {
      // throw new Error(`Key not defined in route => ${key}`)
      search[k] = q
    } else {
      p = p.replace(key, q)
    }
  }
  if (Object.keys(search).length !== 0) {
    p = `${p}?${qs.stringify(search)}`
  }
  return p
}

const routes = [
  {
    asPath: '/photos',
    callbacks: [cookieAuth],
    pathname: '/photos',
    parent: 'user',
    name: 'Photos',
    icon: 'photos',
    title: 'My Photos',
    group: 'general',
  },
  {
    asPath: '/books',
    callbacks: [cookieAuth],
    pathname: '/books',
    parent: 'user',
    name: 'Books',
    icon: 'books',
    title: 'My Books',
    group: 'general',
  },
  {
    asPath: '/orders',
    callbacks: [cookieAuth],
    pathname: '/orders',
    parent: 'user',
    name: 'orders',
    icon: 'orders',
    title: 'My Orders',
    group: 'general',
  },
  {
    asPath: '/orders/book/:id',
    callbacks: [cookieAuth],
    pathname: '/orders/book/[id]',
    parent: 'orders',
    name: 'bookOrderDetails',
    title: 'Order Details',
  },
  {
    asPath: '/orders/box/:id',
    callbacks: [cookieAuth],
    pathname: '/orders/box/[id]',
    parent: 'orders',
    name: 'boxOrderDetails',
    title: 'Order Details',
  },
  {
    asPath: '/orders/book/:id/receipts/:uuid',
    callbacks: [cookieAuth],
    pathname: '/orders/book/[id]/receipts/[uuid]',
    parent: 'bookOrderDetails',
    name: 'bookReceiptDetails',
    title: 'Receipt Details',
  },
  {
    asPath: '/orders/box/:id/receipts/:uuid',
    callbacks: [cookieAuth],
    pathname: '/orders/box/[id]/receipts/[uuid]',
    parent: 'boxOrderDetails',
    name: 'boxReceiptDetails',
    title: 'Receipt Details',
  },
  {
    asPath: '/orders/book/:id/history',
    callbacks: [cookieAuth],
    pathname: '/orders/book/[id]/history',
    parent: 'bookOrderDetails',
    name: 'bookStatusHistory',
    title: 'Status History',
  },
  {
    asPath: '/orders/box/:id/history',
    callbacks: [cookieAuth],
    pathname: '/orders/box/[id]/history',
    parent: 'boxOrderDetails',
    name: 'boxStatusHistory',
    title: 'Status History',
  },
  {
    asPath: '/labels/names',
    callbacks: [cookieAuth],
    pathname: '/labels/names',
    parent: 'user',
    name: 'Name',
    icon: 'name',
    title: 'Name',
    group: 'textLabels',
  },
  {
    asPath: '/labels/age-grade',
    callbacks: [cookieAuth],
    pathname: '/labels/age-grade',
    parent: 'user',
    name: 'Age/Grade',
    icon: 'ageGrade',
    title: 'Age/Grade',
    group: 'textLabels',
  },
  {
    asPath: '/labels/tags',
    callbacks: [cookieAuth],
    pathname: '/labels/tags',
    parent: 'user',
    name: 'Tags',
    icon: 'tags',
    title: 'Tags',
    group: 'textLabels',
  },
  {
    asPath: '/account',
    callbacks: [cookieAuth],
    pathname: '/account',
    parent: 'user',
    name: 'AccountDetails',
    title: 'Account Details',
    icon: 'account-circle',
    group: 'general',
  },
  {
    asPath: '/address',
    callbacks: [cookieAuth],
    pathname: '/address',
    parent: 'user',
    name: 'Address',
    title: 'Address',
    icon: 'shipping',
    group: 'general',
  },
  {
    asPath: '/books/create',
    callbacks: [cookieAuth],
    pathname: '/books/create',
    name: 'Wizard',
    title: 'Wizard',
  },
  {
    asPath: '/books/:id/wizard',
    callbacks: [cookieAuth],
    pathname: '/books/[id]/wizard',
    name: 'WizardEdit',
    title: 'Wizard',
  },
  {
    asPath: '/books/:id/pages',
    callbacks: [cookieAuth],
    pathname: '/books/[id]/pages',
    name: 'AllPages',
    title: 'All Pages',
  },
  {
    asPath: '/books/:id/pages/:page',
    callbacks: [cookieAuth],
    pathname: '/books/[id]/pages/[page]',
    name: 'Page',
    title: 'Page',
  },
  {
    asPath: '/books/:id/checkout',
    callbacks: [cookieAuth],
    pathname: '/books/[id]/checkout',
    name: 'Checkout',
    title: 'Checkout',
  },
  {
    asPath: '/books/:id/preview',
    callbacks: [cookieAuth],
    pathname: '/books/[id]/preview',
    name: 'Preview',
    title: 'Preview Book',
  },
  {
    asPath: '/preview',
    pathname: '/preview',
    name: 'PublicPreview',
    title: 'Preview Book',
  },
  {
    asPath: '/signin',
    pathname: '/signin',
    name: 'SignIn',
    title: 'SignIn',
  },
  {
    asPath: '/signup',
    pathname: '/signup',
    name: 'SignUp',
    title: 'SignUp',
  },
  {
    asPath: '/reset-password',
    pathname: '/reset-password',
    callbacks: [validateResetToken],
    name: 'ResetPassword',
    title: 'Reset Your Password',
  },
  {
    asPath: '/activate-account',
    pathname: '/activate-account',
    callbacks: [validateActivationToken],
    name: 'ActivateAccount',
    title: 'Activate Your Account',
  },
]

module.exports = routes.map((r) => Object.assign(r, { mapSlugToPath }))

import { styled } from '@mui/material/styles'

// element
export default styled('div')`
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding-top: ${({ aspectRatio }) => aspectRatio ? `${aspectRatio * 100}%` : '80%'};
  pointer-events: auto;
  position: relative;
  transition: box-shadow ease-in-out 0.3s;
  background-position: center center;
  width: 100%;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  }
`

export const Img = styled('img')`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: ${({ verticalPosition }) => verticalPosition};
`

// loading element
export const ImageSkeletonStyle = styled('div')`
  height: 100%;
  width: 100%;

  svg {
    height: 100%;
    opacity: 0.3;
    width: 100%;
  }
`

// storybook wrapper
export const ImageStoriesStyle = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
`

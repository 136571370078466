import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import useUserInformation from 'dux/hooks/useUserInformation'
import { setModal, unsetModal, useCurrentModal } from 'dux/slice/ui'
import * as track from 'utils/tracker'

const useModal = () => {
  const dispatch = useDispatch()

  const { isLoggedAsUser } = useUserInformation()

  const dispatchUnsetModal = useCallback(() => dispatch(unsetModal()), [dispatch])

  const dispatchSetModal = useCallback((payload) => {
    const { name, variant } = payload
    dispatch(setModal(payload))
    track.action(isLoggedAsUser, 'ui:modal_open', { name, variant  })
  }, [dispatch, isLoggedAsUser])

  const modal = useCurrentModal()

  return { modal, unsetModal: dispatchUnsetModal, setModal: dispatchSetModal }
}

export default useModal

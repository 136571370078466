import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'

import { StyledBookBuilderHeader, Title } from './PlainFullScreenModal.style'

const PlainFullScreenModal = ({
  children,
  title,
  open,
  isActionButtonDisabled,
  actionButtonText,
  onClose,
  onBack,
  onActionClick,
  id,
  subtitle,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      id={id}
      fullScreen
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.colors.sky[100],
          maxHeight: 'var(--app-height, 100vh)',
        }),
      }}
      onClose={onClose}
      open={open}
      transitionDuration={500}
    >
      <StyledBookBuilderHeader
        actionButtonText={actionButtonText}
        isActionButtonDisabled={isActionButtonDisabled}
        showBackButton={!!onBack}
        showCloseButton={!!onClose}
        onBack={onBack}
        onClose={onClose}
        onActionClick={onActionClick}
      >
        <Title variant={isMobile ? 'body2' : 'h4'}>
          {title}
        </Title>
        {subtitle}
      </StyledBookBuilderHeader>
      {children}
    </Dialog>
  )
}

PlainFullScreenModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  actionButtonText: PropTypes.string,
  open: PropTypes.bool,
  isActionButtonDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onActionClick: PropTypes.func,
  id: PropTypes.string,
  subtitle: PropTypes.node,
}

export default PlainFullScreenModal

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FieldLimitText = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[500]};
  
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 0.75rem;
  }
`

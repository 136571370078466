import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'

export const BorderedSwitchFormLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'isMixed',
})`
  margin: 0;
  width: 100%;
  justify-content: space-between;
  & .MuiFormControlLabel-label {
    font-size: 0.875rem;
    font-style: ${({ isMixed }) => (isMixed ? 'italic' : 'normal')};
  }

`

export const BorderedSwitchStyled = styled('div')`
  border: 1px solid ${({ theme }) => theme.colors.sky[300]};
  border-radius: 8px;
  padding: 11px 12px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 7px 12px;
  }
`

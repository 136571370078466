import { action } from 'easy-peasy'

const booksActions = {

  /**
   * setBooks - sets a user's books
   * @param {object} state - user redux store
   * @param {array} books - list of users books
   */
  setBooks: action((state, books) => {
    state.list = books.map((book) => ({
      ...book,
      coverImageAspectRatio: book.size && book.size.coverPtHeight / book.size.coverPtWidth,
    }))
    state.isLoading = false
  }),

  setBook: action((state, book) => {
    const bookIndex = state.list.findIndex((o) => o.id === book.id)
    if (bookIndex > -1) {
      state.list[bookIndex] = {
        ...state.list[bookIndex],
        ...book,
      }
    }
  }),

  /**
   * removeBook - removes book from list of user books
   * @param {object} state - user redux store
   * @param {integer} bookId - id of book to remove
   */
  removeBook: action((state, bookId) => {
    state.list = state.list.filter(({ id }) => id !== bookId)
    state.isLoading = false
  }),
  /**
   * setIsLoading - handle global lading state
   * @param {boolean} - payload true/false
   */
  setIsLoading: action((state, payload) => {
    if (state.isLoading === payload) return
    state.isLoading = payload
  }),
  /**
   * setError - handle global lading state
   * @param {object} - error object
   */
  setError: action((state, payload) => {
    state.error = payload
    state.isLoading = false
  }),
}

export default booksActions

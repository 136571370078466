import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import useSilentImageLoading from 'components/Image/useSilentImageLoading'
import withLazyLoad from 'hoc/withLazyLoad'
import withSelect from 'hoc/withSelect'

import ImageStyle, { Img } from './Image.style'

/**
 * Picture Component that consists of a background image with event handlers
 */
export const PureImage = memo(forwardRef(({
  aspectRatio,
  backgroundColor,
  className,
  isBackground,
  onClick,
  onLoad,
  picture,
  imageSrc,
  verticalPosition = 'center',
}, ref) => {
  const { src } = useSilentImageLoading(
    imageSrc || picture?.image?.preview || '',
    isBackground ? onLoad : undefined,
  )

  if (isBackground) {
    return (
      <ImageStyle
        aspectRatio={aspectRatio}
        className={className}
        onClick={onClick}
        style={{
          backgroundColor: backgroundColor,
          backgroundImage: src && `url(${src})`,
        }}
      />
    )
  }

  return (
    <Img
      className={className}
      src={src}
      verticalPosition={verticalPosition}
      ref={ref}
      loading={'lazy'}
      onLoad={onLoad}
    />
  )
}))

PureImage.propTypes = {
  aspectRatio: PropTypes.number,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  isBackground: PropTypes.bool,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  picture: PropTypes.object,
  verticalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  imageSrc: PropTypes.string,
}

export const LazyImage = withLazyLoad(PureImage)

LazyImage.displayName = 'LazyImage'

export const ImageWithSelect = withSelect(LazyImage)

ImageWithSelect.displayName = 'SelectableLazyImage'

export default LazyImage

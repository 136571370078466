import { styled } from '@mui/material/styles'
import { transitions } from 'polished'

import theme from 'styles/theme'

export default styled('div')`
  height: 100%;
  width: 100%;
  ${transitions(['width'], '0.3s ease-in-out')};

  .content-wrap {
    display: flex;
    height: 100%;
    max-height: 100%;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    margin: 0 auto;
    position: relative;
    padding: 4rem 1rem 1rem;
    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-top: 1rem;
    }
  }

  .content {
    @media (max-width: ${theme.breakpoints.md}) {
      min-width: 400px;
    }
  }
`

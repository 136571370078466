import React, { useRef, useState } from 'react'
import { bool, node, string } from 'prop-types'

import cl from 'classnames'

import { POSITIONS } from './Tooltip.constants'
import TooltipStyle, { TooltipContent } from './Tooltip.style'

const Tooltip = ({ children, icon, color, showTooltipContent = true }) => {
  const tooltipContentRef = useRef()

  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState(POSITIONS.BOTTOM)

  const handleMouseOver = () => {
    if (showTooltipContent) {
      setTooltipPosition()
      setVisible(true)
    }
  }

  const handleMouseOut = () => {
    setVisible(false)
  }

  const setTooltipPosition = () => {
    const tooltipContentRect = tooltipContentRef.current.getBoundingClientRect()
    const lowestPoint = document.documentElement.clientHeight + window.scrollY
    const rightmostPoint = document.documentElement.clientWidth + window.scrollX

    if (tooltipContentRect.right > rightmostPoint) {
      setPosition(POSITIONS.LEFT)
    } else if (tooltipContentRect.left < 0) {
      setPosition(POSITIONS.RIGHT)
    } else if (tooltipContentRect.bottom > lowestPoint) {
      setPosition(POSITIONS.TOP)
    } else if (tooltipContentRect.top < 0) {
      setPosition(POSITIONS.BOTTOM)
    }
  }


  return (
    <TooltipStyle onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
      {icon}
      {showTooltipContent && (
        <TooltipContent
          color={color}
          ref={tooltipContentRef}
          className={cl('tooltip-content', position, { hidden: !visible })}
        >
          <div className={'arrow-container'}>
            <div className={'arrow'} />
          </div>
          <div className={'box'}>
            {children}
          </div>
        </TooltipContent>
      )}
    </TooltipStyle>
  )
}

Tooltip.propTypes = {
  children: node,
  icon: node,
  color: string,
  showTooltipContent: bool,
}

export default Tooltip

import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Edit as EditIcon } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useStoreActions, useStoreState } from 'easy-peasy'
import dynamic from 'next/dynamic'

import Button from 'components/Buttons/Button'
import Icon from 'components/Icon'
import LoadingSpinner from 'components/LoadingSpinner'
import { TAB_IDS } from 'components/Modal/EditPicture/ReplaceSwapImage/ReplaceSwapImage.constants'
import Label from 'components/Text/Label'
import Tooltip from 'components/Tooltip'
import { MIN_BOOK_CAPACITY } from 'containers/Book/Book.constants'
import { PAGE_SIZE, SET_PICTURES_MODES } from 'containers/Pictures/Pictures.constants'
import { usePicturesLoadMore } from 'containers/Pictures/Pictures.hooks'
import withLoader from 'hoc/withLoader'
import useMetaThemeColor from 'hooks/useMetaThemeColor'
import useModal from 'hooks/useModal'
import { isMobileDevice } from 'utils/browser/parser'
import formatPictureDate from 'utils/formatPictureDate'
import getPictureSubCaption from 'utils/getPictureSubCaption'

import EditLabels from './EditLabels'
import EditPictureStyle, { ImageMeta, ImageWrap } from './EditPicture.style'
import ReplaceSwapImage from './ReplaceSwapImage'

const EDIT_PICTURE_MODAL_THEME_COLOR = '#303135'

const ImageEditor = dynamic(async () => {
  if (isMobileDevice) {
    return import('components/Modal/EditPicture/CropImage')
  } else {
    return import('components/Modal/EditPicture/ImageEditor')
  }
}, {
  ssr: false,
  loading: () => <LoadingSpinner position={'absolute'} />,
})

const EditPicture = ({ pictureId }) => {
  useMetaThemeColor(EDIT_PICTURE_MODAL_THEME_COLOR, true)

  const pictures = useStoreState((state) => state.pictures.list)
  const deletePicture = useStoreActions((actions) => actions.pictures.deletePicture)
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)

  const { setModal, unsetModal } = useModal()

  const [picture, setPicture] = useState()
  const [previewUrl, setPreviewUrl] = useState()
  const [showLabel, setShowLabel] = useState(false)
  const [showCrop, setShowCrop] = useState(false)
  const [defaultReplaceTab, setDefaultReplaceTab] = useState(null)
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const [imageLoading, setImageLoading] = useState(true)

  const {
    page,
    handleLoadMore,
  } = usePicturesLoadMore({})

  /**
   * listen for events that show/hide nested component
   */
  useEffect(() => {
    // if showCrop or showReplace modal flags are true
    if (showCrop || defaultReplaceTab) {
      // hide label sidebar to prevent overlap
      setShowLabel(false)
    }
  }, [showCrop, defaultReplaceTab])

  const setEditPictureModal = (id) => setModal({ name: 'EditPicture', pictureId: id })

  const nextPage = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const currentIndex = pictures.findIndex(({ id }) => pictureId === id)

    const indexToSet = currentIndex === pictures.length - 1 ? 0 : currentIndex + 1

    setEditPictureModal(pictures[indexToSet].id)
  }

  const prevPage = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const currentIndex = pictures.findIndex(({ id }) => pictureId === id)

    const indexToSet = currentIndex === 0 ? pictures.length - 1 : currentIndex - 1

    setEditPictureModal(pictures[indexToSet].id)
  }

  const handleDeleteSuccess = async () => {
    await deletePicture(picture.id)
    unsetModal()
    await handleLoadMore(0, SET_PICTURES_MODES.SET, page * PAGE_SIZE)
  }

  const handleDeletePicture = async () => {
    // if deleting image will cause fewer than 20 pages bail
    if (deleteDisabled) return
    setModal({
      name: 'ConfirmDelete',
      itemName: 'a photo',
      onSuccess: handleDeleteSuccess,
      onCancel: () => {
        setModal({
          name: 'EditPicture',
          pictureId: picture.id,
        })
      },
    })
  }

  const handleEditSave = useCallback(async (file) => {

    await updatePicture({ disableLoading: true, picture: { ...picture, image: file } })

    setShowCrop(false)

  }, [picture])

  const handleClose = useCallback(() => {
    setShowCrop(false)
  }, [])

  const handleSwapImageClick = () => {
    setDefaultReplaceTab(TAB_IDS.REPLACE)
  }

  const handleCloseReplaceSwap = (swapPicId) => {
    setDefaultReplaceTab(null)

    setEditPictureModal(swapPicId || picture.id)
  }

  useEffect(() => {
    const picture = pictures.find(({ id }) => id === pictureId)

    setPicture(picture)
  }, [pictures])

  useEffect(() => {
    const picture = pictures.find(({ id }) => id === pictureId)

    setPicture(picture)

    setDeleteDisabled(false)
  }, [pictureId])

  useEffect(() => {
    const handleArrowPress = (e) => {
      // ignore arrow hotkeys for inputs
      if (e.target.type === 'text') return

      switch (e.key) {
        case 'ArrowRight':
          return nextPage(e)
        case 'ArrowLeft':
          return prevPage(e)
        case 'Escape':
          return unsetModal()
      }
    }

    document.addEventListener('keydown', handleArrowPress)

    return () => document.removeEventListener('keydown', handleArrowPress)
  }, [pictureId])

  useEffect(() => {
    if (!picture || previewUrl === picture.image.preview) return

    setPreviewUrl(picture.image.preview)
    setImageLoading(true)
  }, [picture?.image.preview])

  if (!picture) return null

  return (
    <EditPictureStyle showLabel={showLabel}>
      {!defaultReplaceTab && (
        <>
          <Icon
            name={'close'}
            className={'edit-picture-close white'}
            onClick={unsetModal}
          />

          <div className={'content-wrap'}>

            <div className={'header container'}>
              <div
                className={'icon-wrap'}
                onClick={handleSwapImageClick}
              >
                <Icon name={'replace-swap'} />
                <Label className={'white xs'}>Swap Image</Label>
              </div>

              <div className={'icon-wrap'} onClick={() => setShowCrop(true)}>
                <Icon name={'crop-rotate'} />
                <Label className={'white xs'}>Crop/Rotate</Label>
              </div>

              <a
                className={'icon-wrap'}
                href={picture.image.full}
                download={picture.fileName}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Icon name={'download'} />
                <Label className={'white xs'}>Download</Label>
              </a>

              <Tooltip
                icon={(
                  <div
                    className={'icon-wrap no-padding'}
                    color={'white'}
                    type={'text'}
                    disabled={deleteDisabled}
                    onClick={handleDeletePicture}
                  >
                    <Icon name={'delete'} className={'white'} />
                    <Label className={'xs white'}>Remove</Label>
                  </div>
                )}
                showTooltipContent={deleteDisabled}
              >
                Books need at least {MIN_BOOK_CAPACITY} pictures. Please add more pictures to delete this one.
              </Tooltip>
            </div>

            <ImageWrap style={{ flexShrink: 2 }}>
              <div onClick={prevPage} className={'prev'}>
                <Icon name={'chevron-left'} />
              </div>

              {imageLoading && <LoadingSpinner />}

              <img
                onLoad={() => setImageLoading(false)}
                src={previewUrl}
                style={{ display: imageLoading ? 'none' : 'block' }}
              />

              <div className={'next'} onClick={nextPage}>
                <Icon name={'chevron-right'} />
              </div>
            </ImageWrap>

            <ImageWrap className={'flex'} style={{ paddingTop: 0, maxHeight: 200 }}>
              <ImageMeta>
                <Typography
                  variant={'caption'}
                  sx={{ fontSize: 16, textTransform: 'uppercase', textAlign: 'center', color: 'white' }}
                >
                  {picture.title || 'NO CAPTION'}
                </Typography>
                <Typography variant={'subtitle2'} component={'span'} sx={{ textAlign: 'center' }}>
                  {getPictureSubCaption(picture)}<br />
                  {formatPictureDate(picture.pictureDate)}
                </Typography>
                <Button
                  variant={'text'}
                  color={'primary'}
                  onClick={() => setShowLabel(true)}
                  startIcon={<EditIcon color={'inherit'} />}
                  sx={{ mt: 3, fontSize: '1rem' }}
                >
                  Edit Label
                </Button>
              </ImageMeta>

            </ImageWrap>
          </div>
        </>
      )}

      <span style={{ display: defaultReplaceTab ? 'inline' : 'none' }}>
        <ReplaceSwapImage
          picture={picture}
          onClose={handleCloseReplaceSwap}
          defaultTabId={defaultReplaceTab}
        />
      </span>

      <EditLabels
        picture={picture}
        setShowLabel={setShowLabel}
        showLabel={showLabel}
      />

      {showCrop && (
        <ImageEditor
          picture={picture}
          onSave={handleEditSave}
          onClose={handleClose}
        />
      )}
    </EditPictureStyle>
  )
}

EditPicture.propTypes = {
  pictureId: PropTypes.number,
}

const EditPictureWithLoader = withLoader(EditPicture)

export default EditPictureWithLoader

import { action, actionOn } from 'easy-peasy'
import isEqual from 'lodash/isEqual'

import { BOOK_SECTION_TYPES } from 'utils/constants'

import { DEFAULT_BOOK_v3, DEFAULT_POSITION, DEFAULT_TEXT_SETTINGS } from './Book.constants'
import { FIRST_INSIDE_COVER_INDEX, LAST_INSIDE_COVER_INDEX } from './Book.model'
import {
  adjustBookCoverPosition,
  createPage,
  getImagePageSection,
  getPageRenderKey,
  getPageSectionIndex,
  getTextPageSection,
  maxBookTitleLength,
} from './Book.utils'

const BASE_COVER_PAGE = {
  sections: [
    {
      sectionType: BOOK_SECTION_TYPES.TEXT,
      title: '',
    },
    {
      sectionType: BOOK_SECTION_TYPES.IMAGE,
      pictureId: null,
      picture: {},
      position: DEFAULT_POSITION,
      rotation: 0,
    },
  ],
}

const bookActions_v3 = {
  setBook_v3: action((state, payload) => {
    const activeBook = state.activeBook_v3
    const book = payload
    // Reset activePageKey if the book id has changed
    if (!!book.id && state.activeBook_v3.id !== book.id) {
      state.activePageKey_v3 = null
    }

    const areBookEqual = isEqual(
      { ...activeBook },
      { ...book },
    )

    if (!areBookEqual) {
      state.activeBook_v3 = {
        ...state.activeBook_v3,
        ...book,
      }
      state.activeBook_v3.cover ||= BASE_COVER_PAGE
      state.orderedPageIds_v3 = state.activeBook_v3.pages.map((page) => page.id)
    }
    state.isLoading_v3 = false
  }),

  setDefaultBook_v3: action((state) => {
    state.activeBook_v3 = DEFAULT_BOOK_v3
  }),

  resetWizardBook_v3: action((state) => {
    state.wizardBook_v3 = {
      ...DEFAULT_BOOK_v3,
      ...DEFAULT_TEXT_SETTINGS,
    }
  }),

  setWizardBook_v3: action((state, payload) => {
    state.wizardBook_v3 = {
      ...state.wizardBook_v3,
      ...payload,
    }
  }),

  setWizardBookSize_v3: action((state, payload) => {
    const book = state.wizardBook_v3
    book.productId = payload
    if (book.cover) {
      const textSectionIndex = getPageSectionIndex(book.cover.sections, BOOK_SECTION_TYPES.TEXT)
      if (textSectionIndex !== -1 && !!book.cover.sections[textSectionIndex]) {
        book.cover.sections[textSectionIndex].title =
          book.cover.sections[textSectionIndex].title.slice(0, maxBookTitleLength(payload))
      }
    }
  }),

  setCoverAlign_v3: action((state, payload) => {
    const book = state.activeBook_v3
    const textSectionIndex = getPageSectionIndex(book.cover.sections, BOOK_SECTION_TYPES.TEXT)
    book.cover.sections[textSectionIndex].align = payload
  }),

  setCoverTitle_v3: action((state, payload) => {
    const book = state.activeBook_v3
    const textSectionIndex = getPageSectionIndex(book.cover.sections, BOOK_SECTION_TYPES.TEXT)
    const prevTitle = book.cover.sections[textSectionIndex].title
    if (prevTitle !== payload) {
      book.cover.sections[textSectionIndex].title = payload
    }
  }),

  togglePage_v3: action((state, id) => {
    if (state.selected_v3.includes(id)) {
      state.selected_v3 = state.selected_v3.filter((selectedId) => selectedId !== id)
    } else {
      state.selected_v3.push(id)
    }
  }),

  setWizardCoverPicture_v3: action((state, payload) => {
    state.wizardBook_v3.cover ||= BASE_COVER_PAGE
    if (!state.wizardBook_v3.cover.sections.length) {
      state.wizardBook_v3.cover.sections = BASE_COVER_PAGE.sections
    }
    const imageSectionIndex = getPageSectionIndex(state.wizardBook_v3.cover.sections, BOOK_SECTION_TYPES.IMAGE)
    state.wizardBook_v3.cover.sections[imageSectionIndex] = {
      ...state.wizardBook_v3.cover.sections[imageSectionIndex],
      ...adjustBookCoverPosition(DEFAULT_POSITION, state.activeBook_v3.size, payload.picture),
      pictureId: payload.picture.id,
      picture: { ...payload.picture },
      rotation: 0,
    }
  }),

  setPictureTitle_v3: action((state, payload) => {
    state.activeBook_v3.pages.forEach((page) => {
      page.sections.forEach((section) => {
        if (section.picture.id === payload.pictureId) {
          section.picture.title = payload.title
        }
      })
    })
  }),

  setWizardBookTitle_v3: action((state, payload) => {
    const textSection = getTextPageSection(state.wizardBook_v3.cover.sections)
    textSection.title = payload
  }),

  setWizardBookTextSettings_v3: action((state, payload) => {
    state.wizardBook_v3 = {
      ...state.wizardBook_v3,
      ...payload,
    }
  }),

  swapPicture_v3: action((state, payload) => {
    const updatePix = state.activeBook_v3.pages.map(
      (picture) => (picture.id === payload.pictureToSwapId) ? payload.newPicture : picture,
    )
    state.activeBook.pictures = updatePix.filter(Boolean)
    state.isLoading = false
  }),

  deselectPages_v3: action((state) => {
    state.selected_v3 = []
  }),

  addDragging_v3: action((state, id) => {
    state.dndState_v3.isDragStarted_v3 = true

    state.dndState_v3.draggedPagesIds_v3.push(id)

    const draggedPage = state.mapPagesToView_v3.find((page) => page.id === id)
    state.dndState_v3.draggingPages_v3.push(draggedPage)

    if (state.selected_v3.length) {
      const filteredSelected = state.selected_v3.filter((id) => !state.dndState_v3.draggedPagesIds_v3.includes(id))
      state.dndState_v3.draggedPagesIds_v3.push(...filteredSelected)

      const selectedPages = state.mapPagesToView_v3?.filter(
        (page) => state.selected_v3.includes(page.id) && !state.dndState_v3.draggingPages_v3.includes(page),
      ) || []
      state.dndState_v3.draggingPages_v3.push(...selectedPages)
    }
  }),

  endDragging_v3: action((state) => {
    state.dndState_v3.draggingPages_v3 = []
    state.dndState_v3.draggedPagesIds_v3 = []
  }),

  setTextDisplaySettings_v3: action((state, payload) => {
    state.activeBook_v3 = {
      ...state.activeBook_v3,
      ...payload,
    }
  }),

  setSize_v3: action((state, payload) => {
    const activeBook = state.activeBook_v3
    const textSectionIndex = getPageSectionIndex(activeBook.cover.sections, BOOK_SECTION_TYPES.TEXT)
    state.activeBook_v3.productId = payload
    state.activeBook_v3.cover.sections[textSectionIndex].title =
        activeBook.cover.sections[textSectionIndex].title.slice(0, maxBookTitleLength(payload))
    state.isLoading_v3 = false
  }),

  movePagesOnDrop_v3: action((state, { indexTo }) => {
    const pages = state.activeBook_v3.pages
    const draggedPagesIds = state.dndState_v3.draggedPagesIds_v3

    let moveResult
    const filteredPages = pages.filter((page) => !draggedPagesIds.includes(page.id))
    const draggedImages = pages
      .filter((page) => draggedPagesIds.includes(page.id))

    if (indexTo === FIRST_INSIDE_COVER_INDEX) {
      moveResult = [
        ...draggedImages,
        ...filteredPages,
      ]
    } else if (indexTo === LAST_INSIDE_COVER_INDEX) {
      moveResult = [
        ...filteredPages,
        ...draggedImages,
      ]
    } else {
      const upperHalfRemainingPages = pages
        .slice(0, indexTo)
        .filter((page) => !draggedPagesIds.includes(page.id))
      const lowerHalfRemainingPages = pages
        .slice(indexTo)
        .filter((page) => !draggedPagesIds.includes(page.id))
      moveResult = [
        ...upperHalfRemainingPages,
        ...draggedImages,
        ...lowerHalfRemainingPages,
      ]
    }
    state.activeBook_v3.pages = moveResult
    state.activePageKey_v3 = getPageRenderKey(draggedImages[0])
  }),

  movePages_v3: action((state, { index, pages }) => {
    const bookPages = state.activeBook_v3.pages
    const moveList = pages.map(({ id }) => id)

    const remainingPages = bookPages.filter((page) => !moveList.includes(page.id))

    remainingPages.splice(index, 0, ...pages)

    state.activeBook_v3.pages = remainingPages
  }),

  setIsAddPageSelected_v3: action((state, isAddPageSelected) => {
    if (state.isAddPageSelected_v3 === isAddPageSelected) return
    state.isAddPageSelected_v3 = isAddPageSelected
  }),

  setIsLoading_v3: action((state, payload) => {
    if (state.isLoading_v3 === payload) return
    state.isLoading_v3 = payload
  }),

  setError_v3: action((state, payload) => {
    state.error = payload
  }),

  setIsDragStarted_v3: action((state, payload) => {
    state.dndState_v3.isDragStarted_v3 = payload
  }),

  setActivePage_v3: action((state, payload) => {
    state.activePageKey_v3 = payload.key
  }),

  setActivePageKey_v3: action((state, payload) => {
    state.activePageKey_v3 = payload
  }),

  setBookSection_v3: action((state, { nextSectionData, nextSectionId }) => {
    const coverSection = state.activeBook_v3.cover.sections.find(({ id }) => id === nextSectionId)
    if (coverSection) {
      Object.assign(coverSection, nextSectionData)
    } else {
      state.activeBook_v3.pages = state.activeBook_v3.pages.map((page) => ({
        ...page,
        sections:
          page.sections
            .map((section) => section.id === nextSectionId ? Object.assign({}, section, nextSectionData) : section),
      }))
    }
  }),

  setPagesByPictureIds_v3: action((state, { addedPicturesIds, position }) => {
    const pages = state.activeBook_v3.pages

    const addedPages = addedPicturesIds.map((pictureId) => createPage(pictureId, true))

    state.activeBook_v3.pages = [...pages.slice(0, position), ...addedPages, ...pages.slice(position)]
  }),
  onSetPicture_v3: actionOn(
    (actions, storeActions) => [
      actions.bulkUpdateBookPictures_v3,
      storeActions.pictures.updatePicture,
    ],
    (state, target) => {
      const { activeBook_v3: activeBook } = state
      if (!activeBook.id) return
      const updatedPictures = Array.isArray(target.result) ? target.result : [target.result]
      const updatedPictureIds = new Set(updatedPictures.map((picture) => picture.id))

      const updateImageSection = (sections) => {
        const imageSection = getImagePageSection(sections)
        if (imageSection) {
          const pictureId = imageSection.picture?.id
          if (updatedPictureIds.has(pictureId)) {
            const updatedPicture = updatedPictures.find((picture) => picture.id === pictureId)
            Object.assign(imageSection.picture, updatedPicture)
          }
        }
      }

      activeBook.pages.forEach((page) => {
        updateImageSection(page.sections)
      })
      updateImageSection(activeBook.cover.sections)
    },
  ),

  onAddPages_v3: actionOn(
    (_, storeActions) => [storeActions.user.books_v3.addBookPages_v3],
    (state, target) => {
      const { result, payload } = target
      if (state.activeBook_v3.id === result.id) {
        const firstAddedPictureId = payload.selectedPictures[0].id
        const firstAddedPage = result.pages.find((page) => {
          const imageSection = getImagePageSection(page.sections)
          return imageSection.picture.id === firstAddedPictureId
        })
        state.activeBook_v3.pages = result.pages
        state.activePageKey_v3 = getPageRenderKey(firstAddedPage)
        state.orderedPageIds_v3 = result.pages.map((page) => page.id)
      }
    },
  ),
}

export default bookActions_v3

/**
  * Expects an object from the theme configuration file
  * Loops through all entries and generates a template literal string of classes
  * @param {object} obj - theme object
  * @param {string} cssKey - name of the css property to create
 * @param {string} prefix - optional class name prefix E.G. `.bg-`
  * @return {string} - template literal
*/
const objectToClass = (obj, cssKey, prefix = '') => {
  let classes = ''
  Object.entries(obj).map((size) => {
    const name = size[0]
    const value = size[1]
    classes += `.${prefix}${name} { ${cssKey}: ${value} !important }`
  })
  return classes
}

export default objectToClass
import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'
import isEqual from 'lodash/isEqual'

import { FilterButton } from 'components/Buttons/Button'
import Dropdown from 'components/Dropdown'
import Radio from 'components/FormFields/Radio'
import PictureChildFilters from 'components/PictureChildFilters'
import PictureSort from 'components/PictureSort'
import PictureTagsFilter from 'components/PictureTagsFilter'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'
import TabPanel from 'components/Tabs/TabPanel'
import { UPLOAD_FILTER_OPTIONS } from 'containers/Pictures/Pictures.constants'

import { TAB_IDS } from './PictureMobileFiltersDropdown.constants'
import PictureMobileFiltersDropdownStyle from './PictureMobileFiltersDropdown.style'

const PictureMobileFiltersDropdown = forwardRef(({
  sortQueries,
  setSortQueries,
  tags,
  childrenOptions,
}, ref) => {
  const filters = useStoreState((state) => state.pictures.filters)

  const setUploadFilter = useStoreActions((actions) => actions.pictures.setUploadByFilter)
  const setTagFilters = useStoreActions((actions) => actions.pictures.setTagsFilter)
  const setChildrenFilter = useStoreActions((actions) => actions.pictures.setChildrenFilter)

  const [activeTab, setActiveTab] = useState(TAB_IDS.SORT)

  const handleReset = () => {
    setSortQueries([])
    setTagFilters([])
    setChildrenFilter([])
  }

  return (
    <Dropdown
      className={'filters-dropdown'}
      onReset={handleReset}
      keepMounted={false}
      anchor={<FilterButton className={'filter'}>Filters</FilterButton>}
    >
      <PictureMobileFiltersDropdownStyle ref={ref}>
        <div className={'picture-filters-content'}>
          <Tabs defaultTabId={activeTab} onTabChange={(id) => setActiveTab(id)}>
            <div className={'picture-filters-tabs'}>
              <Tab id={TAB_IDS.SORT} className={'picture-filters-tabs__tab'}>
                Sort By
              </Tab>
              <Tab id={TAB_IDS.FILTER} className={'picture-filters-tabs__tab'}>
                Filters
              </Tab>
            </div>

            <div className={'picture-filters-panels'}>
              <TabPanel id={TAB_IDS.SORT}>
                <PictureSort sortQueries={sortQueries} setSortQueries={setSortQueries} />
              </TabPanel>
              <TabPanel id={TAB_IDS.FILTER}>
                <h4 className={'h4 picture-filter-panels__section-title'}>Name</h4>
                <PictureChildFilters childrenOptions={childrenOptions} />
                <h4 className={'h4 picture-filter-panels__section-title'}>Tags</h4>
                <PictureTagsFilter tags={tags} />
                <div className={'picture-filter-panels__section upload-filter'}>
                  <h4 className={'h4 picture-filter-panels__section-title'}>Upload Type</h4>
                  {UPLOAD_FILTER_OPTIONS.map((option) => {
                    const checked = isEqual(option.value, filters.uploadBy)

                    return (
                      <label className={'upload-filter__item'} key={option.mobileLabel}>
                        <Radio
                          name={option.mobileLabel}
                          checked={checked}
                          onChange={() => setUploadFilter(option.value)}
                        />
                        <p className={'p sm'}>{option.mobileLabel}</p>
                      </label>
                    )
                  })}
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </PictureMobileFiltersDropdownStyle>
    </Dropdown>
  )
})

PictureMobileFiltersDropdown.propTypes = {
  tags: PropTypes.array,
  sortQueries: PropTypes.array,
  setSortQueries: PropTypes.func,
  childrenOptions: PropTypes.array,
}

export default PictureMobileFiltersDropdown

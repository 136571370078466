import { useCallback, useEffect, useRef } from 'react'

import { fabric } from 'fabric'

/**
 * Custom React Hook to load an image from a URL using fabric.js.
 *
 * @param {string} url - The URL of the image to load.
 * @returns {function} A function that returns a Promise which resolves with the loaded image.
 */
const useImageLoader = (url) => {
  const loadingPromise = useRef(null)

  useEffect(() => {
    loadingPromise.current = new Promise((resolve, reject) => {
      fabric.util.loadImage(url, function(img, isError) {
        isError ? reject() : resolve(img)
      }, null, 'anonymous')
    })

    return () => {
      loadingPromise.current = null
    }
  }, [url])

  return useCallback(() => {
    return loadingPromise.current
  }, [])
}

export default useImageLoader

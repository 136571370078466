import { Typography } from '@mui/material'
import MUIBackdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { opacify } from 'polished'

export const Backdrop = styled(MUIBackdrop, { shouldForwardProp: (prop) => prop !== 'variant' })`
  background-color: ${({ variant, theme }) => variant === 'dark'
  ? opacify(-0.07, theme.colors.black)
  : opacify(-0.2, theme.colors.newBlue[900])
};
`

export const Content = styled('div', { shouldForwardProp: (prop) => prop !== 'width' && prop !== 'bgColor' })`
  padding: 32px 16px 24px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 48px 64px;
    width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width) || '768px'};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    background-color: ${({ bgColor }) => bgColor};
  }
`

export const Actions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    flex-direction: row;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 20px;
    margin-top: 32px;
  }
`

export const ActionButton = styled(Button)`
  text-transform: capitalize;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('xs')} {
    max-width: 160px;
    width: 50%;
  }
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: ${({ theme }) => theme.zIndex.modal};
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.newBlue[900]};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding:${({ theme }) => theme.spacing(0, 4)};
  text-transform: capitalize;
`

export const BodyText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray[700]};
  margin-top: 24px;
`

export const SmallGreyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 0.8125rem;
  font-weight: 500;
  text-align: center;
`

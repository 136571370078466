import React from 'react'

import Box from '@mui/material/Box'

import { NewBubble, StyledImg } from './MobileNewBookBuilderImage.style'

const MobileNewBookBuilderImage = () => {
  return (
    <Box position={'relative'}>
      <NewBubble>NEW</NewBubble>
      <StyledImg src={'/images/new-book-builder.png'} alt={'New Book Builder Editor'} loading={'lazy'} />
    </Box>
  )
}

export default MobileNewBookBuilderImage

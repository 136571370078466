export const NOOP = () => null

export const TextDisplayingSetting = {
  name: 'hideName',
  grade: 'hideGrade',
  title: 'hideTitle',
  date: 'hideDate',
}

export const PAGE_TYPES = {
  BLANK: 'blank',
  COVER: 'cover',
  BACK_COVER: 'back_cover',
  PAGE: 'page',
}

export const BOOK_SECTION_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
}

export const PAGES_VIEW_TYPE = {
  GRID: 'grid',
  STRIP: 'strip',
  PREVIEW: 'preview',
}

export const ALWAYS_ADDED_PAGES_COUNT = 3 // Cover, Blank, Back Cover

export const ORDER_TYPE = {
  BOX: 'box',
  BOOK: 'book',
  CARD: 'card',
  ADD_ART: 'add_art',
  PHOTO: 'photo',
}

export const BoxLikeOrderTypes = [ORDER_TYPE.BOX, ORDER_TYPE.CARD, ORDER_TYPE.ADD_ART, ORDER_TYPE.PHOTO]

export const ORDER_ITEM_TYPE = {
  BOOK: 'book',
  MOSAIC: 'mosaic',
  DIGITAL: 'digital',
}

export const RECEIPT_TYPE = {
  FIRST: '1st',
  SECOND: '2nd',
  ART_RETURN: 'art_return',
  OTHER: 'other',
}

export const PAYMENT_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
}

export const ADDON_KIND_TYPE = {
  PROCESSING: 'processing',
  SHIPPING_PROTECTION: 'shipping_protection',
  RETURN_USB: 'return_usb',
  RETURN_ART: 'return_art',
  VIP_SUBSCRIPTION: 'vip_subscription',
  SUBSCRIPTION: 'subscription',
  OTHER: 'other',
}

export const PAYMENT_METHOD = {
  PAYPAL: 'paypal',
  APPLE_PAY: 'apple_pay',
  CREDIT_CARD: 'credit_card',
}

export const BOX_ORDER_STATUS = {
  ORDER_PLACED: 'Order Placed',
  WAITING_FOR_ARTWORK: 'Waiting for Artwork',
  ARTWORK_RECEIVED: 'Artwork Received',
  PHOTOGRAPHY_STARTED: 'Photography Started',
  ART_PHOTOGRAPHED: 'Art Photographed',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETE: 'Payment Complete',
  EDITING: 'Editing',
  READY_TO_RETURN: 'Ready to Return',
  READY_TO_RECYCLE: 'Ready To Recycle',
  ART_RETURNED: 'Art Returned',
  ART_RECYCLED: 'Art Recycled',
  ORDER_COMPLETED: 'Order Completed',
}

export const BOOK_ORDER_STATUS = {
  SENT_TO_PRINT: 'Sent to Print',
  PRINTING: 'Printing',
  SHIPPED: 'Shipped',
  DELIVERED: 'Delivered',
}

export const DATE_FORMAT = 'MM/dd/yy'

export const CURRENCY_FORMAT = '$0,0.00'

export const COMPANY_HELP_EMAIL = 'help@artkivebox.com'

export const ORDERED_BOX_ORDER_STATUSES = [
  BOX_ORDER_STATUS.ORDER_PLACED,
  BOX_ORDER_STATUS.WAITING_FOR_ARTWORK,
  BOX_ORDER_STATUS.ARTWORK_RECEIVED,
  BOX_ORDER_STATUS.PHOTOGRAPHY_STARTED,
  BOX_ORDER_STATUS.ART_PHOTOGRAPHED,
  BOX_ORDER_STATUS.PAYMENT_PENDING,
  BOX_ORDER_STATUS.PAYMENT_COMPLETE,
  BOX_ORDER_STATUS.EDITING,
  [BOX_ORDER_STATUS.READY_TO_RETURN, BOX_ORDER_STATUS.READY_TO_RECYCLE],
  [BOX_ORDER_STATUS.ART_RETURNED, BOX_ORDER_STATUS.ART_RECYCLED],
  BOX_ORDER_STATUS.ORDER_COMPLETED,
]

export const ORDERED_ADD_ART_ORDER_STATUSES = [
  BOX_ORDER_STATUS.WAITING_FOR_ARTWORK,
  BOX_ORDER_STATUS.ARTWORK_RECEIVED,
  BOX_ORDER_STATUS.PHOTOGRAPHY_STARTED,
  BOX_ORDER_STATUS.ART_PHOTOGRAPHED,
  BOX_ORDER_STATUS.PAYMENT_PENDING,
  BOX_ORDER_STATUS.PAYMENT_COMPLETE,
  BOX_ORDER_STATUS.EDITING,
  [BOX_ORDER_STATUS.READY_TO_RETURN, BOX_ORDER_STATUS.READY_TO_RECYCLE],
  [BOX_ORDER_STATUS.ART_RETURNED, BOX_ORDER_STATUS.ART_RECYCLED],
  BOX_ORDER_STATUS.ORDER_COMPLETED,
]

export const ORDERED_CARD_PHOTO_ORDER_STATUSES = [
  BOX_ORDER_STATUS.ORDER_PLACED,
  BOX_ORDER_STATUS.WAITING_FOR_ARTWORK,
  BOX_ORDER_STATUS.ARTWORK_RECEIVED,
  BOX_ORDER_STATUS.PHOTOGRAPHY_STARTED,
  BOX_ORDER_STATUS.ART_PHOTOGRAPHED,
  BOX_ORDER_STATUS.EDITING,
  [BOX_ORDER_STATUS.READY_TO_RETURN, BOX_ORDER_STATUS.READY_TO_RECYCLE],
  [BOX_ORDER_STATUS.ART_RETURNED, BOX_ORDER_STATUS.ART_RECYCLED],
  BOX_ORDER_STATUS.ORDER_COMPLETED,
]

export const ORDER_PRODUCT_TYPES = {
  BOOK: 'book',
  MOSAIC: 'mosaic',
  DIGITIZING: 'digitizing',
}

export const PRODUCT_STATUS = {
  PENDING_EDITS: 'Pending Edits',
  PENDING_PAYMENT: 'Pending Payment',
  IN_QUEUE: 'In Queue',
  EDITING: 'Editing',
  READY_TO_REVIEW: 'Ready to Review',
  SEND_TO_PRINT: 'Send to Print',
  SENT_TO_PRINT: 'Sent to Print',
  PRINTING: 'Printing',
  SHIPPED: 'Shipped',
  COMPLETE: 'Complete',
  DELIVERED: 'Delivered',
}

export const ECOM_LINKS = {
  PACKING: 'https://packing.artkivebox.com/',
  ART_BOOK_PRICING: 'https://www.artkiveapp.com/keepsakes/art-book-for-kids#slider',
}

import React, { createContext, useEffect, useState } from 'react'
import { func, node, string } from 'prop-types'

export const TabsContext = createContext()

const Tabs = ({ children, className = '', defaultTabId = '', onTabChange }) => {
  const [activeTab, setActiveTab] = useState(defaultTabId)

  const handleTabChange = (tabId) => {
    setActiveTab(tabId)
  }

  useEffect(() => {
    setActiveTab(defaultTabId)
  }, [defaultTabId])

  useEffect(() => {
    onTabChange && onTabChange(activeTab)
  }, [activeTab])

  return (
    <TabsContext.Provider value={{
      activeTab,
      setActiveTab: handleTabChange,
    }}>
      <div className={`tabs ${className}`} style={{ width: '100%' }}>
        {children}
      </div>
    </TabsContext.Provider>
  )
}

Tabs.propTypes = {
  children: node,
  className: string,
  defaultTabId: string,
  onTabChange: func,
}

export default Tabs

import React from 'react'
import PropTypes from 'prop-types'

import { useNotificationMessage } from 'dux/slice/ui'

import Notification from './Notification'

const NotificationProvider = ({ children }) => {
  const notification = useNotificationMessage()

  return (
    <>
      {notification && <Notification {...notification} />}
      {children}
    </>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
}

export default NotificationProvider

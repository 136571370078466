import { action, actionOn } from 'easy-peasy'

import { NOTIFICATION_TYPES } from 'components/Notification'
import { sharedRTKStore } from 'dux/index'
import { triggerChildNameWithAgeGradeInvalidate } from 'dux/queries/nameTextLabels'
import { setError, setIsLoading, setNotification, setSuccess } from 'dux/slice/ui'
import { isTest } from 'utils/env'
import { extractRequestErrorMessage } from 'utils/extractRequestErrorMessage'

const proxyUi = {
  setIsLoading: action((state, payload) => {
    sharedRTKStore?.dispatch(setIsLoading(payload))
  }),
  setSuccess: action((state, payload) => {
    sharedRTKStore?.dispatch(setSuccess(payload))
  }),
  onPictureAddOrUpdate: actionOn((actions, storeActions) => [
    storeActions.pictures.addPicture,
    storeActions.pictures.updatePicture,
    storeActions.pictures.bulkUpdatePicture,
  ],
  () => {
    sharedRTKStore.dispatch(triggerChildNameWithAgeGradeInvalidate())
  }),
  onSetError: actionOn(
    (actions, storeActions) => [
      storeActions.pictures.setError,
      storeActions.user.books.setError,
      storeActions.user.books_v3.setError_v3,
      storeActions.book_v3.setError_v3,
      storeActions.user.orders.setError,
      storeActions.checkout.setError,
    ],
    (state, target) => {
      const { payload } = target
      const text = extractRequestErrorMessage(payload)

      if (!isTest()) console.error(payload)
      requestAnimationFrame(() => {
        if (payload.isV2) {
          sharedRTKStore?.dispatch(setNotification({ type: NOTIFICATION_TYPES.ERROR, message: text }))
        } else {
          sharedRTKStore?.dispatch(setError(text))
        }
      })
    },
  ),
}

export default proxyUi

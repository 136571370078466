import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { bool, func, object, string } from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'

import Icon from 'components/Icon'
import InfiniteScroll from 'components/InfiniteScroll'
import LoadingSpinner from 'components/LoadingSpinner'
import PictureGrid from 'components/Picture/Grid'
import PictureList from 'components/Picture/List'
import PictureDropzone from 'components/PictureDropzone'
import PictureSortAndFilter from 'components/PictureSortAndFilter'
import { PicturesLength, UploadButton } from 'components/PictureSortAndFilter/PictureSortAndFilter.style'
import SelectVisibleControl from 'components/PictureSortAndFilter/SelectVisibleControl'
import { SET_PICTURES_MODES, UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import useUserInformation from 'dux/hooks/useUserInformation'
import useModal from 'hooks/useModal'
import { UploadDropzoneContext } from 'hooks/useUploadingDropzone'
import useUploadService from 'hooks/useUploadService'
import uploadService from 'services/uploadService'
import shiftSelect from 'utils/shiftSelect'

import PicturesStyle from './Pictures.style'

const Pictures = ({
  bgColor,
  handleLoadMore,
  multiselect = true,
  onSelectAll,
  onSelectPicture,
  renderEmptyScreen,
  scrollParentRef,
  selectable = false,
  setSelectable,
  showUploads = true,
  showEditPictureModal = true,
  useWindowScroll = true,
  isLoading,
}) => {
  const uploads = useUploadService()
  const {
    toggleSelectPicture,
    setSortOptions,
    selectAllPictures,
    deselectAllPictures,
  } = useStoreActions((actions) => actions.pictures)
  const { openDropzone } = useContext(UploadDropzoneContext)

  const { isLoggedAsUser } = useUserInformation()

  const { setModal } = useModal()

  const {
    filters,
    list: pictures,
    selected: selectedPicturesIds,
    selectedVisible,
    selectedHidden,
    selectedPictures: selectedList,
    total,
    sort: sortQueries,
    totalFiltered: totalPictures,
    totalVisible,
    totalHidden,
  } = useStoreState((state) => state.pictures)

  const [view, setView] = useState('grid')

  const dropzoneRef = useRef()

  const isPictureSelected = (picture) => selectedPicturesIds.includes(picture.id)

  const handleSelectAllToggle = (e, hidden) => {
    if (e.target.checked) {
      onSelectAll ? onSelectAll(hidden) : selectAllPictures(hidden)
    } else {
      deselectAllPictures(hidden)
    }
  }

  const handleSelectPicture = (e, pictureId) => {
    !selectable && setSelectable && setSelectable(true)
    /**
     * shift click
     */
    shiftSelect(e, selectedList, pictures, pictureId, toggleSelectPicture)

    if (!multiselect) {
      deselectAllPictures(null)
    }

    if (onSelectPicture) {
      onSelectPicture(pictureId)
    } else {
      toggleSelectPicture(pictureId)
    }
  }

  const handleEditPicture = useCallback((picture) => {
    // if picture is pending upload bail early
    if (picture?.status === UPLOADING_IMAGE_STATUSES.RETRY || !showEditPictureModal) return

    setModal({
      name: 'EditPicture',
      pictureId: picture.id,
      mode: 'photos',
    })
  }, [showEditPictureModal, setModal])

  const onUploadClick = () => uploadService.requeueFailed()

  const serializedFilters = useMemo(() => JSON.stringify(filters), [filters])

  useEffect(() => {
    dropzoneRef.current && dropzoneRef.current.scrollTo(0, 0)

    handleLoadMore(0, SET_PICTURES_MODES.SET)
  }, [sortQueries, serializedFilters])

  const isItemLoaded = ({ index }) => !!pictures?.[index]

  const pictureLen = pictures?.length ? pictures.length + uploadService.pictures.size : uploadService.pictures.size

  const isDropzoneEmpty = !pictureLen && !totalPictures

  const isAllVisibleSelected = !!totalVisible && totalVisible === selectedVisible.length
  const isAllHiddenSelected = !!totalHidden && totalHidden === selectedHidden.length

  const isAllPicturesSelected = (isAllVisibleSelected || !totalVisible) && (isAllHiddenSelected || !totalHidden)

  return (
    <PicturesStyle>
      <PictureSortAndFilter
        bgColor={bgColor}
        sortQueries={sortQueries}
        setSortQueries={setSortOptions}
        setView={(view) => setView(view)}
        view={view}
      >
        {multiselect && (
          <>
            <SelectVisibleControl
              name={'select-all-visible'}
              className={'input-wrap select-all'}
              checked={isAllVisibleSelected}
              onChange={(e) => handleSelectAllToggle(e, false)}
              label={(
                <>
                  {isAllVisibleSelected ? 'Deselect' : 'Select'} Visible
                  {!!selectedVisible.length && (
                    <PicturesLength className={'xs medgray'}>
                      ({selectedVisible.length} {selectedVisible.length === 1 ? 'picture' : 'pictures'})
                    </PicturesLength>
                  )}
                </>
              )}
            />
            {isLoggedAsUser && (
              <SelectVisibleControl
                name={'select-all-hidden'}
                className={'input-wrap select-all'}
                checked={isAllHiddenSelected}
                onChange={(e) => handleSelectAllToggle(e, true)}
                label={(
                  <>
                    {isAllHiddenSelected ? 'Deselect' : 'Select'} Hidden
                    {!!selectedHidden.length && (
                      <PicturesLength className={'xs medgray'}>
                        ({selectedHidden.length} {selectedHidden.length === 1 ? 'picture' : 'pictures'})
                      </PicturesLength>
                    )}
                  </>
                )}
              />
            )}
          </>
        )}

        {view === 'list' && (
          <UploadButton onClick={openDropzone} className={'label blue sm'}>
            <Icon name={'add-circle'} /> Upload Images
          </UploadButton>
        )}
      </PictureSortAndFilter>

      <PictureDropzone
        renderEmptyScreen={renderEmptyScreen}
        isEmpty={!isLoading && isDropzoneEmpty}
      >
        {isLoading && <LoadingSpinner key={'loader'} />}

        {pictures ? (
          <>
            {view === 'grid' ? (
              <InfiniteScroll
                key={'grid'}
                loadMore={handleLoadMore}
                hasMore={pictures?.length < totalPictures}
                loader={<LoadingSpinner key={'loader'} />}
                useWindowScroll={useWindowScroll}
                scrollParentRef={scrollParentRef}
              >
                <PictureGrid
                  isPictureSelected={isPictureSelected}
                  onClick={handleEditPicture}
                  onIconClick={handleSelectPicture}
                  onUploadClick={onUploadClick}
                  pictures={pictures}
                  pictureLen={pictureLen}
                  selectable={selectable}
                  showUploads={showUploads}
                  uploads={uploads}
                />
              </InfiniteScroll>
            ) : (
              <PictureList
                key={'list'}
                isPictureSelected={isPictureSelected}
                onClick={handleEditPicture}
                onIconClick={handleSelectPicture}
                onUploadClick={onUploadClick}
                pictures={pictures}
                total={total}
                isItemLoaded={isItemLoaded}
                loadMoreItems={handleLoadMore}
                multiselect={multiselect}
                isAllSelected={isAllPicturesSelected}
                onSelectAll={handleSelectAllToggle}
                showUploads={showUploads}
                uploads={uploads}
              />
            )}
          </>
        ) : null}
      </PictureDropzone>
    </PicturesStyle>
  )
}

Pictures.propTypes = {
  bgColor: string,
  isLoading: bool,
  selectable: bool,
  setSelectable: func,
  multiselect: bool,
  scrollParentRef: object,
  useWindowScroll: bool,
  handleLoadMore: func,
  onSelectPicture: func,
  onSelectAll: func,
  renderEmptyScreen: func.isRequired,
  showUploads: bool,
  showEditPictureModal: bool,
}

export default Pictures

import { Replay } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

export default styled('label')`
  color: ${(props) => props.theme.colors.darkgray};
  font-size: ${(props) => props.theme.typography.size.sm};
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  padding: 1rem;
`

export const RetryIcon = styled(Replay)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.medgray};
  border-radius: 100%;
`

export const Wrap = styled('div')`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D6D6D6;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 80%;
    z-index: -1;
  }
`

export const ContentWrap = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`


export const Placeholder = styled('div')`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
  border-radius: 3px 0 0 3px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
`

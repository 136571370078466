import * as Sentry from '@sentry/react'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

import { UPLOADING_IMAGE_STATUSES } from 'containers/Pictures/Pictures.constants'
import { createRequestBody } from 'utils/createRequestParts'
import TypedError from 'utils/errors/TypedError'
import formatBytes from 'utils/formatBytes'

const { FAILED } = UPLOADING_IMAGE_STATUSES

const uploadImageRequest = async (data) => {
  const formData = createRequestBody({
    'X-Amz-Algorithm': data.upload.algorithm,
    'X-Amz-Credential': data.upload.credential,
    'X-Amz-Date': data.upload.date,
    'X-Amz-Signature': data.upload.signature,
    acl: data.upload.acl,
    key: data.upload.key,
    policy: data.upload.policy,
    // NOTE: file key must be last
    // file: data.file,
  })

  formData.append('file', data.file, data.fileName || data.file.name)

  return await axios.post(
    data.upload.uri,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  )
}

export const replaceFilenameVersion = (fileName) => {
  const fileNameParts = fileName.split('.')
  const extension = fileNameParts.pop()

  const delimiter = '-version-'
  const name = fileNameParts.join('.').split(delimiter).shift()
  const version = `${delimiter}${uuid().slice(-12)}`

  return `${name}${version}.${extension}`
}

const s3upload = async (data) => {
  try {
    const { maxSize } = data.upload.limits

    if (data.file.size > maxSize) {
      throw new TypedError(`Must be less than ${formatBytes(maxSize)}`, FAILED)
    }

    await uploadImageRequest(data)
  } catch (err) {
    Sentry.captureException(err)

    throw err
  }
}

export default s3upload

import React, { useMemo } from 'react'
import { func, node, object, string } from 'prop-types'

import Link from 'next/link'
import { useRouter } from 'next/router'

import findRoute from '@/routes/findRoute'

const findParentRoute = (linkName, pathname) => {
  const currentOpenedRoute = findRoute('pathname', pathname)
  let parentRoute = currentOpenedRoute.parent ? findRoute('name', currentOpenedRoute.parent) : null
  if (parentRoute?.parent && linkName !== parentRoute?.name) {
    return findParentRoute(linkName, parentRoute.pathname)
  } else {
    return parentRoute
  }
}

const LinkWrap = ({
  children,
  name,
  query = {},
  className,
  onClick,
}) => {
  const router = useRouter()
  const linkRoute = findRoute('name', name)

  const isActive = useMemo(() => {
    const parentRoute = findParentRoute(name, router.pathname)
    return router.pathname === linkRoute.pathname || name === parentRoute?.name
  }, [router.pathname, linkRoute.pathname, name])

  const appendedClassName = isActive ? `${className} active` : className

  return (
    <Link
      as={linkRoute?.mapSlugToPath?.(query)}
      href={{
        pathname: linkRoute.pathname,
        query,
      }}
      className={appendedClassName}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

LinkWrap.propTypes = {
  /** children to render */
  children: node.isRequired,
  /** custom class name */
  className: string,
  /** route name */
  name: string.isRequired,
  /** query params to substitute in the route */
  query: object,
  onClick: func,
}

export default LinkWrap

import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

import { ActionButton, Actions } from 'components/NewModal/NewModal.style'
import { NO_TITLE_PLACEHOLDER } from 'containers/Book/Book.constants'

const ArtBookSent = ({ bookTitle = NO_TITLE_PLACEHOLDER, onClose }) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box mt={2}>
        <Typography variant={'body1'} align={'center'} color={(theme) => theme.colors.gray[700]}>
          The Art Book{' '}
          <Typography component={'span'} color={(theme) => theme.colors.black} fontWeight={600}>
            {bookTitle.toUpperCase()}
          </Typography>
          {' '}
          was successfully sent to customer.
        </Typography>
      </Box>
      <Actions>
        <ActionButton variant={'new-contained-primary'} onClick={onClose}>
          Ok, Got it!
        </ActionButton>
      </Actions>
    </Box>
  )
}

ArtBookSent.propTypes = {
  onClose: PropTypes.func.isRequired,
  bookTitle: PropTypes.string,
}

export default ArtBookSent

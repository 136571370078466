import React from 'react'

import Button from 'components/Buttons/Button'
import ButtonLabel from 'components/Text/ButtonLabel'
import H2 from 'components/Text/H2'
import useModal from 'hooks/useModal'

const ModalNotificationSent = () => {
  const { unsetModal } = useModal()

  return (
    <div className={'content'}>
      <H2 className={'center darkblue'}>
        Please Check Your Inbox
      </H2>
      <div className={'label center darkgray sm'}>
        We just emailed you a link to reset your password.
      </div>
      <div className={'flex justify-center'}>
        <Button onClick={unsetModal}>
          <ButtonLabel className={'white'}>
            Close
          </ButtonLabel>
        </Button>
      </div>
    </div>
  )
}

export default ModalNotificationSent

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { styled } from '@mui/material/styles'

import H1 from 'components/Text/H1'
import theme from 'styles/theme'

export const UpgradeMembershipStyles = styled('div')`
  background-color: ${theme.colors.sky[100]};

  .modal {
    width: auto;
    max-width: 1260px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    .modal {
      height: auto;
      width: auto;
      max-width: 100%;
    }
  }
`

export const RoundedButton = styled(Button)`
  background-color: white;
  border-radius: 25px;
  border-color: ${theme.colors.sky[300]};
  color: ${theme.colors.darkgray};
  font-size: 16px;
  font-weight: 500;
  padding: 12px 28px;
  transition: background-color .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 50px;

  &.active {
    background-color: ${theme.colors.sky[300]};
    color: ${theme.colors.sky[700]};
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(177, 177, 177, 0.04);
    border: 1px solid ${theme.colors.sky[700]};
  }
`

export const StyledButtonGroup = styled(ButtonGroup)`
  .MuiButtonGroup-grouped:not(:last-of-type) {
    border: 1px solid ${theme.colors.sky[300]};
    border-right: none;

    &:hover {
      border: 1px solid ${theme.colors.sky[700]};
    }
  }
`

export const Header = styled(H1)`
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 700;
  font-family: ${theme.typography.type.montserrat};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.75rem;
  }
`

export const UpgradeDivider = styled('div')`
  width: 80px;
  margin: 48px 24px;
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
  }
`

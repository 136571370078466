import { css } from '@emotion/react'
import { ViewModule, ViewStream } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { transitions } from 'polished'

import theme from 'styles/theme'

const dynamicFilters = ({ theme }) => css`
  @media (max-width: ${theme.breakpoints.md}) {
    .filters-dropdown .dropdown {
      top: 56px;
      bottom: 0;
      height: auto;
    }
  }
`

export default styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
  z-index: 9;

  .input-wrap {
    align-items: center;
    display: flex;
    text-align: left;
  }


  .spaced-btn {
    margin: 0;
    margin-right: 1rem;
  }

  .child-filter-dropdown, .tags-filter-dropdown {
    .dropdown {
      min-width: 450px;
    }
  }

  .align-right {
    text-align: right;
  }

  .margin-top {
    margin-top: 0.5rem;
  }

  ${dynamicFilters}
`

export const AdditionalControls = styled('div')`
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`

const IconStyle = css`
  color: ${theme.colors.medgray};
  cursor: pointer;

  ${transitions(['color'], '0.3s ease-in-out')}
  &:hover {
    color: ${theme.colors.lightgray};
  }

  &.active {
    color: ${theme.colors.blue};

    &:hover {
      color: ${theme.colors.lightblue};
    }
  }
`

export const GridIcon = styled(ViewModule)`
  ${IconStyle}
`

export const ListIcon = styled(ViewStream)`
  ${IconStyle}
`

export const UploadButton = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 4px;

  .icon {
    margin: 0 0.25rem;
  }
`

export const SortFilterOptions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  .filter-wrap-desktop {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-left: 1rem;

    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }

    .filter-label {
      margin-right: 0.5rem;
    }
  }

  .filter-wrap-mobile {
    display: none;

    @media (max-width: ${theme.breakpoints.md}) {
      display: block;
    }
  }
`

export const SortFilterWrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  padding: 0.5rem 0;
`

export const PicturesLength = styled('span')`
  margin-left: 0.25rem;
  font-style: italic;
`

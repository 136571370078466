export const PAGE_TEXT_FIELDS = {
  title: {
    name: 'title',
    label: 'Image Title',
    maxLength: 50,
  },
  child: {
    name: 'child',
    label: 'Name',
    maxLength: 25,
  },
  label: {
    name: 'label',
    label: 'Age/Grade',
    maxLength: 25,
  },
  pictureDate: {
    name: 'pictureDate',
    label: 'Date',
  },
  tags: {
    name: 'tags',
    label: 'Tags',
    maxLength: 25,
  },
}

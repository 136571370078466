const { SESSION_COOKIE_NAME, getSessionCookie } = require('../../../lib/cookies')

const maxAge = 30 * 24 * 60 * 60 * 1000

/**
 * return the cookie domain from host
 * @param {string} host - host request
 * @return {string} root domain
 */
exports.getDomain = (host) => {
  if (process.env.NODE_ENV === 'production') {
    return `.${/^(?:https?:\/\/)?.*?([^.]+\.[^.]+?)(?::\d+)?$/.exec(host)[1]}`
  } else if (process.env.IS_DEVCONTAINER) {
    return new URL(process.env.NEXT_PUBLIC_API_URL).hostname
  } else if (host.includes('localhost')) {
    return 'localhost'
  } else if (process.env.DEV_IP_HOST) {
    return host.split(':')[0]
  } else {
    throw new Error(`Invalid host ${host} for environment ${process.env.NODE_ENV}`)
  }
}

/**
 * Extract session cookie from `cookies` and set session for `res` based on `req` host
 * @param {object} res - response object
 * @param {object} req - request object
 * @param {string} cookies - cookies string
 * @return {undefined}
 */
exports.proxySessionCookie = (res, req, cookies) => {
  const domain = this.getDomain(req.headers.host)
  const sessionCookie = getSessionCookie(cookies)
  res.cookie(SESSION_COOKIE_NAME, sessionCookie, { domain, maxAge })
}

/**
 * Clear out user session from `res` using `req` host value
 * @param {object} res - response object
 * @param {object} req - request object
 * @returns {undefined}
 */
exports.clearSession = (res, req) => {
  const domain = this.getDomain(req.headers.host)
  res.clearCookie(SESSION_COOKIE_NAME, { domain })
}

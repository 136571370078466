import { fabric } from 'fabric'

import { CANVAS_PIECE_MAX_SIZE, getCanvasMaxSize } from 'components/Modal/EditPicture/CropImage/CropImage.helpers'

import { calculateMaxDimensions } from './components/utils'


/*
// Store the original function in a variable
const originalCreateCanvasElement = fabric.util.createCanvasElement

// Override the original function
fabric.util.createCanvasElement = function () {
  console.log('fabric.util.createCanvasElement was called')

  // Call the original function and return its result
  return originalCreateCanvasElement.apply(this, arguments)
}
*/

fabric.AdjustedImage = fabric.util.createClass(fabric.Image, {
  type: 'adjustedImage',

  initialize: function(element, options) {
    options || (options = {})
    this.callSuper('initialize', element, options)
    this.destinationWidth = options.destinationWidth || 0
    this.destinationHeight = options.destinationHeight || 0
  },

  applyFilters: function(filters) {

    filters = filters || this.filters || []
    filters = filters.filter(function(filter) {
      return filter && !filter.isNeutralState()
    })
    this.set('dirty', true)

    // needs to clear out or WEBGL will not resize correctly
    this.removeTexture(this.cacheKey + '_filtered')

    if (filters.length === 0) {
      this._element = this._originalElement
      this._filteredEl = null
      this._filterScalingX = 1
      this._filterScalingY = 1
      return this
    }

    const imgElement = this._originalElement,
      sourceWidth = this.destinationWidth || imgElement.naturalWidth || imgElement.width,
      sourceHeight = this.destinationHeight || imgElement.naturalHeight || imgElement.height

    // we can do async render later I guess, after testing this code.
    if (sourceWidth > CANVAS_PIECE_MAX_SIZE || sourceHeight > CANVAS_PIECE_MAX_SIZE) {
      // Split image and apply filters to pieces
      this._splitAndApplyFilters(imgElement, sourceWidth, sourceHeight, filters)
    } else {
      if (this._element === this._originalElement) {
        // if the element is the same we need to create a new element
        const canvasEl = fabric.util.createCanvasElement()
        canvasEl.width = sourceWidth
        canvasEl.height = sourceHeight
        this._element = canvasEl
        this._filteredEl = canvasEl
      } else {
        // clear the existing element to get new filter data
        // also dereference the eventual resized _element
        this._element = this._filteredEl
        this._filteredEl.getContext('2d').clearRect(0, 0, sourceWidth, sourceHeight)
        // we also need to resize again at next renderAll, so remove saved _lastScaleX/Y
        this._lastScaleX = 1
        this._lastScaleY = 1
      }
      if (!fabric.filterBackend) {
        fabric.filterBackend = fabric.initFilterBackend()
      }
      fabric.filterBackend.applyFilters(
        filters, this._originalElement, sourceWidth, sourceHeight, this._element, this.cacheKey)
    }
    if (this._originalElement.width !== this._element.width ||
      this._originalElement.height !== this._element.height) {
      this._filterScalingX = this._element.width / this._originalElement.width
      this._filterScalingY = this._element.height / this._originalElement.height
    }

    return this
  },
  _splitAndApplyFilters: function(sourceImage, sourceWidth, sourceHeight, filters) {
    if (!fabric.filterBackend) {
      fabric.filterBackend = fabric.initFilterBackend()
    }
    const pieceWidth = CANVAS_PIECE_MAX_SIZE
    const pieceHeight = CANVAS_PIECE_MAX_SIZE

    const numHorizontalPieces = Math.ceil(sourceWidth / pieceWidth)
    const numVerticalPieces = Math.ceil(sourceHeight / pieceHeight)

    const filteredPieces = []

    for (let i = 0; i < numHorizontalPieces; i++) {
      for (let j = 0; j < numVerticalPieces; j++) {
        const pieceX = i * pieceWidth
        const pieceY = j * pieceHeight
        const _pieceWidth = Math.min(pieceWidth, sourceWidth - pieceX)
        const _pieceHeight = Math.min(pieceHeight, sourceHeight - pieceY)

        const pieceCanvas = fabric.util.createCanvasElement()
        const pieceCtx = pieceCanvas.getContext('2d')

        pieceCanvas.width = _pieceWidth
        pieceCanvas.height = _pieceHeight
        pieceCtx.drawImage(sourceImage, pieceX, pieceY, _pieceWidth, _pieceHeight, 0, 0, _pieceWidth, _pieceHeight)
        const filteredPiece = new fabric.Image(pieceCanvas)
        this.removeTexture(filteredPiece.cacheKey + '_filtered')
        fabric.filterBackend.applyFilters(
          filters,
          filteredPiece.getElement(),
          _pieceWidth,
          _pieceHeight,
          filteredPiece.getElement(),
          filteredPiece.cacheKey)

        filteredPiece.left = pieceX
        filteredPiece.top = pieceY
        filteredPieces.push(filteredPiece)
      }
    }
    // Combine filtered pieces into a final canvas
    const finalCanvas = fabric.util.createCanvasElement()
    const finalCtx = finalCanvas.getContext('2d')
    finalCanvas.width = sourceWidth
    finalCanvas.height = sourceHeight

    filteredPieces.forEach(function(filteredPiece) {
      finalCtx.drawImage(filteredPiece.getElement(), filteredPiece.left, filteredPiece.top)
      filteredPiece.width = 0
      filteredPiece.height = 0
      filteredPiece.setElement(null)
    })

    this._element = finalCanvas
    this._filteredEl = finalCanvas
    this._lastScaleX = 1
    this._lastScaleY = 1
  },
})

fabric.AdjustedImage.fromURL = function(url, callback, imgOptions) {
  fabric.util.loadImage(url, function(img, isError) {
    callback && callback(new fabric.AdjustedImage(img, imgOptions), isError)
  }, null, imgOptions && imgOptions.crossOrigin)
}


export const createAdjustableCanvas = (canvasEl, imageSize, style) => {
  if (fabric.enableGLFiltering) {
    fabric.filterBackend = fabric.initFilterBackend()
  }

  const maxArea = getCanvasMaxSize()
  const {
    maxWidth,
    maxHeight,
  } = calculateMaxDimensions(imageSize.width, imageSize.height, maxArea.maxWidth * maxArea.maxHeight)

  const canvas = new fabric.StaticCanvas(canvasEl, {
    width: style ? parseFloat(style.width) : maxWidth,
    height: style ? parseFloat(style.height) : maxHeight,
    imageSmoothingEnabled: false,
    enableRetinaScaling: false,
    objectCaching: false,
    backgroundColor: '#ffffff',
  })

  canvas.setDimensions({
    width: maxWidth,
    height: maxHeight,
  }, { backstoreOnly: true })

  if (fabric.enableGLFiltering) {
    fabric.textureSize = CANVAS_PIECE_MAX_SIZE // as we divide canvas into 512px pieces, it should provide better ux then stretching maxTextureSize (for example 4096px) to the 6000px image
    // second invocation required to apply textureSize
    fabric.filterBackend = fabric.initFilterBackend()
  }

  return canvas
}

const defaultFilterValues = {
  brightness: 0,
  contrast: 0,
  saturation: 0,
  hue: 0,
}

export const loadAdjustableImage = async (canvas, imageEl, maxArea, filtersValues = defaultFilterValues) => {
  return new Promise((resolve, reject) => {
    const filters = {
      // basic: new fabric.Image.filters.BaseFilter(),
      brightness: new fabric.Image.filters.Brightness({ brightness: filtersValues.brightness }),
      contrast: new fabric.Image.filters.Contrast({ contrast: filtersValues.contrast }),
      saturation: new fabric.Image.filters.Saturation({ saturation: filtersValues.saturation }),
      hue: new fabric.Image.filters.HueRotation({ rotation: filtersValues.hue }),
    }

    fabric.AdjustedImage.fromURL(imageEl.src, function(imgInstance, isError) {
      if (isError) {
        reject(isError)
        return
      }

      const scaleX = canvas.width / imgInstance.width
      const scaleY = canvas.height / imgInstance.height

      // Use the smaller scaling factor to ensure the entire image fits in the canvas
      const scale = Math.min(scaleX, scaleY)

      imgInstance.set({
        scaleX: scale,
        scaleY: scale,
      })

      imgInstance.filters = Object.values(filters)
      // for specific maxArea create a virtual canvas to apply filters with desired size
      imgInstance.applyFilters()

      resolve({
        imgInstance,
        filters,
      })
    }, {
      crossOrigin: imageEl.crossOrigin,
      objectCaching: false,
      destinationWidth: maxArea ? maxArea.maxWidth : undefined,
      destinationHeight: maxArea ? maxArea.maxHeight : undefined,
    })
  })
}


/**
 * Applies filters to an image on a virtual canvas.
 *
 * @async
 * @param {HTMLImageElement} imageEl - The image element to apply filters to.
 * @param {Object} filters - The filters to apply to the image.
 * @returns {HTMLCanvasElement} The canvas element with the filtered image.
 */
export const applyFiltersOnVirtualCanvas = async (imageEl, filters) => {
  const canvasEl = fabric.util.createCanvasElement()
  const maxArea = getCanvasMaxSize(imageEl.naturalWidth / imageEl.naturalHeight)
  const {
    maxWidth,
    maxHeight,
  } = calculateMaxDimensions(imageEl.naturalWidth, imageEl.naturalHeight, maxArea.maxWidth * maxArea.maxHeight)

  const canvas = createAdjustableCanvas(canvasEl, {
    width: maxWidth,
    height: maxHeight,
  })

  const { imgInstance } = await loadAdjustableImage(canvas, imageEl, { maxWidth, maxHeight }, filters)
  canvas.backgroundColor='#ffffff'
  canvas.add(imgInstance)
  canvas.renderAll()

  const canvasCleanUp = () => {
    imgInstance.dispose()
    canvas.clear()
    canvas.setWidth(0)
    canvas.setHeight(0)
    canvas.dispose()
  }

  return { canvas, canvasCleanUp }
}

export const caseInsensitiveCompare = (a = '', b = '') => a.toUpperCase().trim() === b.toUpperCase().trim()
export const getOptionLabel = (option) => option.label || ''

export const normalizeValue = (selected, autoCompleteOptions, currentValue) => {
  if (typeof selected === 'string') {
    // need this check for cases when user hits "Enter button"
    // in other case Autocomplete field will have a lot of same tags
    const alreadySelectedValue =
      Array.isArray(currentValue) && currentValue.find(({ label }) => caseInsensitiveCompare(label, selected))
    if (alreadySelectedValue) return null
    // need check not only type 'string' but if had already such option
    // because if user hits Enter - MUI Autocomplete will pass string instead of '{label, value}' even for
    // existing options
    const alreadyExistValue = autoCompleteOptions.find(({ label }) => caseInsensitiveCompare(label, selected))

    if (alreadyExistValue) {
      return alreadyExistValue
    }
    return {
      value: selected,
      label: selected,
      __isNew__: true,
    }
  }

  return selected.__isNew__
    ? {
      ...selected,
      label: selected.value,
    }
    : selected
}

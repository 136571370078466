import { thunk } from 'easy-peasy'

import { calculateImageData, getPictureRecord, updatePictureRecord } from 'services/uploadService/uploadService.helpers'
import api from 'utils/api'
import { queryParamsSerializer } from 'utils/createRequestParts'
import s3upload, { replaceFilenameVersion } from 'utils/s3upload'

import { PAGE_SIZE, SET_PICTURES_MODES } from './Pictures.constants'

const routes = {
  pictures: '/api/v2/user/pictures',
  searchPictures: '/api/v2/user/pictures/search',
}

const picturesService = {
  readPicturesLength: thunk(async (actions) => {
    const config = { method: 'get', url: routes.pictures, params: { perPage: 0 } }
    const { error, response } = await api.handleRequest(config)
    if (error) {
      actions.setError(error)
      return { error }
    }

    if (response) {
      actions.setTotal(response.data.meta.totalCount)
      return response.data.meta.totalCount
    }
  }),
  readPictures: thunk(async (actions, options = {}) => {
    actions.setIsLoading(true)

    const params = { filter: options.filter, sort: options.sort }

    if (options.pagination) {
      params.curPage = options.pagination.page || 1
      params.perPage = options.pagination.pageSize || PAGE_SIZE
    }

    let config
    const filters = params.filter

    if (filters?.excludePictureIds?.length || filters?.fileName?.length || filters?.bookIds?.length) {
      config = { method: 'post', url: routes.searchPictures, data: params }
    } else {
      config = {
        method: 'get',
        url: routes.pictures,
        params,
        paramsSerializer: (params) => queryParamsSerializer(params, { skipNulls: true }),
      }
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError(error)
      actions.setIsLoading(false)
      return
    }

    if (!response) {
      actions.setIsLoading(false)
      return
    }

    actions.setPictures({
      pictures: response.data.data,
      total: response.data.meta.totalCount,
      mode: options.mode || SET_PICTURES_MODES.SET,
    })
    actions.setIsLoading(false)

    return response.data.data
  }),

  updatePicture: thunk(async (
    actions,
    {
      disableLoading = false,
      picture: { replaceTags, image, ...picture },
    },
    { getStoreActions },
  ) => {
    // return if no updates for some reason :shrug:
    if (Object.keys(picture).length <= 1 && !image) return
    // map redux state to request body
    const params = {
      id: picture.id,
      pictureDate: picture.pictureDate,
      title: picture.title,
      childAttributes: picture.child,
      labelAttributes: picture.label,
      tagAttributes: picture.tags,
      hidden: picture.hidden,
    }
    // set loading
    if (image && !disableLoading) {
      getStoreActions().proxyUi.setIsLoading(true)
    }

    try {
      // if image
      if (image) {
        const fileName = replaceFilenameVersion(image.name)

        // fetch picture anew with fresh upload policy for AWS S3
        const fetchedPicture = await getPictureRecord(picture)
        // calculate true image dimensions
        const { height, width } = await calculateImageData(image)
        // upload direct to S3 with new upload policy

        await s3upload({ ...fetchedPicture, file: image, fileName })
        // set values needed to persist relative to this new picture
        params.height = height
        params.image = fileName
        params.width = width
      }
      // update picture in db
      const updatedPicture = await updatePictureRecord(params)
      // set update
      actions.setPicture({ picture: updatedPicture })
      // return picture for any api usage expecting this
      return updatedPicture
    } catch (err) {
      // set error message
      if (err.response?.data?.message) {
        actions.setError({ message: err.response.data.message })
      } else {
        actions.setError(err)
      }
      // return error for any api usage expecting this
      return { err }
    } finally {
      // stop loading state if image edits were sent
      if (image && !disableLoading) {
        getStoreActions().proxyUi.setIsLoading(false)
      }
    }
  }),

  bulkUpdatePicture: thunk(async (actions, { ids, picture: { image, replaceTags, ...picture } }) => {
    try {
      const payload = { picture, ids }

      if (replaceTags) {
        payload.picture._replace_tags = true
      }
      actions.setIsLoading(true)
      const { data: { data: updatedPictures } } = await api.put({
        url: routes.pictures,
        data: payload,
      })

      actions.bulkSetPictures({ pictures: updatedPictures })

      return updatedPictures
    } catch (error) {
      error.isV2 = true
      actions.setError(error)

      return { error }
    } finally {
      actions.setIsLoading(false)
    }
  }),

  deletePicture: thunk(async (actions, pictureId) => {
    try {
      const response = await api.delete({ url: `${routes.pictures}/${pictureId}` })

      actions.removePicture(pictureId)

      return response.data.data
    } catch (error) {
      actions.setError(error)

      return { error }
    } finally {
      actions.setIsLoading(true)
    }
  }),

  bulkDeletePicture: thunk(async (actions, pictureIds) => {
    try {
      actions.setIsLoading(true)

      const response = await api.delete({
        url: routes.pictures,
        data: { ids: pictureIds },
      })

      actions.removePictures(pictureIds)

      return response.data.data
    } catch (error) {
      actions.setError(error)

      return { error }
    } finally {
      actions.setIsLoading(true)
    }
  }),
}

export default picturesService

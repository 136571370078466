import React from 'react'
import { bool, func, node } from 'prop-types'

import { Backdrop, StyledDialog } from './Modal.style'

const LightDialog = ({ children, open, onClose, fullScreen = false, persistent = false }) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && persistent) return

    onClose(event)
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      maxWidth={false}
      fullScreen={fullScreen}
      scroll={'body'}
      PaperProps={{
        elevation: 0,
        classes: {
          root: 'dialog-paper',
        },
      }}
      disableEscapeKeyDown={persistent}
    >
      {children}
    </StyledDialog>
  )
}

LightDialog.propTypes = {
  children: node,
  open: bool,
  onClose: func,
  fullScreen: bool,
  persistent: bool,
}

export default LightDialog

import React, { useContext } from 'react'
import { node, string } from 'prop-types'

import cl from 'classnames'

import { TabsContext } from '../Tabs'

import TabPanelStyle from './TabPanel.style'

/**
 * Tab Panel component that renders if it is active
 */
const TabPanel = ({ id, children, className = '', ...rest }) => {
  const { activeTab } = useContext(TabsContext)

  if (activeTab !== id) {
    return null
  }

  return (
    <TabPanelStyle className={cl('tab-panel', 'active', className)} {...rest}>
      {children}
    </TabPanelStyle>
  )
}

TabPanel.propTypes = {
  id: string,
  className: string,
  children: node,
}

export default TabPanel

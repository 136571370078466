import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`
  margin-bottom: 0.25rem;
  position: relative;
  font-family: ${theme.typography.type.montserrat};
  cursor: pointer;
  width: 100%;
  overflow: hidden;

  .inline-input {
    flex: 1;
  }

  /* elements */

  .__form, .__editWrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: ${({ alignment }) => alignment === 'center' ? 'center' : 'flex-start'};
    margin: 0;
    padding: 0;
    position: relative;
    text-align: ${({ alignment }) => alignment};
    height: 38px;

    ${({ placement }) => placement === 'right' && css`
      // flex-direction: row-reverse;

      @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: row;
      }
    `}
  }

  .__errorLabel {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 500;
    text-transform: capitalize;
    position: absolute;
    bottom: -1rem;
    left: 0;
    font-size: ${({ theme }) => theme.typography.size.xs};
  }

  .__errorIcon {
    fill: ${({ theme }) => theme.colors.red};
    right: 0;
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
  }

  .__editIcon {
    fill: ${({ theme }) => theme.colors.darkgray};
    width: 20px;
    height: 20px;
    margin: 0 0.25rem;
  }

  .__p {
    font-family: ${theme.typography.type.montserrat};
    border-radius: 0;
    border: 0 none;
    color: ${({ theme }) => theme.colors.darkgray};
    font-size: ${({ theme }) => theme.typography.size.md};
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-align: ${({ alignment }) => alignment};
    text-transform: uppercase;
    line-height: 1.4;
    max-width: 100%;
    overflow: hidden;
    flex-grow: 1;

    ${({ $dense }) => $dense && css`
      flex-grow: 0;
    `};

    /* modifiers */

    &.--error {
      border-bottom: 1px solid ${({ theme }) => theme.colors.red};
      padding-right: 0.5rem;
    }

  }

`

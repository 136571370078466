import React from 'react'

import LazyLoad from 'components/LazyLoad'

import { LazyLoadWrap } from './withLazyLoad.style'

const IMAGE_LOAD_OFFSET = 600

const withLazyLoad = (Component) => React.forwardRef((props, ref) => (
  <LazyLoadWrap as={LazyLoad} ref={ref} offset={IMAGE_LOAD_OFFSET}>
    <Component {...props} />
  </LazyLoadWrap>
))

export default withLazyLoad

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SliderWrapper = styled('div')`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  max-width: 420px;
  padding: 28px 16px 22px;
  align-self: center;
`

export const SliderValue = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition-duration: 0.5s;
  transition-property: font-size, opacity;
  color: white;
  font-size: ${({ theme }) => theme.typography.size.sm};
  font-weight: 500;
  top: -24px;
  vertical-align: bottom;
  transform: translate(-50%);
`

export const SliderDot = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const SliderLine = styled('div')`
  background: rgba(255, 255, 255, 0.5);
  height: 2px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`

export const SliderFill = styled('div')`
  background: white;
  align-self: stretch;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;
  height: 2px;
`

export const SliderHandler = styled(Box, { shouldForwardProp: (propName) => !['isHidden', 'isFocused'].includes(propName) })`
  width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition-duration: 0.2s;
  transition-property: height;
  background-color: white;
  height: ${({ isHidden, isFocused }) => isHidden ? '4px' : (isFocused ? '14px' : '12px')};

  &:hover {
    height: 10px;
  }
`

import { styled } from '@mui/material/styles'

import theme from 'styles/theme'

export default styled('div')`

  .picture-filters-header {
    margin-bottom: 1rem;
  }

  .picture-filters-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    width: 100%;

    .tab {
      border-bottom: 1px solid ${(props) => props.theme.colors.lightgray};
      color: ${(props) => props.theme.colors.darkgray};
      cursor: pointer;
      font-family: ${theme.typography.type.secondary};
      padding-bottom: 0.5rem;
      text-align: center;
      width: 50%;

      &.active {
        border-bottom: 4px solid ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  .picture-filter-panels__section-title {
    margin: 2rem 0 1rem;
  }

  .picture-filters-buttons {
    margin-top: 3rem;
  }

  .upload-filter__item {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

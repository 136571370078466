import React, { createContext } from 'react'
import { bool, func } from 'prop-types'

import { useDropzone } from 'react-dropzone'

export const UPLOAD_DROPZONE_ALLOWED_USAGE = [/^\/photos$/]

export const getIsAllowedDropzoneUsage = (routerPath, config = UPLOAD_DROPZONE_ALLOWED_USAGE) => {
  return config.some((path) => path.test(routerPath))
}

export const UploadDropzoneContext = createContext({})

export const UploadDropzoneProvider = ({ children, onDrop, disabled = false }) => {
  const {
    getRootProps,
    getInputProps,
    isDragReject,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop,
    noClick: true,
    disabled,
  })

  return (
    <UploadDropzoneContext.Provider
      value={{
        getRootProps,
        getInputProps,
        isDragReject,
        openDropzone,
      }}
    >
      {children({ getRootProps })}
    </UploadDropzoneContext.Provider>
  )
}

UploadDropzoneProvider.propTypes = {
  children: func,
  onDrop: func,
  disabled: bool,
}

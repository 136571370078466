import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import LoadingSpinner from 'components/LoadingSpinner'

const withLoader = (Component) => {
  const WithLoaderWrapper = (props) => {
    const { isLoading } = props
    const [loading, setLoading] = useState(isLoading)

    const handleLoading = (isComponentLoading) => {
      setLoading(isComponentLoading)
    }

    useEffect(() => {
      setLoading(isLoading)
    }, [isLoading])

    return (
      <>
        {
          loading ? (
            <LoadingSpinner position={props.position || 'relative'} />
          ) : (
            <Component {...props} setLoading={handleLoading} />
          )
        }
      </>
    )
  }

  WithLoaderWrapper.propTypes = {
    isLoading: PropTypes.bool,
    position: PropTypes.string,
  }

  WithLoaderWrapper.displayName = `withLoader(${Component.displayName || Component.name})`

  return WithLoaderWrapper
}

export default withLoader

import { computed } from 'easy-peasy'

import { UPLOAD_FILTER_OPTIONS } from './Pictures.constants'

const picturesModel = {
  selected: [],
  list: [],
  total: 0,
  totalFiltered: 0,
  sort: [],
  filters: {
    children: {},
    tags: [],
    uploadBy: UPLOAD_FILTER_OPTIONS[0].value,
  },
  isLoading: true,
  isMultipleSelectionAllowed: true,
  selectedPictures: computed((state) => state.list?.filter((picture) => state.selected.includes(picture.id)) || []),
  selectedVisible: computed((state) => state.selectedPictures.filter((picture) => !picture.hidden)),
  selectedHidden: computed((state) => state.selectedPictures.filter((picture) => picture.hidden)),
  allPicturesSelected: computed((state) => state.list?.length && state.selected.length === state.list?.length),
  totalVisible: computed((state) => state.list?.filter((picture) => !picture.hidden).length || 0),
  totalHidden: computed((state) => state.list?.filter((picture) => picture.hidden).length || 0),
  filtersApplied: computed((state) => {
    return !!(Object.keys(state.filters.children).length
        || state.filters.tags.length
        || Object.keys(state.filters.uploadBy).length)
  }),
  filtersCount: computed((state) => {
    return (
      Object.keys(state.filters.children).length
        + state.filters.tags.length
        + Object.keys(state.filters.uploadBy).length
    )
  }),
  noChildrenFilterSelected: computed((state) => {
    return Object.keys(state.filters.children).length === 1 && state.filters.children[''] === null
  }),
}

export default picturesModel

import React, { forwardRef } from 'react'
import { func, string } from 'prop-types'

import { Typography } from '@mui/material'

import Button from 'components/Buttons/Button'
import Icon from 'components/Icon'
import NoFilterResults from 'components/NoFilterResults'
import P from 'components/Text/P'

import { EMPTY_SCREEN_REASONS } from './DropzoneEmptyScreen.constants'
import DropzoneEmptyScreenStyle from './DropzoneEmptyScreen.style'

/**
 * Empty Screen component for Dropzone component
 */
const DropzoneEmptyScreen = forwardRef(({
  getRootProps,
  openDropzone,
  className = '',
  reason = EMPTY_SCREEN_REASONS.NO_DATA,
}, ref) => {
  return (
    <DropzoneEmptyScreenStyle
      className={className}
      {...getRootProps({ className: 'dropzone', onClick: openDropzone })}
      ref={ref}
    >
      {reason === EMPTY_SCREEN_REASONS.BULK_REPLACE && (
        <>
          <div className={'top'}>
            <Typography component={'h2'} variant={'h4'} align={'center'}>Replace pictures</Typography>
            <P className={'center secondary'}>Accepted file types include JPG or PNG.</P>
          </div>
          <div className={'bottom'}>
            <Icon className={'blue'} name={'download'} />
            <Typography component={'h2'} variant={'h5'} align={'center'}>
              Drag &amp; Drop your photos here or
            </Typography>
            <Button>Browse Files</Button>
          </div>
        </>
      )}
      {reason === EMPTY_SCREEN_REASONS.NO_DATA && (
        <>
          <div className={'top hide-desktop center'}>
            <Icon name={'download'} className={'blue'} />
            <Typography component={'h2'} variant={'h5'}>
              Start by uploading photos from your phone
            </Typography>
          </div>
          <div className={'top hide-mobile'}>
            <Typography component={'h2'} variant={'h4'}>
              Start By Uploading Some Work
            </Typography>
            <P className={'center secondary'}>Accepted file types include JPG or PNG.</P>
          </div>
          <Button className={'primary upload-button hide-desktop'}>Upload Photos</Button>
          <div className={'bottom hide-mobile'}>
            <Icon className={'blue'} name={'download'} />
            <Typography component={'h2'} variant={'h5'}>
              Drag &amp; Drop your photos here or
            </Typography>
            <Button>Browse Files</Button>
          </div>
        </>
      )}
      {reason === EMPTY_SCREEN_REASONS.FILTERS && (
        <NoFilterResults />
      )}
      {reason === EMPTY_SCREEN_REASONS.NO_AVAILABLE_DATA && (
        <div className={'center'}>
          <Typography component={'h2'} variant={'h4'}>
            All your pictures are already in the book.
          </Typography>
          <Button>Add More Pictures</Button>
        </div>
      )}
    </DropzoneEmptyScreenStyle>
  )
})

DropzoneEmptyScreen.propTypes = {
  /** open file picker when fired */
  getRootProps: func.isRequired,
  openDropzone: func.isRequired,
  className: string,
  reason: string.isRequired,
}

export default DropzoneEmptyScreen

import cookie from 'cookie'

import { getCookieByName } from '@/lib/cookies'

/**
 * set document cookie by its key
 * @param {string} key - cookie key
 * @param {string} value - cookie value
 * @param {object} options - serialize options based on cookie library
 */
export const setCookie = (key, value, options) => {
  if (typeof document !== 'undefined') {
    const cookieString = cookie.serialize(key, value, options)
    document.cookie = cookieString
  }
}
/**
 * get cookie by its key if possible
 * @param {string} key - cookie key
 * @return {string|null} - cookie value
 */
export const getCookie = (key) => {
  if (typeof document !== 'undefined') {
    return getCookieByName(document.cookie, key)
  }
}

import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'

export const AlignTitleButton = styled(ToggleButton)`
  border-radius: 4px;
  padding: 6px 8px;
  border: 1px solid ${({ theme }) => theme.colors.sky[300]};
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background-color: ${({ theme }) => theme.colors.sky[100]};
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.sky[300]};
  }
  &.Mui-selected:hover {
    background-color: ${({ theme }) => theme.colors.sky[300]};
  }
`
